import React, { useState } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import AlertsPanel from "../features/alerts-panel/AlertsPanel";
import NoAlert from "../features/alert-form/NoAlert";
import AlertForm from "../features/alert-form/Alert";
import ConfirmationDialog from "../common/Confirmation";
import { initialConfirmDialog } from "../common/Confirmation";

const AlertsPage = (props) => {
    const { container, tablet } = props;
    const [confirmation, setConfirmation] = useState(initialConfirmDialog);

    const resetConfirmation = () => {
        setConfirmation(initialConfirmDialog);
    };

    return (
        <>
            <AlertsPanel
                toggleable={tablet}
                container={container}
                setConfirmation={setConfirmation}
                resetConfirmation={resetConfirmation}
            >
                <ConfirmationDialog {...confirmation} />
                <Switch>
                    <Route
                        path={`/alerts`}
                        exact
                        render={(props) => (
                            <NoAlert {...props} creating={false} />
                        )}
                    />
                    <Route
                        path={`/alerts/create`}
                        exact
                        render={(props) => (
                            <AlertForm {...props} creating={true} />
                        )}
                    />
                    <Route
                        path={`/alerts/:id`}
                        exact
                        render={(props) => (
                            <AlertForm
                                {...props}
                                creating={false}
                                setConfirmation={setConfirmation}
                                resetConfirmation={resetConfirmation}
                            />
                        )}
                    />
                </Switch>
            </AlertsPanel>
        </>
    );
};

export default withRouter(AlertsPage);
