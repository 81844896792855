import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    logoPath: state.customer.config.logo,
});

const CustomerLogo = (props) => {
    const { logoPath, className } = props;
    const image = require(`../${logoPath}`);
    return <img src={image} alt={"logo"} className={className} />;
};

export default connect(mapStateToProps)(CustomerLogo);
