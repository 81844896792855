import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Radio, Typography } from "@material-ui/core";
import {
    CustomDataTable,
    CustomDataTableBody,
    CustomDataTableCell,
    CustomDataTableHead,
    CustomDataTableRow,
} from "../../common/restyled-mui/CustomDataTable";
import Topbar from "./Topbar";
import CenteredLoading from "../../common/CenteredLoading";
import CloseIcon from "../../common/CloseIcon";

const width = 578;

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        width: (props) =>
            props.containerWidth - 40 < width
                ? props.containerWidth - 40
                : width,
        marginRight: 40,
    },
    content: {
        padding: theme.spacing(4, 5, 4, 2),
        display: "flex",
        flex: 1,
        minHeight: 0,
    },
    scrollContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",

        minHeight: 0,
        overflowY: "auto",
    },
    chartContainer: {
        flex: 1,
        display: "flex",
        "&:first-child": {
            marginBottom: theme.spacing(5),
        },
    },
    chartLabel: {
        width: 30,
        display: "flex",
        alignItems: "center",
    },
    label: {
        transform: "rotate(-90deg)",
        display: "inline-block",
    },
    tableContainer: {
        flex: 1,
        marginLeft: theme.spacing(2.5),
    },
    table: {
        width: "calc(100% - 1px)",
    },
    text: {
        paddingBottom: theme.spacing(2),
    },
    button: {
        margin: `${theme.spacing(4)}px auto 0px auto`,
        minWidth: 104,
    },
    dataTableScroll: {
        overflowY: "auto",
        flex: 1,
        minHeight: 0,
    },
    inputRoot: {
        height: "100%",
        backgroundColor: "transparent",
    },
    inputInput: {
        textAlign: "right",
        "-webkit-appearance": "none",
        margin: 0,
    },
    row: {
        backgroundColor: theme.palette.common.white,
    },
    selectedRow: {
        backgroundColor: theme.palette.generalUi.listSelectBackground,
    },

    colorBox: {
        width: 20,
        height: 20,
        position: "absolute",
        top: 8,
        left: 7,
    },
    colorBoxCell: {
        padding: theme.spacing(2.5, 2.5),
        position: "relative",
    },
    colorBoxCellHeader: {
        padding: theme.spacing(1.25, 1.25),
    },
    subRow: {
        borderLeft: "none",
    },
    tableSubtitle: {
        fontSize: 11,
        color: theme.palette.text.defaultSub,
        opacity: 0.8,
    },
}));

const Settings = (props) => {
    const classes = useStyles(props);
    const { parameters, setChartSets, chartSets, close, site } = props;

    const [offshoreSite, setOffshoreSite] = useState(site.isOffshore);
    // const [onshoreOffshoreSwitch, setOnshoreOffshoreSwitch] = useState(false);

    useEffect(() => {
        if (offshoreSite !== site.isOffshore) {
            console.log("change between onshore and offshore");
            // setOnshoreOffshoreSwitch(offshoreSite !== site.isOffshore);
            setOffshoreSite(site.isOffshore);
        }
    }, [site, offshoreSite]);

    //fix sort in chart list so that "upper" charts come first
    let sortedParameters = [];
    if (parameters !== null) {
        sortedParameters = [...parameters];
        sortedParameters.sort((a, b) =>
            a.chartType < b.chartType ? -1 : a.chartType > b.chartType ? 1 : 0
        );
    }

    const changeChartSetSelection = (id, index) => (e) => {
        let newSelection = [...chartSets];
        newSelection[index] = id;
        setChartSets(newSelection);
    };

    const renderOffshoreWarning = (isOffshore) => {
        if (isOffshore) {
            return "\n (Only available for offshore sites)";
        } else {
            return "";
        }
    };

    return (
        <>
            {parameters && chartSets ? (
                <div className={classes.root}>
                    <Topbar
                        title={"Select View Set - For all sites"}
                        subtitle={<CloseIcon onClick={close} />}
                    />
                    <div className={classes.content}>
                        <div className={classes.scrollContainer}>
                            <div>
                                {sortedParameters.map((chart, chartIndex) => {
                                    //calculate if selection needs to be moved to different group
                                    // in case offshore parameters are selected for onshore point
                                    chart.groups.map((group) => {
                                        let selected = Boolean(
                                            chartSets[chart.chartType] ===
                                                group.chartSetId
                                        );
                                        const groupHasOffshoreParam =
                                            group.parameters.reduce(
                                                (prev, cur) => {
                                                    return (
                                                        prev ||
                                                        cur.onlyAvailableForOffshore
                                                    );
                                                },
                                                false
                                            );
                                        if (
                                            groupHasOffshoreParam &&
                                            !site.isOffshore &&
                                            selected
                                        ) {
                                            //set to first chart type that is not offshore
                                            let newSelected = chart.groups.find(
                                                (g) =>
                                                    !g.parameters.reduce(
                                                        (prev, cur) => {
                                                            return (
                                                                prev ||
                                                                cur.onlyAvailableForOffshore
                                                            );
                                                        },
                                                        false
                                                    )
                                            );
                                            let newChartSets = chartSets;
                                            newChartSets[chart.chartType] =
                                                newSelected.chartSetId;
                                            setChartSets(newChartSets);
                                        }
                                        return null;
                                    });

                                    return (
                                        <div
                                            className={classes.chartContainer}
                                            key={chartIndex}
                                        >
                                            <div className={classes.chartLabel}>
                                                <Typography
                                                    variant="h5"
                                                    className={classes.label}
                                                >
                                                    {chart.chartType === 0
                                                        ? "Upper"
                                                        : "Lower"}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.tableContainer
                                                }
                                            >
                                                <CustomDataTable
                                                    className={classes.table}
                                                >
                                                    <CustomDataTableHead>
                                                        <CustomDataTableRow>
                                                            <CustomDataTableCell
                                                                style={{
                                                                    width: 32,
                                                                }}
                                                                align="center"
                                                            >
                                                                Select
                                                            </CustomDataTableCell>
                                                            <CustomDataTableCell
                                                                style={{
                                                                    width: 96,
                                                                }}
                                                            >
                                                                Set
                                                            </CustomDataTableCell>
                                                            <CustomDataTableCell
                                                                style={{
                                                                    width: 16,
                                                                }}
                                                                align="center"
                                                                className={
                                                                    classes.colorBoxCellHeader
                                                                }
                                                            >
                                                                Col
                                                            </CustomDataTableCell>
                                                            <CustomDataTableCell>
                                                                Parameter
                                                            </CustomDataTableCell>
                                                            <CustomDataTableCell>
                                                                Unit
                                                            </CustomDataTableCell>
                                                        </CustomDataTableRow>
                                                    </CustomDataTableHead>
                                                    <CustomDataTableBody>
                                                        {chart.groups.map(
                                                            (group) => {
                                                                let selected =
                                                                    Boolean(
                                                                        chartSets[
                                                                            chart
                                                                                .chartType
                                                                        ] ===
                                                                            group.chartSetId
                                                                    );

                                                                return group.parameters.map(
                                                                    (
                                                                        parameter,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <CustomDataTableRow
                                                                                className={
                                                                                    selected
                                                                                        ? classes.selectedRow
                                                                                        : classes.row
                                                                                }
                                                                                style={
                                                                                    !site.isOffshore &&
                                                                                    parameter.onlyAvailableForOffshore
                                                                                        ? {
                                                                                              opacity: 0.4,
                                                                                          }
                                                                                        : {}
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {index ===
                                                                                    0 && (
                                                                                    <>
                                                                                        <CustomDataTableCell
                                                                                            rowSpan={
                                                                                                group
                                                                                                    .parameters
                                                                                                    .length
                                                                                            }
                                                                                        >
                                                                                            <Radio
                                                                                                size={
                                                                                                    "small"
                                                                                                }
                                                                                                color={
                                                                                                    "primary"
                                                                                                }
                                                                                                checked={
                                                                                                    selected
                                                                                                }
                                                                                                disabled={
                                                                                                    !site.isOffshore &&
                                                                                                    parameter.onlyAvailableForOffshore
                                                                                                }
                                                                                                onChange={changeChartSetSelection(
                                                                                                    group.chartSetId,
                                                                                                    chart.chartType
                                                                                                )}
                                                                                            />
                                                                                        </CustomDataTableCell>
                                                                                        <CustomDataTableCell
                                                                                            rowSpan={
                                                                                                group
                                                                                                    .parameters
                                                                                                    .length
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                group.chartSetName
                                                                                            }
                                                                                            <Typography
                                                                                                variant="subtitle1"
                                                                                                className={
                                                                                                    classes.tableSubtitle
                                                                                                }
                                                                                            >
                                                                                                {renderOffshoreWarning(
                                                                                                    parameter.onlyAvailableForOffshore
                                                                                                )}
                                                                                            </Typography>
                                                                                        </CustomDataTableCell>
                                                                                    </>
                                                                                )}
                                                                                <CustomDataTableCell
                                                                                    className={
                                                                                        classes.colorBoxCell
                                                                                    }
                                                                                >
                                                                                    <span
                                                                                        className={
                                                                                            classes.colorBox
                                                                                        }
                                                                                        style={{
                                                                                            backgroundColor: `#${parameter.color}`,
                                                                                        }}
                                                                                    />
                                                                                </CustomDataTableCell>
                                                                                <CustomDataTableCell>
                                                                                    {
                                                                                        parameter.displayName
                                                                                    }
                                                                                </CustomDataTableCell>
                                                                                <CustomDataTableCell>
                                                                                    {
                                                                                        parameter.unit
                                                                                    }
                                                                                </CustomDataTableCell>
                                                                            </CustomDataTableRow>
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        )}
                                                    </CustomDataTableBody>
                                                </CustomDataTable>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <CenteredLoading />
            )}
        </>
    );
};

export default Settings;
