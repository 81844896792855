import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { PrimaryButton } from "../common/restyled-mui/CustomButton";

const useStyles = makeStyles((theme) => ({
    centered: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
}));

const Unauthorized = (props) => {
    const classes = useStyles(props);
    const { goToHomepage } = props;

    return (
        <div className={classes.centered}>
            <Typography variant="h2">Unauthorized</Typography>
            <br />
            <PrimaryButton onClick={goToHomepage}>Frontpage</PrimaryButton>
        </div>
    );
};

export default Unauthorized;
