import React from "react";
import {
    makeStyles,
    Typography,
    Grid,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import {
    CustomInputLabel,
    CustomTextField,
    CustomSelect,
} from "../../common/restyled-mui/CustomInputs";
import { GreenSwitch } from "../../common/restyled-mui/GreenSwitch";
import RecipientInputs from "./form-inputs/RecipientInputs";
import SendingTime from "./form-inputs/SendingTime";
import Timezone from "./form-inputs/Timezone";
import SendingDays from "./form-inputs/SendingDays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSettingsStyles } from "../../styles/settingsStyles";
import { calendarIcon } from "../../assets/icons/fontawesome/calendarIcon";
import ChartSelection from "./form-inputs/ChartsSelection";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    formContainer: {
        padding: theme.spacing(3, 7),
        maxWidth: 700,
    },
    textFieldGridContainer: {
        display: "flex",
        flexDirection: "column",
    },
    textFieldFormContainer: {
        flex: 1,
    },
    textField: {
        height: "100%",
        paddingBottom: theme.spacing(1),
        display: "block",
    },
    endAdornmentIcon: {
        height: 21,
        width: 21,
        color: theme.palette.generalUi.icons,
    },
    numberInputInput: {
        "-webkit-appearance": "none",
        margin: 0,
        /* Chrome, Safari, Edge, Opera */
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        /* Firefox */
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
    },
    calendarField: {
        paddingRight: 0,
    },
}));

const ReportForm = (props) => {
    const classes = useStyles();
    const {
        reportTypes,
        sites,
        title,
        report,
        handlePropertyChange,
        validation,
        charts,
    } = props;

    const handleTextInputChange = (state) => (e) => {
        handlePropertyChange(state)(e.target.value);
    };

    const toggleProperty = (state) => (e) => {
        handlePropertyChange(state)(!report[state]);
    };

    const handleDateChange = (state) => (date, value) => {
        if (value) {
            handlePropertyChange(state)(value);
        }
    };

    return (
        <Grid container spacing={4} className={classes.formContainer}>
            <Grid item xs={12}>
                <div className={classes.titleContainerDetails}>
                    <Typography variant="h2">{title}</Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <GreenSwitch
                                    checked={report.isActive}
                                    onChange={toggleProperty("isActive")}
                                />
                            }
                            label="Active"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6} lg={6}>
                                <CustomInputLabel>Report Name</CustomInputLabel>
                                <CustomTextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    type="text"
                                    onChange={handleTextInputChange("name")}
                                    value={report.name}
                                    inputProps={{ maxLength: 50 }}
                                    error={!validation.name.valid}
                                    helperText={validation.name.message}
                                    name={"reportName"}
                                />
                                <CustomInputLabel>Site</CustomInputLabel>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                >
                                    <Autocomplete
                                        options={sites.map((x) => x.id)}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        autoHighlight
                                        value={report.pointId}
                                        onChange={(e, value) =>
                                            handlePropertyChange("pointId")(
                                                value
                                            )
                                        }
                                        getOptionLabel={(option) =>
                                            sites.find(
                                                (site) =>
                                                    parseInt(site.id) === option
                                            )?.name
                                        }
                                        renderInput={(params) => {
                                            return (
                                                <CustomTextField
                                                    {...params}
                                                    name={"site"}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type="text"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        autoComplete:
                                                            "new-password", // disable autocomplete and autofill
                                                    }}
                                                    error={
                                                        !validation.pointId
                                                            .valid
                                                    }
                                                    helperText={
                                                        validation.pointId
                                                            .message
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                className={classes.textFieldGridContainer}
                            >
                                <CustomInputLabel>Description</CustomInputLabel>
                                <CustomTextField
                                    name={"description"}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    type="text"
                                    multiline
                                    rows={5}
                                    className={classes.textFieldFormContainer}
                                    InputProps={{
                                        className: classes.textField,
                                    }}
                                    onChange={handleTextInputChange(
                                        "description"
                                    )}
                                    value={report.description}
                                    inputProps={{
                                        maxLength: 200,
                                        className: classes.textArea,
                                    }}
                                    error={!validation.description.valid}
                                    helperText={validation.description.message}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            className={classes.formSubtitle}
                        >
                            Report Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6} lg={6}>
                                <CustomInputLabel>Report Type</CustomInputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    error={!validation.reportTypeId.valid}
                                >
                                    <CustomSelect
                                        inputProps={{
                                            margin: "dense",
                                        }}
                                        value={report.reportTypeId}
                                        onChange={handleTextInputChange(
                                            "reportTypeId"
                                        )}
                                        renderValue={(x) =>
                                            reportTypes.find((y) => y.id === x)
                                                ?.type
                                        }
                                        name={"reportTypeId"}
                                    >
                                        {reportTypes.map((reportType) => {
                                            return (
                                                <MenuItem
                                                    key={reportType.id}
                                                    value={reportType.id}
                                                    className={
                                                        classes.selectMenuItem
                                                    }
                                                >
                                                    {reportType.type}
                                                </MenuItem>
                                            );
                                        })}
                                    </CustomSelect>
                                    {validation.reportTypeId.message && (
                                        <FormHelperText>
                                            {validation.reportTypeId.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <CustomInputLabel>
                                    Forecast Length (days)
                                </CustomInputLabel>
                                <CustomTextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    onChange={handleTextInputChange(
                                        "daysForecast"
                                    )}
                                    value={report.daysForecast}
                                    InputProps={{
                                        classes: {
                                            input: classes.numberInputInput,
                                        },
                                    }}
                                    error={!validation.daysForecast.valid}
                                    helperText={validation.daysForecast.message}
                                    name={"daysForecast"}
                                />
                                <CustomInputLabel>
                                    Sending Times
                                </CustomInputLabel>
                                <SendingTime
                                    validation={validation.sendTime}
                                    sendTime={report.sendTime}
                                    updateSendTimes={handlePropertyChange(
                                        "sendTime"
                                    )}
                                />
                                <CustomInputLabel>
                                    Recipient Addresses
                                </CustomInputLabel>
                                <RecipientInputs
                                    validation={validation.recipients}
                                    recipients={report.recipients}
                                    updateRecipients={handlePropertyChange(
                                        "recipients"
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <CustomInputLabel>Expiry Date</CustomInputLabel>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    style={{ width: 164 }}
                                    margin="dense"
                                    value={report.expireDate}
                                    onChange={handleDateChange("expireDate")}
                                    disablePast
                                    maxDate={moment().add(5, "y").toDate()}
                                    format="yyyy-MM-DD"
                                    InputProps={{
                                        className: classes.calendarField,
                                    }}
                                    keyboardIcon={
                                        <FontAwesomeIcon
                                            icon={calendarIcon}
                                            className={classes.endAdornmentIcon}
                                        />
                                    }
                                    error={!validation.expireDate.valid}
                                    helperText={validation.expireDate.message}
                                />
                                <CustomInputLabel>
                                    Report Timezone
                                </CustomInputLabel>
                                <Timezone
                                    value={report.inLocalTime}
                                    handleChange={handlePropertyChange(
                                        "inLocalTime"
                                    )}
                                />
                                <CustomInputLabel>
                                    Sending Days
                                </CustomInputLabel>
                                <SendingDays
                                    days={report.sendDays}
                                    handleDayChange={(value) =>
                                        handlePropertyChange("sendDays")(value)
                                    }
                                    error={!validation.sendDays.valid}
                                    helperText={validation.sendDays.message}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.formSubtitle}>
                    Charts
                </Typography>
                <ChartSelection
                    charts={charts.list}
                    selectedCharts={report.reportCharts}
                    setSelectedCharts={handlePropertyChange("reportCharts")}
                    error={!validation.reportCharts.valid}
                    helperText={validation.reportCharts.message}
                />
            </Grid>
        </Grid>
    );
};
export default ReportForm;
