import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const SimpleTable = withStyles((theme) => ({
    root: {},
}))(Table);

export const SimpleTableHead = withStyles((theme) => ({
    root: {},
}))(TableHead);

export const SimpleTableBody = withStyles((theme) => ({
    root: {},
}))(TableBody);

export const SimpleTableCell = withStyles((theme) => ({
    root: {},
    head: {
        padding: theme.spacing(0.5),
        color: theme.palette.text.defaultSub,
        textTransform: "uppercase",
        fontSize: 10,
        lineHeight: 1.3,
        fontWeight: 600,
        borderBottom: `${theme.palette.generalUi.borders} 1px solid`,
    },
    body: {
        color: theme.palette.text.default,
        fontSize: 10,
        lineHeight: 1.4,
        borderBottom: "none",
        fontWeight: 600,
        "&:first-child": {
            fontWeight: 400,
            color: theme.palette.text.defaultSub,
        },
    },
    sizeSmall: {
        padding: theme.spacing(0.5),
    },
}))(TableCell);

export const SimpleTableContainer = withStyles((theme) => ({
    root: {},
}))(TableContainer);

export const SimpleTableRow = withStyles((theme) => ({
    root: {},
}))(TableRow);
