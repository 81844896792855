import { USER } from "../types";

export function logout() {
    return {
        type: USER.LOGOUT,
    };
}

export const rolesAndPermissionsLoaded = (rp) => {
    return (dispatch) => {
        dispatch({
            type: USER.LOADED,
            payload: {
                isUser: checkRole(rp.roles, "User"),
                isMultiUser: checkRole(rp.roles, "MultiUser"),
                isAdministrator: checkRole(rp.roles, "Administrator"),
                permissions: rp.permissions,
                mainPage: rp.mainPage,
            },
        });
    };
};

function checkRole(roles, roleName) {
    return roles.find((r) => r === roleName) != null;
}
