import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { savePDF } from "@progress/kendo-react-pdf";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import Topbar from "./Topbar";
import Api from "../../api/index";
import CloseIcon from "../../common/CloseIcon";
import { isMobile } from "react-device-detect";

const width = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: (props) =>
            props.containerWidth - 40 < width
                ? props.containerWidth - 40
                : width,
        marginRight: 40,
    },
    content: {
        padding: theme.spacing(6.5, 5),
        display: "flex",
        flexDirection: "column",
    },
    text: {
        paddingBottom: theme.spacing(2),
    },
    button: {
        margin: `${theme.spacing(4)}px auto 0px auto`,
        minWidth: 104,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.common.white,
        fontWeight: 600,
    },
    pdfExport: {
        textAlign: "center",
        paddingTop: theme.spacing(4),
    },
}));

const Export = (props) => {
    const classes = useStyles(props);
    const {
        setPDFDate,
        pdfDate,
        setPDFReady,
        pdfReady,
        days,
        site,
        localTimezone,
        timezoneOffset,
        dataAvailable,
        close,
    } = props;

    const exportToCSV = () => {
        Api.getCSVReport(site.id, localTimezone, days).then((res) => {
            const element = document.createElement("a");
            const file = new Blob([res.data], { type: "text/csv" });
            element.href = URL.createObjectURL(file);
            element.download =
                site.name +
                "_" +
                moment
                    .utc()
                    .add(timezoneOffset, "milliseconds")
                    .format("YYYY-MM-DD_HHmm") +
                ".csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        });
    };

    const exportPDFWithMethod = () => {
        const currentDate = moment
            .utc()
            .add(timezoneOffset, "milliseconds")
            .format("DD-MM-YYYY HH:mm");
        setPDFDate(currentDate);
    };

    useEffect(() => {
        if (pdfDate && pdfReady) {
            // timeout is to ensure chart is drawn before pdf printed. currently, there is not a predictable callback available
            // that will be called when all of the elements are fully drawn.
            setTimeout(() => {
                savePDF(
                    document.getElementById("chart-pdf-container"),
                    {
                        paperSize: "auto",
                        margin: 40,
                        fileName: (
                            site.name +
                            "_" +
                            moment
                                .utc()
                                .add(timezoneOffset, "milliseconds")
                                .format("YYYY-MM-DD_HHmm") +
                            ".pdf"
                        ).replaceAll(" ", "_"),
                    },
                    () => {
                        setPDFDate(null);
                        setPDFReady(false);
                    }
                );
            }, 1000);
        }
    }, [pdfDate, pdfReady, setPDFReady, setPDFDate, site.name, timezoneOffset]);

    return (
        <>
            <div className={classes.root}>
                <Topbar
                    title={"Export"}
                    subtitle={<CloseIcon onClick={close} />}
                />
                <div className={classes.content}>
                    <Typography variant="body1" className={classes.text}>
                        Download a CSV or PDF file with data as defined in the
                        current chart view.
                    </Typography>
                    <CustomButton
                        disableElevation
                        disabled={!dataAvailable}
                        variant="contained"
                        className={classes.button}
                        onClick={exportToCSV}
                    >
                        Save CSV
                    </CustomButton>
                    <CustomButton
                        disableElevation
                        disabled={!dataAvailable}
                        variant="contained"
                        className={classes.button}
                        onClick={exportPDFWithMethod}
                    >
                        Save PDF
                    </CustomButton>
                    {isMobile && (
                        <Typography
                            variant="subtitle1"
                            className={classes.pdfExport}
                        >
                            PDF Export is not supported on mobile devices.
                        </Typography>
                    )}
                </div>
            </div>
        </>
    );
};

export default Export;
