import {
    List,
    ListItem,
    Typography,
    ListItemSecondaryAction,
    ListItemText,
    withStyles,
    makeStyles,
    FormHelperText,
} from "@material-ui/core";
import { CustomCheckbox } from "../../../common/restyled-mui/CustomInputs";
import React from "react";

export const CustomList = withStyles((theme) => ({
    root: {
        padding: 0,
    },
}))(List);
export const CustomListItem = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 4),
    },
    container: {
        "&:nth-child(odd)": {
            backgroundColor: theme.palette.table.cellBg,
        },
        borderBottom: `1px solid ${theme.palette.table.cellBorder}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
}))(ListItem);

export const CustomListItemText = withStyles((theme) => ({
    root: {},
}))(ListItemText);

export const CustomListItemSecondaryAction = withStyles((theme) => ({
    root: {},
}))(ListItemSecondaryAction);

const useStyles = makeStyles((theme) => ({
    listContainer: {
        height: 266,
        overflowY: "auto",
        border: `1px solid ${theme.palette.table.cellBorder}`,
    },
    checkbox: {
        margin: 0,
    },
}));

const ChartSelection = (props) => {
    const classes = useStyles(props);
    const { charts, selectedCharts, setSelectedCharts, error, helperText } =
        props;

    const handleChartClick = (chart) => (e) => {
        if (selectedCharts.some((x) => x.chartId === chart.chartId)) {
            setSelectedCharts([
                ...selectedCharts.filter((x) => x.chartId !== chart.chartId),
            ]);
        } else {
            setSelectedCharts([...selectedCharts, chart]);
        }
    };
    return charts.length > 0 ? (
        <>
            <div className={classes.listContainer}>
                <CustomList>
                    {charts.map((chart, index) => {
                        const selected = selectedCharts.some(
                            (x) => x.chartId === chart.chartId
                        );
                        return (
                            <CustomListItem key={index}>
                                <CustomListItemText primary={chart.name} />
                                <CustomListItemSecondaryAction>
                                    <CustomCheckbox
                                        checked={selected}
                                        onClick={handleChartClick(chart)}
                                        className={classes.checkbox}
                                    />
                                </CustomListItemSecondaryAction>
                            </CustomListItem>
                        );
                    })}
                </CustomList>
            </div>
            <FormHelperText error={error}>{helperText}</FormHelperText>
        </>
    ) : (
        <Typography>Please select a site</Typography>
    );
};

export default ChartSelection;
