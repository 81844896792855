import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../src/authConfig";
import { Button } from "@material-ui/core";

function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch((e) => {
        console.error("Failed to process AAD login popup: " + e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button
            variant={"contained"}
            color={"primary"}
            disableElevation
            className="ml-auto"
            style={{ width: 260 }}
            onClick={() => handleLogin(instance)}
        >
            Login using SSO
        </Button>
    );
};
