import React, { useEffect, useState } from "react";
import { useTable, useBlockLayout, useColumnOrder } from "react-table";

import moment from "moment";
import _ from "lodash";

import { useSticky } from "react-table-sticky";

import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { connect } from "react-redux";
import { getConfig } from "../../redux/actions/config";
import { ifDefined } from "../../utils/values";

const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
    style: {
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // change background colour if dragging
        ...(isDragging && { backgroundColor: "rgba(144, 238, 144, 1)" }),
    },
    ...(!isDragging && { transform: "translate(0,0)" }),
    ...(isDropAnimating && { transitionDuration: "0.001s" }),

    // styles we need to apply on draggables
});

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
    };
};

const Styles = styled.div`
    /* This is required to make the table full-width */
    display: flex;

    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    background-color: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 16px;

    font: 400 12px/13px Open sans, Regular;
    white-space: pre-wrap;
    text-align: center;

    .table {
        border: 1px solid #ddd;

        width: calc(100vw - 400px);

        height: 400px;
        margin: auto;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        .tr {
            min-width: 100%;
            :last-child {
                .td {
                    border-bottom: 1px solid #ccc;
                }
            }
        }

        .header .wind {
            background-color: rgba(173, 216, 230, 1);
        }
        .header .wave {
            background-color: lightsalmon;
        }
        .header .currents {
            background-color: lightsalmon;
        }

        .th,
        .td {
            padding: 5px;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background-color: #fff;
            overflow: hidden;

            :last-child {
                border-right: 0;
            }

            :not([data-sticky-td]) {
                flex-grow: 1;
            }

            .resizer {
                display: inline-block;
                width: 5px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;

                &.isResizing {
                    background: red;
                }
            }
        }

        .threshold-ok {
            background-color: rgba(76, 175, 81, 1);
        }
        .threshold-warning {
            background-color: #fff000;
        }
        .threshold-critical {
            background-color: rgb(198, 40, 40, 1);
        }

        &.sticky {
            overflow: scroll;

            .header,
            .footer {
                position: sticky;
                z-index: 1;
            }

            .header {
                top: 0;
                box-shadow: 0px 0px 0px #ccc;
                height: 63px;
            }
            .footer {
                top: 63px;
                box-shadow: 0px 0px 0px #ccc;
            }
            .tbody {
                position: relative;
                z-index: 0;
            }

            [data-sticky-td] {
                position: sticky;
            }

            [data-sticky-last-left-td] {
                box-shadow: 0px 0px 0px #ccc;
            }

            [data-sticky-first-right-td] {
                box-shadow: 0px 0px 0px #ccc;
            }
        }

        &.mapOpen {
            width: calc(100vw - 400px - 34vw);
        }

        &.mobile {
            width: calc(100vw - 84px);
        }
    }
`;

const filterDataColumnsList = [
    //"Surface Elevation",
    //"Surface pressure",
    //   "Prob. wind speed > 5 m/s at 100 m",
    //  "Prob. wind gust > 10 m/s at 100 m",
    // "Prob. wind gust > 15 m/s at 100 m",
];
const generateThresholdString = (p) => {
    let min = "";
    let max = "";
    //let tol = "(+/-0)";
    let limits = "";
    if (
        p !== undefined &&
        (p.thresholdMax !== null || p.thresholdMin !== null)
    ) {
        if (p.thresholdMin !== null) {
            min = p.thresholdMin;
        }
        if (p.thresholdMax !== null) {
            max = p.thresholdMax;
        }

        if (max !== "") {
            limits = "Max: " + max;
        }
        if (min !== "") {
            limits += "\nMin: " + min;
        }
    }
    return limits;
};

const generateColumnList = (
    siteData,
    thresholdsVisible,
    parameters,
    filterDataColumnsList
) => {
    return [].concat
        .apply(
            [
                {
                    accessor: "Time",
                    Header: "Time",
                    Footer: "Thresholds",
                    sticky: "left",
                },
            ],
            siteData.chartSets.map((cs) => {
                return cs.groups.map((e) => {
                    if (e.groupName != null) {
                        if (
                            filterDataColumnsList.find(
                                (item) => item === e.groupName
                            ) === undefined
                        ) {
                            let p = parameters.find(
                                (p) => p.displayName === e.groupName
                            );

                            let limits = generateThresholdString(p);

                            let decimalPlaces =
                                ["Visibility", "Wave Height"].indexOf(
                                    e.groupName
                                ) > -1
                                    ? 2
                                    : e.groupName
                                          .toLowerCase()
                                          .search("direction") > -1
                                    ? 0
                                    : 1;

                            return {
                                accessor: e.groupName
                                    .replaceAll(" ", "_")
                                    .replaceAll(".", "_"),
                                Header: e.groupName + "\n[" + e.unit + "]",

                                Footer: limits,
                                Cell: (props) => {
                                    return (
                                        <>
                                            {props.value !== undefined &&
                                            !Number.isNaN(props.value) &&
                                            props.value !== "NaN"
                                                ? props.value.toFixed(
                                                      decimalPlaces
                                                  )
                                                : "-"}
                                        </>
                                    );
                                },
                            };
                        }
                        return <></>;
                    } else {
                        return {
                            accessor: "timestampStatus",
                            Header: "timestampStatus",
                            Footer: "timestampStatus",
                        };
                    }
                });
            })
        )
        .filter((e) => e !== undefined);
};

const reshapeSiteDataToTableData = (siteData, timezoneOffset) => {
    let data = {};
    siteData.chartSets.map((cs) => {
        return cs.groups.map((g) => {
            return g.data.map((e) => {
                let time = moment.unix(e.time).utc();
                if (time.minute !== 0) {
                    if (data[e.time] === undefined) {
                        data[e.time] = {};
                        data[e.time]["Time"] =
                            moment
                                .unix(e.time + timezoneOffset / 1000)
                                .utc()
                                .format("MMM-DD HH") + "h";
                    }
                    if (g.groupName !== null) {
                        if (g.groupName === "Time") {
                            data[e.time][g.groupName.replaceAll(" ", "_")] =
                                moment.unix(e.time).utc().format("MMM-DD H") +
                                "h";
                        } else {
                            data[e.time][
                                g.groupName
                                    .replaceAll(" ", "_")
                                    .replaceAll(".", "_")
                            ] = e.value;
                        }
                    } else {
                        data[e.time]["timestampStatus"] = e.value;
                    }
                }
                return data;
            });
        });
    });
    return Object.entries(data).map(([key, value]) => value);
};

const createFlatParameters = (parameters) => {
    let flatParameters = [];
    parameters.forEach((chart) =>
        chart.groups.forEach((group) =>
            group.parameters.forEach((parameter) =>
                flatParameters.push({
                    ...parameter,
                    version: parameter.twinChartParameterId,
                })
            )
        )
    );
    return _.orderBy(flatParameters, "displayName");
};

const ForecastTable = (props) => {
    //const classes = useStyles(props);
    const theme = useTheme();

    const {
        siteData,
        parameters,
        timezoneOffset,
        thresholdsVisible,
        getConfig,
        config,
        setPDFReady,
    } = props;

    const [mapOpen, setMapOpen] = useState(false);

    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        ifDefined(
            getConfig(
                `${config.username}_sitedetails_mapopen`,
                undefined,
                true
            ),
            (value) => {
                const bvalue = value === "true";
                if (mapOpen !== bvalue) {
                    setMapOpen(bvalue);
                }
            }
        );
    }, [config, getConfig, mapOpen]);

    const [currentParameters, setCurrentParameters] = useState(
        createFlatParameters(parameters)
    );

    useEffect(() => {
        setCurrentParameters(createFlatParameters(parameters));
    }, [parameters]);

    const data = React.useMemo(() => {
        return reshapeSiteDataToTableData(siteData, timezoneOffset);
    }, [siteData, timezoneOffset]);

    const columns = React.useMemo(() => {
        return generateColumnList(
            siteData,
            thresholdsVisible,
            currentParameters,
            filterDataColumnsList
        );
    }, [siteData, thresholdsVisible, currentParameters]);

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 80,
            maxWidth: 200,
        }),
        []
    );

    //Vestas version => warning = threshold +/- tolerance
    //Conwx version => warning = min_threshold - tolerance, max_threshold + tolerance
    const getClassNameFromThresholdVestas = (value, colname, params) => {
        let p = params.find(
            (e) => e.displayName.replaceAll(" ", "_") === colname
        );

        if (p !== undefined) {
            if (p.thresholdMin === null && p.thresholdMax === null) {
                return {};
            }

            if (p.thresholdMax !== null) {
                let max_upper = p.thresholdMax;
                let max_lower = p.thresholdMax;
                if (p.tolerance !== null) {
                    if (p.toleranceType === 1) {
                        max_upper *= 1 + p.tolerance / 100;
                        max_lower *= 1 - p.tolerance / 100;
                    }
                    if (p.toleranceType === 0) {
                        max_upper += p.tolerance;
                        max_lower -= p.tolerance;
                    }
                }

                if (value >= max_upper) {
                    return { className: "threshold-critical" };
                }
                if (value >= max_lower && value < max_upper) {
                    return { className: "threshold-warning" };
                }
            }
            if (p.thresholdMin !== null) {
                let min_upper = p.thresholdMin;
                let min_lower = p.thresholdMin;

                if (p.tolerance !== null) {
                    if (p.toleranceType === 1) {
                        min_upper *= 1 + p.tolerance / 100;
                        min_lower *= 1 - p.tolerance / 100;
                    }
                    if (p.toleranceType === 0) {
                        min_upper += p.tolerance;
                        min_lower -= p.tolerance;
                    }
                }
                if (value > min_lower && value <= min_upper) {
                    return { className: "threshold-warning" };
                }
                if (value <= min_lower) {
                    return { className: "threshold-critical" };
                }
            }
            return { className: "threshold-ok" };
        }
        return {};
    };

    const getClassNameFromThresholdRegular = (value, colname, params) => {
        let p = params.find(
            (e) => e.displayName.replaceAll(" ", "_") === colname
        );

        if (p !== undefined) {
            if (p.thresholdMin === null && p.thresholdMax === null) {
                return {};
            }

            if (p.thresholdMax !== null) {
                let max_upper = p.thresholdMax;
                let max_lower = p.thresholdMax;
                if (p.tolerance !== null) {
                    if (p.toleranceType === 1) {
                        max_upper *= 1 + p.tolerance / 100;
                    }
                    if (p.toleranceType === 0) {
                        max_upper += p.tolerance;
                    }
                }

                if (value > max_upper) {
                    return { className: "threshold-critical" };
                }
                if (value >= max_lower && value <= max_upper) {
                    return { className: "threshold-warning" };
                }
            }
            if (p.thresholdMin !== null) {
                let min_upper = p.thresholdMin;
                let min_lower = p.thresholdMin;

                if (p.tolerance !== null) {
                    if (p.toleranceType === 1) {
                        min_lower *= 1 - p.tolerance / 100;
                    }
                    if (p.toleranceType === 0) {
                        min_lower -= p.tolerance;
                    }
                }
                if (value >= min_lower && value <= min_upper) {
                    return { className: "threshold-warning" };
                }
                if (value < min_lower) {
                    return { className: "threshold-critical" };
                }
            }
            return { className: "threshold-ok" };
        }
        return {};
    };

    const getClassNameFromThreshold = (value, colname, params) => {
        return "vestas" ===
            `${process.env.REACT_APP_DEPLOYMENT_CUSTOMER}`.toLowerCase()
            ? getClassNameFromThresholdVestas(value, colname, params)
            : getClassNameFromThresholdRegular(value, colname, params);
    };

    const getCellProps = (cellInfo, parameters) => {
        if (cellInfo.column.Header !== "Time") {
            return getClassNameFromThreshold(
                cellInfo.value,
                cellInfo.column.id,
                parameters
            );
        } else {
            let tsCell = cellInfo.row.allCells.find(
                (e) => e.column.Header === "timestampStatus"
            );
            if (tsCell !== undefined) {
                return {
                    className:
                        tsCell.value === 0
                            ? "threshold-ok"
                            : tsCell.value === 1
                            ? "threshold-warning"
                            : "threshold-critical",
                };
            }
        }
    };
    const getColumnProps = (column) => {
        if (
            column.Header.toLowerCase().indexOf("wind") > -1 &&
            column.Header.toLowerCase().indexOf("chill") === -1
        ) {
            return {
                className: "wind",
            };
        }
        if (
            column.Header.toLowerCase().indexOf("wave") > -1 ||
            column.Header.toLowerCase().indexOf("swell") > -1
        ) {
            return {
                className: "wave",
            };
        }
        if (column.Header.toLowerCase().indexOf("current") > -1) {
            return {
                className: "currents",
            };
        }
        return {};
    };

    const getHeaderProps = (column) => ({});
    const getFooterProps = (column) => {
        return column.Footer === "Thresholds"
            ? {
                  "data-sticky-td": "true",
                  "data-sticky-last-left-td": "true",
                  style: {
                      position: "sticky",
                      left: 0,
                      zIndex: 3,
                  },
              }
            : {};
    };

    const tableInstance = useTable(
        {
            columns: columns,
            data: data,
            defaultColumn: defaultColumn,

            getColumnProps: getColumnProps,
            getHeaderProps: getHeaderProps,
            getCellProps: getCellProps,
            initialState: {
                hiddenColumns: [
                    "timestampStatus",
                    //    "Prob._wind_speed_>_5_m/s_at_100_m",
                    //   "Prob._wind_gust_>_10_m/s_at_100_m",
                    //  "Prob._wind_gust_>_15_m/s_at_100_m",
                ],
            },
        },
        useColumnOrder,
        useBlockLayout,
        useSticky
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        flatHeaders,
        setColumnOrder,
    } = tableInstance;

    const currentColOrder = React.useRef();

    setPDFReady(true);

    return (
        <Styles>
            <div
                {...getTableProps()}
                className={
                    "table sticky " +
                    (mapOpen ? " mapOpen" : "") +
                    (mobile || tablet ? " mobile " : "")
                }
            >
                <div className="header">
                    {headerGroups.map((headerGroup) => (
                        <DragDropContext
                            onDragStart={() => {
                                currentColOrder.current = flatHeaders.map(
                                    (o) => o.id
                                );
                            }}
                            onDragUpdate={(dragUpdateObj, b) => {
                                const colOrder = [...currentColOrder.current];
                                const sIndex = dragUpdateObj.source.index;
                                const dIndex =
                                    dragUpdateObj.destination &&
                                    dragUpdateObj.destination.index;
                                if (
                                    typeof sIndex === "number" &&
                                    typeof dIndex === "number"
                                ) {
                                    colOrder.splice(sIndex, 1);
                                    colOrder.splice(
                                        dIndex,
                                        0,
                                        dragUpdateObj.draggableId
                                    );
                                    setColumnOrder(colOrder);
                                }
                            }}
                        >
                            <Droppable
                                droppableId="droppable"
                                direction="horizontal"
                            >
                                {(droppableProvided, snapshot) => (
                                    <div
                                        {...headerGroup.getHeaderGroupProps()}
                                        ref={droppableProvided.innerRef}
                                        className="tr"
                                    >
                                        {headerGroup.headers.map(
                                            (column, index) => (
                                                <Draggable
                                                    key={column.id}
                                                    draggableId={column.id}
                                                    index={index}
                                                    isDragDisabled={
                                                        column.accessor ===
                                                            "Time" ||
                                                        !column.accessor
                                                    }
                                                >
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                // {...extraProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...column.getHeaderProps(
                                                                    [
                                                                        {
                                                                            className:
                                                                                "th header",
                                                                        },
                                                                        getColumnProps(
                                                                            column
                                                                        ),
                                                                        getItemStyle(
                                                                            snapshot,
                                                                            provided
                                                                                .draggableProps
                                                                                .style
                                                                        ),
                                                                    ]
                                                                )}
                                                                /*
                                                                style={{
                                                                    ...getItemStyle(
                                                                        snapshot,
                                                                        provided
                                                                            .draggableProps
                                                                            .style
                                                                    ),
                                                                }}
                                                                */
                                                            >
                                                                {column.render(
                                                                    "Header"
                                                                )}
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            )
                                        )}
                                        {/* {droppableProvided.placeholder} */}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ))}
                </div>

                {thresholdsVisible && (
                    <div className="footer">
                        {footerGroups.map((group) => (
                            <div
                                role="row"
                                {...group.getFooterGroupProps()}
                                className="tr"
                            >
                                {group.headers.map((column) => (
                                    <div
                                        role="cell"
                                        {...column.getFooterProps([
                                            {
                                                className: "td footer",
                                            },
                                            getFooterProps(column),
                                        ])}
                                    >
                                        {column.render("Footer")}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}

                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            {...cell.getCellProps([
                                                {
                                                    className: "td",
                                                },
                                                // getColumnProps(cell.column),
                                                getCellProps(
                                                    cell,
                                                    currentParameters
                                                ),
                                            ])}
                                        >
                                            {cell.render("Cell")}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Styles>
    );
};

export default connect(mapStateToProps, { getConfig })(ForecastTable);
