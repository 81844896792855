import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const Sites = {
    getSites() {
        return axios({
            method: 'get',
            url: `${API_URL}/sites`,
        });
    },
    getFavoriteSites() {
        return axios({
            method: 'get',
            url: `${API_URL}/sites/favorites`,
        });
    }, 
    getSearchSites(field) {
        return axios({
            method: 'get',
            url: `${API_URL}/sites?searchQuery=${field}`,
        });
    },
    addFavoriteSite(id) {
        return axios({
            method: 'put',
            url: `${API_URL}/sites/favorites/${id}`,
        });
    },
    removeFavoriteSite(id) {
        return axios({
            method: 'delete',
            url: `${API_URL}/sites/favorites/${id}`,
        });
    },
    getSite(id) {
        return axios({
            method: 'get',
            url: `${API_URL}/sites/${id}`,
        });
    },
    getSiteForecasts(id, days) {
        return axios({
            method: 'get',
            url: `${API_URL}/forecasts/${id}?days=${days}`,
        });
    },
    getSiteForecastsWithParams(id, days, params) {
        return axios({
            method: 'post',
            url: `${API_URL}/forecasts/${id}?days=${days}`,
            data: params
        });
    },
    getSiteForecastsPreview(id, count) {
        return axios({
            method: 'get',
            url: `${API_URL}/forecasts/preview/${id}?forecastCount=${count}`,
        });
    },
}

export default Sites;