import { USER } from "../types";

export const userObj = {
    isUser: undefined,
    isMultiUser: undefined,
    isAdministrator: undefined,
    permissions: [],
    mainPage: undefined,
};

export function user(state = userObj, action) {
    const { type, payload } = action;

    switch (type) {
        case USER.LOADED:
            return {
                ...state,
                isUser: payload.isUser,
                isMultiUser: payload.isMultiUser,
                isAdministrator: payload.isAdministrator,
                permissions: payload.permissions,
                mainPage: payload.mainPage,
            };

        default:
            return state;
    }
}
