import React, { useState } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import ReportsPanel from "../features/reports-panel/ReportsPanel";
import NoReport from "../features/report-form/NoReport";
import ReportForm from "../features/report-form/Report";
import ConfirmationDialog from "../common/Confirmation";
import { initialConfirmDialog } from "../common/Confirmation";

const ReportsPage = (props) => {
    const { container, tablet } = props;
    const [confirmation, setConfirmation] = useState(initialConfirmDialog);

    const resetConfirmation = () => {
        setConfirmation(initialConfirmDialog);
    };

    return (
        <>
            <ReportsPanel
                toggleable={tablet}
                container={container}
                setConfirmation={setConfirmation}
                resetConfirmation={resetConfirmation}
            >
                <ConfirmationDialog {...confirmation} />
                <Switch>
                    <Route
                        path={`/reports`}
                        exact
                        render={(props) => (
                            <NoReport {...props} creating={false} />
                        )}
                    />
                    <Route
                        path={`/reports/create`}
                        exact
                        render={(props) => (
                            <ReportForm {...props} creating={true} />
                        )}
                    />
                    <Route
                        path={`/reports/:id`}
                        exact
                        render={(props) => (
                            <ReportForm
                                {...props}
                                creating={false}
                                setConfirmation={setConfirmation}
                                resetConfirmation={resetConfirmation}
                            />
                        )}
                    />
                </Switch>
            </ReportsPanel>
        </>
    );
};

export default withRouter(ReportsPage);
