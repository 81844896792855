import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RadioGroup, FormLabel, FormControl } from "@material-ui/core";
import {
    CustomFormControlLabel,
    CustomRadioButton,
} from "../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../styles/settingsStyles";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    radioButtonGroup: {
        flexDirection: "column",
        /* alignItems: "center", */
        width: "90px",
        /*    border: "1px solid lightgrey", */
        backgroundColor: "white",
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    radioButtonsFormControl: {
        marginLeft: theme.spacing(1),
    },
}));

const HindcastsPercentilesSection = (props) => {
    const classes = useStyles(props);
    const { selectedPercentile, changeSelectedPercentile } = props;

    const percentiles = [
        "None",
        "P10",
        "P20",
        "P30",
        "P40",
        "P50",
        "P60",
        "P70",
        "P80",
        "P90",
    ];

    return (
        <FormControl component="fieldset">
            <FormLabel
                component="legend"
                labelPlacement="top"
                style={{
                    marginLeft: 16,
                }}
            >
                Percentiles
            </FormLabel>
            <RadioGroup
                name="Percentiles"
                className={classes.radioButtonGroup}
                value={selectedPercentile}
                onChange={changeSelectedPercentile}
                label={"Percentiles"}
            >
                {percentiles.map((e) => {
                    return (
                        <CustomFormControlLabel
                            value={e}
                            control={
                                <CustomRadioButton
                                    variant="outlined"
                                    margin="dense"
                                />
                            }
                            label={e}
                            labelPlacement="end"
                            className={classes.radioButtonsFormControl}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default HindcastsPercentilesSection;
