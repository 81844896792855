import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import {
    CustomDataTable,
    CustomDataTableBody,
    CustomDataTableCell,
    CustomDataTableHead,
    CustomDataTableRow,
} from "../../common/restyled-mui/CustomDataTable";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import { textFieldNoArrowStyles } from "../../common/restyled-mui/CustomInputs";
import Topbar from "./Topbar";
import CenteredLoading from "../../common/CenteredLoading";
import _ from "lodash";
import clsx from "clsx";
import CloseIcon from "../../common/CloseIcon";

const width = 615;

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        width: (props) =>
            props.containerWidth - 40 < width
                ? props.containerWidth - 40
                : width,
        marginRight: 40,
    },
    content: {
        padding: theme.spacing(6.5, 5),
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: 0,
    },
    text: {
        paddingBottom: theme.spacing(2),
    },
    button: {
        margin: `${theme.spacing(4)}px auto 0px auto`,
        minWidth: 104,
    },
    dataTableScroll: {
        overflowY: "auto",
        flex: 1,
        minHeight: 0,
    },
    table: {
        width: "calc(100% - 1px)",
    },
    inputRoot: {
        height: "100%",
        backgroundColor: "transparent",
        fontSize: 13,
        color: theme.palette.text.default,
    },
    inputInput: {
        textAlign: "right",
        margin: 0,
        fontSize: 13,
        ...textFieldNoArrowStyles,
    },
    unitButton: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.generalUi.borders}`,
        padding: theme.spacing(1, 1),
        width: 58,
        height: 20,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    toleranceField: {
        display: "inline-block",
        width: "calc(100% - 70px)",
    },
    buttonContainer: {
        padding: theme.spacing(4, 4, 0, 4),
        textAlign: "center",
    },
    buttons: {
        width: 80,
        height: 28,
        fontWeight: 600,
    },
    whiteButton: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.generalUi.borders}`,
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main,
    },
}));

const Export = (props) => {
    const classes = useStyles(props);
    const [currentParameters, setCurrentParameters] = useState(null);
    const { parameters, updateParameters, close, containerWidth } = props;
    const smallScreen = Boolean(containerWidth - 40 < width);

    useEffect(() => {
        const createFlatParameters = () => {
            let flatParameters = [];
            parameters.forEach((chart) =>
                chart.groups.forEach((group) =>
                    group.parameters.forEach((parameter) =>
                        flatParameters.push({
                            ...parameter,
                            version: parameter.twinChartParameterId,
                        })
                    )
                )
            );

            return _.orderBy(flatParameters, "displayName");
        };

        if (!currentParameters && parameters) {
            setCurrentParameters(createFlatParameters());
        }
    }, [parameters, currentParameters]);

    const changeValue = (field, index) => (e) => {
        const value = e.target.value;
        const currentParameter = { ...currentParameters[index] };
        currentParameter[field] = value ? parseFloat(value) : "";
        const newParameters = [...currentParameters];
        newParameters[index] = currentParameter;
        setCurrentParameters(newParameters);
    };

    const toggleToleranceType = (index) => {
        const currentParameter = { ...currentParameters[index] };
        currentParameter.toleranceType =
            currentParameter.toleranceType === 0 ? 1 : 0;
        const newParameters = [...currentParameters];
        newParameters[index] = currentParameter;
        setCurrentParameters(newParameters);
    };

    const submit = () => {
        updateParameters(
            currentParameters.map((parameter) => ({
                twinChartParameterId: parameter.twinChartParameterId,
                thresholdMin: parameter.thresholdMin,
                thresholdMax: parameter.thresholdMax,
                tolerance: parameter.tolerance,
                toleranceType: parameter.toleranceType,
            }))
        );
        close();
    };

    const cancel = () => {
        setCurrentParameters(null);
        close();
    };

    const clear = () => {
        setCurrentParameters((flatParameters) => {
            var cleanParameters = [];

            flatParameters.forEach((parameter) => {
                var cleanParameter = { ...parameter };
                cleanParameter.version = parameter.version + 100;
                cleanParameter.thresholdMax = null;
                cleanParameter.thresholdMin = null;
                cleanParameter.tolerance = null;
                cleanParameter.toleranceType = null;
                cleanParameters.push(cleanParameter);
            });

            return cleanParameters;
        });
    };

    const getTolerance = (parameter) => {
        switch (parameter.toleranceType) {
            case 0:
                if (parameter.unit === "%") {
                    return "% (abs)";
                } else {
                    return parameter.unit;
                }

            case 1:
                return "%";
            default:
                return "";
        }
    };

    const renderButtons = () => {
        return (
            <>
                <CustomButton
                    variant="contained"
                    disableElevation
                    className={clsx(classes.whiteButton, classes.buttons)}
                    onClick={cancel}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.buttons}
                    onClick={submit}
                >
                    Submit
                </CustomButton>
            </>
        );
    };

    return (
        <>
            <div className={classes.root}>
                <Topbar
                    title={"Weather Window Thresholds - For all sites"}
                    subtitle={
                        <div>
                            {!smallScreen ? (
                                renderButtons()
                            ) : (
                                <CloseIcon onClick={cancel} />
                            )}
                        </div>
                    }
                />
                <div className={classes.content}>
                    <div className={classes.dataTableScroll}>
                        {currentParameters ? (
                            <CustomDataTable
                                stickyHeader
                                className={classes.table}
                            >
                                <CustomDataTableHead>
                                    <CustomDataTableRow>
                                        <CustomDataTableCell>
                                            Parameter
                                        </CustomDataTableCell>
                                        <CustomDataTableCell
                                            style={{ width: 59 }}
                                            align="center"
                                        >
                                            Unit
                                        </CustomDataTableCell>
                                        <CustomDataTableCell
                                            style={{ width: 59 }}
                                            align="right"
                                        >
                                            Min
                                        </CustomDataTableCell>
                                        <CustomDataTableCell
                                            style={{ width: 59 }}
                                            align="right"
                                        >
                                            Max
                                        </CustomDataTableCell>
                                        <CustomDataTableCell
                                            style={{ width: 110 }}
                                            align="right"
                                        >
                                            Tolerance
                                        </CustomDataTableCell>
                                    </CustomDataTableRow>
                                </CustomDataTableHead>
                                <CustomDataTableBody>
                                    {currentParameters.map(
                                        (parameter, index) => {
                                            return (
                                                <CustomDataTableRow
                                                    key={parameter.version}
                                                    className={classes.row}
                                                >
                                                    <CustomDataTableCell>
                                                        {parameter.displayName}
                                                    </CustomDataTableCell>
                                                    <CustomDataTableCell
                                                        style={{ width: 58 }}
                                                        align="center"
                                                    >
                                                        {`[${parameter.unit}]`}
                                                    </CustomDataTableCell>
                                                    <CustomDataTableCell
                                                        style={{ width: 58 }}
                                                        align="right"
                                                    >
                                                        <TextField
                                                            value={
                                                                parameter.thresholdMin
                                                                    ? parameter.thresholdMin
                                                                    : ""
                                                            }
                                                            onChange={changeValue(
                                                                "thresholdMin",
                                                                index
                                                            )}
                                                            type="number"
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput,
                                                                },
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </CustomDataTableCell>
                                                    <CustomDataTableCell
                                                        style={{ width: 58 }}
                                                        align="right"
                                                    >
                                                        <TextField
                                                            value={
                                                                parameter.thresholdMax
                                                                    ? parameter.thresholdMax
                                                                    : ""
                                                            }
                                                            onChange={changeValue(
                                                                "thresholdMax",
                                                                index
                                                            )}
                                                            type="number"
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput,
                                                                },
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </CustomDataTableCell>
                                                    <CustomDataTableCell
                                                        style={{ width: 110 }}
                                                        align="right"
                                                        className={
                                                            classes.toleranceCell
                                                        }
                                                    >
                                                        <TextField
                                                            value={
                                                                parameter.tolerance
                                                                    ? parameter.tolerance
                                                                    : ""
                                                            }
                                                            onChange={changeValue(
                                                                "tolerance",
                                                                index
                                                            )}
                                                            type="number"
                                                            className={
                                                                classes.toleranceField
                                                            }
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputRoot,
                                                                    input: classes.inputInput,
                                                                },
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                        <CustomButton
                                                            variant="contained"
                                                            disableElevation
                                                            className={
                                                                classes.unitButton
                                                            }
                                                            onClick={() =>
                                                                toggleToleranceType(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            {getTolerance(
                                                                parameter
                                                            )}
                                                        </CustomButton>
                                                    </CustomDataTableCell>
                                                </CustomDataTableRow>
                                            );
                                        }
                                    )}
                                </CustomDataTableBody>
                            </CustomDataTable>
                        ) : (
                            <CenteredLoading />
                        )}
                    </div>
                    <div className={classes.buttonContainer}>
                        <CustomButton
                            variant="contained"
                            disableElevation
                            className={clsx(
                                classes.whiteButton,
                                classes.buttons
                            )}
                            onClick={clear}
                        >
                            Clear
                        </CustomButton>
                        {smallScreen && renderButtons()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Export;
