import { REPORTS, EXPIRED_REPORTS, REPORT_TYPES } from "../types";

export function reports(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case REPORTS.LOADED:
            return payload;
        default:
            return state;
    }
}

export function expiredReports(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case EXPIRED_REPORTS.LOADED:
            return payload;
        default:
            return state;
    }
}

export function reportTypes(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case REPORT_TYPES.LOADED:
            return payload;
        default:
            return state;
    }
}
