import React from "react";
import {
    TextField,
    InputLabel,
    Select,
    Checkbox,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import UIIcons from "../../assets/icons/ui-icons";

export const CustomInputLabel = withStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        fontSize: 14,
    },
}))(InputLabel);

export const StyledTextField = withStyles((theme) => ({}))(TextField);

const defaultTextFieldProps = {
    variant: "outlined",
    margin: "dense",
    size: "small",
    fullWidth: true,
};

export const CustomTextField = (props) => {
    return <StyledTextField {...defaultTextFieldProps} {...props} />;
};

export const CustomSelect = withStyles((theme) => ({
    select: {
        "&:focus": {
            backgroundColor: theme.palette.common.white,
        },
    },
}))(Select);

const StyledCheckbox = withStyles((theme) => ({
    root: {
        "&:hover": {
            color: "#1C71AD",
        },
        margin: 5,
        padding: 5,
    },
    checked: {},
}))(Checkbox);

export const CustomCheckbox = (props) => {
    return (
        <StyledCheckbox
            icon={<UIIcons.CheckboxUncheckedIcon />}
            checkedIcon={<UIIcons.CheckboxCheckedIcon />}
            {...props}
        />
    );
};

const StyledRadioButton = withStyles((theme) => ({
    root: {
        "&:hover": {
            color: "#1C71AD",
        },
        margin: 5,
        padding: 5,
    },
    checked: {},
}))(Radio);

export const CustomRadioButton = (props) => {
    return (
        <StyledRadioButton
            icon={<UIIcons.RadioButtonUncheckedIcon />}
            checkedIcon={<UIIcons.RadioButtonCheckedIcon />}
            {...props}
        />
    );
};

export const CustomFormControlLabel = withStyles((theme) => ({
    root: {
        fontSize: 14,
    },
}))(FormControlLabel);

export const textFieldNoArrowStyles = {
    "-webkit-appearance": "none",
    /* Chrome, Safari, Edge, Opera */
    "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    /* Firefox */
    "&[type=number]": {
        "-moz-appearance": "textfield",
    },
};
