import { USERS, USER_ROLES, USER_SITES } from "../types";

export function users(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case USERS.LOADED:
            return payload;
        default:
            return state;
    }
}

export function userRoles(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_ROLES.LOADED:
            return payload;
        default:
            return state;
    }
}

export function userSites(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_SITES.LOADED:
            return payload;
        default:
            return state;
    }
}
