import {
    ALERTS,
    EXPIRED_ALERTS,
    ALERT_FREQ,
    ALERT_OP,
    ALERT_LOGIC,
} from "../types";

export function alerts(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case ALERTS.LOADED:
            return payload;
        default:
            return state;
    }
}

export function expiredAlerts(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case EXPIRED_ALERTS.LOADED:
            return payload;
        default:
            return state;
    }
}

export function alertFrequencies(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case ALERT_FREQ.LOADED:
            return payload;
        default:
            return state;
    }
}

export function alertOperators(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case ALERT_OP.LOADED:
            return payload;
        default:
            return state;
    }
}

export function alertLogics(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case ALERT_LOGIC.LOADED:
            return payload;
        default:
            return state;
    }
}
