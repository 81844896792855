

import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const Alerts = {
    getAlerts() {
        return axios({
            method: "get",
            url: `${API_URL}/alert`,
        });
    },
    createAlert(data) {
        return axios({
            method: "post",
            url: `${API_URL}/alert`,
            data,
        });
    },
    updateAlert(data) {
        return axios({
            method: "put",
            url: `${API_URL}/alert`,
            data,
        });
    },
    deleteAlert(id) {
        return axios({
            method: "delete",
            url: `${API_URL}/alert/${id}`,
        });
    },
    getExpiredAlerts() {
        return axios({
            method: "get",
            url: `${API_URL}/alert/expired`,
        });
    },
    deleteExpiredAlerts() {
        return axios({
            method: "delete",
            url: `${API_URL}/alert/expired`,
        });
    },
    getAlertFrequencies() {
        return axios({
            method: "get",
            url: `${API_URL}/alertfrequency`,
        });
    },
    getAlertOperators() {
        return axios({
            method: "get",
            url: `${API_URL}/alertoperator`,
        });
    },
    getAlertLogics() {
        return axios({
            method: "get",
            url: `${API_URL}/alertlogic`,
        });
    },
};

export default Alerts;