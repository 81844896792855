export const STATUS = {
    FETCHING: "STATUS_FETCHING",
    SUCCESS: "STATUS_SUCCESS",
    ERROR: "STATUS_ERROR",
    RESET: "STATUS_RESET",
};

export const SITES = {
    LOADED: "SITES_LOADED",
    UPDATED: "SITE_UPDATED",
    CREATED: "SITE_CREATED",
};

export const PARAMETERS = {
    LOADED: "PARAMETERS_LOADED",
    UPDATED: "PARAMETERS_UPDATED",
};

export const SEARCHED_SITES = {
    LOADED: "SEARCHED_SITES_LOADED",
};

export const CHART_SETS = {
    SET: "CHART_SETS_SET",
};

export const SEARCH_FIELD = {
    SET: "SEARCH_FIELD_SET",
};

export const FAVORITE_SITES = {
    LOADED: "FAVORITE_SITES_LOADED",
    ADDED: "FAVORITE_SITE_ADDED",
    REMOVED: "FAVORITE_SITE_REMOVED",
};

export const SITES_VIEW = {
    TOGGLED: "SITES_VIEW_TOGGLED",
};

export const USER = {
    LOGOUT: "USER_LOGOUT",
    LOGIN: "USER_LOGIN",
    LOADED: "USER_LOADED",
};

export const USERS = {
    LOADED: "USERS_LOADED",
    UPDATED: "USER_UPDATED",
    CREATED: "USER_CREATED",
    DELETED: "USER_DELETED",
};

export const SITES_SETTINGS = {
    LOADED: "SITES_SETTINGS_LOADED",
    CLEAR: "SITES_SETTINGS_CLEAR",
    UPDATED: "SITES_SETTINGS_UPDATED",
    CREATED: "SITES_SETTINGS_CREATED",
    DELETED: "SITES_SETTINGS_DELETED",
};

export const SITES_LIST = {
    LOADED: "SITES_LIST_LOADED",
    UPDATED: "SITES_LIST_UPDATED",
    CREATED: "SITES_LIST_CREATED",
    DELETED: "SITES_LIST_DELETED",
};

export const SITES_SEARCH_SETTINGS = {
    CHANGED: "SITESEARCH_CHANGED",
};

export const USER_ROLES = {
    LOADED: "USER_ROLES_LOADED",
};

export const CONFIG = {
    UPDATED: "CONFIG_UPDATED",
};

export const SITE_USERS = {
    LOADED: "SITE_USERS_LOADED",
};

export const USER_SITES = {
    LOADED: "USER_SITES_LOADED",
};

export const MAP_STATE = {
    SET: "MAP_STATE_SET",
};

export const ALERTS = {
    LOADED: "ALERTS_LOADED",
};

export const EXPIRED_ALERTS = {
    LOADED: "EXPIRED_ALERTS_LOADED",
};

export const ALERT_FREQ = {
    LOADED: "ALERT_FREQ_LOADED",
};

export const ALERT_OP = {
    LOADED: "ALERT_OP_LOADED",
};

export const ALERT_LOGIC = {
    LOADED: "ALERT_LOGIC_LOADED",
};

export const VESTAS_SITES = {
    LOADED: "VESTAS_SITES_LOADED",
};

export const REPORTS = {
    LOADED: "REPORTS_LOADED",
};

export const EXPIRED_REPORTS = {
    LOADED: "EXPIRED_REPORTS_LOADED",
};

export const REPORT_TYPES = {
    LOADED: "REPORT_TYPES_LOADED",
};

export const CUSTOMER = {
    LOADED: "CUSTOMER_LOADED",
    LOADED_FAILED: "CUSTOMER_LOADED_FAILED",
};
