import {
    SITES,
    SEARCHED_SITES,
    FAVORITE_SITES,
    SEARCH_FIELD,
    SITES_VIEW,
} from "../types";

export function sites(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case SITES.LOADED:
            return payload;
        default:
            return state;
    }
}

export function favoriteSites(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case FAVORITE_SITES.LOADED:
            return payload;
        case FAVORITE_SITES.ADDED:
            return payload;
        case FAVORITE_SITES.REMOVED:
            return payload;
        default:
            return state;
    }
}

export function sitesView(state = 0, action) {
    const { type, payload } = action;

    switch (type) {
        case SITES_VIEW.TOGGLED:
            return payload;
        default:
            return state;
    }
}

export function searchedSites(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCHED_SITES.LOADED:
            return payload;
        default:
            return state;
    }
}

export function searchField(state = "", action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_FIELD.SET:
            return payload;
        default:
            return state;
    }
}
