import axios from "axios";

const HWWA_API_URL = `${process.env.REACT_APP_HWWA_API_URL}`;
const API_URL = `${process.env.REACT_APP_API_URL}`;

const HWWA = {
    getData(data) {
        return axios({
            method: "post",
            url: `${HWWA_API_URL}/hwwa`,
            data,
        });
    },
    getSites() {
        return axios({
            method: "get",
            url: `${API_URL}/hwwa/sites`,
        });
    },
    getParametersBySite(siteId, height) {
        return axios({
            method: "get",
            url: `${API_URL}/hwwa/parameters/?siteId=${siteId}&height=${height}`,
        });
    },
    getHeightsBySite(siteId) {
        return axios({
            method: "get",
            url: `${API_URL}/hwwa/heights/${siteId}`,
        });
    },
};

export default HWWA;
