import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomDataTable = withStyles((theme) => ({
    root: {},
}))(Table);

export const CustomDataTableHead = withStyles((theme) => ({
    root: {},
}))(TableHead);

export const CustomDataTableBody = withStyles((theme) => ({
    root: {},
}))(TableBody);

export const CustomDataTableCell = withStyles((theme) => ({
    root: {
        fontSize: 13,
        lineHeight: "16px",
    },
    head: {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 600,
        padding: theme.spacing(2, 2.5),
    },
    body: {
        color: theme.palette.text.defaultSub,
        borderRight: `1px solid ${theme.palette.table.cellBorder}`,
        padding: theme.spacing(2.5, 3),
        "&:first-child": {
            borderLeft: `1px solid ${theme.palette.table.cellBorder}`,
        },
    },
    stickyHeader: {
        backgroundColor: theme.palette.common.white,
    },
}))(TableCell);

export const CustomDataTableContainer = withStyles((theme) => ({
    root: {},
}))(TableContainer);

export const CustomDataTableRow = withStyles((theme) => ({
    root: {
        "&:nth-child(odd)": {
            backgroundColor: theme.palette.table.cellBg,
        },
    },
}))(TableRow);

export const CustomSmallDataTableCell = withStyles((theme) => ({
    root: {
        fontSize: 13,
        lineHeight: "16px",
    },
    head: {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 600,
        padding: theme.spacing(1.5, 2.5),
        borderRight: `1px solid ${theme.palette.table.cellBorder}`,
        borderTop: `1px solid ${theme.palette.table.cellBorder}`,
        borderBottom: `1px solid ${theme.palette.table.cellBorder}`,
        "&:first-child": {
            borderLeft: `1px solid ${theme.palette.table.cellBorder}`,
        },
    },
    body: {
        color: theme.palette.text.defaultSub,
        borderRight: `1px solid ${theme.palette.table.cellBorder}`,
        padding: theme.spacing(1, 3),
        "&:first-child": {
            borderLeft: `1px solid ${theme.palette.table.cellBorder}`,
        },
    },
    stickyHeader: {
        backgroundColor: theme.palette.common.white,
    },
}))(TableCell);
