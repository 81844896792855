import React from "react";
import { useTable, useBlockLayout, useColumnOrder } from "react-table";

import { useSticky } from "react-table-sticky";

import styled from "styled-components";

import HindcastsPercentilesSection from "./HindcastsPercentileSelection";

const Styles = styled.div`
    /* This is required to make the table full-width */
    display: flex;

    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;

    background: white;
    height: fit-content;

    width: 100%;

    box-sizing: border-box;
    padding: 16px;

    font: 400 12px/13px Open sans, Regular;
    white-space: pre-wrap;
    text-align: center;

    .table {
        border: 1px solid #ddd;

        /* width: calc(100vw - 700px); */
        width: 90%;

        min-width: 700px;
        /*height: 400px;*/
        margin: auto;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        .tr {
            min-width: 100%;
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
        }

        .header .wind {
            background-color: rgba(173, 216, 230, 1);
        }
        .header .wave {
            background-color: lightsalmon;
        }
        .header .currents {
            background-color: lightsalmon;
        }

        .th,
        .td {
            padding: 5px;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background-color: #fff;
            overflow: hidden;
            height: 30px;

            :last-child {
                border-right: 0;
            }

            :not([data-sticky-td]) {
                flex-grow: 1;
            }

            .resizer {
                display: inline-block;
                width: 5px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;

                &.isResizing {
                    background-color: red;
                }
            }
        }

        .percentile_0 {
            background-color: red;
        }
        .percentile_1 {
            background-color: #dc143c;
        }

        .percentile_2 {
            background-color: orange;
        }

        .percentile_3 {
            background-color: #ffd700;
        }

        .percentile_4 {
            background-color: yellow;
        }

        .percentile_5 {
            background-color: #adff2f;
        }

        .percentile_6 {
            background-color: #00ff00;
        }

        .percentile_7 {
            background-color: green;
        }

        .percentile_nodata {
            background-color: white;
        }

        .percentile_nan {
            background-color: #ddd;
        }

        .threshold-ok {
            background-color: rgba(76, 175, 81, 1);
        }
        .threshold-warning {
            background-color: orange;
        }
        .threshold-critical {
            background-color: rgb(198, 40, 40, 1);
        }

        &.sticky {
            overflow: scroll;

            .header {
                position: sticky;
                z-index: 1;
            }

            .header {
                top: 0;
                box-shadow: 0px 0px 0px #ccc;
            }

            .tbody {
                position: relative;
                z-index: 0;
            }

            [data-sticky-td] {
                position: sticky;
            }

            [data-sticky-last-left-td] {
                box-shadow: 0px 0px 0px #ccc;
            }

            [data-sticky-first-right-td] {
                box-shadow: 0px 0px 0px #ccc;
            }
        }

        .tbody .tr:hover {
            outline: 1px solid lightgrey;
        }
    }
`;

const percentileColumns = [
    "P10",
    "P20",
    "P30",
    "P40",
    "P50",
    "P60",
    "P70",
    "P80",
    "P90",
];

const defaultColumnOrder = [
    "Year",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Average",
    "P10",
    "P20",
    "P30",
    "P40",
    "P50",
    "P60",
    "P70",
    "P80",
    "P90",
];

const HindcastsTable = (props) => {
    const { data, selectedPercentile, setSelectedPercentile } = props;

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 70,
            maxWidth: 200,
        }),
        []
    );

    const changeSelectedPercentile = (e) => {
        setSelectedPercentile(e.target.value);
        setHiddenColumns(
            percentileColumns.filter((elem) => elem !== e.target.value)
        );
    };

    const columns = React.useMemo(() => {
        const generateColumnList = (data) => {
            let cols = [];
            let existingNames = [];
            Object.keys(Object.values(data)[0]).forEach((k) => {
                //cols = cols.concat({ Header: k, accessor: k });
                existingNames = existingNames.concat(k);
            });

            defaultColumnOrder.forEach((element) => {
                if (existingNames.includes(element)) {
                    cols = cols.concat({ Header: element, accessor: element });
                }
            });
            return cols;
        };

        return generateColumnList(data);
    }, [data]);

    const getColumnProps = (column) => ({});
    const getHeaderProps = (column) => ({});
    const getCellProps = (cellInfo) => {
        if (cellInfo.value === "NaN") {
            return { className: "percentile_nan" };
        }
        if (
            cellInfo.value === "" ||
            cellInfo.value === null ||
            Number.isNaN(cellInfo.value)
        ) {
            return { className: "percentile_nodata" };
        }
        let pct = Number.parseInt((8 * cellInfo.value) / 100);
        if (pct === 8) {
            pct = 7;
        }
        return { className: "percentile_" + pct };
    };

    const tableInstance = useTable(
        {
            columns: columns,
            data: data,
            defaultColumn: defaultColumn,

            getColumnProps: getColumnProps,
            getHeaderProps: getHeaderProps,
            getCellProps: getCellProps,

            initialState: {
                hiddenColumns: percentileColumns,
            },
        },
        useColumnOrder,
        useBlockLayout,
        useSticky
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
    } = tableInstance;

    return (
        <Styles>
            <div {...getTableProps()} className="table ">
                <div className="header">
                    {headerGroups.map((headerGroup) => (
                        <div
                            {...headerGroup.getHeaderGroupProps()}
                            className="tr"
                        >
                            {headerGroup.headers.map((column, index) => {
                                return (
                                    <div
                                        {...column.getHeaderProps([
                                            {
                                                className: "th header",
                                            },
                                            getColumnProps(column),
                                        ])}
                                    >
                                        {column.render("Header")}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row, i) => {
                        if (
                            !Number.isNaN(parseInt(row.values.Year)) ||
                            row.values.Year === "Average" ||
                            row.values.Year === selectedPercentile
                        ) {
                            prepareRow(row);
                            return (
                                <div {...row.getRowProps()} className="tr">
                                    {row.cells.map((cell) => {
                                        return (
                                            <div
                                                {...cell.getCellProps([
                                                    {
                                                        className: "td",
                                                    },

                                                    getCellProps(cell),
                                                ])}
                                            >
                                                {cell.value !== "NaN"
                                                    ? cell.render("Cell")
                                                    : "-"}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        return <></>;
                    })}
                </div>
            </div>
            <HindcastsPercentilesSection
                selectedPercentile={selectedPercentile}
                changeSelectedPercentile={changeSelectedPercentile}
            ></HindcastsPercentilesSection>
        </Styles>
    );
};

export default HindcastsTable;
