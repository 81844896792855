import { MAP_STATE } from "../types";

export function mapState(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case MAP_STATE.SET:
            return payload;
        default:
            return state;
    }
}
