import { getCookie, setCookie } from "../../utils/cookies";
import { CONFIG } from "../types";

export const getConfig = (field, defaultValue, useCookie) => {
    return (dispatch, getState) => {
        let value = getState().config[field];
        if (typeof value === "undefined") {
            if (useCookie) {
                value = getCookie(field, value);
            }
            if (typeof value === "undefined") {
                value = defaultValue;
            }
        }
        return value;
    };
};

export const updateConfig = (field, value, useCookie) => {
    return (dispatch, getState) => {
        if (useCookie) {
            setCookie(field, value);
            value = getCookie(field);
        }
        let config = { ...getState().config, [field]: value };
        dispatch({ type: CONFIG.UPDATED, payload: config });
    };
};
