import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { Drawer, List, ListItem, ListItemIcon } from "@material-ui/core";
import AnalyticsPanelTopbar from "./AnalyticsPanelTopbar";
import AnalyticsForm from "./AnalyticsForm";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { getVestasSites } from "../../redux/actions/vestasSites";
import { TertiaryButton } from "../../common/restyled-mui/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { searchIcon } from "../../assets/icons/fontawesome/searchIcon";
import { checkIcon } from "../../assets/icons/fontawesome/checkIcon";
import CenteredLoading from "../../common/CenteredLoading";
import Api from "../../api";
import {
    initialSingleValidation,
    validSingleValidation,
    checkValidation,
    validateRequiredNumber,
    validateRequiredObj,
    validateRequiredArray,
} from "../../utils/validation";

const sitesListWidth = 407;
const tabletSidebarWidth = 48;

const useStyles = makeStyles((theme) => ({
    childrenContainer: {
        marginLeft: (props) =>
            props.fullscreen || props.toggleable ? 0 : sitesListWidth,
        width: (props) =>
            props.fullscreen || props.toggleable
                ? "100%"
                : `calc(100% - ${sitesListWidth}px)`,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    formContainer: {
        backgroundColor: theme.palette.common.white,
        height: "100%",
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
        display: "flex",
        flexDirection: "column",
    },
    drawer: {
        marginLeft: (props) => (props.toggleable ? tabletSidebarWidth - 1 : 0),
        borderRight: `${theme.palette.menu.border} 1px solid`,
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
    },
    menu: {
        display: "flex",
        flexDirection: "column",
        width: tabletSidebarWidth,
        zIndex: 1400,
        backgroundColor: theme.palette.menu.bg,
        borderRight: `${theme.palette.menu.border} 1px solid`,
    },
    menuItem: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(1, 3),
    },
    menuItemIcon: {
        textAlign: "center",
        minWidth: "100%",
    },
    menuIcon: {
        color: theme.palette.menu.icons,
    },
    selectedMenuIcon: {
        color: theme.palette.primary.main,
    },
    tickIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
}));

const mapStateToProps = (state, ownProps) => {
    return {
        vestasSites: state.vestasSites,
        user: state.user,
        config: state.config,
    };
};

const parseQueriesIntoFormData = (queries) => {
    const values = JSON.parse(queries.get("formValues"));
    return {
        site: values.site,
        months: values.months,
        analysisLevelMeters: values.workWindow.analysisLevel.value,
        percentiles: [values.workWindow.percentiles],
        workingDays: values.workWindow.workingDays,
        windowLengthHours: values.workWindow.windowLength.value,
        windowSelection: values.workWindow.windowSelection,
        //  isTimezoneUTC: `${values.workWindow.isTimezoneUTC}`,
        weatherParameters: values.weatherParameters,
    };
};

const parseValuesIntoApiData = (values) => {
    return {
        site: values.site,
        months: values.months,
        workWindow: {
            analysisLevel: {
                value: makeNumber(values.analysisLevelMeters),
                unit: "meter",
            },
            percentiles: [makeNumber(values.percentiles)],
            workingDays: values.workingDays,
            windowLength: {
                value: makeNumber(values.windowLengthHours),
                unit: "hour",
            },
            windowSelection: {
                ...(values.windowSelection.daylight && {
                    daylight: values.windowSelection.daylight,
                }),
                name: values.windowSelection.name,
                ...(values.windowSelection.name === "custom" && {
                    customRange: {
                        start: makeNumber(
                            values.windowSelection.customRange.start
                        ),
                        end: makeNumber(values.windowSelection.customRange.end),
                    },
                }),
            },
            //    isTimezoneUTC: Boolean(values.isTimezoneUTC === 'true'),
        },
        weatherParameters: values.weatherParameters.filter((x) => x.isActive),
        includeInputWeatherParametersInTheResponse: false,
    };
};

const makeNumber = (number) => {
    return number * 1;
};

const initialValues = {
    site: null,
    months: [],
    analysisLevelMeters: "",
    percentiles: "",
    workingDays: {
        start: 0,
        end: 6,
    },
    windowLengthHours: "",
    windowSelection: {
        name: "custom", //'24h', 'daylight' or 'custom'
        customRange: {
            start: "07",
            end: "19",
        },
        daylight: false,
    },
    isTimezoneUTC: "true",
    weatherParameters: [],
};

const initialValidation = {
    site: initialSingleValidation,
    months: initialSingleValidation,
    analysisLevelMeters: initialSingleValidation,
    percentiles: initialSingleValidation,
    workingDays: initialSingleValidation,
    windowLengthHours: initialSingleValidation,
    windowSelection: initialSingleValidation,
    isTimezoneUTC: initialSingleValidation,
    weatherParameters: initialSingleValidation,
};

const SearchPanel = (props) => {
    const classes = useStyles(props);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [parameters, setParameters] = useState({
        list: [],
        siteId: null,
        height: null,
    });
    const [analyticsHeights, setAnalyticsHeights] = useState({
        list: [],
        siteId: null,
    });
    const [values, handleChangeValues] = useState(null);
    const [validation, setValidation] = useState(initialValidation);
    const { vestasSites, push, getVestasSites, container, toggleable } = props;

    useEffect(() => {
        if (!vestasSites) {
            getVestasSites();
        }
    }, [vestasSites, getVestasSites]);

    useEffect(() => {
        if (!values) {
            if (location.search) {
                const queries = new URLSearchParams(location.search);
                handleChangeValues(parseQueriesIntoFormData(queries));
            } else {
                handleChangeValues(initialValues);
            }
        }
    }, [values, location]);

    useEffect(() => {
        if (values?.site) {
            if (
                (values.site.id !== parameters.siteId ||
                    values.analysisLevelMeters !== parameters.height) &&
                values.analysisLevelMeters
            ) {
                Api.HWWA.getParametersBySite(
                    values.site.id,
                    values.analysisLevelMeters
                )
                    .then((res) => {
                        const updatedParams = res.data.map((item) => {
                            const updated = item;
                            updated.description = item.description
                                .replaceAll("Ice [bool]", "No Ice [bool]")
                                .replaceAll(
                                    "Lightning [bool]",
                                    "No Lightning [bool]"
                                )
                                .replaceAll("Fog [bool]", "No Fog [bool]");
                            return updated;
                        });

                        setParameters({
                            list: updatedParams,
                            siteId: values.site.id,
                            height: values.analysisLevelMeters,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            if (values.site.id !== parameters.siteId) {
                Api.HWWA.getHeightsBySite(values.site.id)
                    .then((res) => {
                        setAnalyticsHeights({
                            list: res.data,
                            siteId: values.site.id,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            if (parameters.siteId) {
                setParameters({
                    list: [],
                    siteId: null,
                });
                setAnalyticsHeights({
                    list: [],
                    siteId: null,
                });
            }
        }
    }, [values, parameters]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const submitConfig = () => {
        const valid = validate(values);
        if (valid) {
            const parsedValues = parseValuesIntoApiData(values);
            const queries = compileIntoQueries(parsedValues);
            push(`/analytics/${values.site.id}?${queries}`);
            setOpen(false);
        }
    };

    const validate = (values) => {
        const currentValidation = { ...initialValidation };
        currentValidation.site = validateRequiredObj(values.site);
        currentValidation.months = validateRequiredArray(values.months);
        currentValidation.analysisLevelMeters = validateRequiredNumber(
            values.analysisLevelMeters
        );
        currentValidation.percentiles = validatePercentiles(values.percentiles);

        currentValidation.workingDays = validateRequiredObj(values.workingDays);
        currentValidation.windowLengthHours = validateRequiredNumber(
            values.windowLengthHours
        );
        currentValidation.windowSelection = validateWindowSelection(
            values.windowSelection
        );
        currentValidation.weatherParameters = validateRequiredArray(
            values.weatherParameters
        );
        const valid = checkValidation(currentValidation);
        if (!valid) {
            setValidation(currentValidation);
        }
        return valid;
    };

    const validatePercentiles = (value) => {
        if (validateRequiredNumber(value).valid) {
            if (
                value >= 1 &&
                value <= 99 &&
                Number.parseFloat(value) === Math.round(value)
            ) {
                return validSingleValidation;
            } else {
                return {
                    valid: false,
                    message: "Must be a round number in range (1-99)",
                };
            }
        } else {
            return {
                valid: false,
                message: "Required",
            };
        }
    };

    const validateWindowSelection = (value) => {
        if (value?.name !== "custom") {
            return validSingleValidation;
        } else {
            if (
                value.customRange?.start !== "" &&
                value.customRange?.end !== ""
            ) {
                if (
                    value.customRange.start <= 24 &&
                    value.customRange.start >= 0 &&
                    value.customRange.end <= 24 &&
                    value.customRange.end >= 0
                ) {
                    return validSingleValidation;
                } else {
                    return {
                        valid: false,
                        message: "Must be in range (0-24)",
                    };
                }
            } else {
                return {
                    valid: false,
                    message: "Required",
                };
            }
        }
    };

    const compileIntoQueries = (values) => {
        const stringifiedFormValues = {
            formValues: JSON.stringify(values),
        };
        return new URLSearchParams(stringifiedFormValues).toString();
    };

    const handleValidationUpdate = (state) => {
        setValidation({
            ...validation,
            [state]: validSingleValidation,
        });
    };

    return (
        <>
            {toggleable && (
                <List className={classes.menu}>
                    <ListItem
                        className={classes.menuItem}
                        button
                        onClick={toggleOpen}
                    >
                        <ListItemIcon className={classes.menuItemIcon}>
                            <FontAwesomeIcon
                                icon={searchIcon}
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    open && classes.selectedMenuIcon
                                )}
                            />
                        </ListItemIcon>
                    </ListItem>
                </List>
            )}
            <Drawer
                open={toggleable ? open : true}
                variant={toggleable ? "persistent" : "permanent"}
                anchor={"left"}
                ModalProps={{
                    keepMounted: true,
                    container: container.current,
                    style: { position: "absolute" },
                }}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                onClose={toggleOpen}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.formContainer}>
                    <AnalyticsPanelTopbar>
                        <TertiaryButton
                            disableElevation
                            className={classes.addNewButton}
                            onClick={submitConfig}
                        >
                            <FontAwesomeIcon
                                icon={checkIcon}
                                size={"lg"}
                                className={classes.tickIcon}
                            />
                            Submit
                        </TertiaryButton>
                    </AnalyticsPanelTopbar>
                    {values && vestasSites ? (
                        <AnalyticsForm
                            values={values}
                            handleChangeValues={handleChangeValues}
                            sites={vestasSites}
                            parameters={parameters}
                            analyticsHeights={analyticsHeights}
                            validation={validation}
                            handleValidationUpdate={handleValidationUpdate}
                        />
                    ) : (
                        <CenteredLoading />
                    )}
                </div>
            </Drawer>
            <div className={classes.childrenContainer}>{props.children}</div>
        </>
    );
};

export default connect(mapStateToProps, { getVestasSites, push })(SearchPanel);
