import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartValueAxis,
    ChartTooltip,
    ChartPlotArea,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { returnLabelFont } from "../../common/charts/chartStyles";
import CustomChartLegend from "../../common/charts/CustomChartLegend";
import CustomChartCategoryAxisItem from "../../common/charts/CustomChartCategoryAxisItem";
import CustomChartValueAxisItem from "../../common/charts/CustomChartValueAxisItem";
import { Typography, makeStyles } from "@material-ui/core";
import { Path, Group } from "@progress/kendo-drawing";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
    tooltipSpan: {
        height: 3,
        width: 16,
        marginRight: theme.spacing(2),
    },
    tooltipSeries: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    tooltipCategory: {
        paddingBottom: theme.spacing(1),
        textAlign: "center",
    },
}));

const seriesMapping = {
    windSpeed: 0,
    windAvg: 1,
    windStdDev: 2,
};

const YearVariabilityChart = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { data } = props;
    const chartCategoryName = "category";
    const chartValueName = "value";
    const categories = Object.keys(data.windSpeedYearly);
    const roundTo = 2;
    const [shown, setShown] = useState([
        seriesMapping.windSpeed,
        seriesMapping.windAvg,
        seriesMapping.windStdDev,
    ]);
    const stdDevColor = theme.palette.generalUi.icons;
    const avgColor = theme.palette.primary.light;
    const dataColor = theme.palette.primary.main;

    const parseWindSpeedData = (data) => {
        const windSpeedData = data.windSpeedYearly;
        return Object.keys(windSpeedData).map((year, index) => {
            return windSpeedData[year];
        });
    };

    const renderTooltip = (props) => {
        const { categoryText, points } = props;
        return (
            <div className={classes.tooltip}>
                <Typography
                    className={classes.tooltipCategory}
                    variant={"subtitle1"}
                >
                    <b>{categoryText}</b>
                </Typography>
                {points.map((point) => {
                    return (
                        <div
                            key={point.series.index}
                            className={classes.tooltipSeries}
                        >
                            <span
                                className={classes.tooltipSpan}
                                style={{ backgroundColor: point.series.color }}
                            />
                            <Typography variant={"subtitle1"}>
                                {roundTo
                                    ? point.value?.toFixed(roundTo)
                                    : point.value}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderAverageLine = (value, stroke, valueAxis, categorySlot) => {
        const valueSlot = valueAxis.slot(value);
        const line = new Path({
            stroke,
        })
            .moveTo(valueSlot.origin)
            .lineTo(categorySlot.topRight().x, valueSlot.origin.y);

        return line;
    };

    const renderThresholdLines = (chart) => {
        const valueAxis = chart.findAxisByName(chartValueName);
        const categoryAxis = chart.findAxisByName(chartCategoryName);
        const range = categoryAxis.range();
        const categorySlot = categoryAxis.slot(range.min, range.max);
        const group = new Group();
        if (shown.includes(seriesMapping.windAvg)) {
            const avg = renderAverageLine(
                data.windSpeedAvg,
                {
                    color: avgColor,
                    width: 2,
                    dashType: "dash",
                },
                valueAxis,
                categorySlot
            );
            group.append(avg);
        }
        if (shown.includes(seriesMapping.windStdDev)) {
            const lower = renderAverageLine(
                data.windSpeedLower,
                {
                    color: stdDevColor,
                    width: 2,
                    dashType: "dot",
                },
                valueAxis,
                categorySlot
            );
            const upper = renderAverageLine(
                data.windSpeedUpper,
                {
                    color: stdDevColor,
                    width: 2,
                    dashType: "dot",
                },
                valueAxis,
                categorySlot
            );
            group.append(lower, upper);
        }
        chart.surface.draw(group);
    };

    const onRender = (args) => {
        const chart = args.target.chartInstance;
        if (chart) {
            renderThresholdLines(chart);
        }
    };

    const onLegendItemClick = ({ series }) => {
        if (shown.includes(series.id)) {
            setShown(shown.filter((x) => x !== series.id));
        } else {
            setShown([...shown, series.id]);
        }
    };
    const windSpeedData = parseWindSpeedData(data);
    const getMinMax = (data) => {
        let min;
        let max;
        [
            ...windSpeedData,
            data.windSpeedAvg,
            data.windSpeedLower,
            data.windSpeedUpper,
        ].forEach((value) => {
            if (min) {
                if (value < min) {
                    min = value;
                }
            } else {
                min = value;
            }
            if (max) {
                if (value > max) {
                    max = value;
                }
            } else {
                max = value;
            }
        });

        return { min, max };
    };

    const addPaddingToMinMax = ({ min, max }) => {
        const range = max - min;
        const padding = range / 2;
        return {
            min: Math.floor(min - padding),
            max: Math.ceil(max + padding),
        };
    };

    const chartMinMax = addPaddingToMinMax(getMinMax(data));
    return (
        <Chart
            onRender={onRender}
            transitions={false}
            onLegendItemClick={onLegendItemClick}
            style={{ height: "100%" }}
        >
            <ChartTooltip
                border={{ width: 0, radius: 5 }}
                background={"white"}
                padding={4}
                render={renderTooltip}
                font={returnLabelFont(theme, 11, 400)}
                shared={true}
            />
            <ChartPlotArea
                background={theme.palette.table.cellBg}
                border={{ color: theme.palette.generalUi.borders, width: 1 }}
            />
            <CustomChartLegend />
            <ChartCategoryAxis>
                <CustomChartCategoryAxisItem
                    categories={categories}
                    name={chartCategoryName}
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <CustomChartValueAxisItem
                    title={{ text: data.windSpeedUnit }}
                    name={chartValueName}
                    min={chartMinMax.min}
                    max={chartMinMax.max}
                />
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem
                    data={windSpeedData}
                    tooltip={{ visible: true }}
                    name={"Monthly mean wind speed at 100 metres"}
                    color={dataColor}
                    axis={chartValueName}
                    type={"line"}
                    markers={{ visible: false }}
                    visible={shown.includes(seriesMapping.windSpeed)}
                    id={seriesMapping.windSpeed}
                />
                <ChartSeriesItem
                    type={"line"}
                    dashType={"dash"}
                    tooltip={{ visible: false }}
                    data={[]}
                    color={avgColor}
                    markers={{ visible: false }}
                    name={"Mean wind speed across years"}
                    visible={shown.includes(seriesMapping.windAvg)}
                    id={seriesMapping.windAvg}
                />
                <ChartSeriesItem
                    type={"line"}
                    dashType={"dot"}
                    tooltip={{ visible: false }}
                    data={[]}
                    color={stdDevColor}
                    markers={{ visible: false }}
                    name={"Mean ± StdDev wind speed across years"}
                    visible={shown.includes(seriesMapping.windStdDev)}
                    id={seriesMapping.windStdDev}
                />
            </ChartSeries>
        </Chart>
    );
};

export default YearVariabilityChart;
