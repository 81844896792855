import { USERS, USER_ROLES, USER_SITES } from "../types";
import Api from "../../api";
import { STATUS } from "../types";
import { changeStatus } from "./status";
import { handleError } from "../../utils/error";

export const getUsers = () => {
    return (dispatch) => {
        return Api.Settings.getUsers()
            .then((res) => {
                dispatch({ type: USERS.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const updateUser = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Settings.updateUser(data)
            .then((res) => {
                const users = getState().users;
                users.splice(
                    users.findIndex((x) => x.id === data.id),
                    1,
                    data
                );
                dispatch({ type: USERS.UPDATED, payload: users });

                clearUserCaches(dispatch);

                dispatch(changeStatus(STATUS.SUCCESS, "User updated"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const createUser = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Settings.createUser(data)
            .then((res) => {
                const newData = {
                    ...data,
                    id: res.data,
                };
                let users = getState().users;
                users.push(newData);
                dispatch({ type: USERS.CREATED, payload: users });

                clearUserCaches(dispatch);

                dispatch(changeStatus(STATUS.SUCCESS, "User created"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const deleteUser = (id) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Settings.deleteUser(id)
            .then((res) => {
                const users = getState().users;
                users.splice(
                    users.findIndex((u) => u.id === id),
                    1
                );
                dispatch({ type: USERS.UPDATED, payload: users });

                clearUserCaches(dispatch);

                dispatch(changeStatus(STATUS.SUCCESS, "User deleted"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

const clearUserCaches = (dispatch) => {
    dispatch({ type: USER_SITES.LOADED, payload: null });
};

export const getUserRoles = () => {
    return (dispatch) => {
        const userRoles = [
            {
                value: "User",
                id: "1",
            },
            {
                value: "MultiUser",
                id: "2",
            },
            {
                value: "Administrator",
                id: "3",
            },
        ];
        dispatch({ type: USER_ROLES.LOADED, payload: userRoles });
    };
};

export const getUserSites = (userId) => {
    return (dispatch) => {
        return Api.Settings.getUserSites(userId)
            .then((res) => {
                dispatch({ type: USER_SITES.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};
