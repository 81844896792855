import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
    makeStyles,
    Typography,
    CircularProgress,
    Grid,
    MenuItem,
    FormControl,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import {
    CustomInputLabel,
    CustomTextField,
    CustomSelect,
} from "../../common/restyled-mui/CustomInputs";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import ConfirmationDialog from "../../common/Confirmation";
import {
    getUsers,
    updateUser,
    createUser,
    deleteUser,
    getUserRoles,
} from "../../redux/actions/users";
import { push } from "connected-react-router";
import Navigation from "../settings-navigation/Navigation";
import { getSettingsStyles } from "../../styles/settingsStyles";
import PermissionsSelection from "./PermissionsSelection";
import Api from "../../api";
import { permissionsToModules } from "../../utils/moduleConfig";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
}));

const mapStateToProps = (state, ownProps) => ({
    users: state.users,
    userRoles: state.userRoles,
    apiLoading: state.status.loading,
    apiStatusOk: state.status.snackbar.ok,
    permissions: state.user.permissions,
});

const User = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [userPermissions, setUserPermissions] = useState(null);
    const [permissionList, setPermissionList] = useState(null);
    const [modulesAvailable, setModulesAvailable] = useState([]);
    const {
        users,
        getUsers,
        updateUser,
        createUser,
        deleteUser,
        match,
        push,
        apiLoading,
        apiStatusOk,
        creating,
        userRoles,
        getUserRoles,
        permissions,
    } = props;
    const initialConfirmDialog = {
        open: false,
        confirmCallback: null,
        cancelCallback: null,
        title: "",
        content: "",
        color: "",
    };
    const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialog);

    const transformPermissionList = (fullList) => {
        if (!fullList) {
            return [];
        }
        let moduleList = fullList
            .filter(
                (e) =>
                    e.split(".")[0] === "Module" || e.split(".")[0] === "Admin"
            )
            .sort((f, s) => {
                if (f > s) {
                    return 1;
                } else if (f < s) {
                    return -1;
                } else {
                    return 0;
                }
            });

        let listWithSubpermissions = moduleList.map((m) => {
            let related = fullList.filter((p) => {
                if (
                    p.split(".") !== "Module" &&
                    p.split(".") !== "Admin" &&
                    p.split(".")[0] === m.split(".")[1]
                ) {
                    return p;
                }
                return null;
            });

            return { name: m, related: related };
        });

        return listWithSubpermissions;
    };

    useEffect(() => {
        if (!permissionList) {
            Api.Settings.getPermissionList().then((res) => {
                setPermissionList(
                    res.data.map((e) => {
                        return e.permissionName;
                    })
                );
            });
        }
    }, [permissionList, setPermissionList]);

    useEffect(() => {
        if (!users) {
            getUsers();
        }
    }, [users, getUsers]);

    useEffect(() => {
        if (!userRoles) {
            getUserRoles();
        }
    }, [userRoles, getUserRoles]);

    useEffect(() => {
        const equalArrays = (arr1, arr2) => {
            if (userPermissions.length !== user.permissions.length) {
                return false;
            }

            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) {
                    return false;
                }
            }
            return true;
        };

        if (userPermissions !== null && user?.permissions != null) {
            if (!equalArrays(userPermissions, user.permissions)) {
                setUser({ ...user, permissions: userPermissions });
            }
        }
    }, [userPermissions, user]);

    useEffect(() => {
        if (users && userRoles) {
            if (creating) {
                // Set all permissions to modules on create new user available
                const userPermissions = permissionList?.filter(
                    (e) => e.split(".")[0] === "Module"
                );

                setUser({
                    customerId: "",
                    username: "",
                    roles: [userRoles[0].value],
                    permissions: userPermissions,
                    mainPage: "",
                    password: "",
                });
                setUserPermissions(userPermissions ? userPermissions : []);
                setLoading(false);
            } else {
                const user = users.find((x) => x.id === match.params.id);
                if (user) {
                    if (!user["mainPage"]) {
                        user["mainPage"] = permissionsToModules(
                            user.permissions,
                            user.roles.includes("Administrator")
                        )[0].permission;
                    }
                    user["password"] = "";
                    setUser(user);
                    setUserPermissions(user.permissions);
                    setLoading(false);
                } else {
                    setUserPermissions([]);
                    setLoading(false);
                }
            }
        }
    }, [users, match.params.id, creating, userRoles, permissionList]);

    useEffect(() => {
        if (userPermissions) {
            let availableModules = permissionsToModules(
                userPermissions,
                user.roles.includes("Administrator")
            );
            setModulesAvailable(availableModules);
            // If there is no main page set, set it to the first available module
            // or if the set main page is not available, set it to the first available module
            if (
                !user?.mainPage ||
                !availableModules.find(
                    (mod) => mod.permission === user.mainPage
                )
            ) {
                setUser({ ...user, mainPage: availableModules[0].permission });
            }
        }
    }, [userPermissions, user]);

    useEffect(() => {
        if (!apiLoading && apiStatusOk && submitted) {
            push(`/settings/users`);
            setSubmitted(false);
        }
    }, [apiLoading, submitted, apiStatusOk, push]);

    const handleTextInputChange = (state, converter) => (e) => {
        setUser({
            ...user,
            [state]: converter ? converter(e.target.value) : e.target.value,
        });
    };

    const handleSubmit = () => {
        updateUser(user);
        setSubmitted(true);
    };

    const handleCreate = () => {
        createUser(user);
        setSubmitted(true);
    };

    const handleCancel = () => {
        push(`/settings/users`);
    };

    const handleDelete = () => {
        setConfirmDialog({
            open: true,
            confirmCallback: () => {
                deleteTheUser(user.id);
                setConfirmDialog(initialConfirmDialog);
            },
            cancelCallback: () => setConfirmDialog(initialConfirmDialog),
            title: "Delete User",
            content: (
                <Typography>
                    Are you sure you want to delete <b>{user.name}</b>?
                </Typography>
            ),
            color: theme.palette.error.main,
            type: "warning",
        });
    };

    const deleteTheUser = (id) => {
        deleteUser(id);
        setSubmitted(true);
    };

    const renderActionButtons = () => {
        return (
            <div className={classes.actionButtons}>
                {permissions.includes("Admin.ManageUsers.Delete") &&
                    !creating && (
                        <CustomButton
                            onClick={handleDelete}
                            disabled={apiLoading}
                            variant="contained"
                            disableElevation
                            className={classes.deleteButton}
                        >
                            Delete
                        </CustomButton>
                    )}
                <CustomButton
                    onClick={handleCancel}
                    disabled={apiLoading}
                    variant="contained"
                    disableElevation
                    className={classes.cancelButton}
                >
                    Cancel
                </CustomButton>
                {(permissions.includes("Admin.ManageUsers.Modify") ||
                    (permissions.includes("Admin.ManageUsers.Create") &&
                        creating)) && (
                    <CustomButton
                        onClick={creating ? handleCreate : handleSubmit}
                        disabled={apiLoading}
                        variant="contained"
                        disableElevation
                        color="primary"
                        className={classes.submitButton}
                    >
                        {apiLoading ? <CircularProgress size={14} /> : "Submit"}
                    </CustomButton>
                )}
            </div>
        );
    };

    let expandedPermissionList = transformPermissionList(permissionList);

    const renderForm = () => {
        return (
            <Grid container spacing={4} className={classes.formContainer}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography
                            variant="h4"
                            className={classes.formSubtitle}
                        >
                            Details
                        </Typography>
                        <CustomInputLabel>Username</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="text"
                            onChange={handleTextInputChange("username")}
                            value={user.username}
                        />
                        <CustomInputLabel>Email</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="text"
                            onChange={handleTextInputChange("email")}
                            value={user.email}
                        />
                        {creating ? (
                            <CustomInputLabel>Password</CustomInputLabel>
                        ) : (
                            <CustomInputLabel>Change password</CustomInputLabel>
                        )}
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="password"
                            autoComplete="new-password"
                            onChange={handleTextInputChange("password")}
                            value={user.password}
                        />
                        <CustomInputLabel>Roles</CustomInputLabel>
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                        >
                            <CustomSelect
                                inputProps={{
                                    margin: "dense",
                                }}
                                type="text"
                                value={user.roles}
                                onChange={handleTextInputChange(
                                    "roles",
                                    (s) => {
                                        return [s];
                                    }
                                )}
                            >
                                {userRoles.map((role) => {
                                    return (
                                        <MenuItem
                                            key={role.id}
                                            value={role.value}
                                            className={classes.selectMenuItem}
                                        >
                                            {role.value}
                                        </MenuItem>
                                    );
                                })}
                            </CustomSelect>
                        </FormControl>

                        <CustomInputLabel>Main Page</CustomInputLabel>
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                        >
                            <CustomSelect
                                inputProps={{
                                    margin: "dense",
                                }}
                                type="text"
                                value={user.mainPage}
                                onChange={handleTextInputChange(
                                    "mainPage",
                                    (s) => {
                                        return s;
                                    }
                                )}
                            >
                                {modulesAvailable.map((module) => {
                                    return (
                                        <MenuItem
                                            key={module.id}
                                            value={module.permission}
                                            className={classes.selectMenuItem}
                                        >
                                            {module.name}
                                        </MenuItem>
                                    );
                                })}
                            </CustomSelect>
                        </FormControl>

                        <CustomInputLabel>Permissions</CustomInputLabel>
                        <PermissionsSelection
                            permissionList={expandedPermissionList}
                            userPermissions={userPermissions}
                            setUserPermissions={setUserPermissions}
                        ></PermissionsSelection>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Navigation push={push}>
            {loading ? (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            ) : user ? (
                <>
                    <ConfirmationDialog {...confirmDialog} />
                    <div className={classes.headerContainer}>
                        <div className={classes.titleContainer}>
                            <Typography variant="h2" className={classes.title}>
                                {creating ? "New User" : user.name}
                            </Typography>
                        </div>
                        {!smallScreen && renderActionButtons()}
                    </div>
                    {renderForm()}
                    {smallScreen && renderActionButtons()}
                </>
            ) : (
                <Typography variant="h2">User not found</Typography>
            )}
        </Navigation>
    );
};

export default connect(mapStateToProps, {
    getUsers,
    updateUser,
    createUser,
    deleteUser,
    getUserRoles,
    push,
})(User);
