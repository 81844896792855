import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 8),
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.generalUi.panelBackground,
    },
    title: {
        flexGrow: 1,
        fontWeight: 400,
    },
    appbarContainer: {
        top: 0,
        position: "sticky",
        width: "100%",
    },
}));

const ActionBar = (props) => {
    const classes = useStyles(props);

    return (
        <>
            <div className={classes.appbarContainer}>
                <Toolbar className={classes.root}>
                    <Typography variant="h3" className={classes.title}>
                        {props.title}
                    </Typography>
                    {props.children}
                </Toolbar>
            </div>
        </>
    );
};

export default ActionBar;
