import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    useTheme,
    IconButton,
    Divider,
    FormControlLabel,
} from "@material-ui/core";
import SwapHorizontalCircleRoundedIcon from "@material-ui/icons/SwapHorizontalCircleRounded";
import { CustomCheckbox } from "../../common/restyled-mui/CustomCheckBox";
//import SelectionTable from '../../common/SelectionTable';
import SelectionTable from "../../common/SelectionTableVirtualized";
import {
    getSitesList,
    getSiteUsers,
    connectSiteUser,
    disconnectSiteUser,
} from "../../redux/actions/settings";
import { getUsers, getUserSites } from "../../redux/actions/users";
import Navigation from "../settings-navigation/Navigation";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    loading: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
    },
    titleContainer: {
        marginBottom: `${theme.spacing(2)}px`,
        height: `${theme.spacing(11)}px`,
        lineHeight: `${theme.spacing(11)}px`,
        display: "flex",
        width: "100%",
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    topContainer: {
        display: "flex",
        flexDirection: "row",
    },
    mainContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
    },
    mainDivider: {
        top: `-${theme.spacing(4)}px`,
        position: "relative",
        height: `calc(100% + ${theme.spacing(4)}px)`,
        margin: `0 ${theme.spacing(8)}px`,
    },
    tableContainer: {
        flex: 1,
        display: "flex",
        width: 330,
        flexDirection: "column",
    },
    largeIcon: {
        width: 32,
        height: 32,
        margin: 4,
    },
    rightAlign: {
        textAlign: "end",
        width: "100%",
    },
}));

const mapStateToProps = (state) => ({
    sites: state.sitesList,
    siteUsers: state.siteUsers,
    users: state.users,
    userSites: state.userSites,
    permissions: state.user.permissions,
});

const UserAccess = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [source, setSource] = useState("Sites");
    const [selectedSites, setSelectedSites] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedSite, setSelectedSite] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedSiteName, setSelectedSiteName] = useState();
    const [selectedUserName, setSelectedUserName] = useState();
    const [filterSelected, setFilterSelected] = useState(false);
    const {
        sites,
        getSitesList,
        users,
        getUsers,
        siteUsers,
        getSiteUsers,
        userSites,
        getUserSites,
        connectSiteUser,
        disconnectSiteUser,
        push,
        // permissions,
    } = props;

    useEffect(() => {
        if (!sites) {
            getSitesList();
        }
    }, [sites, getSitesList]);

    useEffect(() => {
        if (!users) {
            getUsers();
        }
    }, [users, getUsers]);

    useEffect(() => {
        if (selectedSite) {
            getSiteUsers(selectedSite);
        }
    }, [selectedSite, getSiteUsers]);

    useEffect(() => {
        if (siteUsers) {
            setSelectedUsers(siteUsers);
        } else {
            setSelectedUsers([]);
        }
    }, [siteUsers, setSelectedUsers]);

    useEffect(() => {
        if (selectedUser) {
            getUserSites(selectedUser);
        }
    }, [selectedUser, getUserSites]);

    useEffect(() => {
        if (userSites) {
            setSelectedSites(userSites);
        } else {
            setSelectedSites([]);
        }
    }, [userSites, setSelectedSites]);

    const swapSource = () => {
        setSelectedUsers([]);
        setSelectedUser();
        setSelectedSites([]);
        setSelectedSite();
        if (source === "Sites") {
            setSource("Users");
        } else {
            setSource("Sites");
        }
    };

    const siteSelected = (id) => {
        if (id !== selectedSite) {
            if (source === "Sites") {
                setSelectedUsers([]);
                setSelectedSite(id);
                setSelectedSiteName(sites.find((s) => s.id === id).name);
            } else {
                connectSiteUser(id, selectedUser);
            }
        }
    };

    const siteUnselected = (id) => {
        if (source !== "Sites") {
            disconnectSiteUser(id, selectedUser);
        }
    };

    const userSelected = (id) => {
        if (id !== selectedUser) {
            if (source === "Users") {
                setSelectedSites([]);
                setSelectedUser(id);
                setSelectedUserName(users.find((s) => s.id === id).name);
            } else {
                connectSiteUser(selectedSite, id);
            }
        }
    };

    const userUnselected = (id) => {
        if (source !== "Users") {
            disconnectSiteUser(selectedSite, id);
        }
    };

    const searchFilterUsers = (item) => {
        return selectedUsers.length > 0
            ? selectedUsers.findIndex((u) => u === item.id) >= 0
            : false;
    };

    const searchFilterSites = (item) => {
        return selectedSites.length > 0
            ? selectedSites.findIndex((u) => u === item.id) >= 0
            : false;
    };

    const changeFilterSelected = () => {
        setFilterSelected(!filterSelected);
    };

    const filteredUsers = users
        ? !filterSelected || source === "Users"
            ? users
            : users.filter((x) => searchFilterUsers(x))
        : [];
    const filteredSites = sites
        ? !filterSelected || source === "Sites"
            ? sites
            : sites.filter((x) => searchFilterSites(x))
        : [];

    return (
        <Navigation push={push}>
            <div className={classes.topContainer}>
                <div className={classes.tableContainer}>
                    <div className={classes.titleContainer}>
                        <Typography variant="h2">Site/user access</Typography>
                    </div>
                </div>
                <div>
                    <IconButton
                        color="primary"
                        aria-label="Swap"
                        component="span"
                        onClick={swapSource}
                    >
                        <SwapHorizontalCircleRoundedIcon
                            className={classes.largeIcon}
                        />
                    </IconButton>
                </div>
                <div className={classes.tableContainer}>
                    <div className={classes.titleContainer}>
                        <Typography
                            variant="h5"
                            style={{ lineHeight: `${theme.spacing(11)}px` }}
                        >
                            Access
                        </Typography>
                        <div className={classes.rightAlign}>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        style={{ height: "auto" }}
                                        checked={filterSelected}
                                        onChange={changeFilterSelected}
                                    />
                                }
                                label="Show selected only"
                                style={{ marginRight: 0 }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.mainContainer}>
                <div
                    className={classes.tableContainer}
                    style={{ order: source === "Sites" ? 0 : 2 }}
                >
                    {(source === "Sites" || selectedUsers?.length) > 0 ? (
                        <>
                            {source === "Sites" ? (
                                <Typography variant="h3">Sites</Typography>
                            ) : (
                                <Typography variant="h3">
                                    {selectedUserName}
                                </Typography>
                            )}
                            <SelectionTable
                                header="sites"
                                columnHeaders={["Id", "Name", "Description"]}
                                columnNames={["id", "name", "description"]}
                                multiSelect={source !== "Sites"}
                                items={filteredSites}
                                selected={selectedSites}
                                setSelected={setSelectedSites}
                                itemSelected={siteSelected}
                                itemUnselected={siteUnselected}
                            />
                        </>
                    ) : (
                        <div
                            className={classes.headerContainer}
                            style={{ justifyContent: "center" }}
                        >
                            <Typography variant="h3">
                                No user selected
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ opacity: 0.5 }}
                            >
                                Select one to change access
                            </Typography>
                        </div>
                    )}
                </div>

                <div style={{ order: 1 }}>
                    <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.mainDivider}
                    />
                </div>

                <div
                    className={classes.tableContainer}
                    style={{ order: source === "Users" ? 0 : 2 }}
                >
                    {(source === "Users" || selectedSites?.length) > 0 ? (
                        <>
                            {source === "Users" ? (
                                <Typography variant="h3">Users</Typography>
                            ) : (
                                <Typography variant="h3">
                                    {selectedSiteName}
                                </Typography>
                            )}
                            <SelectionTable
                                header="users"
                                columnHeaders={["Id", "Username", "Email"]}
                                columnNames={["id", "username", "email"]}
                                multiSelect={source !== "Users"}
                                items={filteredUsers}
                                selected={selectedUsers}
                                setSelected={setSelectedUsers}
                                itemSelected={userSelected}
                                itemUnselected={userUnselected}
                            />
                        </>
                    ) : (
                        <div
                            className={classes.headerContainer}
                            style={{ justifyContent: "center" }}
                        >
                            <Typography variant="h3">
                                No site selected
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ opacity: 0.5 }}
                            >
                                Select one to change access
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
        </Navigation>
    );
};

export default connect(mapStateToProps, {
    getSitesList,
    getUsers,
    getSiteUsers,
    getUserSites,
    connectSiteUser,
    disconnectSiteUser,
    push,
})(UserAccess);
