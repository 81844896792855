import React, { useState } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import SearchPanel from "../features/hwwa-panel/SearchPanel";
import NoSite from "../features/hwwa-charts/NoSite";
import AnalyticsCharts from "../features/hwwa-charts/AnalyticsCharts";
import ConfirmationDialog from "../common/Confirmation";
import { initialConfirmDialog } from "../common/Confirmation";

const HWWAPage = (props) => {
    const { container, mobile, tablet } = props;
    const [confirmation, setConfirmation] = useState(initialConfirmDialog);

    const resetConfirmation = () => {
        setConfirmation(initialConfirmDialog);
    };

    return (
        <>
            <SearchPanel
                toggleable={tablet}
                container={container}
                setConfirmation={setConfirmation}
                resetConfirmation={resetConfirmation}
            >
                <ConfirmationDialog {...confirmation} />
                <Switch>
                    <Route
                        path={`/analytics`}
                        exact
                        render={(props) => (
                            <NoSite
                                {...props}
                                creating={false}
                                mobile={mobile}
                            />
                        )}
                    />
                    <Route
                        path={`/analytics/:id`}
                        exact
                        render={(props) => (
                            <AnalyticsCharts
                                {...props}
                                creating={false}
                                mobile={mobile}
                            />
                        )}
                    />
                </Switch>
            </SearchPanel>
        </>
    );
};

export default withRouter(HWWAPage);
