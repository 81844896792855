import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
    SimpleTab as Tab,
    SimpleTabs as Tabs,
    SimpleTabPanel as TabPanel,
} from "../../common/restyled-mui/SimpleTabs";
import YearVariabilityChart from "./YearVariabilityChart";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: 14,
        paddingBottom: theme.spacing(2),
    },
    tabPanel: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    chart: {
        flex: 1,
    },
}));

const YearVariability = (props) => {
    const classes = useStyles();
    const { months, data } = props;

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const getMonths = (data) => {
        const listOfMonths = data.map((x) => x.month);
        return listOfMonths.map((value) => ({
            value,
            name: months.find((month, index) => index + 1 === value),
        }));
    };

    const [tab, setTab] = useState(getMonths(data)[0].value);
    const tabData = data.find((x) => x.month === tab);
    return (
        <>
            <Tabs value={tab} onChange={handleChange}>
                {getMonths(data).map((month) => {
                    return (
                        <Tab
                            key={month.value}
                            label={month.name}
                            value={month.value}
                        />
                    );
                })}
            </Tabs>
            <TabPanel className={classes.tabPanel}>
                <Typography variant="h4" className={classes.subtitle}>
                    Year To Year Variability Of Monthly Mean Wind Speed
                </Typography>
                <div className={classes.chart}>
                    <YearVariabilityChart data={tabData} />
                </div>
            </TabPanel>
        </>
    );
};

export default YearVariability;
