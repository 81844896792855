import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    CircularProgress,
    useTheme,
    Divider,
} from "@material-ui/core";
import moment from "moment";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import {
    getAlerts,
    getAlertOperators,
    getAlertLogics,
    getAlertFrequencies,
    updateAlert,
    createAlert,
    deleteAlert,
} from "../../redux/actions/alerts";
import { getSites } from "../../redux/actions/sites";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteIcon } from "../../assets/icons/fontawesome/deleteIcon";
import { closeIcon } from "../../assets/icons/fontawesome/closeIcon";
import { checkIcon } from "../../assets/icons/fontawesome/checkIcon";
import { getSettingsStyles } from "../../styles/settingsStyles";
import { handleValidationUpdate } from "../../utils/validation";
import { initialValidation, validate } from "./validation";
import Api from "../../api";
import AlertForm from "./AlertForm";
import NotFoundAlert from "./NotFoundAlert";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    headerContainerDetails: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1.5),
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        height: theme.spacing(12),
        margin: theme.spacing(7),
    },
    headerDivider: {},
    formContainer: {
        padding: theme.spacing(3, 7),
        maxWidth: 700,
    },
    scrollContainer: {
        overflow: "auto",
        flex: 1,
    },
}));

const mapStateToProps = (state) => ({
    alerts: state.alerts,
    alertOperators: state.alertOperators,
    alertLogics: state.alertLogics,
    alertFrequencies: state.alertFrequencies,
    sites: state.sites,
    apiLoading: state.status.loading,
    queries: state.router.location.query,
});

const Alert = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [parameters, setParameters] = useState([]);
    const [validation, setValidation] = useState(initialValidation);
    const {
        alerts,
        alertOperators,
        alertLogics,
        alertFrequencies,
        getAlertFrequencies,
        getAlertOperators,
        getAlertLogics,
        sites,
        getSites,
        updateAlert,
        createAlert,
        deleteAlert,
        match,
        push,
        apiLoading,
        creating,
        setConfirmation,
        resetConfirmation,
        user,
    } = props;

    useEffect(() => {
        if (!alertOperators) {
            getAlertOperators();
        }
    }, [alertOperators, getAlertOperators]);

    useEffect(() => {
        if (!alertLogics) {
            getAlertLogics();
        }
    }, [alertLogics, getAlertLogics]);

    useEffect(() => {
        if (!alertFrequencies) {
            getAlertFrequencies();
        }
    }, [alertFrequencies, getAlertFrequencies]);

    useEffect(() => {
        if (!sites) {
            getSites();
        }
    }, [sites, getSites]);

    useEffect(() => {
        if (alerts) {
            if (!alerts.find((x) => x.id.toString() === match.params.id)) {
                setAlert(null);
            }
        }
    }, [alerts, match.params.id]);

    useEffect(() => {
        if (parameters.length === 0 && alert?.site) {
            Api.getParameters(alert.site.id)
                .then((res) => {
                    const newParameters = res.data;
                    setParameters(newParameters);
                })
                .catch((err) => {
                    console.log(err);
                    setParameters([]);
                });
        }
    }, [parameters, alert]);

    useEffect(() => {
        const initAlert = async (alert) => {
            const frequency = alertFrequencies.find(
                (x) => x.id === alert.frequency.id
            );
            const site = sites.find((x) => x.id === alert.site.id);

            // Repeated, but solves the problem of not having parameters
            if (parameters.length === 0) {
                const newParameters = await Api.getParameters(site.id);

                setParameters(newParameters.data);

                alert.parameters.forEach((parameter) => {
                    parameter.parameter = newParameters.data.find(
                        (x) => x.parameterId === parameter.parameterId
                    );

                    parameter.operators.forEach((operator, idx) => {
                        parameter.operators[idx] = alertOperators.find(
                            (x) => x.id === operator.id
                        );
                    });

                    parameter.logic = alertLogics.find(
                        (x) => x.id === parameter.logicId
                    );
                });
            } else {
                alert.parameters.forEach((parameter) => {
                    parameter.parameter = parameters.find(
                        (x) => x.parameterId === parameter.parameterId
                    );

                    parameter.operators.forEach((operator, idx) => {
                        parameter.operators[idx] = alertOperators.find(
                            (x) => x.id === operator.id
                        );
                    });

                    parameter.logic = alertLogics.find(
                        (x) => x.id === parameter.logicId
                    );
                });
            }

            alert.weatherWindowLengthOperator = alertOperators.find(
                (x) => x.id === alert.windowLength.operator.id
            );
            alert.weatherWindowLengthValue = alert.windowLength.value;

            return {
                ...alert,
                frequency,
                site,
            };
        };

        const setNewAlert = () => {
            setAlert({
                id: 0,
                name: "",
                description: "",
                isActive: false,
                expiryDate: moment().add(2, "months").format(),
                recipients: [""],
                frequency: { id: 1, frequency: 1 },
                site: null,
                weatherWindowLengthOperator: { id: 5, type: "EQ" },
                weatherWindowLengthValue: "1",
                parameters: [
                    {
                        values: [],
                        operators: [{ id: 3, type: "GT" }],
                        logic: null,
                    },
                ],
            });

            setLoading(false);
        };

        if (
            alerts &&
            alertOperators &&
            alertFrequencies &&
            sites &&
            alertLogics
        ) {
            if (creating) {
                if (!alert || alert.id) {
                    setNewAlert();
                }
            } else if (!alert || alert.id.toString() !== match.params.id) {
                const alert = alerts.find(
                    (x) => x.id.toString() === match.params.id
                );
                if (alert) {
                    setValidation(initialValidation);
                    initAlert(alert)
                        .then((res) => {
                            setAlert(res);
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            }
        }
    }, [
        alerts,
        match.params.id,
        creating,
        alertOperators,
        alertFrequencies,
        sites,
        alert,
        parameters,
        alertLogics,
    ]);

    useEffect(() => {
        if (submitted && !apiLoading) {
            if (creating) {
                push("/alerts");
            }
            setSubmitted(false);
        }
    }, [submitted, apiLoading, creating, push]);

    const handleSiteChange = (_e, value) => {
        if (value) {
            Api.getParameters(value.id)
                .then((res) => {
                    const newParameters = res.data;
                    setParameters(newParameters);
                })
                .catch((err) => {
                    console.log(err);
                    setParameters([]);
                });
        } else {
            setParameters([]);
        }
        handlePropertyChange("site")(value);
    };

    const handlePropertyChange = (state) => (value) => {
        setAlert((prevState) => ({
            ...prevState,
            [state]: value,
        }));

        handleValidationUpdate(setValidation, state);
    };

    const handleParametersAdd = () => {
        const newParameters = [...alert.parameters];
        newParameters.push({
            values: [],
            operators: [],
            logic: null,
        });
        setAlert((prevState) => ({
            ...prevState,
            parameters: newParameters,
        }));
    };

    const handleParametersParamChange = (id) => (value) => {
        const newParameters = [...alert.parameters];
        newParameters[id].parameter = value;
        newParameters[id].parameterId = value.parameterId;
        newParameters[id].name = value.name;
        newParameters[id].unit = value.unit;
        setAlert((prevState) => ({
            ...prevState,
            parameters: newParameters,
        }));
    };

    const handleParametersOperatorChange = (id, idx) => (e) => {
        const newParameters = [...alert.parameters];
        newParameters[id].operators[idx] = e.target.value;

        // Special condition if the value is =, change the operator to null
        if (e.target.value.id === 5 && idx === 0) {
            handleParametersLogicChange(id)({ target: { value: null } });
        }

        setAlert((prevState) => ({
            ...prevState,
            parameters: newParameters,
        }));
    };

    const handleParametersValueChange = (id, idx) => (e) => {
        const newParameters = [...alert.parameters];
        newParameters[id].values[idx] = e.target.value;
        setAlert((prevState) => ({
            ...prevState,
            parameters: newParameters,
        }));
    };

    const handleParametersLogicChange = (id) => (e) => {
        const newParameters = [...alert.parameters];
        newParameters[id].logic = e.target.value;

        // Special condition if the value is null, it has to delete the second value and operator
        if (e.target.value === null || e.target.value.type === null) {
            newParameters[id].values = [newParameters[id].values[0], 0];
            newParameters[id].operators = [newParameters[id].operators[0]];
        }

        setAlert((prevState) => ({
            ...prevState,
            parameters: newParameters,
        }));
    };

    const handleParametersDelete = (id) => () => {
        const newParameters = [...alert.parameters];
        newParameters.splice(id, 1);
        setAlert((prevState) => ({
            ...prevState,
            parameters: newParameters,
        }));
    };

    const handleSubmit = () => {
        const valid = validate(setValidation, alert, sites);
        if (valid) {
            const updatedAlert = {
                ...alert,
            };
            updatedAlert.windowLength = {
                value: updatedAlert.weatherWindowLengthValue,
                operator: updatedAlert.weatherWindowLengthOperator,
            };
            updatedAlert.parameters.forEach((param) => {
                if (param.logic && param.logic.id === 0) {
                    param.logic = null;
                }
            });
            delete updatedAlert.weatherWindowLengthValue;
            delete updatedAlert.weatherWindowLengthOperator;

            updateAlert(updatedAlert);
            setSubmitted(true);
        }
    };

    const handleCreate = () => {
        const valid = validate(setValidation, alert, sites);
        if (valid) {
            const alertFixed = { ...alert };
            alertFixed.windowLength = {
                value: alertFixed.weatherWindowLengthValue,
                operator: alertFixed.weatherWindowLengthOperator,
            };
            alertFixed.parameters.forEach((param) => {
                delete param.parameter;
            });
            delete alertFixed.id;
            delete alertFixed.weatherWindowLengthValue;
            delete alertFixed.weatherWindowLengthOperator;

            createAlert(alertFixed);
            setSubmitted(true);
        }
    };

    const handleCancel = () => {
        push(`/alerts`);
    };

    const handleDelete = () => {
        setConfirmation({
            open: true,
            confirmCallback: () => {
                handleTheAlert(alert.id);
                resetConfirmation();
            },
            cancelCallback: () => resetConfirmation(),
            title: "Delete Alert",
            content: (
                <Typography>
                    Are you sure you want to delete <b>{alert.name}</b>?
                </Typography>
            ),
            color: theme.palette.error.main,
            type: "warning",
        });
    };

    const handleTheAlert = (id) => {
        push(`/alerts`);
        deleteAlert(id);
        setSubmitted(true);
    };

    const renderActionButtons = () => {
        return (
            <div className={classes.actionButtonsDetails}>
                {!creating && (
                    <CustomButton
                        onClick={handleDelete}
                        disabled={apiLoading}
                        variant="text"
                        color="inherit"
                        className={classes.actionButton}
                    >
                        <FontAwesomeIcon
                            icon={deleteIcon}
                            className={classes.actionIcon}
                        />
                        Delete
                    </CustomButton>
                )}
                <CustomButton
                    onClick={handleCancel}
                    disabled={apiLoading}
                    variant="text"
                    color="inherit"
                    className={classes.actionButton}
                >
                    <FontAwesomeIcon
                        icon={closeIcon}
                        className={classes.actionIcon}
                    />
                    Cancel
                </CustomButton>
                <CustomButton
                    onClick={creating ? handleCreate : handleSubmit}
                    disabled={apiLoading}
                    variant="text"
                    color="inherit"
                    className={classes.actionButton}
                >
                    <FontAwesomeIcon
                        icon={checkIcon}
                        className={classes.actionIcon}
                    />
                    {apiLoading ? <CircularProgress size={14} /> : "Submit"}
                </CustomButton>
            </div>
        );
    };

    let title = "New Alert";
    if (!creating && alerts) {
        const alertName = alerts.find(
            (x) => x.id.toString() === match.params.id
        )?.name;
        title = alertName || title;
    }

    return loading ? (
        <div className={classes.loading}>
            <CircularProgress />
        </div>
    ) : alert ? (
        <>
            <div className={classes.headerContainerDetails}>
                {renderActionButtons()}
            </div>
            <Divider
                orientation="horizontal"
                variant="fullWidth"
                className={classes.headerDivider}
            />
            <div className={classes.scrollContainer}>
                <AlertForm
                    alert={alert}
                    handlePropertyChange={handlePropertyChange}
                    handleParametersAdd={handleParametersAdd}
                    handleParametersParamChange={handleParametersParamChange}
                    handleParametersOperatorChange={
                        handleParametersOperatorChange
                    }
                    handleParametersValueChange={handleParametersValueChange}
                    handleParametersLogicChange={handleParametersLogicChange}
                    handleParametersDelete={handleParametersDelete}
                    handleSiteChange={handleSiteChange}
                    validation={validation}
                    alertFrequencies={alertFrequencies}
                    alertOperators={alertOperators}
                    alertLogics={alertLogics}
                    sites={sites}
                    parameters={parameters}
                    title={title}
                    user={user}
                    creating={creating}
                />
            </div>
        </>
    ) : (
        <NotFoundAlert />
    );
};

export default connect(mapStateToProps, {
    getAlerts,
    getAlertOperators,
    getAlertLogics,
    getAlertFrequencies,
    updateAlert,
    createAlert,
    deleteAlert,
    getSites,
    push,
})(Alert);
