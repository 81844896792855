const generateUniqueId = (prefix) => {
    return `${Math.random().toString(36).substring(2, 10)}`;
};

const createIcon = ({
    iconType,
    path,
    width,
    height,
    params = ["currentColor"],
}) => {
    const uniqueId = generateUniqueId(iconType);

    return {
        prefix: "conwx",
        iconName: `${iconType}-${uniqueId}`,
        icon: [width, height, params, uniqueId, path],
    };
};

export default createIcon;
