import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import AuthApp from "./indexApp";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles/theme";
import { configureStore } from "./redux/store";
import { history } from "./redux/reducers";
import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import config from "./config.json";

export const store = configureStore();

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <MsalProvider instance={msalInstance}>
                <ConnectedRouter history={history}>
                    <CookiesProvider>
                        <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                        >
                            <Helmet
                                htmlAttributes={{ style: "height: 100%;" }}
                                bodyAttributes={{
                                    style: `background-color: ${theme.palette.generalUi.background}; margin: 0; height: 100%;`,
                                }}
                            >
                                <title>{config.appTitle}</title>
                                <link
                                    rel="preconnect"
                                    href="https://fonts.gstatic.com"
                                />
                                <link
                                    href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                                    rel="stylesheet"
                                />
                            </Helmet>
                            <AuthApp />
                        </MuiPickersUtilsProvider>
                    </CookiesProvider>
                </ConnectedRouter>
            </MsalProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);
