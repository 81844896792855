import {
    SITES_SETTINGS,
    SITES_SEARCH_SETTINGS,
    SITES_LIST,
    SITE_USERS,
} from "../types";

export function sitesSettings(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case SITES_SETTINGS.LOADED:
            return payload;
        case SITES_SETTINGS.CLEAR:
            return payload;
        default:
            return state;
    }
}

export function siteSearchSettings(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case SITES_SEARCH_SETTINGS.CHANGED:
            return payload;
        default:
            return state;
    }
}

export function sitesList(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case SITES_LIST.LOADED:
            return payload;
        default:
            return state;
    }
}

export function siteUsers(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case SITE_USERS.LOADED:
            return payload;
        default:
            return state;
    }
}
