import { PARAMETERS } from "../types";
import Api from "../../api";
import { STATUS } from "../types";
import { changeStatus } from "./status";
import { handleError } from "../../utils/error";

export const getGroupedParameters = () => {
    return (dispatch) => {
        return Api.getGroupedParameters()
            .then((res) => {
                dispatch({ type: PARAMETERS.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const updateParameters = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        if (getState().user.isMultiUser) {
            Api.getGroupedParameters()
                .then((res) => {
                    res.data.forEach((chart) =>
                        chart.groups.forEach((group) => {
                            for (let i = 0; i < group.parameters.length; i++) {
                                const newData = data.find(
                                    (d) =>
                                        d.twinChartParameterId ===
                                        group.parameters[i].twinChartParameterId
                                );
                                if (newData) {
                                    group.parameters[i] = {
                                        ...group.parameters[i],
                                        ...newData,
                                    };
                                }
                            }
                        })
                    );

                    dispatch({ type: PARAMETERS.LOADED, payload: res.data });
                })
                .catch((err) => {
                    handleError(dispatch, err);
                });
        } else {
            return Api.updateParameters(data)
                .then((res) => {
                    dispatch({ type: PARAMETERS.UPDATED });
                    dispatch(getGroupedParameters());
                    dispatch(
                        changeStatus(
                            STATUS.SUCCESS,
                            "Parameters updated successfully"
                        )
                    );
                })
                .catch((err) => {
                    handleError(dispatch, err);
                });
        }
    };
};
