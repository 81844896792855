import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const Hindcasts = {
    getHindcasts(data) {
        return axios({
            method: 'post',
            url: `${API_URL}/hindcast/analysis/?format=portal`,
            data,
        });
    },

    getHindcastCsv(lat, lon, parameterList) {
        return axios({
            method: 'get',
            url:
                `${API_URL}/hindcast/csv/?lat=${lat}&lon=${lon}&parameterlist=` +
                parameterList.join(','),
        });
    },

    getHindcastParameters() {
        return axios({
            method: 'get',
            url: `${API_URL}/hindcast/parameterlist`,
        });
    },
};

export default Hindcasts;
