import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Sites from "../features/settings-sites/Sites";
import Site from "../features/settings-sites/Site";

import Users from "../features/settings-users/Users";
import User from "../features/settings-users/User";

import UserAccess from "../features/settings-access/UserAccess";

import StatusSnackbar from "../common/StatusSnackbar";

import PageNotFound from "./PageNotFound";

const mapStateToProps = (state) => ({
    permissions: state.user.permissions,
});

const App = (props) => {
    const { permissions } = props;

    const dispatch = useDispatch();

    const goToHomepage = () => {
        dispatch(push("/"));
    };

    return (
        <>
            <StatusSnackbar />
            <Switch>
                <Route exact path={`/settings`}>
                    {permissions.includes("Admin.ManageSites") && (
                        <Redirect to={`/settings/sites`} />
                    )}
                    {permissions.includes("Admin.ManageUsers") && (
                        <Redirect to={`/settings/users`} />
                    )}
                    {permissions.includes("Admin.ManageAccessSites") && (
                        <Redirect to={`/settings/access`} />
                    )}
                </Route>

                {/* sites */}
                {permissions.includes("Admin.ManageSites") && (
                    <Route
                        path={`/settings/sites`}
                        exact
                        render={(props) => <Sites {...props} />}
                    />
                )}
                {permissions.includes("Admin.ManageSites.Create") && (
                    <Route
                        path={`/settings/sites/create`}
                        exact
                        render={(props) => <Site {...props} creating={true} />}
                    />
                )}
                {permissions.includes("Admin.ManageSites") && (
                    <Route
                        path={`/settings/sites/:id`}
                        exact
                        render={(props) => <Site {...props} creating={false} />}
                    />
                )}

                {/* users */}
                {permissions.includes("Admin.ManageUsers") && (
                    <Route
                        path={`/settings/users`}
                        exact
                        render={(props) => <Users {...props} />}
                    />
                )}
                {permissions.includes("Admin.ManageUsers.Create") && (
                    <Route
                        path={`/settings/users/create`}
                        exact
                        render={(props) => <User {...props} creating={true} />}
                    />
                )}
                {permissions.includes("Admin.ManageUsers") && (
                    <Route
                        path={`/settings/users/:id`}
                        exact
                        render={(props) => <User {...props} creating={false} />}
                    />
                )}

                {/* access */}
                {permissions.includes("Admin.ManageAccessSites") && (
                    <Route
                        path={`/settings/access`}
                        exact
                        render={(props) => <UserAccess {...props} />}
                    />
                )}

                <Route
                    render={(props) => (
                        <PageNotFound goToHomepage={goToHomepage} />
                    )}
                />
            </Switch>
        </>
    );
};

export default connect(mapStateToProps, null)(withRouter(App));
