import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    sub: {
        opacity: 0.5,
    },
}));

const NoSite = (props) => {
    const classes = useStyles(props);
    const { heading, body } = props;

    return (
        <div className={classes.container}>
            <Typography variant="h3" className={classes.parent}>
                {heading}
            </Typography>
            <Typography variant="body1" className={classes.sub}>
                {body}
            </Typography>
        </div>
    );
};

export default NoSite;
