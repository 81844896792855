import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartValueAxis,
    ChartTooltip,
    ChartPlotArea,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { returnLabelFont } from "../../common/charts/chartStyles";
import CustomChartLegend from "../../common/charts/CustomChartLegend";
import CustomChartCategoryAxisItem from "../../common/charts/CustomChartCategoryAxisItem";
import CustomChartValueAxisItem from "../../common/charts/CustomChartValueAxisItem";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
    tooltipSpan: {
        height: 3,
        width: 16,
        marginRight: theme.spacing(2),
    },
    tooltipSeries: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    tooltipCategory: {
        paddingBottom: theme.spacing(1),
        textAlign: "center",
    },
}));

const SimpleChart = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const {
        data,
        categories,
        categoriesLabel,
        valueLabel,
        seriesNames,
        colors,
        onRender,
        valueName,
        categoryName,
        roundTo,
    } = props;
    const chartCategoryName = categoryName ? categoryName : "category";
    const chartValueName = valueName ? valueName : "value";

    const renderSeriesItem = (data, index) => {
        return (
            <ChartSeriesItem
                key={index}
                data={data}
                /* stack={{ type: "100%" }} */
                stack={false}
                gap={1}
                spacing={-1}
                tooltip={{ visible: true }}
                name={seriesNames[index]}
                color={colors[index]}
                axis={chartValueName}
                type={"column"}
                markers={{ visible: false }}
            />
        );
    };

    const renderTooltip = (props) => {
        const { categoryText, points } = props;
        return (
            <div className={classes.tooltip}>
                <Typography
                    className={classes.tooltipCategory}
                    variant={"subtitle1"}
                >
                    <b>{categoryText}</b>
                </Typography>
                {points.map((point) => {
                    return (
                        <div
                            key={point.series.index}
                            className={classes.tooltipSeries}
                        >
                            <span
                                className={classes.tooltipSpan}
                                style={{ backgroundColor: point.series.color }}
                            />
                            <Typography variant={"subtitle1"}>
                                {roundTo
                                    ? point.value?.toFixed(roundTo)
                                    : point.value}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Chart
            onRender={onRender ? onRender : () => {}}
            transitions={false}
            style={{ height: "100%" }}
        >
            <ChartTooltip
                border={{ width: 0, radius: 5 }}
                background={"white"}
                padding={4}
                render={renderTooltip}
                font={returnLabelFont(theme, 11, 400)}
                shared={true}
            />
            <ChartPlotArea
                background={theme.palette.table.cellBg}
                border={{ color: theme.palette.generalUi.borders, width: 1 }}
            />
            <CustomChartLegend />
            <ChartCategoryAxis>
                <CustomChartCategoryAxisItem
                    title={{ text: categoriesLabel }}
                    categories={categories}
                    name={chartCategoryName}
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <CustomChartValueAxisItem
                    title={{ text: valueLabel }}
                    name={chartValueName}
                />
            </ChartValueAxis>
            <ChartSeries>
                {data.map((x, i) => renderSeriesItem(x, i))}
            </ChartSeries>
        </Chart>
    );
};

export default SimpleChart;
