import React, { useState } from "react";
import { makeStyles, CardContent, CardMedia, Fade } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    imagePane: {
        width: "100%",
        padding: 0,
        backgroundColor: theme.palette.company.conWxBlue,
    },
    media: {
        width: "100%",
        height: "100%",
        backgroundAttachment: "fixed",
    },
}));

const showVestasPopup = window.location.href.indexOf("vestas.nu") !== -1;
//const showVestasPopup = true;

function getRandomInt(min, max) {
    //better random number generation
    let arr = new Uint8Array(1);
    window.crypto.getRandomValues(arr);

    return Math.floor(min + (arr[0] % (max - min)));
}

const LoginImage = (props) => {
    const classes = useStyles(props);
    const { imageUrls } = props;
    const [imageNo] = useState(getRandomInt(0, imageUrls.length - 1));
    const image = require(`../../${imageUrls[imageNo]}`);

    return (
        <CardContent className={classes.imagePane}>
            <Fade in={true}>
                <CardMedia
                    className={classes.media}
                    image={image}
                    title="Login image"
                >
                    {showVestasPopup && (
                        <Fade in={true}>
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "50px",
                                    width: "50%",
                                    alignSelf: "center",
                                    alignContent: "justified",
                                    marginTop: "20%",
                                    lineHeight: "30px",
                                    display: "inline-block",
                                    borderRadius: "8px",
                                    fontSize: "20px",
                                }}
                            >
                                You have been redirected to the temporary
                                platform of Vestas Weather. <br /> We are
                                working on recovery of the full functionality
                                after the cybersecurity incident.
                            </div>
                        </Fade>
                    )}
                </CardMedia>
            </Fade>
        </CardContent>
    );
};

export default LoginImage;
