import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import "../../styles/fonts.css";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        height: 16,
        padding: theme.spacing(2, 0),
    },
    topContent: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    bottomContent: {
        padding: theme.spacing(2, 0),
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        padding: 0,
    },
    subTitle: {
        fontSize: 14,
        padding: 0,
        color: theme.palette.primary.main,
        "&:first-child": {
            paddingRight: theme.spacing(3),
        },
    },
    date: {
        fontSize: 14,
        padding: 0,
    },
    titleContainer: {
        borderRight: `1px solid ${theme.palette.generalUi.borders}`,
        padding: theme.spacing(0, 3),
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    subTitleContainer: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        alignItems: "center",
        height: "100%",
    },
}));

const PDFData = (props) => {
    const classes = useStyles(props);
    const { site, pdfDate } = props;

    return (
        <>
            <div className={classes.content}>
                <div className={classes.topContent}>
                    <div className={classes.titleContainer}>
                        <Typography variant="h4" className={classes.title}>
                            {site.name}
                        </Typography>
                    </div>
                    <div className={classes.titleContainer}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {`LAT: ${site.latitude}`}
                        </Typography>
                        <Typography variant="h4" className={classes.subTitle}>
                            {`LON: ${site.longitude}`}
                        </Typography>
                    </div>
                    <div className={classes.subTitleContainer}>
                        <Typography variant={"body1"} className={classes.date}>
                            {pdfDate}
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PDFData;
