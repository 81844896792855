import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CustomerLogo from '../../../common/CustomerLogo';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        padding: theme.spacing(0, 0, 2),
        borderBottom: `1px solid ${theme.palette.menu.border}`,
    },
    leftContent: {
        flex: 1,
        display: 'flex',
    },
    rightContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    bottomContent: {
        padding: theme.spacing(2, 0),
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        padding: 0,
    },
    subTitle: {
        fontSize: 14,
        padding: 0,
        color: theme.palette.primary.main,
        '&:first-child': {
            paddingRight: theme.spacing(3),
        },
    },
    date: {
        fontSize: 14,
        padding: 0,
    },
    titleContainer: {
        borderRight: `1px solid ${theme.palette.generalUi.borders}`,
        padding: theme.spacing(0, 3),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
            borderRight: 'none',
        },
    },
    logoContainer: {
        height: 22,
    },
    logo: {
        height: '100%',
    },
}));

const PDFTop = (props) => {
    const classes = useStyles(props);
    const { site, pdfDate } = props;

    return (
        <>
            <div className={classes.content}>
                <div className={classes.leftContent}>
                    <div className={classes.titleContainer}>
                        <Typography variant="h4" className={classes.title}>
                            {site.name}
                        </Typography>
                    </div>
                    <div className={classes.titleContainer}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {`LAT: ${site.latitude}`}
                        </Typography>
                        <Typography variant="h4" className={classes.subTitle}>
                            {`LON: ${site.longitude}`}
                        </Typography>
                    </div>
                    <div className={classes.titleContainer}>
                        <Typography variant={'body1'} className={classes.date}>
                            {pdfDate}
                        </Typography>
                    </div>
                </div>
                <div className={classes.rightContent}>
                    <div className={classes.logoContainer}>
                        <CustomerLogo className={classes.logo} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PDFTop;
