import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText } from "@material-ui/core";
import {
    CustomFormControlLabel,
    CustomCheckbox,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import { sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    checkboxControl: {
        width: 80,
    },
    control: {
        display: "block",
    },
}));

const monthsList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const MonthCheckboxes = (props) => {
    const classes = useStyles(props);
    const { months, handleMonthChange, error, helperText } = props;

    const toggleMonth = (month) => (e) => {
        if (months.includes(month)) {
            handleMonthChange(months.filter((x) => x !== month));
        } else {
            handleMonthChange(sortBy([...months, month]));
        }
    };

    const isChecked = (month) => {
        return months.includes(month);
    };

    return (
        <>
            <FormControl error={error} className={classes.control}>
                {monthsList.map((month, index) => {
                    const monthNumber = index + 1;
                    return (
                        <CustomFormControlLabel
                            control={
                                <CustomCheckbox
                                    variant="outlined"
                                    margin="dense"
                                    onClick={toggleMonth(monthNumber)}
                                    checked={isChecked(monthNumber)}
                                />
                            }
                            key={index}
                            className={classes.checkboxControl}
                            label={month}
                            labelPlacement="end"
                        />
                    );
                })}
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        </>
    );
};

export default MonthCheckboxes;
