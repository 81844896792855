export const msalConfig = {
    auth: {
        authority: process.env.REACT_APP_AAD_AUTHORITY,
        clientId: process.env.REACT_APP_AAD_CLIENT_ID,
        redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [process.env.REACT_APP_AAD_SCOPE],
};

//
