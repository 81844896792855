import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
    },
    topContent: {
        display: "flex",
        alignItems: "center",
        height: 22,
        paddingBottom: theme.spacing(1),
        justifyContent: "center",
    },
    subTitle: {
        fontSize: 12,
        padding: 0,
        color: theme.palette.primary.main,
    },
    titleContainer: {
        borderRight: `1px solid ${theme.palette.generalUi.borders}`,
        padding: theme.spacing(0, 3),
        height: "100%",
        display: "flex",
        alignItems: "center",
        "&:last-child": {
            borderRight: "none",
        },
    },
    leftContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
    },
    rightContainer: {
        flex: 1,
    },
    notice: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
    },
    noticeText: {
        fontSize: 12,
        padding: 0,
    },
}));

const PDFBottom = (props) => {
    const classes = useStyles(props);
    return (
        <>
            <div className={classes.content}>
                <div className={classes.topContent}>
                    <div
                        className={clsx(
                            classes.titleContainer,
                            classes.leftContainer
                        )}
                    >
                        <Typography variant="h4" className={classes.subTitle}>
                            Vestas Wind Systems A/S Operational Support
                        </Typography>
                    </div>

                    <div
                        className={clsx(
                            classes.titleContainer,
                            classes.rightContainer
                        )}
                    >
                        <Typography variant="h4" className={classes.subTitle}>
                            PowerForeCastOperations@vestas.com
                        </Typography>
                    </div>
                </div>
                <div className={classes.notice}>
                    <Typography
                        variant={"body1"}
                        className={classes.noticeText}
                    >
                        <b>VESTAS PROPRIETARY NOTICE:</b> This document contains
                        valuable confidential information of Vestas Wind Systems
                        A/S. It is protected by copyright law as an unpublished
                        work. Vestas reserves all patent, copyright, trade
                        secret, and other proprietary rights to it. The
                        information in this document may not be used,
                        reproduced, or disclosed except if and to the extent
                        rights are expressly granted by Vestas in writing and
                        subject to applicable conditions. Vestas disclaims all
                        warranties except as expressly granted by written
                        agreement and is not responsible for unauthorized uses,
                        for which it may pursue legal remedies against
                        responsible parties.
                    </Typography>
                </div>
            </div>
        </>
    );
};

export default PDFBottom;
