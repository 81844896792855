import {
    initialSingleValidation,
    checkValidation,
    validateRequiredString,
    validateRequiredNumber,
    validateRequiredEmail,
    validSingleValidation,
    validateOneRequiredArray,
} from "../../utils/validation";
import moment from "moment";

export const initialValidation = {
    isActive: initialSingleValidation,
    name: initialSingleValidation,
    description: initialSingleValidation,
    pointId: initialSingleValidation,
    reportTypeId: initialSingleValidation,
    daysForecast: initialSingleValidation,
    inLocalTime: initialSingleValidation,
    sendTime: initialSingleValidation,
    sendDays: initialSingleValidation,
    expireDate: initialSingleValidation,
    recipients: initialSingleValidation,
    reportCharts: initialSingleValidation,
};

export const validate = (setValidation, report, availableSites) => {
    const currentValidation = { ...initialValidation };
    currentValidation.name = validateRequiredString(report.name);
    currentValidation.description = validateRequiredString(report.description);
    currentValidation.pointId = validatePointId(report.pointId, availableSites);
    currentValidation.reportTypeId = validateRequiredNumber(
        report.reportTypeId
    );
    currentValidation.daysForecast = validateForecastDays(report.daysForecast);
    currentValidation.sendTime = validateSendTimes(report.sendTime);
    currentValidation.sendDays = validateOneRequiredArray(report.sendDays);
    currentValidation.reportCharts = validateOneRequiredArray(
        report.reportCharts
    );
    currentValidation.expireDate = validateExpiryDate(report.expireDate);
    currentValidation.recipients = validateRecipients(report.recipients);
    const valid = checkValidation(currentValidation);
    if (!valid) {
        setValidation(currentValidation);
    }
    return valid;
};

const validatePointId = (pointId, availableSites) => {
    if (pointId) {
        if (availableSites.some((x) => x.id === pointId)) {
            return validSingleValidation;
        } else {
            return {
                valid: false,
                message:
                    "Associated site has been deleted. Please add valid site.",
            };
        }
    }
    return {
        valid: false,
        message: "Required",
    };
};

const validateExpiryDate = (expiryDate) => {
    if (moment(expiryDate).isBefore(moment(), "day")) {
        return {
            valid: false,
            message: "Cannot be earlier than current date",
        };
    } else if (moment(expiryDate).isAfter(moment().add(5, "y").toDate())) {
        return {
            valid: false,
            message: "Cannot be more than 5 years in the future",
        };
    } else {
        return {
            valid: true,
            message: "",
        };
    }
};

const validateSendTimes = (sendTimes) => {
    return sendTimes.map((sendTime) => {
        if (moment(sendTime, "HH:mm", true).isValid()) {
            return {
                valid: true,
                message: "",
            };
        } else {
            return {
                valid: false,
                message: "Please enter a valid time",
            };
        }
    });
};

const validateRecipients = (recipients) => {
    return recipients.map((recipient) => {
        return validateRequiredEmail(recipient);
    });
};

const validateForecastDays = (value) => {
    const numberValidation = validateRequiredNumber(value);
    if (numberValidation.valid) {
        if (value > 0) {
            if (value > 1000000) {
                return {
                    valid: false,
                    message: "Must be smaller than 1000000",
                };
            } else {
                return {
                    valid: true,
                    message: "",
                };
            }
        } else {
            return {
                valid: false,
                message: "Must be positive",
            };
        }
    } else {
        return numberValidation;
    }
};
