import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    bottomBarRoot: {
        border: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.generalUi.panelBackground,
        borderRadius: theme.spacing(1.25),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            height: "auto",
            alignItems: "normal",
        },
    },
    settingsItem: {
        display: "flex",
        height: 24,
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
        },
    },
    settingsTextContainer: {
        height: "100%",
        padding: theme.spacing(0, 3),
        display: "flex",
        alignItems: "center",
        borderRight: `1px solid ${theme.palette.generalUi.borders}`,
        [theme.breakpoints.down("sm")]: {
            width: "30%",
        },
    },
    settingsText: {
        fontSize: 12,
        fontWeight: 600,
    },
    settingsActionContainer: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
    },
    actionButton: {
        height: 18,
        minWidth: 18,
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0, 1),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.generalUi.borders}`,
        fontSize: 11,
        lineHeight: "10px",
        fontWeight: 600,
        color: theme.palette.text.subText,
        textTransform: "uppercase",
        transition: `
            color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        "&:hover": {
            backgroundColor: theme.palette.generalUi.panelBackground + "EF",
            color: theme.palette.text.subText,
        },
    },
    actionButtonActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.main + "EF",
            color: theme.palette.common.white,
        },
    },
}));

const ChartBottomBar = (props) => {
    const classes = useStyles(props);
    const {
        loading,
        toggleDays,
        days,
        localTimezone,
        toggleTimezone,
        localTimezoneName,
        thresholdsVisible,
        toggleThresholds,
    } = props;

    return (
        <>
            <div className={classes.bottomBarRoot}>
                <div className={classes.settingsItem}>
                    <div className={classes.settingsTextContainer}>
                        <Typography
                            variant="h5"
                            className={classes.settingsText}
                        >
                            Date/Time
                        </Typography>
                    </div>
                    <div className={classes.settingsActionContainer}>
                        {
                            <Button
                                disabled={loading}
                                style={{ width: 48 }}
                                className={clsx(
                                    classes.actionButton,
                                    !localTimezone && classes.actionButtonActive
                                )}
                                onClick={toggleTimezone}
                            >
                                UTC
                            </Button>
                        }
                        <Button
                            disabled={loading}
                            style={{ width: 86 }}
                            className={clsx(
                                classes.actionButton,
                                localTimezone && classes.actionButtonActive
                            )}
                            onClick={toggleTimezone}
                        >
                            {"Local: " + localTimezoneName}
                        </Button>
                    </div>
                </div>
                <div className={classes.settingsItem}>
                    <div className={classes.settingsTextContainer}>
                        <Typography
                            variant="h5"
                            className={classes.settingsText}
                        >
                            Forecast Horizon
                        </Typography>
                    </div>
                    <div className={classes.settingsActionContainer}>
                        <Button
                            disabled={loading}
                            style={{ width: 55 }}
                            className={clsx(
                                classes.actionButton,
                                days === 5 && classes.actionButtonActive
                            )}
                            onClick={() => toggleDays()}
                        >
                            5 DAYS
                        </Button>
                        <Button
                            disabled={loading}
                            style={{ width: 55 }}
                            className={clsx(
                                classes.actionButton,
                                days === 10 && classes.actionButtonActive
                            )}
                            onClick={toggleDays}
                        >
                            MAX
                        </Button>
                    </div>
                </div>
                <div className={classes.settingsItem}>
                    <div className={classes.settingsTextContainer}>
                        <Typography
                            variant="h5"
                            className={classes.settingsText}
                        >
                            Thresholds
                        </Typography>
                    </div>
                    <div className={classes.settingsActionContainer}>
                        <Button
                            onClick={toggleThresholds}
                            disabled={loading}
                            style={{ width: 48 }}
                            className={clsx(
                                classes.actionButton,
                                thresholdsVisible && classes.actionButtonActive
                            )}
                        >
                            {thresholdsVisible ? "Hide" : "Show"}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChartBottomBar;
