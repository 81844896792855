import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import { Typography, List, ListItem, Divider } from "@material-ui/core";
import {
    Chart,
    ChartSeries,
    ChartValueAxis,
    ChartCategoryAxis,
    ChartTooltip,
    ChartPanes,
    ChartPane,
    ChartPlotArea,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";
import "@progress/kendo-theme-default/dist/all.css";
import { Path, Group, Text } from "@progress/kendo-drawing";
import "../../styles/charts.css";
import CustomChartLegend from "../../common/charts/CustomChartLegend";
import { hidden, returnLabelFont } from "../../common/charts/chartStyles";
import CustomChartCategoryAxisItem from "../../common/charts/CustomChartCategoryAxisItem";
import CustomChartValueAxisItem from "../../common/charts/CustomChartValueAxisItem";
import { getRange } from "../../utils/forecastCharts";
import {
    negativeValuesInChart,
    findRoundedMinMax,
    renderEqualMinMax,
    getUnitValueMinMax,
    getMostCommonUnit,
} from "../../utils/forecastCharts";
import { removeTransparencyFromHex } from "../../utils/removeTransparencyFromHex";

const styles = (theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    topCharts: {
        flex: 1,
    },
    tooltip: {
        padding: theme.spacing(2, 3),
    },
    tooltipTitle: {
        color: theme.palette.primary.main,
        fontSize: 11,
        fontWeight: 700,
    },
    tooltipList: {
        padding: theme.spacing(1, 0),
    },
    tooltipListItem: {
        display: "flex",
        padding: theme.spacing(0.25, 0),
    },
    tooltipListItemTitle: {
        flex: 1,
        color: theme.palette.text.defaultSub,
        fontSize: 11,
        fontWeight: 400,
        paddingRight: theme.spacing(5),
    },
    tooltipListItemValue: {
        color: theme.palette.text.default,
        fontSize: 11,
        fontWeight: 600,
    },
});

const TwinCharts = (props) => {
    const {
        classes,
        upper,
        lower,
        range,
        renderSeriesOfChart,
        handleLegendOnClick,
        minMaxView,
        timezoneOffset,
        sizes,
        setMainChartAreaSize,
        mainChartAreaSize,
        targetSteps,
        miniChartHeight,
        hiddenSeries,
        pdfReady,
        pdfDate,
        setPDFReady,
        weatherWindowData,
        weatherWindowBar,
        thresholdsVisible,
        mainWeatherWindowPadding,
        mainWeatherWindow,
        createArrayOfMidnights,
    } = props;
    const theme = useTheme();

    const renderAxisCrossingValues = () => {
        const numberOfAxis = numberOfValueAxis();
        let axisCrossingValues = [minMaxView.min]; //for weather window bar
        //upper
        axisCrossingValues.push(minMaxView.min);
        for (let i = 1; i < numberOfAxis.upper; i++) {
            axisCrossingValues.push(minMaxView.max);
        }
        //lower
        axisCrossingValues.push(minMaxView.min);
        for (let i = 1; i < numberOfAxis.lower; i++) {
            axisCrossingValues.push(minMaxView.max);
        }
        return axisCrossingValues;
    };

    const renderCategoryAxis = (chartData, chartType, props) => {
        const borderWidth = 232;
        const labelWidth = 115;
        const negativeValuesExist = negativeValuesInChart(chartData);
        const containerWidth = sizes.width - borderWidth;
        const arbitraryChartPadding = 30;
        const areaHeight =
            mainChartAreaSize.length > 0
                ? mainChartAreaSize[1].height - arbitraryChartPadding
                : 10;
        const numberOfLabels = Math.floor(containerWidth / labelWidth);
        const displayStep = Math.ceil(
            (chartData.groups[0].data.length *
                (range / getRange(chartData.groups[0].data))) /
                numberOfLabels
        );
        const categories = chartData
            ? chartData.groups[0].data.map((x) => moment.unix(x.time).toDate())
            : [];
        return (
            <CustomChartCategoryAxisItem
                pane={chartData.chartType.toString()}
                categories={categories}
                min={minMaxView.min}
                max={minMaxView.max}
                labels={{
                    step: displayStep,
                    padding: {
                        top: negativeValuesExist ? areaHeight / 2 : 0,
                    },
                    content: (e) => {
                        return moment
                            .utc(e.value.getTime())
                            .add(timezoneOffset, "ms")
                            .format("HH:mm - DD/MM");
                    },
                }}
                axisCrossingValues={renderAxisCrossingValues()}
                name={chartType}
                overlap="true"
                {...props}
                key={chartType}
            />
        );
    };
    const numberOfValueAxis = () => {
        let upperNumber = 0;
        let lowerNumber = 0;
        upper.groups
            .filter(
                (x, i) => upper.groups.findIndex((y) => y.unit === x.unit) === i
            )
            .forEach((group) => {
                if (group.plotType !== "Arrow") {
                    upperNumber += 1;
                }
            });
        lower.groups
            .filter(
                (x, i) => lower.groups.findIndex((y) => y.unit === x.unit) === i
            )
            .forEach((group) => {
                if (group.plotType !== "Arrow") {
                    lowerNumber += 1;
                }
            });
        return {
            upper: upperNumber,
            lower: lowerNumber,
        };
    };

    const renderValueAxis = (chartData, type, props) => {
        let axis = [];
        const negativeValuesExist = negativeValuesInChart(chartData);
        const commonUnit = getMostCommonUnit(chartData.groups);
        chartData.groups
            .filter(
                (x, i) =>
                    chartData.groups.findIndex((y) => y.unit === x.unit) === i
            )
            .forEach((group) => {
                if (group.plotType !== "Arrow") {
                    let containsArrows = chartData.groups.some(
                        (group) => group.plotType === "Arrow"
                    );
                    const addArrowPadding =
                        commonUnit === group.unit && containsArrows;
                    const minMax = negativeValuesExist
                        ? findRoundedMinMax(
                              renderEqualMinMax(
                                  chartData,
                                  group.unit,
                                  addArrowPadding
                              ),
                              targetSteps
                          )
                        : findRoundedMinMax(
                              getUnitValueMinMax(
                                  chartData.groups,
                                  group.unit,
                                  addArrowPadding
                              ),
                              targetSteps
                          );
                    axis.push(
                        <CustomChartValueAxisItem
                            key={group.groupId}
                            pane={chartData.chartType.toString()}
                            title={{ text: `[${group.unit}]` }}
                            name={group.unit + type}
                            min={minMax.min}
                            max={minMax.max}
                            {...props}
                        />
                    );
                }
            });
        return axis;
    };

    const renderDayLine = (
        date,
        categoryAxis,
        valueSlot,
        group,
        labelAlignment
    ) => {
        const line = new Path({
            stroke: {
                color: theme.palette.generalUi.lines,
                width: 1,
            },
        });
        // get the coordinates of the value at which the plot band will be rendered
        const categorySlot = categoryAxis.slot(date);
        line.moveTo(categorySlot.origin.x, valueSlot.origin.y).lineTo(
            categorySlot.origin.x,
            valueSlot.bottomRight().y
        );

        const padding = 4;
        const label = new Text(
            moment(date).add(timezoneOffset, "ms").format("ddd"),
            [0, 0],
            {
                fill: {
                    color: theme.palette.generalUi.lines,
                },
                font: returnLabelFont(theme, 11, 400),
            }
        );
        //    console.log(date, label.options.content);
        const bbox = label.bbox();
        const labelBottomAlignedPosition = valueSlot.bottomLeft().y + padding;
        const labelTopAlignedPosition =
            valueSlot.origin.y - bbox.size.height - padding;
        const y =
            labelAlignment === "top"
                ? labelTopAlignedPosition
                : labelBottomAlignedPosition;
        label.position([categorySlot.origin.x, y]);

        group.append(line, label);
    };

    const renderDayLineByChartType = (
        chart,
        group,
        arrayOfMidnights,
        valueAxisName,
        categoryAxisName,
        labelAlignment
    ) => {
        const valueAxis = chart.findAxisByName(valueAxisName);
        const categoryAxis = chart.findAxisByName(categoryAxisName);
        const range = valueAxis.range();
        const valueSlot = valueAxis.slot(range.min, range.max);
        arrayOfMidnights.forEach((date) => {
            renderDayLine(date, categoryAxis, valueSlot, group, labelAlignment);
        });
    };

    const renderDayLines = (chart, arrayOfMidnights, labelAlignment) => {
        const lineGroup = new Group();
        [
            { data: upper, type: "upper" },
            { data: lower, type: "lower" },
        ].forEach((chartData) => {
            const group = chartData.data.groups[0];
            const valueAxis = group.unit + chartData.type;
            const categoryAxis = chartData.type;
            renderDayLineByChartType(
                chart,
                lineGroup,
                arrayOfMidnights,
                valueAxis,
                categoryAxis,
                "top"
            );
        });
        if (!pdfReady && pdfDate) {
            setPDFReady(true);
        }
        chart.surface.draw(lineGroup);
    };

    const renderThresholdLine = (
        value,
        color,
        valueAxis,
        categorySlot,
        group,
        currentLines
    ) => {
        if (value) {
            const line = new Path({
                stroke: {
                    color: `#${removeTransparencyFromHex(color)}`,
                    width: 2,
                    dashType: "dash",
                },
            });
            //ensure all colors/lines are visible when lines are overlapping
            let offset = 0;
            const sameValue = currentLines.filter(
                (x) => x.value === value
            ).length;
            if (sameValue > 0) {
                offset = 16 / (sameValue + 1);
            }
            const valueSlot = valueAxis.slot(value);
            line.moveTo(
                categorySlot.topLeft().x + offset,
                valueSlot.origin.y
            ).lineTo(categorySlot.topRight().x, valueSlot.origin.y);
            currentLines.push({
                value,
                color,
            });
            group.append(line);
        }
    };

    const renderThresholdLines = (chart) => {
        const thresholdGroup = new Group();
        [
            { data: upper, type: "upper" },
            { data: lower, type: "lower" },
        ].forEach((chartData) => {
            let currentLines = [];
            chartData.data.groups.forEach((group) => {
                if (
                    group.plotType !== "Arrow" &&
                    !hiddenSeries.some((x) => x === group.groupId)
                ) {
                    const valueAxis = chart.findAxisByName(
                        group.unit + chartData.type
                    );
                    const categoryAxis = chart.findAxisByName(chartData.type);
                    const range = categoryAxis.range();
                    const categorySlot = categoryAxis.slot(
                        range.min,
                        range.max
                    );
                    renderThresholdLine(
                        group.thresholdMin,
                        group.color,
                        valueAxis,
                        categorySlot,
                        thresholdGroup,
                        currentLines
                    );
                    renderThresholdLine(
                        group.thresholdMax,
                        group.color,
                        valueAxis,
                        categorySlot,
                        thresholdGroup,
                        currentLines
                    );
                }
            });
        });
        //draw on the surface
        chart.surface.draw(thresholdGroup);
        if (!pdfReady && pdfDate) {
            setPDFReady(true);
        }
    };
    const onRender = (args) => {
        const chart = args.target.chartInstance;
        if (chart) {
            const arrayOfMidnights = createArrayOfMidnights(
                minMaxView.min,
                minMaxView.max
            );
            renderDayLines(chart, arrayOfMidnights, "top");
            if (thresholdsVisible) {
                renderThresholdLines(chart);
            } else if (!pdfReady && pdfDate) {
                setPDFReady(true);
            }
        }
    };

    const renderTooltip = (props) => {
        const { points } = props;
        if (points.length === 0) {
            return (
                <div className={classes.tooltip}>
                    <Typography
                        variant="body1"
                        className={classes.tooltipTitle}
                    >
                        No weather parameters available
                    </Typography>
                </div>
            );
        }

        return (
            <div className={classes.tooltip}>
                <Typography variant="body1" className={classes.tooltipTitle}>
                    {moment
                        .utc(points[0].category.getTime())
                        .add(timezoneOffset, "ms")
                        .format("DD/MM/YYYY - HH:mm")}
                </Typography>
                <List className={classes.tooltipList}>
                    {points
                        .filter(
                            (point) =>
                                point.series.pane === "0" &&
                                typeof point.value !== "object"
                        )
                        .map((point, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    className={classes.tooltipListItem}
                                >
                                    <Typography
                                        className={classes.tooltipListItemTitle}
                                    >
                                        {point.series.name}
                                    </Typography>
                                    <Typography
                                        className={classes.tooltipListItemValue}
                                    >
                                        {point.series.arrow
                                            ? point.dataItem.rotation.toFixed(
                                                  point.series.decimals
                                              )
                                            : point.dataItem.value.toFixed(
                                                  point.series.decimals
                                              )}
                                    </Typography>
                                </ListItem>
                            );
                        })}
                </List>
                <Divider />
                <List className={classes.tooltipList}>
                    {points
                        .filter(
                            (point) =>
                                point.series.pane === "1" &&
                                typeof point.value !== "object"
                        )
                        .map((point, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    className={classes.tooltipListItem}
                                >
                                    <Typography
                                        className={classes.tooltipListItemTitle}
                                    >
                                        {point.series.name}
                                    </Typography>
                                    <Typography
                                        className={classes.tooltipListItemValue}
                                    >
                                        {!Number.isNaN(point.dataItem.value) &&
                                            point.dataItem.value !== null &&
                                            point.dataItem.value !== "NaN" &&
                                            point.dataItem.value.toFixed(
                                                point.series.decimals
                                            )}
                                    </Typography>
                                </ListItem>
                            );
                        })}
                </List>
            </div>
        );
    };

    return (
        <Chart
            onRender={onRender}
            onLegendItemClick={handleLegendOnClick}
            transitions={false}
            style={{ height: `calc(100% - ${miniChartHeight}px)` }}
            ref={(chart) => {
                if (chart && mainChartAreaSize.length === 0) {
                    if (chart.chartInstance) {
                        if (chart.chartInstance._plotArea) {
                            let charts = [];
                            chart.chartInstance._plotArea.charts.forEach(
                                (chart) => {
                                    charts.push({
                                        width: chart.pane.options.width,
                                        height: chart.pane.options.height,
                                    });
                                }
                            );
                            setMainChartAreaSize(charts);
                        }
                    }
                }
            }}
        >
            <ChartTooltip
                shared={true}
                render={renderTooltip}
                border={{ width: 0, radius: 5 }}
                background={"white"}
            />
            <ChartPlotArea
                background={theme.palette.table.cellBg}
                border={{ color: theme.palette.generalUi.borders, width: 1 }}
            />
            <CustomChartLegend />
            <ChartPanes>
                <ChartPane
                    name={"2"}
                    height={mainWeatherWindow + mainWeatherWindowPadding - 1}
                    margin={{ bottom: mainWeatherWindowPadding }}
                />
                <ChartPane name={"0"} margin={{ bottom: 10 }} />
                <ChartPane name={"1"} margin={{ top: 10 }} />
            </ChartPanes>
            <ChartCategoryAxis>
                {[
                    renderCategoryAxis(upper, "upper"),
                    renderCategoryAxis(lower, "lower"),
                    renderCategoryAxis(weatherWindowBar, "weatherWindow", {
                        labels: hidden,
                        majorGridlines: hidden,
                        minorGridlines: hidden,
                        plotBands: weatherWindowData,
                    }),
                ]}
            </ChartCategoryAxis>
            <ChartValueAxis>
                {[
                    <CustomChartValueAxisItem
                        majorGridLines={hidden}
                        minorTicks={hidden}
                        majorTicks={hidden}
                        labels={hidden}
                        name={"weatherWindow"}
                        title={""}
                        pane={2}
                        key={"weatherWindow"}
                    />,
                    ...renderValueAxis(upper, "upper"),
                    ...renderValueAxis(lower, "lower"),
                ]}
            </ChartValueAxis>
            <ChartSeries>
                {[
                    ...renderSeriesOfChart(upper, "upper"),
                    ...renderSeriesOfChart(lower, "lower"),
                ]}
            </ChartSeries>
        </Chart>
    );
};

export default withStyles(styles)(TwinCharts);
