import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomTable = withStyles((theme) => ({
    root: {
        tableLayout: "fixed",
    },
}))(Table);

export const CustomTableHead = withStyles((theme) => ({
    root: {},
}))(TableHead);

export const CustomTableBody = withStyles((theme) => ({
    root: {},
}))(TableBody);

export const CustomTableCell = withStyles((theme) => ({
    root: {},
    head: {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 600,
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    body: {
        color: theme.palette.text.defaultSub,
        borderBottom: "none",
    },
}))(TableCell);

export const CustomTableContainer = withStyles((theme) => ({
    root: {},
}))(TableContainer);

export const CustomTableRow = withStyles((theme) => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.secondMenuItem.hoverBackground,
        },
        "&$selected": {
            backgroundColor: theme.palette.secondMenuItem.selectedBackground,
            "&:hover": {
                backgroundColor:
                    theme.palette.secondMenuItem.selectedBackground,
            },
        },
    },
    selected: {},
}))(TableRow);
