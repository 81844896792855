export const getSettingsStyles = (theme) => {
    const styles = {
        loading: {
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
        },
        formContainer: {
            padding: theme.spacing(2, 0),
        },
        formSubtitle: {
            margin: theme.spacing(4, 0, 4, 0),
            color: theme.palette.primary.main,
            fontSize: 12,
        },
        headerContainer: {
            display: "flex",
            alignItems: "center",
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
        },
        deleteButton: {
            margin: theme.spacing(0, 2, 0, 0),
            backgroundColor: theme.palette.common.white,
        },
        cancelButton: {
            margin: theme.spacing(0, 2, 0, 0),
            backgroundColor: theme.palette.common.white,
        },
        submitButton: {
            margin: theme.spacing(0, 0, 0, 2),
        },
        buttonProgress: {
            marginRight: theme.spacing(2),
        },
        selectMenuItem: {
            flexDirection: "column",
            alignItems: "start",
        },
        readOnlyContainer: {
            display: "flex",
            alignItems: "center",
        },
        copyIcon: {
            height: 15,
            width: 15,
        },
        copyButton: {
            padding: theme.spacing(2),
        },
        activeDot: {
            width: 8,
            height: 8,
            borderRadius: "50%",
            margin: theme.spacing(3),
        },
        activeButton: {
            marginRight: theme.spacing(4),
        },
        activeButtonInactive: {
            backgroundColor: theme.palette.common.white,
        },
        actionButtons: {
            marginLeft: "auto",
        },
        colorSelectDot: {
            height: 19,
            width: 19,
            borderRadius: "50%",
            display: "inline-block",
            verticalAlign: "middle",
        },
        colorSelectText: {
            display: "inline-block",
            paddingLeft: 10,
            fontSize: 16,
            fontWeight: 400,
            color: "rgba(0,0,0,0.87)",
            verticalAlign: "middle",
        },
        scrollableTableBodyContainer: {
            flex: "1 1 0px",
            minHeight: 0,
            overflow: "auto",
        },
        stickyTableHeader: {
            marginRight: theme.spacing(4),
        },
        tableContainer: {
            overflow: "auto",
            flex: "1 1 600px",
            display: "flex",
            flexDirection: "column",
            "& th": {
                overflow: "hidden",
                textOverflow: "clip",
                whiteSpace: "nowrap",
            },
            "& td": {
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
            "@media (max-width: 960px)": {
                "& th": {
                    padding: theme.spacing(4, 1, 4, 1),
                    whiteSpace: "normal",
                },
                "& td": {
                    padding: theme.spacing(4, 1, 4, 1),
                },
            },
        },
        addNewButton: {
            marginLeft: "auto",
        },
        sortIcon: {
            width: 0,
            height: 0,
            display: "inline-grid",
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            cursor: "pointer",
        },
        sortIconInactive: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: `6px solid ${theme.palette.secondary.main}`,
            opacity: 0.5,
        },
        sortIconActive: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: `6px solid ${theme.palette.secondary.main}`,
            opacity: 1,
        },
        sortIconAsc: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: `6px solid ${theme.palette.secondary.main}`,
        },
        sortIconDes: {
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: `6px solid ${theme.palette.secondary.main}`,
        },
        tableRow: {
            cursor: "pointer",
        },
        searchBar: {
            marginLeft: theme.spacing(8),

            width: 250,
        },
        titleContainerDetails: {
            margin: theme.spacing(1, 0, 3, 0),
            height: theme.spacing(10),
        },
        actionButtonsDetails: {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            padding: theme.spacing(0, 4),
        },
        inputAdornmentIconSearch: {
            height: 15,
            width: 15,
        },
        searchIcon: {
            fontSize: 16,
            color: theme.palette.generalUi.icons,
        },
        iconWrapper: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                marginRight: -26,
            },
        },
        actionIcon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
            fontSize: 18,
        },
        actionButton: {
            width: 90,
        },
        buttonIcon: {
            fontSize: 12,
            marginRight: 8,
        },
        noMargin: {
            margin: 0,
            padding: 0,
        },
        tableColumnBottom: {
            alignSelf: "flex-end",
            textAlign: "right",
        },
        searchActionButton: {
            margin: theme.spacing(1),
        },
        searchActionClear: {
            backgroundColor: theme.palette.generalUi.background,
            margin: theme.spacing(1),
        },
        activeDotContainer: {
            position: "relative",
        },
        searchContainer: {
            border: `1px solid ${theme.palette.generalUi.borders}`,
            backgroundColor: theme.palette.generalUi.panelBackground,
            margin: theme.spacing(2, 0, 1, 0),
            padding: theme.spacing(1, 2, 2, 2),
            width: "100%",
        },
    };
    return styles;
};
