import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { MenuItem, Grid, Typography } from "@material-ui/core";
import { SecondaryButton } from "../../common/restyled-mui/CustomButton";

import {
    CustomTextField,
    CustomInputLabel,
    CustomSelect,
} from "../../common/restyled-mui/CustomInputs";
import { textFieldNoArrowStyles } from "../../common/restyled-mui/CustomInputs";

import { getSettingsStyles } from "../../styles/settingsStyles";

import HindcastParameterFormTable from "./form-inputs/HindcastParameterFormTable";
import HindcastWindowSelectionRadio from "./form-inputs/HindcastWindowSelectionRadio";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    formContainer: {
        padding: theme.spacing(4),
        overflowY: "auto",
    },
    checkboxControl: {
        width: 80,
    },
    customSubtitle: {
        fontSize: 14,
    },
    smallMarginTextField: {
        marginBottom: 0,
    },
    inputNumber: {
        ...textFieldNoArrowStyles,
    },
    loading: {
        loading: {
            position: "relative",
            marginTop: "50%",
            marginLeft: "50%",
        },
    },
    downloadDataButton: {
        width: "100%",
    },
}));

const HindcastsForm = (props) => {
    const classes = useStyles(props);
    const {
        value,
        handleChangeValues,
        parameters,
        validation,
        handleValidationUpdate,
        downloadData,
        canDownloadRawData,
        loading,
        runAnalysis,
    } = props;

    // const toggleCompressed = (e) => {
    //     e.persist();
    //     handleChangeValues((oldValues) => ({
    //         ...oldValues,
    //         isCompressed: !oldValues.isCompressed,
    //     }));
    // };

    const handleValueAttributeChange = (name, value) => {
        handleChangeValues((oldValues) => ({
            ...oldValues,
            [name]: value,
        }));
        handleValidationUpdate(name);
    };

    const handleTextFieldChange = (name) => (e) => {
        e.persist();
        handleValueAttributeChange(name, e.target.value);
        handleValidationUpdate(name);
    };

    const InputPropsNumber = {
        classes: {
            input: classes.inputNumber,
        },
    };

    const workingDayOptions = [
        { id: 1, value: "Mon-Fri" },
        { id: 2, value: "Mon-Sat" },
        { id: 3, value: "Mon-Sun" },
    ];

    return (
        <div className={classes.formContainer}>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.formSubtitle,
                            classes.customSubtitle
                        )}
                    >
                        Coordinates
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <CustomInputLabel>Latitude</CustomInputLabel>
                            <CustomTextField
                                name={"latitude"}
                                type="number"
                                InputProps={InputPropsNumber}
                                value={value.latitude}
                                onChange={handleTextFieldChange("latitude")}
                                error={!validation.latitude.valid}
                                helperText={validation.latitude.message}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CustomInputLabel>Longitude</CustomInputLabel>
                            <CustomTextField
                                name={"longitude"}
                                type="number"
                                InputProps={InputPropsNumber}
                                value={value.longitude}
                                onChange={handleTextFieldChange("longitude")}
                                error={!validation.longitude.valid}
                                helperText={validation.longitude.message}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} item>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.formSubtitle,
                            classes.customSubtitle
                        )}
                    >
                        Work Window
                    </Typography>

                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <CustomInputLabel
                                style={{
                                    marginBottom: 2,
                                }}
                            >
                                Working days
                            </CustomInputLabel>
                            <CustomSelect
                                inputProps={{
                                    margin: "dense",
                                }}
                                type="text"
                                value={value.workingDays}
                                onChange={handleTextFieldChange("workingDays")}
                            >
                                {workingDayOptions.map((item) => {
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.value}
                                            className={classes.selectMenuItem}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    );
                                })}
                            </CustomSelect>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomInputLabel>
                                Window Length (hours)
                            </CustomInputLabel>
                            <CustomTextField
                                name={"Window Length"}
                                type="number"
                                InputProps={InputPropsNumber}
                                value={value.windowLength}
                                onChange={handleTextFieldChange("windowLength")}
                                error={!validation.windowLength.valid}
                                helperText={validation.windowLength.message}
                            />
                        </Grid>
                    </Grid>
                    <CustomInputLabel>Work Window</CustomInputLabel>

                    <HindcastWindowSelectionRadio
                        handleChangeValues={handleChangeValues}
                        handleValidationUpdate={handleValidationUpdate}
                        value={value.windowSelection}
                        error={!validation.windowSelection.valid}
                        helperText={validation.windowSelection.message}
                    />
                    {/* 
                    <CustomInputLabel>Time Zone</CustomInputLabel>
                    <HindcastTimezoneRadio
                        value={value.isTimezoneUTC}
                        handleChange={handleTextFieldChange("isTimezoneUTC")}
                    /> */}
                </Grid>
                <Grid xs={12} item>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.formSubtitle,
                            classes.customSubtitle
                        )}
                    >
                        Parameters
                    </Typography>
                    <HindcastParameterFormTable
                        parameters={parameters}
                        selectedParameters={value.conditionList}
                        updateSelected={(value) =>
                            handleValueAttributeChange("conditionList", value)
                        }
                        error={!validation.conditionList.valid}
                        helperText={validation.conditionList.message}
                    />
                </Grid>
                <Grid
                    xs={12}
                    item
                    style={{
                        //  borderTop: '1px solid #E0E0E0',
                        marginTop: "20px",
                    }}
                >
                    <Grid container spacing={6}>
                        <Grid item xs={6} style={{ textAlign: "center" }}>
                            {canDownloadRawData && (
                                <SecondaryButton
                                    onClick={downloadData}
                                    className={classes.downloadDataButton}
                                    disabled={loading}
                                >
                                    Download CSV File
                                </SecondaryButton>
                            )}
                            {!canDownloadRawData && <div></div>}
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton
                                className={classes.downloadDataButton}
                                onClick={runAnalysis}
                                disabled={loading}
                            >
                                Run Analysis
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                    {/* <Grid xs={12} item>
                            {downloadLoading && <CenteredLoading />}
                        </Grid> */}
                </Grid>
            </Grid>
        </div>
    );
};

export default HindcastsForm;
