import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import {
    TextField,
    InputAdornment,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
} from "@material-ui/core";
import AlertsPanelTopbar from "./AlertsPanelTopbar";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
    getAlerts,
    getExpiredAlerts,
    deleteExpiredAlerts,
} from "../../redux/actions/alerts";
import UIIcons from "../../assets/icons/ui-icons";
import AlertsPanelBottomCount from "./AlertsPanelBottomCount";
import AlertsPanelExpireAll from "./AlertsPanelExpireAll";
import { SecondaryButton } from "../../common/restyled-mui/CustomButton";
import { CustomTab, CustomTabs } from "../../common/restyled-mui/CustomTabs";
import AlertsList from "./AlertsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addIcon } from "../../assets/icons/fontawesome/addIcon";
import { searchIcon } from "../../assets/icons/fontawesome/searchIcon";
import escapeRegExp from "../../utils/escapeRegExp";

const sitesListWidth = 240;
const topbarHeight = 40;
const tabletSidebarWidth = 48;

const useStyles = makeStyles((theme) => ({
    childrenContainer: {
        marginLeft: (props) =>
            props.fullscreen || props.toggleable ? 0 : sitesListWidth,
        width: (props) =>
            props.fullscreen || props.toggleable
                ? "100%"
                : `calc(100% - ${sitesListWidth}px)`,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    title: {
        flexGrow: 1,
        fontWeight: 400,
    },
    appbarContainer: {
        top: 0,
        position: "sticky",
        width: "100%",
    },
    list: {
        backgroundColor: theme.palette.common.white,
        height: "100%",
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
        display: "flex",
        flexDirection: "column",
    },
    headerBar: {
        height: topbarHeight - theme.spacing(8),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(4),
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    searchBar: {
        height: 39 - theme.spacing(2),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 4),
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    searchIcon: {
        height: 16,
        width: 16,
        marginRight: theme.spacing(0.8),
        // '& path': {
        //     fill:props => props.searchField.length > 0 ? theme.palette.primary.main : theme.palette.generalUi.icons,
        // }
    },
    closeIconButton: {
        padding: theme.spacing(1),
    },
    closeIcon: {
        height: 16,
        width: 16,
        "& path": {
            fill: theme.palette.generalUi.lines,
        },
    },
    searchRoot: {
        height: 39,
    },
    searchInput: {
        fontSize: 14,
        color: "#383838",
        "&::placeholder": {
            opacity: 0.5,
            fontSize: 14,
            color: "#383838",
            fontWeight: 400,
        },
    },
    tabsBar: {
        height: 24,
        backgroundColor: theme.palette.generalUi.panelBackground,
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    tabPanel: {
        overflowY: "auto",
        flex: 1,
        display: "flex",
        minHeight: 0,
    },
    listContainer: {
        position: "relative",
        flex: 1,
    },
    row: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    rowText: {
        color: theme.palette.text.default,
        fontSize: 13,
        lineHeight: 1.3,
    },
    rowSubtext: {
        color: theme.palette.text.defaultSub,
        fontSize: 9,
        lineHeight: 1.3,
        "&:last-child": {
            padding: theme.spacing(0, 0, 0, 2),
        },
    },
    rowSubtextContainer: {
        width: "100%",
        display: "flex",
    },
    drawer: {
        marginLeft: (props) => (props.toggleable ? tabletSidebarWidth - 1 : 0),
        borderRight: `${theme.palette.menu.border} 1px solid`,
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
    },
    menu: {
        display: "flex",
        flexDirection: "column",
        width: tabletSidebarWidth,
        zIndex: 1400,
        backgroundColor: theme.palette.menu.bg,
        borderRight: `${theme.palette.menu.border} 1px solid`,
    },
    menuItem: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(1, 3),
    },
    menuItemIcon: {
        textAlign: "center",
        minWidth: "100%",
    },
    menuIcon: {
        color: theme.palette.menu.icons,
    },
    selectedMenuIcon: {
        color: theme.palette.primary.main,
    },
    plusIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
}));

const mapStateToProps = (state, ownProps) => {
    return {
        alerts: state.alerts,
        expiredAlerts: state.expiredAlerts,
        user: state.user,
        config: state.config,
    };
};

const SitesPanel = (props) => {
    const classes = useStyles(props);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(0);
    const [searchField, setSearchField] = useState("");
    const {
        alerts,
        expiredAlerts,
        push,
        getAlerts,
        getExpiredAlerts,
        deleteExpiredAlerts,
        container,
        toggleable,
        setConfirmation,
        resetConfirmation,
    } = props;

    useEffect(() => {
        if (!alerts) {
            getAlerts();
        }
    }, [alerts, getAlerts]);

    useEffect(() => {
        if (!expiredAlerts) {
            getExpiredAlerts();
        }
    }, [expiredAlerts, getExpiredAlerts]);

    const filterByView = () => {
        switch (view) {
            case 0:
                return alerts;
            case 1:
                return alerts.filter((x) => x.isActive);
            case 2:
                return expiredAlerts;
            default:
                return alerts;
        }
    };

    const onSearchChange = (e) => {
        setSearchField(e.target.value);
    };

    const emptySearchField = () => {
        setSearchField("");
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleChange = (event, newValue) => {
        setView(newValue);
    };

    const filterAlerts = (a = null) => {
        if (a) {
            if (searchField.length > 0) {
                return filterByView().filter((x) => searchFilter(x));
            } else {
                return filterByView();
            }
        } else {
            return null;
        }
    };

    const searchFilter = (x) => {
        const regex = new RegExp(escapeRegExp(searchField), "i");
        return regex.test(`${x.name}`);
    };

    const selectAlert = (id) => {
        push(`/alerts/${id}`);
    };

    const createAlert = () => {
        push(`/alerts/create`);
    };

    const findCount = () => {
        const filteredAlerts = filterAlerts();
        if (filteredAlerts) {
            return filteredAlerts.length;
        } else {
            return null;
        }
    };

    const isSelected = (id) => {
        const routes = location.pathname.split("/");
        if (routes[routes.length - 1].length > 1) {
            return routes[routes.length - 1] === id.toString();
        } else {
            return routes[routes.length - 2] === id.toString();
        }
    };
    const alertsList = filterAlerts(alerts);
    return (
        <>
            {toggleable && (
                <List className={classes.menu}>
                    <ListItem
                        className={classes.menuItem}
                        button
                        onClick={toggleOpen}
                    >
                        <ListItemIcon className={classes.menuItemIcon}>
                            <FontAwesomeIcon
                                icon={searchIcon}
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    open && classes.selectedMenuIcon
                                )}
                            />
                        </ListItemIcon>
                    </ListItem>
                </List>
            )}
            <Drawer
                open={toggleable ? open : true}
                variant={toggleable ? "persistent" : "permanent"}
                anchor={"left"}
                ModalProps={{
                    keepMounted: true,
                    container: container.current,
                    style: { position: "absolute" },
                }}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                onClose={toggleOpen}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.list}>
                    <AlertsPanelTopbar>
                        <SecondaryButton
                            disableElevation
                            className={classes.addNewButton}
                            onClick={createAlert}
                        >
                            <FontAwesomeIcon
                                icon={addIcon}
                                size={"lg"}
                                className={classes.plusIcon}
                            />
                            Add new
                        </SecondaryButton>
                    </AlertsPanelTopbar>
                    <div className={classes.searchBar}>
                        <TextField
                            placeholder="Search..."
                            value={searchField}
                            onChange={onSearchChange}
                            InputProps={{
                                classes: {
                                    root: classes.searchRoot,
                                    input: classes.searchInput,
                                },
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <UIIcons.SearchIcon
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    searchField.length > 0 ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={
                                                    classes.closeIconButton
                                                }
                                                onClick={emptySearchField}
                                            >
                                                <UIIcons.CloseIcon
                                                    className={
                                                        classes.closeIcon
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                            }}
                        />
                    </div>
                    <div className={classes.tabsBar}>
                        <CustomTabs
                            value={view}
                            onChange={handleChange}
                            variant="fullWidth"
                        >
                            <CustomTab label="All" value={0} />
                            <CustomTab label="Active" value={1} />
                            <CustomTab label="Expired" value={2} />
                        </CustomTabs>
                    </div>
                    <AlertsList
                        alerts={alertsList}
                        selectAlert={selectAlert}
                        isSelected={isSelected}
                    />
                    {view === 2 && alertsList.length > 0 && (
                        <AlertsPanelExpireAll
                            handleExpire={deleteExpiredAlerts}
                            setConfirmation={setConfirmation}
                            resetConfirmation={resetConfirmation}
                        />
                    )}
                    <AlertsPanelBottomCount count={findCount()} />
                </div>
            </Drawer>
            <div className={classes.childrenContainer}>{props.children}</div>
        </>
    );
};

export default connect(mapStateToProps, {
    getAlerts,
    getExpiredAlerts,
    deleteExpiredAlerts,
    push,
})(SitesPanel);
