import React from 'react';
import { ReactComponent as RadioButtonChecked } from './radiobutton_checked.svg';
import { ReactComponent as RadioButtonUnchecked } from './radiobutton_unchecked.svg';

export const RadioButtonCheckedIcon = props => {
    const { className, style } = props;
    return <RadioButtonChecked className={className} style={style} aria-label='Radio Button Checked Icon' />
}

export const RadioButtonUncheckedIcon = props => {
    const { className, style } = props;
    return <RadioButtonUnchecked className={className} style={style} aria-label='Radio Button Unchecked Icon' />
}

