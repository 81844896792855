import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
    makeStyles,
    Typography,
    CircularProgress,
    InputAdornment,
} from "@material-ui/core";
import {
    CustomTable,
    CustomTableHead,
    CustomTableBody,
    CustomTableCell,
    CustomTableRow,
} from "../../common/restyled-mui/CustomTable";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import { CustomTextField } from "../../common/restyled-mui/CustomInputs";
import { getUsers } from "../../redux/actions/users";
import Navigation from "../settings-navigation/Navigation";
import UIIcons from "../../assets/icons/ui-icons";
import { push } from "connected-react-router";
import _ from "lodash";
import { getSettingsStyles } from "../../styles/settingsStyles";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    virtualTableBodyContainer: {
        height: "100%",
        backgroundColor: "white",
    },
    virtualTableBody: {
        height: "100%",

        backgroundColor: "white",
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        width: "100%",
        borderBottom: "1px solid rgb(224,224,224)",
    },

    tableCell: {
        border: "none",
    },
}));

const mapStateToProps = (state) => ({
    users: state.users,
    permissions: state.user.permissions,
});

const Users = (props) => {
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const { users, getUsers, push, location, permissions } = props;
    const [sort, setSort] = useState(["username", "asc"]);
    const [sortedUsers, setSortedUsers] = useState([]);

    useEffect(() => {
        if (!users) {
            getUsers();
        }
    }, [users, getUsers]);

    useEffect(() => {
        if (users && users.length > 0) {
            let ordered = null;
            if (typeof users[0][sort[0]] == "string") {
                ordered = _.orderBy(
                    users,
                    [
                        (user) =>
                            user[sort[0]] === null
                                ? ""
                                : user[sort[0]].toLowerCase(),
                    ],
                    sort[1]
                );
            } else {
                ordered = _.orderBy(users, sort[0], sort[1]);
            }
            setSortedUsers(ordered);
        }
    }, [sort, users]);

    const handleCreate = () => {
        push(`${location.pathname}/create`);
    };

    const renderActionButtons = () => {
        return (
            permissions.includes("Admin.ManageUsers.Create") && (
                <CustomButton
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                    className={classes.addNewButton}
                >
                    Add new
                </CustomButton>
            )
        );
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const searchFilter = (user) => {
        const regex = new RegExp(search, "i");
        return regex.test(`${user.username} ${user.email}`);
    };

    const handleSortBy = (key, order) => {
        setSort([key, order]);
    };

    const renderSortingLink = (key) => {
        const sortBy = sort[0];
        const sortDirection = sort[1];
        return (
            <span
                className={clsx(
                    classes.sortIcon,
                    sortBy === key
                        ? clsx(
                              sortDirection === "asc"
                                  ? classes.sortIconAsc
                                  : classes.sortIconDes
                          )
                        : classes.sortIconInactive
                )}
                onClick={() =>
                    handleSortBy(
                        key,
                        sortBy === key
                            ? sortDirection === "asc"
                                ? "desc"
                                : "asc"
                            : "asc"
                    )
                }
            />
        );
    };

    const Row = ({ index, style, data: { users, classes } }) => {
        const user = users[index];
        return (
            <CustomTableRow
                key={user.id}
                component="div"
                style={style}
                className={classes.tableRow}
                onClick={() => push(`${location.pathname}/${user.id}`)}
            >
                <CustomTableCell
                    className={classes.tableCell}
                    component="div"
                    style={{ width: "30%" }}
                >
                    {user.username}
                </CustomTableCell>
                <CustomTableCell
                    className={classes.tableCell}
                    component="div"
                    style={{ width: "40%" }}
                >
                    {user.email}
                </CustomTableCell>
                <CustomTableCell
                    className={classes.tableCell}
                    component="div"
                    style={{ width: "30%" }}
                >
                    {user.roles.join(", ")}
                </CustomTableCell>
            </CustomTableRow>
        );
    };

    const filteredUsers = sortedUsers
        ? sortedUsers.filter((x) => searchFilter(x))
        : [];

    const itemKey = (index, data) => {
        return data.users.length > 0 ? data.users[index].id : 0;
    };

    return (
        <Navigation push={push}>
            <div className={classes.headerContainer}>
                <Typography variant="h2">Users</Typography>
                <CustomTextField
                    variant="outlined"
                    size="small"
                    type="text"
                    placeholder="Filter Users"
                    className={classes.searchBar}
                    onChange={handleSearchChange}
                    value={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UIIcons.SearchIcon
                                    className={classes.inputAdornmentIconSearch}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
                {renderActionButtons()}
            </div>
            <div className={classes.tableContainer}>
                <div className={classes.stickyTableHeader}>
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableCell style={{ width: "30%" }}>
                                    Username
                                    {renderSortingLink("username")}
                                </CustomTableCell>
                                <CustomTableCell style={{ width: "30%" }}>
                                    Email
                                    {renderSortingLink("email")}
                                </CustomTableCell>
                                <CustomTableCell style={{ width: "30%" }}>
                                    Roles
                                    {renderSortingLink("roles")}
                                </CustomTableCell>
                            </CustomTableRow>
                        </CustomTableHead>
                    </CustomTable>
                </div>
                <div className={classes.virtualTableBodyContainer}>
                    {users && (
                        <CustomTable
                            component="div"
                            style={{ height: "100%", display: "flex" }}
                        >
                            <CustomTableBody
                                component="div"
                                style={{ flex: 1 }}
                            >
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        height: "100%",
                                    }}
                                >
                                    {users && filteredUsers.length > 0 && (
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    height={height}
                                                    width={width}
                                                    itemCount={
                                                        filteredUsers.length
                                                    }
                                                    itemSize={55}
                                                    itemKey={itemKey}
                                                    itemData={{
                                                        users: filteredUsers,
                                                        classes: classes,
                                                    }}
                                                >
                                                    {Row}
                                                </List>
                                            )}
                                        </AutoSizer>
                                    )}
                                    {users && filteredUsers.length === 0 && (
                                        <CustomTableRow
                                            className={classes.tableRow}
                                            component="div"
                                        >
                                            <CustomTableCell
                                                component="div"
                                                style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                No users found
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    )}
                                </div>
                            </CustomTableBody>
                        </CustomTable>
                    )}
                    {!users && (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        </Navigation>
    );
};

export default connect(mapStateToProps, { getUsers, push })(Users);
