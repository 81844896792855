import createIcon from "./utils";

const hindcastAnalysisIcon = createIcon({
    iconType: "game-board-hindcast-analysis-icon",
    path: "M256 160h-96v96h96zm-32 64h-32v-32h32zm224 32v-96h-96v96zm-64-64h32v32h-32zM352 64h-96v96h96zm-32 64h-32V96h32zM160 256H64v96h96zm-32 64H96v-32h32zm32 128h96v-96h-96zm32-64h32v32h-32zm256 64v-96h-96v96zm-64-64h32v32h-32zm-128-32h96v-96h-96zm32-64h32v32h-32zM480 0H32A32 32 0 0 0 0 32v448a32 32 0 0 0 32 32h448a32 32 0 0 0 32-32V32a32 32 0 0 0-32-32zm0 480H32V32h448zM64 64v96h96V64zm64 64H96V96h32z",
    width: 512,
    height: 512,
});

const hindcastAnalysisIconSelected = createIcon({
    iconType: "game-board-hindcast-analysis-icon-selected",
    path: "M256 160h-96v96h96zm0 192h96v-96h-96zM480 0H32A32 32 0 0 0 0 32v448a32 32 0 0 0 32 32h448a32 32 0 0 0 32-32V32a32 32 0 0 0-32-32zm-32 160h-96v96h96v96h-96v96h-96v-96h-96v96H64v-96h96v-96H64v-96h96V64h96v96h96V64h96z",
    width: 512,
    height: 512,
});

export { hindcastAnalysisIcon, hindcastAnalysisIconSelected };
