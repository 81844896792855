import {
    SITES,
    FAVORITE_SITES,
    SEARCHED_SITES,
    SEARCH_FIELD,
    SITES_VIEW,
} from "../types";
import Api from "../../api";
import { handleError } from "../../utils/error";
import orderBy from "lodash/sortBy";

export const getSites = () => {
    return (dispatch) => {
        return Api.Sites.getSites()
            .then((res) => {
                const sites = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: SITES.LOADED, payload: sites });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getFavoriteSites = () => {
    return (dispatch) => {
        return Api.Sites.getFavoriteSites()
            .then((res) => {
                const favoriteSites = orderBy(res.data, ["name"], ["asc"]);
                dispatch({
                    type: FAVORITE_SITES.LOADED,
                    payload: favoriteSites,
                });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const clearFavoriteSites = () => {
    return (dispatch) => {
        dispatch({ type: FAVORITE_SITES.LOADED, payload: [] });
    };
};

export const addFavoriteSite = (id) => {
    return (dispatch, getState) => {
        return Api.Sites.addFavoriteSite(id)
            .then((res) => {
                const state = getState();
                const site = state.sites.find((x) => x.id === id);
                const favoriteSites = orderBy(
                    [...state.favoriteSites, site],
                    ["name"],
                    ["asc"]
                );
                dispatch({
                    type: FAVORITE_SITES.ADDED,
                    payload: favoriteSites,
                });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const removeFavoriteSite = (id) => {
    return (dispatch, getState) => {
        return Api.Sites.removeFavoriteSite(id)
            .then((res) => {
                const state = getState();
                let favoriteSites = [...state.favoriteSites];
                favoriteSites.splice(
                    favoriteSites.findIndex((site) => site.id === id),
                    1
                );
                dispatch({
                    type: FAVORITE_SITES.REMOVED,
                    payload: favoriteSites,
                });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const toggleSitesView = (view) => {
    return (dispatch) => {
        //0: favorites
        //1: all sites
        dispatch({ type: SITES_VIEW.TOGGLED, payload: view });
    };
};

export const searchSites = (field) => {
    return (dispatch, getState) => {
        if (field.length > 0) {
            return Api.Sites.getSearchSites(field)
                .then((res) => {
                    //check if field has changed since api call
                    if (getState().searchField === field) {
                        dispatch({
                            type: SEARCHED_SITES.LOADED,
                            payload: res.data,
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        dispatch({ type: SEARCHED_SITES.LOADED, payload: [] });
                    } else {
                        handleError(dispatch, err);
                    }
                });
        } else {
            dispatch({ type: SEARCHED_SITES.LOADED, payload: null });
        }
    };
};

export const setSearchField = (field) => {
    return (dispatch) => {
        dispatch({ type: SEARCH_FIELD.SET, payload: field });
    };
};
