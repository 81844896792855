import React, { useState } from "react";
import HindcastsPanel from "../features/hindcasts-panel/HindcastsPanel";
import HindcastsTablePanel from "../features/hindcasts-table/HindcastsTablePanel";
import HindcastsTableTopbar from "../features/hindcasts-table/HindcastsTableTopbar";
import NoSite from "../features/hindcasts-panel/NoSite";
import CenteredLoading from "../common/CenteredLoading";
// import ConfirmationDialog from "../common/Confirmation";
// import { initialConfirmDialog } from "../common/Confirmation";

const HindcastsPage = (props) => {
    const { container, mobile, tablet } = props;
    const [tableData, setTableData] = useState(null);
    const [failedParameters, setFailedParameters] = useState([]);
    const [coordinates, setCoordinates] = useState(null);
    const [loading, setLoading] = useState(false);

    const title =
        coordinates !== null
            ? "Lat: " + coordinates.latitude + " Lon: " + coordinates.longitude
            : "";

    // const [confirmation, setConfirmation] = useState(initialConfirmDialog);

    const [nodataContent, setNodataContent] = useState({
        heading: "No data",
        body: "Enter correct coordinates and submit",
    });

    // const resetConfirmation = () => {
    //     setConfirmation(initialConfirmDialog);
    // };

    const resultPanel = () => {
        return tableData !== null ? (
            <>
                <HindcastsTableTopbar title={title}></HindcastsTableTopbar>
                <HindcastsTablePanel
                    data={tableData}
                    failedParameters={failedParameters}
                    loading={loading}
                    setLoading={setLoading}
                ></HindcastsTablePanel>
            </>
        ) : (
            <NoSite
                {...props}
                creating={false}
                mobile={mobile}
                heading={nodataContent.heading}
                body={nodataContent.body}
            ></NoSite>
        );
    };

    //  setLoading(true);
    const resetLoading = {
        position: "relative",
        marginLeft: "407",
    };
    return (
        <>
            <HindcastsPanel
                toggleable={tablet}
                container={container}
                setTableData={setTableData}
                setFailedParameters={setFailedParameters}
                loading={loading}
                setLoading={setLoading}
                setNodataContent={setNodataContent}
                setCoordinates={setCoordinates}
            >
                {/* <ConfirmationDialog {...confirmation} /> */}
                {!loading ? (
                    resultPanel()
                ) : (
                    <CenteredLoading className={{ resetLoading }} />
                )}
            </HindcastsPanel>
        </>
    );
};

export default HindcastsPage;
