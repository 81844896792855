import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.generalUi.panelBackground,
        padding: theme.spacing(0, 5),
        height: 48,
        minHeight: 48,
        justifyContent: "space-between",
    },
    title: {
        fontSize: 14,
    },
    subtitle: {
        color: "#959595",
        fontSize: 12,
    },
}));

const Topbar = (props) => {
    const classes = useStyles(props);
    const { title, subtitle } = props;

    return (
        <>
            <div className={classes.root}>
                <Typography className={classes.title} variant="h5">
                    {title}
                </Typography>
                {subtitle}
            </div>
        </>
    );
};

export default Topbar;
