import React, { useState, useEffect, useCallback } from "react";
import App from "./App";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Login from "./pages/Login";
import { useCookies } from "react-cookie";
import { updateConfig } from "./redux/actions/config";
import { logout, rolesAndPermissionsLoaded } from "./redux/actions/user";
import Api from "./api";
import { push } from "connected-react-router";
import { configureApi } from "./api/config";
import sjcl from "sjcl";
import { useMsal, useAccount } from "@azure/msal-react";

import jwt_decode from "jwt-decode";
import { EventType, InteractionStatus } from "@azure/msal-browser";

import { modules } from "./utils/moduleConfig";

const LoadingIcon = () => {
    return (
        <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <CircularProgress />
        </div>
    );
};

const IndexApp = (props) => {
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(["logo"]);

    const [authenticated, setAuthenticated] = useState(null);

    const [failedLoginCounter, setFailedLoginCounter] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const enableAADLogin =
        process.env.REACT_APP_ENABLE_AAD_LOGIN.toString() === "true";
    const disablePasswordLogin =
        process.env.REACT_APP_DISABLE_PASSWORD_LOGIN.toString() === "true";

    useEffect(() => {
        if (cookies.logo) {
            configureApi(cookies.logo.slice(0, 30) + cookies.logo.slice(31));
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }, [cookies.logo]);

    useEffect(() => {
        if (cookies.logo && cookies.company) {
            const rp = JSON.parse(
                sjcl.decrypt(
                    cookies.logo.slice(0, 5),
                    JSON.parse(atob(cookies.company))
                )
            );
            dispatch(rolesAndPermissionsLoaded(rp));
        }
    }, [cookies.logo, cookies.company, dispatch]);

    useEffect(() => {
        dispatch(updateConfig("username", cookies.username));
    }, [cookies.username, dispatch]);

    const authenticate = (username, password, rememberLogin) => {
        setLoading(true);
        Api.authenticateUser(username, password)
            .then((res) => {
                let token = res.data.token;

                let rolesAndPermissions = extractRolesAndPermissionsFromToken(
                    res.data
                );
                if (rememberLogin) {
                    saveRolesAndPermissions(rolesAndPermissions, token);
                }

                configureApi(token);
                setAuthenticated(true);
                dispatch(updateConfig("username", username, true));
                dispatch(rolesAndPermissionsLoaded(rolesAndPermissions));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (failedLoginCounter < 2) {
                    setErrorMessage(
                        "Incorrect username and/or password. Please try again."
                    );
                    setFailedLoginCounter(failedLoginCounter + 1);
                } else {
                    setErrorMessage(
                        "Incorrect username and/or password. Please try again or contact your administrator to reset your password."
                    );
                }
                setAuthenticated(false);
            });
    };

    const extractUsernameFromToken = (token) => {
        let decoded_token = jwt_decode(token);
        return decoded_token.preferred_username.split("@")[0];
    };

    const extractRolesAndPermissionsFromToken = (data) => {
        const roleNames = data.roles.map((r) => r.roleName);
        const permissions = data.permissions.map((r) => r.permissionName);
        const mainPage = data.mainPage;

        return {
            roles: roleNames,
            permissions: permissions,
            mainPage: mainPage ? mainPage : modules["forecasts"].permission,
        };
    };

    const saveRolesAndPermissions = useCallback(
        (rolesAndPermissions, token) => {
            let e = sjcl.encrypt(
                token.slice(0, 5),
                JSON.stringify(rolesAndPermissions)
            );
            setCookie("company", btoa(JSON.stringify(e)), {
                path: "/",
                expires: new Date(2500, 12, 31),
            });

            setCookie("logo", token.slice(0, 30) + "s" + token.slice(30), {
                path: "/",
                expires: new Date(2500, 12, 31),
            });
        },
        [setCookie]
    );

    const logoutUser = useCallback(() => {
        removeCookie("logo", { path: "/" });
        removeCookie("company", { path: "/" });
        setAuthenticated(false);
        dispatch(logout());
        dispatch(push("/"));
    }, [removeCookie, dispatch]);

    const { instance, accounts, inProgress } = useMsal();

    const account = useAccount(accounts[0] || {});

    useEffect(() => {
        const updateUserUsingAADAuthentication = (token) => {
            let username = extractUsernameFromToken(token);

            configureApi(token);

            Api.getAuthenticatedUser()
                .then((res) => {
                    const rolesAndPermissions =
                        extractRolesAndPermissionsFromToken(res.data);

                    saveRolesAndPermissions(rolesAndPermissions, token);

                    setAuthenticated(true);
                    dispatch(updateConfig("username", username, true));
                    dispatch(rolesAndPermissionsLoaded(rolesAndPermissions));
                    setLoading(false);
                })
                .catch((err) => {
                    setErrorMessage(
                        "User deleted. Please contact your administrator."
                    );
                    setAuthenticated(false);
                    setLoading(false);
                    logoutUser();
                });
        };

        if (
            instance != null &&
            inProgress === InteractionStatus.None &&
            accounts.length > 0
        ) {
            // Retrieve an access token
            const tokenRequest = {
                account: accounts[0],
                scopes: [process.env.REACT_APP_AAD_SCOPE],
            };

            instance
                .acquireTokenSilent(tokenRequest)
                .then((response) => {
                    if (response.accessToken) {
                        console.log("acquired token silently");

                        instance.setActiveAccount(accounts[0]);

                        let token = response.accessToken;

                        updateUserUsingAADAuthentication(token);
                    } else {
                        setLoading(false);
                        setAuthenticated(false);
                        setErrorMessage("Unable to authenticate using SSO");
                        logoutUser();
                    }
                })
                .catch((err) => {
                    // call acquireTokenPopup in case of acquireTokenSilent failure
                    // due to consent or interaction required
                    if (
                        err.errorCode === "consent_required" ||
                        err.errorCode === "interaction_required" ||
                        err.errorCode === "login_required"
                    ) {
                        instance
                            .acquireTokenPopup(tokenRequest)
                            .then(function (response) {
                                console.log("acquired token from popup");
                                const token = response.accessToken;
                                updateUserUsingAADAuthentication(token);
                            })
                            .catch(function (error) {
                                setErrorMessage(
                                    "Unable to authorize. Please contact your administrator"
                                );
                                logoutUser();
                            });
                    }
                });
        }
    }, [
        inProgress,
        accounts,
        instance,
        logoutUser,
        saveRolesAndPermissions,
        dispatch,
    ]);

    const logoutAADUser = () => {
        const logoutRequest = {
            account: accounts[0],
            mainWindowRedirectUri: process.env.REACT_APP_AAD_REDIRECT_URL,
            postLogoutRedirectUri: process.env.REACT_APP_AAD_REDIRECT_URL,
        };
        instance.logoutPopup(logoutRequest);
    };

    instance.addEventCallback((message) => {
        if (message.eventType === EventType.LOGOUT_END && authenticated) {
            logoutUser();
        }
    });

    if (authenticated) {
        return (
            <App
                logout={enableAADLogin && account ? logoutAADUser : logoutUser}
            />
        );
    } else if (authenticated === false) {
        return (
            <Login
                loading={loading}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                onSubmit={authenticate}
                enableAADLogin={enableAADLogin}
                disablePasswordLogin={disablePasswordLogin}
            />
        );
    } else {
        return LoadingIcon();
    }
};

export default IndexApp;
