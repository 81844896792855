import {
    ALERTS,
    EXPIRED_ALERTS,
    ALERT_FREQ,
    ALERT_OP,
    ALERT_LOGIC,
    STATUS,
} from "../types";
import Api from "../../api";
import { handleError } from "../../utils/error";
import { changeStatus } from "./status";
import orderBy from "lodash/sortBy";

export const getAlerts = () => {
    return (dispatch) => {
        return Api.Alerts.getAlerts()
            .then((res) => {
                const alerts = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: ALERTS.LOADED, payload: alerts });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const createAlert = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Alerts.createAlert(data)
            .then((res) => {
                dispatch(getAlerts());
                dispatch(getExpiredAlerts());
                dispatch(changeStatus(STATUS.SUCCESS, "Alert created"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const updateAlert = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Alerts.updateAlert(data)
            .then((res) => {
                if (res) {
                    dispatch(getAlerts());
                    dispatch(getExpiredAlerts());
                    dispatch(changeStatus(STATUS.SUCCESS, "Alert updated"));
                } else {
                    dispatch(
                        changeStatus(STATUS.ERROR, "Alert failed to update")
                    );
                }
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const deleteAlert = (id) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Alerts.deleteAlert(id)
            .then((res) => {
                dispatch(getAlerts());
                dispatch(changeStatus(STATUS.SUCCESS, "Alert deleted"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getExpiredAlerts = () => {
    return (dispatch) => {
        return Api.Alerts.getExpiredAlerts()
            .then((res) => {
                const alerts = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: EXPIRED_ALERTS.LOADED, payload: alerts });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const deleteExpiredAlerts = () => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Alerts.deleteExpiredAlerts()
            .then((res) => {
                dispatch(getAlerts());
                dispatch(getExpiredAlerts());
                dispatch(
                    changeStatus(STATUS.SUCCESS, "Expired alerts deleted")
                );
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getAlertFrequencies = () => {
    return (dispatch) => {
        return Api.Alerts.getAlertFrequencies()
            .then((res) => {
                dispatch({ type: ALERT_FREQ.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getAlertOperators = () => {
    return (dispatch) => {
        return Api.Alerts.getAlertOperators()
            .then((res) => {
                dispatch({ type: ALERT_OP.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getAlertLogics = () => {
    return (dispatch) => {
        return Api.Alerts.getAlertLogics()
            .then((res) => {
                dispatch({ type: ALERT_LOGIC.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};
