import { VESTAS_SITES } from "../types";

export function vestasSites(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case VESTAS_SITES.LOADED:
            return payload;
        default:
            return state;
    }
}
