import axios from "axios";
import Alerts from "./alerts";
import Settings from "./settings";
import Sites from "./sites";
import HWWA from "./hwwa";
import Reports from "./reports";
import Hindcasts from "./hindcasts";

const API_URL = process.env.REACT_APP_API_URL;

const Api = {
    authenticateUser(username, password) {
        return axios({
            method: "get",
            url: `${API_URL}/authentication`,
            headers: {
                username,
                password,
            },
        });
    },
    getAuthenticatedUser() {
        return axios({
            method: "get",
            url: `${API_URL}/authentication_aad`,
        });
    },

    getCustomer() {
        return axios({
            method: "get",
            url: `${API_URL}/customer`,
        });
    },
    getCSVReport(id, local, days) {
        return axios({
            method: "get",
            url: `${API_URL}/report/csv/${id}?inLocalTime=${local}&days=${days}`,
        });
    },
    getParameters(siteId) {
        return axios({
            method: "get",
            url: `${API_URL}/parameter${siteId ? "?siteId=" + siteId : ""}`,
        });
    },
    getGroupedParameters() {
        return axios({
            method: "get",
            url: `${API_URL}/parameter/grouped`,
        });
    },
    updateParameters(data) {
        return axios({
            method: "put",
            url: `${API_URL}/parameter`,
            data,
        });
    },
    Sites,
    Settings,
    Alerts,
    HWWA,
    Reports,
    Hindcasts,
};
export default Api;
