import DefaultPDFHeader from "../assets/customers/default/PDFTop";
import DefaultPDFFooter from "../assets/customers/default/PDFBottom";
import VestasPDFHeader from "../assets/customers/vestas/VestasPDFTop";
import VestasPDFFooter from "../assets/customers/vestas/VestasPDFBottom";

export const defaultCustomerName = "default";

export const customers = [
    {
        name: defaultCustomerName,
        customerId: -1,
        logo: "assets/customers/default/conwx_logo.svg",
        modules: ["forecasts", "alerts", "reports", "hindcasts", "forecastui"],
        pdf: {
            header: DefaultPDFHeader,
            footer: DefaultPDFFooter,
        },
    },
    {
        name: "vestas",
        customerId: 7,
        logo: "assets/customers/vestas/Vestas logo_RGB_100.jpg",
        modules: ["forecasts", "alerts", "reports", "hwwa"],
        pdf: {
            header: VestasPDFHeader,
            footer: VestasPDFFooter,
        },
    },
];
