import React from "react";
import { ChartLegend } from "@progress/kendo-react-charts";

const CustomChartLegend = (props) => {
    return (
        <ChartLegend
            position={"bottom"}
            align={"center"}
            labels={{
                font: "600 12px/15px Open Sans",
                color: "#6f6f6f",
                margin: 4,
            }}
            spacing={20}
            {...props}
        />
    );
};

export default CustomChartLegend;
