import { PARAMETERS } from "../types";

export function parameters(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case PARAMETERS.LOADED:
            return payload;
        case PARAMETERS.UPDATED:
            return state;
        default:
            return state;
    }
}
