import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Typography,
    ListItem,
    Tabs,
    Tab,
    withStyles,
    IconButton,
} from "@material-ui/core";
import CenteredLoading from "../../common/CenteredLoading";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    starIcon,
    starIconSelected,
} from "../../assets/icons/fontawesome/starIcons";

export const CustomTab = withStyles((theme) => ({
    root: {
        minHeight: 23,
        minWidth: "50%",
        padding: 0,
        fontSize: 13,
        color: theme.palette.common.white,
        opacity: 1,
        transition: "color 150ms ease 0ms",
        "&$selected": {
            color: "#2699FB",
            fontWeight: 600,
        },
    },
    selected: {},
    wrapper: {
        zIndex: 1,
        height: `calc(100% - ${theme.spacing(0.25)}px)`,
        marginBottom: theme.spacing(0.25),
    },
}))((props) => <Tab disableRipple {...props} />);

export const CustomTabs = withStyles((theme) => ({
    root: {
        minHeight: 23,
        height: 23,
    },
    indicator: {
        height: 24,
        zIndex: 0,
        transition: "all 150ms ease 0ms",
        bottom: 0,
        backgroundColor: theme.palette.common.white,
    },
}))(Tabs);

const useStyles = makeStyles((theme) => ({
    listContainer: {
        position: "relative",
        flex: 1,
    },
    row: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: "space-between",
        paddingRight: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.secondMenuItem.hoverBackground,
        },
    },
    selectedRow: {
        backgroundColor: `${theme.palette.secondMenuItem.selectedBackground} !important`,
    },
    noDataRow: {
        padding: theme.spacing(3, 4),
    },
    rowText: {
        color: theme.palette.text.default,
        fontSize: 13,
        lineHeight: 1.3,
    },
    rowSubtext: {
        color: theme.palette.text.defaultSub,
        fontSize: 9,
        lineHeight: 1.3,
        "&:last-child": {
            padding: theme.spacing(0, 0, 0, 2),
        },
    },
    rowSubtextContainer: {
        width: "100%",
        display: "flex",
    },
    rowMainContent: {
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "display",
    },
    rowFavouriteStar: {},
    starIcon: {
        fontSize: 12,
        color: theme.palette.generalUi.icons,
    },
}));

const SiteList = (props) => {
    const classes = useStyles(props);
    const { sites, siteSelected, toggleFavorite, isFavorite, selectedSite } =
        props;

    const renderRow = (props) => {
        const { index, style } = props;
        const site = sites[index];
        const { name, latitude, longitude, id } = site;

        const selected = id === selectedSite;
        return (
            <ListItem
                button
                style={style}
                key={index}
                selected={selected}
                className={
                    selected
                        ? `${classes.row} ${classes.selectedRow}`
                        : classes.row
                }
                onClick={() => siteSelected(id)}
            >
                <div className={classes.rowMainContent}>
                    <Typography className={classes.rowText}>{name}</Typography>
                    <div className={classes.rowSubtextContainer}>
                        <Typography
                            className={classes.rowSubtext}
                        >{`LAT: ${latitude.toFixed(2)}`}</Typography>
                        <Typography
                            className={classes.rowSubtext}
                        >{`LON: ${longitude.toFixed(2)}`}</Typography>
                    </div>
                </div>
                {toggleFavorite && (
                    <div className={classes.rowFavouriteStar}>
                        <IconButton onClick={(e) => toggleFavorite(e, site)}>
                            {isFavorite(id) ? (
                                <FontAwesomeIcon
                                    icon={starIconSelected}
                                    className={classes.starIcon}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={starIcon}
                                    className={classes.starIcon}
                                />
                            )}
                        </IconButton>
                    </div>
                )}
            </ListItem>
        );
    };

    const renderRowNoData = () => {
        return (
            <ListItem className={clsx(classes.row, classes.noDataRow)}>
                <div className={classes.rowMainContent}>
                    <Typography className={classes.rowText}>
                        {"No sites found"}
                    </Typography>
                </div>
            </ListItem>
        );
    };

    return (
        <div className={classes.listContainer}>
            {sites ? (
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            className="List"
                            height={height - 1}
                            itemCount={sites.length > 0 ? sites.length : 1}
                            itemSize={40}
                            width={width}
                        >
                            {sites.length > 0 ? renderRow : renderRowNoData}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <CenteredLoading />
            )}
        </div>
    );
};

export default SiteList;
