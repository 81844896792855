import React from "react";
import { ChartCategoryAxisItem } from "@progress/kendo-react-charts";
import { hidden, visible, returnLabelFont } from "./chartStyles";
import { useTheme } from "@material-ui/core";

const CustomChartCategoryAxisItem = (props) => {
    const theme = useTheme();
    const { labels, ...otherProps } = props;
    return (
        <ChartCategoryAxisItem
            line={{ color: theme.palette.generalUi.borders, width: 1 }}
            labels={{
                font: returnLabelFont(theme, 11, 400),
                ...labels,
            }}
            majorGridLines={visible}
            majorTicks={hidden}
            overlap="true"
            {...otherProps}
        />
    );
};

export default CustomChartCategoryAxisItem;
