import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { getConfig, updateConfig } from "../redux/actions/config";
import { getSites } from "../redux/actions/sites";
import {
    getGroupedParameters,
    updateParameters,
} from "../redux/actions/parameters";
import Api from "../api";
import DetailsTopbar from "../features/forecasts-charts-topbar/DetailsTopbar";
import DetailsToolbar from "../features/forecasts-charts-toolbar/DetailsToolbar";
import Map from "../features/forecasts-map/Map";
import { push } from "connected-react-router";
import CloseButton from "../common/CloseButton";
import CenteredLoading from "../common/CenteredLoading";
import Charts from "../features/forecasts-charts/Charts";
import ChartsBottomBar from "../features/forecasts-charts-bottombar/ChartsBottomBar";
import { modules } from "../utils/moduleConfig";

import { ifDefined } from "../utils/values";

import ForecastTable from "../features/forecast-table/ForecastTable";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        top: 0,
        flex: 1,
        width: `100%`,
        display: "flex",
        flexDirection: "column",
    },
    fullHeight: {
        height: "100%",
    },
    map: {
        // transition:'all 200ms ease-in-out 0ms',
        width: "0%",
    },
    mapOpen: {
        width: "36%",
    },
    detailsMain: {
        display: "flex",
        flex: 1,
        position: "relative",
    },
    detailsContent: {
        flex: 1,
        display: "flex",
        position: "relative",
        overflow: "hidden",
    },
    detailsCharts: {
        padding: theme.spacing(4),
        flex: 1,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    chartContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    bottomBarContainer: {
        padding: theme.spacing(1, 10, 4, 10),
        height: 24,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2, 1, 1, 1),
            height: "auto",
        },
    },
    noData: {
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "50%",
        left: "50%",
    },
    pdfTop: {
        bottom: "100%",
        padding: theme.spacing(0, 10),
        width: "100%",
        boxSizing: "border-box",
        position: "absolute",
        marginBottom: theme.spacing(4),
    },
    pdfBottom: {
        top: "100%",
        padding: theme.spacing(0, 10),
        position: "absolute",
        width: "100%",
        boxSizing: "border-box",
        marginTop: theme.spacing(4),
    },
}));

const mapStateToProps = (state, ownProps) => {
    return {
        sites: state.sites,
        searchedSites: state.searchedSites,
        favoriteSites: state.favoriteSites,
        sitesView: state.sitesView,
        config: state.config,
        parameters: state.parameters,
        user: state.user,
        pdfComponents: state.customer.config.pdf,
    };
};

const SiteDetails = (props) => {
    const classes = useStyles(props);
    const {
        sites,
        searchedSites,
        favoriteSites,
        sitesView,
        getSites,
        parameters,
        getGroupedParameters,
        updateParameters,
        match,
        push,
        config,
        getConfig,
        updateConfig,
        mapToggleable,
        mobile,
        user,
        pdfComponents,
    } = props;
    const [configRead, setConfigRead] = useState();
    const [site, setSite] = useState(null);
    const [siteData, setSiteData] = useState(null);
    const [mapOpen, setMapOpen] = useState(false);
    const [days, setDays] = useState(10);
    const [timezoneOffset, setTimezoneOffset] = useState(0);
    const [thresholdsVisible, setThresholdsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfDate, setPDFDate] = useState(null);
    const [pdfReady, setPDFReady] = useState(false);
    const [chartSets, setChartSets] = useState(null);
    const dataAvailable = Boolean(
        siteData ? siteData.chartSets[0].groups[0].data.length > 0 : false
    );
    const [showForecastTable, setShowForecastTable] = useState(false);
    const PDFHeader = pdfComponents.header;
    const PDFFooter = pdfComponents.footer;

    useEffect(() => {
        if (siteData && !chartSets) {
            const upperChart = siteData.chartSets.find(
                (x) => x.chartType === 0
            );
            const lowerChart = siteData.chartSets.find(
                (x) => x.chartType === 1
            );
            const upper = upperChart ? upperChart.chartId : null;
            const lower = lowerChart ? lowerChart.chartId : null;
            setChartSets([upper, lower]);
        }
    }, [siteData, chartSets, setChartSets]);

    useEffect(() => {
        if (!parameters) {
            setLoading(true);
            getGroupedParameters();
        }
    }, [parameters, getGroupedParameters]);

    useEffect(() => {
        if (siteData && sites && config && !configRead) {
            ifDefined(
                getConfig(
                    `${config.username}_sitedetails_days`,
                    undefined,
                    true
                ),
                (value) => {
                    const ivalue = parseInt(value);
                    if (days !== ivalue) {
                        setLoading(true);
                        setDays(ivalue);
                    }
                }
            );
            ifDefined(
                getConfig(
                    `${config.username}_sitedetails_chartsets`,
                    undefined,
                    true
                ),
                (value) => {
                    setChartSets(value.split(",").map((x) => +x));
                }
            );
            ifDefined(
                getConfig(
                    `${config.username}_sitedetails_timezoneoffset_is_local`,
                    undefined,
                    true
                ),
                (value) => {
                    const bvalue = value === "true";
                    if (bvalue) {
                        setTimezoneOffset(siteData.timeZone.timeZoneOffsetMs);
                    } else {
                        setTimezoneOffset(0);
                    }
                }
            );
            ifDefined(
                getConfig(
                    `${config.username}_sitedetails_mapopen`,
                    undefined,
                    true
                ),
                (value) => {
                    const bvalue = value === "true";
                    if (mapOpen !== bvalue) {
                        setMapOpen(bvalue);
                    }
                }
            );
            ifDefined(
                getConfig(
                    `${config.username}_sitedetails_thresholdsvisible`,
                    undefined,
                    true
                ),
                (value) => {
                    const bvalue = value === "true";
                    if (thresholdsVisible !== bvalue) {
                        setThresholdsVisible(bvalue);
                    }
                }
            );
            setConfigRead(true);
        }
    }, [
        sites,
        config,
        configRead,
        days,
        getConfig,
        mapOpen,
        timezoneOffset,
        thresholdsVisible,
        siteData,
    ]);

    useEffect(() => {
        if (sites) {
            const newSite = sites.find(
                (x) => x.id.toString() === match.params.id
            );
            if (newSite) {
                setSite(newSite);
            } else {
                push(`/`);
            }
        }
    }, [sites, getSites, match.params.id, push]);

    useEffect(() => {
        if (siteData !== null) {
            ifDefined(
                getConfig(
                    `${config.username}_sitedetails_timezoneoffset_is_local`,
                    undefined,
                    true
                ),
                (value) => {
                    const bvalue = value === "true";
                    if (bvalue) {
                        setTimezoneOffset(siteData.timeZone.timeZoneOffsetMs);
                    } else {
                        setTimezoneOffset(0);
                    }
                }
            );
        }
    }, [siteData, config.username, getConfig]);

    useEffect(() => {
        if (parameters) {
            setLoading(true);
            if (user?.isMultiUser) {
                let flatParameters = [];
                parameters.forEach((chart) =>
                    chart.groups.forEach((group) =>
                        group.parameters.forEach((parameter) =>
                            flatParameters.push({ ...parameter })
                        )
                    )
                );
                Api.Sites.getSiteForecastsWithParams(
                    match.params.id,
                    days,
                    flatParameters
                ).then((res) => {
                    setSiteData(res.data);
                    setLoading(false);
                });
            } else {
                Api.Sites.getSiteForecasts(match.params.id, days).then(
                    (res) => {
                        setSiteData(res.data);
                        setLoading(false);
                    }
                );
            }
        }
    }, [match.params.id, days, parameters, user]);

    const toggleDays = () => {
        const newValue = days === 5 ? 10 : 5;
        setLoading(true);
        setDays(newValue);
        updateConfig(`${config.username}_sitedetails_days`, newValue, true);
    };

    const toggleTimezone = () => {
        const newValue =
            timezoneOffset === 0 ? siteData.timeZone.timeZoneOffsetMs : 0;
        setLoading(true);
        setTimezoneOffset(newValue);
        updateConfig(
            `${config.username}_sitedetails_timezoneoffset_is_local`,
            Boolean(newValue !== 0),
            true
        );
        setLoading(false);
    };

    const toggleThresholds = () => {
        const newValue = !thresholdsVisible;
        setThresholdsVisible(newValue);
        updateConfig(
            `${config.username}_sitedetails_thresholdsvisible`,
            newValue,
            true
        );
    };

    const toggleMap = () => {
        const newValue = !mapOpen;
        setMapOpen(newValue);
        updateConfig(`${config.username}_sitedetails_mapopen`, newValue, true);
    };

    const toggleChartSets = (chartSets) => {
        setChartSets(chartSets);
        updateConfig(
            `${config.username}_sitedetails_chartsets`,
            chartSets,
            true
        );
    };

    const close = () => {
        push(modules["forecasts"]["url"]);
    };

    const findSiteList = () => {
        if (searchedSites) {
            return searchedSites;
        } else {
            switch (sitesView) {
                case 0:
                    return favoriteSites;
                case 1:
                    return sites;
                default:
                    return [];
            }
        }
    };

    const toggleTableDisplay = () => {
        setShowForecastTable(!showForecastTable);
    };

    return (
        <div className={classes.root}>
            {site ? (
                <>
                    <DetailsTopbar
                        site={site}
                        showForecastTable={showForecastTable}
                        toggleTableDisplay={toggleTableDisplay}
                    >
                        <CloseButton small={mobile} onClick={close} />
                    </DetailsTopbar>
                    <div className={classes.detailsMain}>
                        <div
                            className={clsx(
                                classes.map,
                                mapOpen && mapToggleable && classes.mapOpen
                            )}
                        >
                            {mapOpen && mapToggleable && (
                                <Map
                                    fullScreen={true}
                                    allSites={sites}
                                    sites={findSiteList()}
                                    selectedSite={match.params.id}
                                    isMapLoading={Boolean(!sites)}
                                    isHomepage={false}
                                />
                            )}
                        </div>
                        <div
                            id={"drawer-container"}
                            className={classes.detailsContent}
                        >
                            <div className={classes.detailsCharts}>
                                {siteData && chartSets ? (
                                    dataAvailable ? (
                                        <div
                                            className={classes.chartContainer}
                                            id="chart-pdf-container"
                                        >
                                            <div className={classes.pdfTop}>
                                                <PDFHeader
                                                    site={site}
                                                    pdfDate={pdfDate}
                                                />
                                            </div>
                                            {!showForecastTable ? (
                                                <Charts
                                                    loading={loading}
                                                    siteData={siteData}
                                                    days={days}
                                                    timezoneOffset={
                                                        timezoneOffset
                                                    }
                                                    pdfDate={pdfDate}
                                                    setPDFReady={setPDFReady}
                                                    pdfReady={pdfReady}
                                                    thresholdsVisible={
                                                        thresholdsVisible
                                                    }
                                                    setChartSets={
                                                        toggleChartSets
                                                    }
                                                    chartSets={chartSets}
                                                />
                                            ) : (
                                                <ForecastTable
                                                    siteData={siteData}
                                                    parameters={parameters}
                                                    setPDFReady={setPDFReady}
                                                    timezoneOffset={
                                                        timezoneOffset
                                                    }
                                                    thresholdsVisible={
                                                        thresholdsVisible
                                                    }
                                                ></ForecastTable>
                                            )}
                                            <div
                                                className={
                                                    classes.bottomBarContainer
                                                }
                                            >
                                                <ChartsBottomBar
                                                    loading={loading}
                                                    toggleDays={toggleDays}
                                                    days={days}
                                                    toggleTimezone={
                                                        toggleTimezone
                                                    }
                                                    localTimezoneName={
                                                        siteData.timeZone
                                                            .shortName
                                                    }
                                                    localTimezone={Boolean(
                                                        timezoneOffset !== 0
                                                    )}
                                                    thresholdsVisible={
                                                        thresholdsVisible
                                                    }
                                                    toggleThresholds={
                                                        toggleThresholds
                                                    }
                                                />
                                            </div>
                                            <div className={classes.pdfBottom}>
                                                <PDFFooter />
                                            </div>
                                        </div>
                                    ) : (
                                        <Typography
                                            variant="h3"
                                            className={classes.noData}
                                        >
                                            No data available for site.
                                        </Typography>
                                    )
                                ) : (
                                    <CenteredLoading />
                                )}
                            </div>
                            <DetailsToolbar
                                //drawers
                                container={document.getElementById(
                                    "drawer-container"
                                )}
                                dataAvailable={dataAvailable}
                                mobile={mobile}
                                //map
                                toggleMap={toggleMap}
                                mapOpen={mapOpen}
                                mapToggleable={mapToggleable}
                                //export
                                setPDFDate={setPDFDate}
                                pdfDate={pdfDate}
                                setPDFReady={setPDFReady}
                                pdfReady={pdfReady}
                                days={days}
                                localTimezone={Boolean(timezoneOffset !== 0)}
                                timezoneOffset={timezoneOffset}
                                site={site}
                                //settings/thresholds
                                parameters={parameters}
                                updateParameters={updateParameters}
                                chartSets={chartSets}
                                setChartSets={toggleChartSets}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <CenteredLoading />
            )}
        </div>
    );
};

export default connect(mapStateToProps, {
    getSites,
    getGroupedParameters,
    updateParameters,
    push,
    getConfig,
    updateConfig,
})(SiteDetails);
