import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
    Chart,
    ChartSeries,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartPanes,
    ChartPane,
    ChartLegend,
} from "@progress/kendo-react-charts";
import { Path, Group } from "@progress/kendo-drawing";
import moment from "moment";

const miniChartValueAxisName = "miniChartValueAxis";
const miniChartCategoryAxisName = "miniChartCategoryAxis";

const MiniChart = (props) => {
    const theme = useTheme();
    const {
        miniChartWeatherWindow,
        miniChartHeight,
        miniChartBottomSpacing,
        mobile,
        categories,
        weatherWindowData,
        minMaxView,
        setMinMaxView,
        renderSeriesOfChart,
        miniChart,
        pdfDate,
        renderDayLineByChartType,
        createArrayOfMidnights,
    } = props;
    const hidden = { visible: false };
    const visible = { visible: true };
    const navColor = theme.palette.generalUi.lines;

    const onSelectEnd = (args) => {
        // set the axis range displayed in the main pane to the selected range
        setMinMaxView({
            min: args.from,
            max: args.to,
        });
    };

    const renderSelectionLines = (chart) => {
        // get the axes
        const valueAxis = chart.findAxisByName(miniChartValueAxisName);
        const categoryAxis = chart.findAxisByName(miniChartCategoryAxisName);

        // get the coordinates of the value at which the plot band will be rendered
        const categorySlotMin = categoryAxis.slot(minMaxView.min);
        const categorySlotMax = categoryAxis.slot(minMaxView.max);
        // get the coordinates of the entire category axis range
        const range = valueAxis.range();
        const valueSlot = valueAxis.slot(range.min, range.max);

        // draw the plot band based on the found coordinates
        const min = new Path({
            stroke: {
                color: "black",
                width: 2,
            },
        })
            .moveTo(categorySlotMin.origin.x, valueSlot.origin.y)
            .lineTo(categorySlotMin.origin.x, valueSlot.bottomRight().y);

        const max = new Path({
            stroke: {
                color: "black",
                width: 2,
            },
        })
            .moveTo(categorySlotMax.origin.x, valueSlot.origin.y)
            .lineTo(categorySlotMax.origin.x, valueSlot.bottomRight().y);

        const group = new Group();
        group.append(min, max);
        //draw on the surface
        chart.surface.draw(group);
    };

    const renderDayLines = (chart) => {
        const lineGroup = new Group();
        const labelAlignment = "bottom";
        const data = miniChart.groups[0].data;
        const arrayOfMidnights = createArrayOfMidnights(
            moment.unix(data[0].time).toDate(),
            moment.unix(data[data.length - 1].time).toDate()
        );
        renderDayLineByChartType(
            chart,
            lineGroup,
            arrayOfMidnights,
            miniChartValueAxisName,
            miniChartCategoryAxisName,
            labelAlignment
        );
        chart.surface.draw(lineGroup);
    };

    const onRender = (args) => {
        const chart = args.target.chartInstance;
        if (chart) {
            renderDayLines(chart);
            if (pdfDate) {
                renderSelectionLines(chart);
            }
        }
    };

    return (
        <div id="mini-chart">
            <Chart
                onRender={onRender}
                onSelectEnd={onSelectEnd}
                transitions={false}
                style={{
                    height:
                        miniChartHeight +
                        miniChartBottomSpacing +
                        miniChartWeatherWindow,
                    padding: mobile ? "0px 4px" : "0px 32px",
                }}
            >
                <ChartLegend visible={false} />
                <ChartPanes>
                    <ChartPane
                        name={"weatherWindowPane"}
                        height={miniChartWeatherWindow}
                    />
                    <ChartPane
                        name={"miniChartPane"}
                        margin={{ bottom: miniChartBottomSpacing }}
                    />
                </ChartPanes>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        pane={"weatherWindowPane"}
                        categories={categories}
                        labels={hidden}
                        plotBands={weatherWindowData}
                        majorGridLines={hidden}
                        majorTicks={hidden}
                    />
                    <ChartCategoryAxisItem
                        categories={categories}
                        pane={"miniChartPane"}
                        name={miniChartCategoryAxisName}
                        labels={hidden}
                        majorGridLines={visible}
                        majorTicks={hidden}
                        select={{ from: minMaxView.min, to: minMaxView.max }}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        majorGridLines={hidden}
                        minorTicks={hidden}
                        majorTicks={hidden}
                        labels={hidden}
                        name={"weatherWindow"}
                        pane={"weatherWindowPane"}
                        axisCrossingValues={[-1e6]}
                    />
                    <ChartValueAxisItem
                        name={miniChartValueAxisName}
                        labels={hidden}
                        width={30}
                        majorTicks={hidden}
                        pane={"miniChartPane"}
                        axisCrossingValues={[-1e6, -1e6, -1e6, -1e6]}
                    />
                </ChartValueAxis>
                <ChartSeries>
                    {renderSeriesOfChart(miniChart, "", {
                        axis: miniChartValueAxisName,
                        visible: true,
                        type: "area",
                        markers: { visible: false },
                        color: navColor,
                    })}
                </ChartSeries>
            </Chart>
        </div>
    );
};

export default MiniChart;
