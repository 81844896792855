import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
    makeStyles,
    Typography,
    CircularProgress,
    Grid,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import {
    CustomTable,
    CustomTableHead,
    CustomTableBody,
    CustomTableCell,
    CustomTableRow,
} from "../../common/restyled-mui/CustomTable";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import {
    CustomInputLabel,
    CustomTextField,
} from "../../common/restyled-mui/CustomInputs";
import { findSites, clearSites } from "../../redux/actions/settings";
import Navigation from "../settings-navigation/Navigation";
import { push } from "connected-react-router";
import _ from "lodash";
import { getSettingsStyles } from "../../styles/settingsStyles";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    addNewButton: {
        float: "right",
    },
    virtualTableBodyContainer: {
        height: "100%",
        backgroundColor: "white",
    },
    virtualTableBody: {
        height: "100%",
        backgroundColor: "white",
    },
    tableRow: {
        borderBottom: "1px solid rgb(224,224,224)",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        width: "100%",
    },
    tableCell: {
        border: "none",
    },
}));

const isVestas =
    "vestas" === `${process.env.REACT_APP_DEPLOYMENT_CUSTOMER}`.toLowerCase();
const mapStateToProps = (state) => ({
    sites: state.sitesSettings,
    searchState: state.siteSearchSettings,
    permissions: state.user.permissions,
});

const Sites = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [search, setSearch] = useState({
        name: "",
        description: "",
        isHidden: null,
        latMin: "",
        latMax: "",
        lonMin: "",
        lonMax: "",
    });
    const [sort, setSort] = useState(["name", "asc"]);
    const [sortedSites, setSortedSites] = useState([]);

    const { sites, findSites, clearSites, push, location, permissions } = props;
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        return () => {
            clearSites();
        };
    }, [clearSites]);

    useEffect(() => {
        if (!sites) {
            findSites();
        }
    }, [sites, findSites]);

    useEffect(() => {
        if (sites) {
            if (sites.length === 0) {
                setSortedSites([]);
            } else {
                let ordered = null;
                if (typeof sites[0][sort[0]] == "string") {
                    ordered = _.orderBy(
                        sites,
                        [
                            (site) =>
                                site[sort[0]] == null
                                    ? ""
                                    : site[sort[0]].toLowerCase(),
                        ],
                        sort[1]
                    );
                } else {
                    ordered = _.orderBy(sites, sort[0], sort[1]);
                }
                setSortedSites(ordered);
            }
        }
    }, [sort, sites]);

    const handleCreate = () => {
        push(`${location.pathname}/create`);
    };

    const renderActionButtons = () => {
        return (
            permissions.includes("Admin.ManageSites.Create") && (
                <CustomButton
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                    className={classes.addNewButton}
                >
                    Add new
                </CustomButton>
            )
        );
    };

    const renderSearchButtons = () => {
        return (
            <>
                <CustomButton
                    onClick={clearSearch}
                    variant="contained"
                    disableElevation
                    className={classes.searchActionClear}
                >
                    Clear
                </CustomButton>
                <CustomButton
                    onClick={handleSearch}
                    variant="contained"
                    disableElevation
                    color="primary"
                    className={classes.searchActionButton}
                >
                    Search
                </CustomButton>
            </>
        );
    };

    const clearSearch = (_e) => {
        setSearch(emptySearch());
    };

    const emptySearch = () => {
        return {
            name: "",
            description: "",
            isHidden: null,
            latMin: "",
            latMax: "",
            lonMin: "",
            lonMax: "",
        };
    };

    const handleSearchChange = (property) => (e) => {
        setSearch({
            ...search,
            [property]: e.target.value,
        });
    };

    // const handleSearchCheckBoxChange = (property) => (e) => {
    //     var value;
    //     if (search[property] === null) value = false;
    //     else if (search[property] === false) value = true;
    //     else if (search[property] === true) value = null;

    //     setSearch({
    //         ...search,
    //         [property]: value,
    //     });
    // };

    const handleSearch = (_e) => {
        findSites(
            search.name,
            search.description,
            null,
            search.latMin,
            search.latMax,
            search.lonMin,
            search.lonMax
        );
    };

    const handleSortBy = (key, order) => {
        setSort([key, order]);
    };

    const renderSortingLink = (key) => {
        const sortBy = sort[0];
        const sortDirection = sort[1];
        return (
            <span
                className={clsx(
                    classes.sortIcon,
                    sortBy === key
                        ? clsx(
                              sortDirection === "asc"
                                  ? classes.sortIconAsc
                                  : classes.sortIconDes
                          )
                        : classes.sortIconInactive
                )}
                onClick={() =>
                    handleSortBy(
                        key,
                        sortBy === key
                            ? sortDirection === "asc"
                                ? "desc"
                                : "asc"
                            : "asc"
                    )
                }
            />
        );
    };

    const Row = ({ index, style, data: { sites, classes } }) => {
        const site = sites[index];
        const {
            id,
            name,
            description,
            latitude,
            longitude,
            isOffshore,
            identifierMapping,
        } = site;
        return (
            <CustomTableRow
                component="div"
                style={style}
                key={id}
                className={classes.tableRow}
                onClick={() => push(`${location.pathname}/${id}`)}
            >
                <CustomTableCell
                    className={classes.tableCell}
                    component="div"
                    style={{
                        width: "30%",
                    }}
                >
                    {name}
                </CustomTableCell>
                <CustomTableCell
                    className={classes.tableCell}
                    component="div"
                    style={{ width: "40%" }}
                >
                    {description}
                </CustomTableCell>
                <CustomTableCell
                    component="div"
                    className={classes.tableCell}
                    style={{
                        width: "10%",
                    }}
                >
                    {latitude}
                </CustomTableCell>
                <CustomTableCell
                    component="div"
                    className={classes.tableCell}
                    style={{
                        width: "10%",
                    }}
                >
                    {longitude}
                </CustomTableCell>
                <CustomTableCell
                    component="div"
                    className={classes.tableCell}
                    style={{
                        width: "10%",
                    }}
                >
                    {isOffshore ? "Offshore" : "Onshore"}
                </CustomTableCell>
                {isVestas && (
                    <CustomTableCell
                        component="div"
                        className={classes.tableCell}
                        style={{
                            width: "10%",
                        }}
                    >
                        {identifierMapping}
                    </CustomTableCell>
                )}
            </CustomTableRow>
        );
    };

    const itemKey = (index, data) =>
        data.sites[index] ? data.sites[index].id : "";

    return (
        <Navigation push={push}>
            <div className={classes.headerContainer}>
                <Grid item xs={6}>
                    <Typography variant="h2">Sites</Typography>
                </Grid>
                <Grid item xs={6}>
                    {renderActionButtons()}
                </Grid>
            </div>

            <Grid
                container
                spacing={smallScreen ? 0 : 4}
                className={classes.searchContainer}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        handleSearch();
                    }
                }}
            >
                <Grid item xs={12} md={4} lg={4}>
                    <CustomInputLabel>Name</CustomInputLabel>
                    <CustomTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        type="text"
                        onChange={handleSearchChange("name")}
                        value={search.name}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <CustomInputLabel>Description</CustomInputLabel>
                    <CustomTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        type="text"
                        onChange={handleSearchChange("description")}
                        value={search.description}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}></Grid>

                <Grid item xs={6} md={2} lg={2}>
                    <CustomInputLabel>Lat Min</CustomInputLabel>
                    <CustomTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        type="number"
                        onChange={handleSearchChange("latMin")}
                        value={search.latMin}
                    />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                    <CustomInputLabel>Lat Max</CustomInputLabel>
                    <CustomTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        type="number"
                        onChange={handleSearchChange("latMax")}
                        value={search.latMax}
                    />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                    <CustomInputLabel>Lon Min</CustomInputLabel>
                    <CustomTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        type="number"
                        onChange={handleSearchChange("lonMin")}
                        value={search.lonMin}
                    />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                    <CustomInputLabel>Lon Max</CustomInputLabel>
                    <CustomTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        type="number"
                        onChange={handleSearchChange("lonMax")}
                        value={search.lonMax}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    className={classes.tableColumnBottom}
                >
                    {renderSearchButtons()}
                </Grid>
            </Grid>

            <div className={classes.tableContainer}>
                <div className={classes.stickyTableHeader}>
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableCell style={{ width: "30%" }}>
                                    Name
                                    {renderSortingLink("name")}
                                </CustomTableCell>
                                <CustomTableCell style={{ width: "35%" }}>
                                    Description
                                    {renderSortingLink("description")}
                                </CustomTableCell>
                                <CustomTableCell style={{ width: "10%" }}>
                                    Latitude
                                    {renderSortingLink("latitude")}
                                </CustomTableCell>
                                <CustomTableCell style={{ width: "10%" }}>
                                    Longitude
                                    {renderSortingLink("longitude")}
                                </CustomTableCell>
                                <CustomTableCell style={{ width: "10%" }}>
                                    Offshore
                                    {renderSortingLink("isOffshore")}
                                </CustomTableCell>
                                {isVestas && (
                                    <CustomTableCell style={{ width: "15%" }}>
                                        Identifier Mapping
                                        {renderSortingLink("identifierMapping")}
                                    </CustomTableCell>
                                )}
                            </CustomTableRow>
                        </CustomTableHead>
                    </CustomTable>
                </div>
                <div className={classes.virtualTableBodyContainer}>
                    {sites && (
                        <CustomTable
                            component="div"
                            style={{
                                height: "100%",
                                display: "flex",
                            }}
                        >
                            <CustomTableBody
                                component="div"
                                style={{ flex: 1 }}
                            >
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        height: "100%",
                                    }}
                                >
                                    {sortedSites.length > 0 && (
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    className={classes.list}
                                                    height={height}
                                                    width={width}
                                                    itemCount={
                                                        sortedSites.length
                                                    }
                                                    itemSize={55}
                                                    itemKey={itemKey}
                                                    itemData={{
                                                        sites: sortedSites,
                                                        classes: classes,
                                                    }}
                                                >
                                                    {Row}
                                                </List>
                                            )}
                                        </AutoSizer>
                                    )}
                                    {sortedSites.length === 0 && (
                                        <CustomTableRow
                                            className={classes.tableRow}
                                            component="div"
                                        >
                                            <CustomTableCell
                                                component="div"
                                                style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                No sites found
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    )}
                                </div>
                            </CustomTableBody>
                        </CustomTable>
                    )}
                    {!sites && (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        </Navigation>
    );
};

export default connect(mapStateToProps, { findSites, clearSites, push })(Sites);
