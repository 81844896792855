import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Typography, MenuItem } from "@material-ui/core";
import { CustomSelect } from "../../../common/restyled-mui/CustomInputs";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        marginTop: theme.spacing(1),
    },
    field: {
        flex: 1,
    },
    dash: {
        padding: theme.spacing(1.75),
    },
}));

const days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

const WorkingDaysSelection = (props) => {
    const classes = useStyles(props);
    const { values, handleChangeValues } = props;

    const handleChange = (name) => (e) => {
        handleChangeValues({
            ...values,
            [name]: e.target.value,
        });
    };

    const renderOptions = () => {
        return days.map((day, index) => {
            return (
                <MenuItem key={index} value={index}>
                    {day}
                </MenuItem>
            );
        });
    };

    return (
        <div className={classes.container}>
            <FormControl
                variant="outlined"
                size="small"
                className={classes.field}
            >
                <CustomSelect
                    name={"start"}
                    type="text"
                    value={values["start"]}
                    onChange={handleChange("start")}
                >
                    {renderOptions()}
                </CustomSelect>
            </FormControl>
            <Typography className={classes.dash}>-</Typography>
            <FormControl
                variant="outlined"
                size="small"
                className={classes.field}
            >
                <CustomSelect
                    name={"end"}
                    type="text"
                    value={values["end"]}
                    onChange={handleChange("end")}
                >
                    {renderOptions()}
                </CustomSelect>
            </FormControl>
        </div>
    );
};

export default WorkingDaysSelection;
