import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    ListItemText,
    ListItemIcon,
    ListItem,
    List,
    Drawer,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { moduleIsAvailable, modules } from "../../utils/moduleConfig";
import {
    settingsIcon,
    settingsIconSelected,
} from "../../assets/icons/fontawesome/settingsIcons";
import { menuBarsIcon } from "../../assets/icons/fontawesome/menuBarsIcon";
import { menuOverlayBreakpointDown, menuPushBreakpointUp } from "./Navigation";

const CustomList = withStyles((theme) => ({
    root: {
        padding: 0,
        width: "100%",
    },
}))(List);

const CustomListItem = withStyles((theme) => ({
    root: {
        width: "100%",
        color: theme.palette.menu.icons,
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
    },
    gutters: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}))(ListItem);

const CustomListItemIcon = withStyles((theme) => ({
    root: {
        color: theme.palette.menu.icons,
    },
}))(ListItemIcon);

const CustomListItemText = withStyles((theme) => ({
    primary: {
        color: theme.palette.menu.text,
        fontSize: 14,
    },
}))(ListItemText);

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: (props) => props.maxDrawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerPaper: {
        overflowX: "hidden",
        backgroundColor: theme.palette.menu.bg,
        top: "auto",
        bottom: 0,
        border: `1px solid ${theme.palette.menu.border}`,
        [theme.breakpoints.down(menuOverlayBreakpointDown)]: {
            height: "100%",
            position: "relative",
        },
        [theme.breakpoints.up(menuPushBreakpointUp)]: {
            height: (props) => `calc(100% - ${props.topbarHeight}px)`,
        },
    },
    [theme.breakpoints.up(menuPushBreakpointUp)]: {
        drawerOpen: {
            width: (props) => props.maxDrawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: (props) => props.minDrawerWidth,
        },
    },
    mobileMenuItem: {
        height: (props) => props.topbarHeight,
    },
    topList: {
        top: 0,
        position: "absolute",
    },
    topListItem: {
        top: 0,
    },
    bottomList: {
        position: "absolute",
        bottom: 0,
        paddingBottom: theme.spacing(0),
    },
    bottomListItem: {
        bottom: 0,
    },
    burgerMenuItem: {
        paddingTop: theme.spacing(3.5),
        paddingBottom: theme.spacing(3.5),
    },
    menuIcon: {
        margin: "auto",
        "& path": {
            fill: theme.palette.menu.icons,
        },
    },
    selectedMenuIcon: {
        "& path": {
            fill: theme.palette.primary.main,
        },
    },
    profileIcon: {
        height: 35,
        width: 35,
        margin: "auto",
    },
    listItemIcon: {
        minWidth: (props) => props.minDrawerWidth,
    },
}));

const mapStateToProps = (state) => {
    return {
        isAdministrator: state.user.isAdministrator,
        username: state.config.username,
        permissions: state.user.permissions,
    };
};

const Sidebar = (props) => {
    const classes = useStyles(props);
    const {
        open,
        goTo,
        handleDrawerChange,
        menuOverlay,
        isAdministrator,
        selectedMenuItem,
        routes,
        availableModules,
        permissions,
    } = props;

    const handleItemClick = (path) => {
        goTo(path);
        if (menuOverlay) {
            handleDrawerChange();
        }
    };

    const selected = selectedMenuItem();

    const renderMenuIcon = (module) => {
        return (
            moduleIsAvailable(module.id, availableModules, permissions) && (
                <CustomListItem
                    className={classes.topListItem}
                    button
                    key={module.id}
                    onClick={() => handleItemClick(module.url)}
                >
                    <CustomListItemIcon className={classes.listItemIcon}>
                        <FontAwesomeIcon
                            icon={
                                selected === routes[module.id]
                                    ? module.icon.selected
                                    : module.icon.default
                            }
                            size={"lg"}
                            title={module.title}
                            className={clsx(
                                classes.menuIcon,
                                selected === routes[module.id] &&
                                    classes.selectedMenuIcon
                            )}
                        />
                    </CustomListItemIcon>
                    <CustomListItemText primary={module.name} />
                </CustomListItem>
            )
        );
    };

    return (
        <>
            <Drawer
                variant={menuOverlay ? "temporary" : "permanent"}
                anchor={"left"}
                open={open}
                ModalProps={{ keepMounted: true }}
                onClose={handleDrawerChange}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.drawerPaper, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <CustomList className={classes.topList}>
                    <CustomListItem
                        className={clsx(
                            classes.topListItem,
                            classes.burgerMenuItem
                        )}
                        button
                        key={"Menu"}
                        onClick={handleDrawerChange}
                    >
                        <CustomListItemIcon className={classes.listItemIcon}>
                            <FontAwesomeIcon
                                icon={menuBarsIcon}
                                size={"lg"}
                                className={classes.menuIcon}
                            />
                        </CustomListItemIcon>
                        <CustomListItemText primary={"Menu"} />
                    </CustomListItem>
                    {availableModules.map((moduleId) => {
                        return renderMenuIcon(modules[moduleId]);
                    })}
                </CustomList>
                <CustomList className={classes.bottomList}>
                    {/* <CustomListItem
                        className={classes.bottomListItem}
                        button
                        key={modules.wiki.title}
                        onClick={() => goTo(modules.wiki.url)}
                    >
                        <CustomListItemIcon className={classes.listItemIcon}>
                            <FontAwesomeIcon
                                icon={
                                    selected === routes.wiki
                                        ? selectedWikiIcon
                                        : wikiIcon
                                }
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    selected === routes.wiki &&
                                        classes.selectedMenuIcon
                                )}
                            />
                        </CustomListItemIcon>
                        <CustomListItemText primary={modules.wiki.name} />
                    </CustomListItem> */}

                    {isAdministrator && (
                        <CustomListItem
                            className={classes.bottomListItem}
                            button
                            key={modules.settings.title}
                            onClick={() => goTo(modules.settings.url)}
                        >
                            <CustomListItemIcon
                                className={classes.listItemIcon}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        selected === routes.settings
                                            ? settingsIconSelected
                                            : settingsIcon
                                    }
                                    size={"lg"}
                                    className={clsx(
                                        classes.menuIcon,
                                        selected === routes.settings &&
                                            classes.selectedMenuIcon
                                    )}
                                />
                            </CustomListItemIcon>
                            <CustomListItemText
                                primary={modules.settings.name}
                            />
                        </CustomListItem>
                    )}
                </CustomList>
            </Drawer>
        </>
    );
};

export default connect(mapStateToProps, null)(Sidebar);
