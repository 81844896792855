import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    embeddedContent: {
        width: "100%",
        border: 0,
    },
}));

const ForecastUIPage = (props) => {
    const classes = useStyles();

    return (
        <iframe
            title="External Webpage for Forecast UI"
            className={classes.embeddedContent}
            src="https://forecastui.conwx.com/"
        ></iframe>
    );
};

export default ForecastUIPage;
