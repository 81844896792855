import React from 'react';

const MapMarkerColouredIcon = props => {
    const { className } = props;
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <title
                id="title22">map_marker_icon</title>
            <path
                d="M13.25,10A3.25,3.25,0,1,1,10,6.75,3.26,3.26,0,0,1,13.25,10ZM18,10a8,8,0,1,1-8-8A8,8,0,0,1,18,10Zm-1.5,0A6.5,6.5,0,1,0,10,16.5,6.51,6.51,0,0,0,16.5,10Z"
                fill={props.color}
                id="path24" />
            <g
                id="layer1"
                style={{display:'inline'}}>
                <ellipse
                id="path40"
                cx="9.929245"
                cy="10.04717"
                rx="6.9103775"
                ry="7.2877359" />
                <path
                style={{strokeWidth:'0.02358491'}}
                d="M 9.5873722,16.475351 C 7.9832877,16.373169 6.4621158,15.669968 5.340441,14.512098 4.303862,13.44207 3.6851764,12.09473 3.537397,10.585527 3.4835307,10.035415 3.5411368,9.1055948 3.659998,8.6066233 3.8378556,7.8599892 4.0713687,7.2778118 4.4461201,6.6467229 4.9339426,5.82522 5.7457684,5.0035015 6.5825472,4.4842621 8.2410252,3.4551403 10.26537,3.2324271 12.133524,3.8735579 c 0.354574,0.1216859 1.011895,0.4365271 1.324023,0.6341756 0.729268,0.4617932 1.44226,1.1527849 1.92185,1.8625495 0.940865,1.3924229 1.307067,3.1421505 1.002029,4.787736 -0.334676,1.805473 -1.402775,3.37979 -2.947464,4.344391 -1.141686,0.712941 -2.506989,1.058275 -3.8465898,0.972941 z m 0.6862128,-3.230464 c 1.467394,-0.135416 2.638279,-1.189349 2.929415,-2.636816 0.02941,-0.146193 0.0413,-0.319463 0.04159,-0.605713 C 13.245015,9.5711068 13.215815,9.3700983 13.100688,9.0117925 12.732323,7.8653453 11.750847,7.0075802 10.557981,6.7895821 10.238512,6.7311986 9.6819851,6.7375498 9.3729149,6.8031064 8.053275,7.0830142 7.091304,8.0396769 6.8025972,9.359241 c -0.032824,0.1500255 -0.042665,0.2983642 -0.042665,0.643117 0,0.344754 0.00984,0.493092 0.042665,0.643118 0.1358045,0.620708 0.4220705,1.161507 0.847978,1.601956 0.6215293,0.642748 1.3560283,0.967266 2.3046135,1.018229 0.032429,0.0017 0.1757073,-0.0076 0.3183963,-0.02077 z"
                id="path44"/>
                <path
                style={{strokeWidth:'0.02358491'}}
                d="M 9.5873722,16.475351 C 7.9832877,16.373169 6.4621158,15.669968 5.340441,14.512098 4.303862,13.44207 3.6851764,12.09473 3.537397,10.585527 3.4835307,10.035415 3.5411368,9.1055948 3.659998,8.6066233 3.8378556,7.8599892 4.0713687,7.2778118 4.4461201,6.6467229 4.9339426,5.82522 5.7457684,5.0035015 6.5825472,4.4842621 8.2410252,3.4551403 10.26537,3.2324271 12.133524,3.8735579 c 0.354574,0.1216859 1.011895,0.4365271 1.324023,0.6341756 0.729268,0.4617932 1.44226,1.1527849 1.92185,1.8625495 0.940865,1.3924229 1.307067,3.1421505 1.002029,4.787736 -0.334676,1.805473 -1.402775,3.37979 -2.947464,4.344391 -1.141686,0.712941 -2.506989,1.058275 -3.8465898,0.972941 z m 0.6862128,-3.230464 c 1.467394,-0.135416 2.638279,-1.189349 2.929415,-2.636816 0.02941,-0.146193 0.0413,-0.319463 0.04159,-0.605713 C 13.245015,9.5711068 13.215815,9.3700983 13.100688,9.0117925 12.732323,7.8653453 11.750847,7.0075802 10.557981,6.7895821 10.238512,6.7311986 9.6819851,6.7375498 9.3729149,6.8031064 8.053275,7.0830142 7.091304,8.0396769 6.8025972,9.359241 c -0.032824,0.1500255 -0.042665,0.2983642 -0.042665,0.643117 0,0.344754 0.00984,0.493092 0.042665,0.643118 0.1358045,0.620708 0.4220705,1.161507 0.847978,1.601956 0.6215293,0.642748 1.3560283,0.967266 2.3046135,1.018229 0.032429,0.0017 0.1757073,-0.0076 0.3183963,-0.02077 z"
                id="path46"/>
                <path
                style={{strokeWidth:'0.02358491'}}
                d="M 9.5873722,16.475351 C 7.9832877,16.373169 6.4621158,15.669968 5.340441,14.512098 4.303862,13.44207 3.6851764,12.09473 3.537397,10.585527 3.4835307,10.035415 3.5411368,9.1055948 3.659998,8.6066233 3.8378556,7.8599892 4.0713687,7.2778118 4.4461201,6.6467229 4.9339426,5.82522 5.7457684,5.0035015 6.5825472,4.4842621 8.2410252,3.4551403 10.26537,3.2324271 12.133524,3.8735579 c 0.354574,0.1216859 1.011895,0.4365271 1.324023,0.6341756 0.729268,0.4617932 1.44226,1.1527849 1.92185,1.8625495 0.940865,1.3924229 1.307067,3.1421505 1.002029,4.787736 -0.334676,1.805473 -1.402775,3.37979 -2.947464,4.344391 -1.141686,0.712941 -2.506989,1.058275 -3.8465898,0.972941 z m 0.6862128,-3.230464 c 1.467394,-0.135416 2.638279,-1.189349 2.929415,-2.636816 0.02941,-0.146193 0.0413,-0.319463 0.04159,-0.605713 C 13.245015,9.5711068 13.215815,9.3700983 13.100688,9.0117925 12.732323,7.8653453 11.750847,7.0075802 10.557981,6.7895821 10.238512,6.7311986 9.6819851,6.7375498 9.3729149,6.8031064 8.053275,7.0830142 7.091304,8.0396769 6.8025972,9.359241 c -0.032824,0.1500255 -0.042665,0.2983642 -0.042665,0.643117 0,0.344754 0.00984,0.493092 0.042665,0.643118 0.1358045,0.620708 0.4220705,1.161507 0.847978,1.601956 0.6215293,0.642748 1.3560283,0.967266 2.3046135,1.018229 0.032429,0.0017 0.1757073,-0.0076 0.3183963,-0.02077 z"
                id="path48"/>
                <path
                style={{strokeWidth:'0.02358491'}}
                d="M 9.5873722,16.475351 C 7.9832877,16.373169 6.4621158,15.669968 5.340441,14.512098 4.303862,13.44207 3.6851764,12.09473 3.537397,10.585527 3.4835307,10.035415 3.5411368,9.1055948 3.659998,8.6066233 3.8378556,7.8599892 4.0713687,7.2778118 4.4461201,6.6467229 4.9339426,5.82522 5.7457684,5.0035015 6.5825472,4.4842621 8.2410252,3.4551403 10.26537,3.2324271 12.133524,3.8735579 c 0.354574,0.1216859 1.011895,0.4365271 1.324023,0.6341756 0.729268,0.4617932 1.44226,1.1527849 1.92185,1.8625495 0.940865,1.3924229 1.307067,3.1421505 1.002029,4.787736 -0.334676,1.805473 -1.402775,3.37979 -2.947464,4.344391 -1.141686,0.712941 -2.506989,1.058275 -3.8465898,0.972941 z m 0.6862128,-3.230464 c 1.467394,-0.135416 2.638279,-1.189349 2.929415,-2.636816 0.02941,-0.146193 0.0413,-0.319463 0.04159,-0.605713 C 13.245015,9.5711068 13.215815,9.3700983 13.100688,9.0117925 12.732323,7.8653453 11.750847,7.0075802 10.557981,6.7895821 10.238512,6.7311986 9.6819851,6.7375498 9.3729149,6.8031064 8.053275,7.0830142 7.091304,8.0396769 6.8025972,9.359241 c -0.032824,0.1500255 -0.042665,0.2983642 -0.042665,0.643117 0,0.344754 0.00984,0.493092 0.042665,0.643118 0.1358045,0.620708 0.4220705,1.161507 0.847978,1.601956 0.6215293,0.642748 1.3560283,0.967266 2.3046135,1.018229 0.032429,0.0017 0.1757073,-0.0076 0.3183963,-0.02077 z"
                id="path50"/>
                <path
                style={{strokeWidth:'0.02358491'}}
                d="M 9.5873722,16.475351 C 7.9832877,16.373169 6.4621158,15.669968 5.340441,14.512098 4.303862,13.44207 3.6851764,12.09473 3.537397,10.585527 3.4835307,10.035415 3.5411368,9.1055948 3.659998,8.6066233 3.8378556,7.8599892 4.0713687,7.2778118 4.4461201,6.6467229 4.9339426,5.82522 5.7457684,5.0035015 6.5825472,4.4842621 8.2410252,3.4551403 10.26537,3.2324271 12.133524,3.8735579 c 0.354574,0.1216859 1.011895,0.4365271 1.324023,0.6341756 0.729268,0.4617932 1.44226,1.1527849 1.92185,1.8625495 0.940865,1.3924229 1.307067,3.1421505 1.002029,4.787736 -0.334676,1.805473 -1.402775,3.37979 -2.947464,4.344391 -1.141686,0.712941 -2.506989,1.058275 -3.8465898,0.972941 z m 0.6862128,-3.230464 c 1.467394,-0.135416 2.638279,-1.189349 2.929415,-2.636816 0.02941,-0.146193 0.0413,-0.319463 0.04159,-0.605713 C 13.245015,9.5711068 13.215815,9.3700983 13.100688,9.0117925 12.732323,7.8653453 11.750847,7.0075802 10.557981,6.7895821 10.238512,6.7311986 9.6819851,6.7375498 9.3729149,6.8031064 8.053275,7.0830142 7.091304,8.0396769 6.8025972,9.359241 c -0.032824,0.1500255 -0.042665,0.2983642 -0.042665,0.643117 0,0.344754 0.00984,0.493092 0.042665,0.643118 0.1358045,0.620708 0.4220705,1.161507 0.847978,1.601956 0.6215293,0.642748 1.3560283,0.967266 2.3046135,1.018229 0.032429,0.0017 0.1757073,-0.0076 0.3183963,-0.02077 z"
                id="path52"/>
                <path
                style={{strokeWidth:'0.02358491'}}
                d="M 9.5873722,16.475351 C 7.9832877,16.373169 6.4621158,15.669968 5.340441,14.512098 4.303862,13.44207 3.6851764,12.09473 3.537397,10.585527 3.4835307,10.035415 3.5411368,9.1055948 3.659998,8.6066233 3.8378556,7.8599892 4.0713687,7.2778118 4.4461201,6.6467229 4.9339426,5.82522 5.7457684,5.0035015 6.5825472,4.4842621 8.2410252,3.4551403 10.26537,3.2324271 12.133524,3.8735579 c 0.354574,0.1216859 1.011895,0.4365271 1.324023,0.6341756 0.729268,0.4617932 1.44226,1.1527849 1.92185,1.8625495 0.940865,1.3924229 1.307067,3.1421505 1.002029,4.787736 -0.334676,1.805473 -1.402775,3.37979 -2.947464,4.344391 -1.141686,0.712941 -2.506989,1.058275 -3.8465898,0.972941 z m 0.6862128,-3.230464 c 1.467394,-0.135416 2.638279,-1.189349 2.929415,-2.636816 0.02941,-0.146193 0.0413,-0.319463 0.04159,-0.605713 C 13.245015,9.5711068 13.215815,9.3700983 13.100688,9.0117925 12.732323,7.8653453 11.750847,7.0075802 10.557981,6.7895821 10.238512,6.7311986 9.6819851,6.7375498 9.3729149,6.8031064 8.053275,7.0830142 7.091304,8.0396769 6.8025972,9.359241 c -0.032824,0.1500255 -0.042665,0.2983642 -0.042665,0.643117 0,0.344754 0.00984,0.493092 0.042665,0.643118 0.1358045,0.620708 0.4220705,1.161507 0.847978,1.601956 0.6215293,0.642748 1.3560283,0.967266 2.3046135,1.018229 0.032429,0.0017 0.1757073,-0.0076 0.3183963,-0.02077 z"
                id="path54"/>
                <path
                fill='#ffffff'
                style={{strokeWidth:'0.02358491'}}
                d="M 9.3592285,17.30225 C 7.7660297,17.147514 6.351841,16.480197 5.2120643,15.345317 4.6416842,14.777387 4.2292447,14.219288 3.8810851,13.544281 3.4319099,12.673426 3.1809068,11.833848 3.0654398,10.816038 3.0291278,10.495957 3.0298144,9.5777399 3.0666038,9.259434 3.1798336,8.2797559 3.4142153,7.4810417 3.8347248,6.6418703 4.1937647,5.9253682 4.5938032,5.3731842 5.1778778,4.7878799 5.7882104,4.1762625 6.2845318,3.8174792 7.0188679,3.4570556 7.7203241,3.11277 8.3108901,2.932409 9.1179245,2.815996 9.4462675,2.768633 10.487986,2.776277 10.82783,2.828542 c 1.230183,0.1891929 2.298174,0.6514107 3.259806,1.4108176 0.307168,0.2425722 0.872793,0.8084104 1.121118,1.1215407 0.812398,1.0244076 1.333198,2.2328552 1.542917,3.580137 0.06141,0.3945156 0.08605,1.4073447 0.04426,1.8196017 -0.181457,1.790199 -0.902093,3.339229 -2.1224,4.562165 -1.05261,1.054879 -2.348025,1.715347 -3.788936,1.931793 -0.370907,0.05571 -1.1815926,0.08104 -1.5253655,0.04765 z"
                id="path58" />
            </g>
            <g
                id="layer2"
                style={{display:'inline'}}>
                <path
                fill={props.color}
                id="path34"
                d="M 13.25,10 A 3.25,3.25 0 1 1 10,6.75 3.26,3.26 0 0 1 13.25,10 Z M 18,10 a 8,8 0 1 1 -8,-8 8,8 0 0 1 8,8 z m -1.5,0 A 6.5,6.5 0 1 0 10,16.5 6.51,6.51 0 0 0 16.5,10 Z" />
            </g>
        </svg>
    )
}



export default MapMarkerColouredIcon