import React, { useState } from "react";
import {
    makeStyles,
    TextField,
    Typography,
    InputLabel,
    Button,
    LinearProgress,
    Card,
    CardContent,
    CardMedia,
    Fade,
} from "@material-ui/core";

import LoginImage from "../features/login/LoginImage";
import { getLoginCustomer } from "../utils/loginPageCustomerConfig";

import { SignInButton } from "./SignInButton";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        width: "100%",
        position: "absolute",
        textAlign: "center",
        display: "flex",
    },
    imagePane: {
        width: "100%",
        padding: 0,
        backgroundColor: theme.palette.company.conWxBlue,
    },
    contentPane: {
        width: 420,
        backgroundColor: theme.palette.generalUi.popupBackground,
        padding: 0,
    },
    content: {
        width: 260,
        margin: "65px 80px 65px 80px",
    },
    title: {},
    inputContainer: {
        textAlign: "left",
        width: "100%",
        margin: theme.spacing(4, 0),
    },
    textField: {
        width: "100%",
    },
    errorText: {
        color: theme.palette.error.main,
        fontSize: "0.75rem",
        textAlign: "left",
    },
    submitButton: {
        width: "100%",
    },
    loadingPlaceholder: {
        height: theme.spacing(1),
    },
    logoMedia: {
        width: "100%",
        height: "100%",
        backgroundSize: "contain",
        backgroundPosition: "bottom",
    },
    logo: {
        margin: "0px 0px 36px 0px",
        height: 97,
        width: 212,
        "& path": {
            fill: theme.palette.company.logoColor,
        },
    },
}));

const Login = (props) => {
    const classes = useStyles(props);
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const loginCustomer = getLoginCustomer();
    const {
        onSubmit,
        errorMessage,
        setErrorMessage,
        loading,
        enableAADLogin,
        disablePasswordLogin,
    } = props;
    const logo = require(`../${loginCustomer.logo}`);

    const submission = (e) => {
        e.preventDefault();
        setErrorMessage("");
        if (validation()) {
            onSubmit(username, password, true);
        }
    };

    const validation = () => {
        let validated = true;
        if (username.length === 0) {
            setUsernameError("Required");
            validated = false;
        } else {
            setUsernameError("");
        }
        if (password.length === 0) {
            setPasswordError("Required");
            validated = false;
        } else {
            setPasswordError("");
        }
        return validated;
    };

    const onChange = (callback) => (e) => {
        callback(e.target.value);
    };

    return (
        <Card className={classes.container} elevation={0}>
            <LoginImage imageUrls={loginCustomer.wallpaperImages} />
            <CardContent className={classes.contentPane}>
                <div className={classes.content}>
                    <CardContent className={classes.logo}>
                        <Fade in={true}>
                            <CardMedia
                                className={classes.logoMedia}
                                image={logo}
                                title="Logo"
                            />
                        </Fade>
                    </CardContent>
                    {!disablePasswordLogin && (
                        <form onSubmit={submission}>
                            <div className={classes.inputContainer}>
                                <InputLabel>Username</InputLabel>
                                <TextField
                                    className={classes.textField}
                                    value={username}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    size={"small"}
                                    disabled={loading}
                                    error={Boolean(usernameError.length !== 0)}
                                    helperText={usernameError}
                                    placeholder="Enter username"
                                    onChange={onChange(setUsername)}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <InputLabel>Password</InputLabel>
                                <TextField
                                    className={classes.textField}
                                    value={password}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    size={"small"}
                                    disabled={loading}
                                    type={"password"}
                                    error={Boolean(passwordError.length !== 0)}
                                    helperText={passwordError}
                                    placeholder="Enter password"
                                    onChange={onChange(setPassword)}
                                />
                                <Typography
                                    variant="body1"
                                    className={classes.errorText}
                                >
                                    {errorMessage}
                                </Typography>
                            </div>
                            {loading ? (
                                <LinearProgress />
                            ) : (
                                <div className={classes.loadingPlaceholder} />
                            )}
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                disableElevation
                                className={classes.submitButton}
                                type="submit"
                            >
                                Login
                            </Button>
                        </form>
                    )}
                </div>
                {enableAADLogin && (
                    <div className={classes.content}>
                        <SignInButton />
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default Login;
