import { VESTAS_SITES } from "../types";
import Api from "../../api";
import { handleError } from "../../utils/error";
import orderBy from "lodash/sortBy";

export const getVestasSites = () => {
    return (dispatch) => {
        return Api.HWWA.getSites()
            .then((res) => {
                const sites = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: VESTAS_SITES.LOADED, payload: sites });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};
