import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import { Drawer, List, ListItem, ListItemIcon } from "@material-ui/core";

import Api from "../../api";
import {
    initialSingleValidation,
    validSingleValidation,
    checkValidation,
    validateRequiredNumber,
    validateRequiredObj,
    validateRequiredArray,
} from "../../utils/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hindcastAnalysisIcon } from "../../assets/icons/fontawesome/hindcastAnalysisIcons";
import HindcastsForm from "./HindcastsForm";
import FileDownload from "js-file-download";

const sitesListWidth = 407;
const tabletSidebarWidth = 48;

const useStyles = makeStyles((theme) => ({
    childrenContainer: {
        marginLeft: (props) =>
            props.fullscreen || props.toggleable ? 0 : sitesListWidth,
        width: (props) =>
            props.fullscreen || props.toggleable
                ? "100%"
                : `calc(100% - ${sitesListWidth}px)`,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    formContainer: {
        backgroundColor: theme.palette.common.white,
        height: "100%",
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
        display: "flex",
        flexDirection: "column",
    },
    drawer: {
        marginLeft: (props) => (props.toggleable ? tabletSidebarWidth - 1 : 0),
        borderRight: `${theme.palette.menu.border} 1px solid`,
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
    },
    menu: {
        display: "flex",
        flexDirection: "column",
        width: tabletSidebarWidth,
        zIndex: 1400,
        backgroundColor: theme.palette.menu.bg,
        borderRight: `${theme.palette.menu.border} 1px solid`,
    },
    menuItem: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(1, 3),
    },
    menuItemIcon: {
        textAlign: "center",
        minWidth: "100%",
    },
    menuIcon: {
        color: theme.palette.menu.icons,
    },
    selectedMenuIcon: {
        color: theme.palette.primary.main,
    },
    tickIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
}));

//list of users that don't have hindcast download option
// users that don't have access to hindcast module are controller in moduleConfig.js
// bilfinger is van ord
// const usersWithoutHindcastDownload = ['windcarrier', 'semco', 'bilfinger'];

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        config: state.config,
        permissions: state.user.permissions,
    };
};

const initialValue = {
    latitude: "",
    longitude: "",
    workingDays: "Mon-Sun",
    windowLength: 1,
    windowSelection: {
        name: "24h",
        customRange: {
            start: "",
            end: "",
        },
    },
    conditionList: [],
    isTimezoneUTC: "true",
    isCompressed: true,
};

const initialValidation = {
    latitude: initialSingleValidation,
    longitude: initialSingleValidation,
    workingDays: initialSingleValidation,
    windowLength: initialSingleValidation,
    windowSelection: initialSingleValidation,

    conditionList: initialSingleValidation,
    isTimezoneUTC: initialSingleValidation,
    isCompressed: initialSingleValidation,
};

const HindcastsPanel = (props) => {
    const classes = useStyles(props);
    const [open, setOpen] = useState(false);
    // const [downloadLoading, setDownloadLoading] = useState(false);
    const [validation, setValidation] = useState(initialValidation);
    const {
        config,
        container,
        toggleable,
        setTableData,
        loading,
        setLoading,
        setFailedParameters,
        setNodataContent,
        permissions,
        setCoordinates,
    } = props;

    const runAnalysis = () => {
        const valid = validate(values);
        if (valid) {
            const msgBody = JSON.parse(
                JSON.stringify(parseValuesIntoApiData(values))
            );

            setLoading(true);
            Api.Hindcasts.getHindcasts(msgBody)
                .then((res) => {
                    setOpen(false);
                    setFailedParameters(res.data.failedParameters);
                    if (res.data.failedParameters.length > 0) {
                        setTableData(null);
                        setNodataContent({
                            heading: "No data",
                            body: res.data.statusMessage,
                        });
                    } else {
                        setTableData(res.data.data);
                        setCoordinates({
                            latitude: values.latitude,
                            longitude: values.longitude,
                        });
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setTableData(null);
                    setNodataContent({
                        heading: "Internal error",
                        body: "Please contact support@conwx.com",
                    });
                    console.log(err);
                });
        }
    };

    const downloadData = () => {
        const currentValidation = { ...initialValidation };
        currentValidation.longitude = validateLongitude(values.longitude);
        currentValidation.latitude = validateLatitude(values.latitude);

        if (
            currentValidation.longitude.valid &&
            currentValidation.latitude.valid
        ) {
            handleValidationUpdate("latitude");
            handleValidationUpdate("longitude");

            if (!validateDomain(values.latitude, values.longitude)) {
                currentValidation.latitude = {
                    valid: false,
                    message: "Coordinates outside of available domains",
                };
                currentValidation.longitude = {
                    valid: false,
                    message: "",
                };
            } else {
                currentValidation.latitude = {
                    valid: true,
                    message: "",
                };
                currentValidation.longitude = {
                    valid: true,
                    message: "",
                };
            }
        }

        currentValidation.conditionList = validateRequiredArray(
            values.conditionList
        );

        const valid = checkValidation(currentValidation);

        setValidation(currentValidation);

        if (valid) {
            //setDownloadLoading(true);
            setLoading(true);
            Api.Hindcasts.getHindcastCsv(
                values.latitude,
                values.longitude,
                values.conditionList.map((e) => {
                    return e.id;
                })
            )
                .then((res) => {
                    setLoading(false);
                    /*
                    if (res.data.status !== 'OK') {
                        setTableData(null);
                        setNodataContent({
                            heading: 'No data',
                            body: res.data.statusMessage,
                        });
                    } else {
                        setNodataContent({ heading: '', body: '' });
                        FileDownload(
                            res.data.data,
                            values.latitude + '_' + values.longitude + '.csv'
                        );
                    }
                    */
                    // If there is a field in res.data called status, and it is FAILED
                    if (res.data.status !== "OK") {
                        setTableData(null);
                        setNodataContent({
                            heading: "No data",
                            body: res.data.statusMessage,
                        });
                        setLoading(false);
                    } else {
                        setNodataContent({ heading: "", body: "" });
                        FileDownload(
                            res.data.data,
                            values.latitude + "_" + values.longitude + ".csv"
                        );
                    }
                })
                .catch((err) => {
                    setTableData(null);
                    setNodataContent({
                        heading: "Error processing request",
                        body: "Please contact support@conwx.com",
                    });
                    setLoading(false);
                });
        }
    };

    const validateWindowSelection = (value) => {
        if (value?.name !== "custom") {
            return validSingleValidation;
        } else {
            if (
                value.customRange?.start !== "" &&
                value.customRange?.end !== ""
            ) {
                if (
                    value.customRange.start <= 24 &&
                    value.customRange.start >= 0 &&
                    value.customRange.end <= 24 &&
                    value.customRange.end >= 0
                ) {
                    return validSingleValidation;
                } else {
                    return {
                        valid: false,
                        message: "Must be in range (0-24)",
                    };
                }
            } else {
                return {
                    valid: false,
                    message: "Required start and end hours",
                };
            }
        }
    };

    const validateDomain = (lat, lon) => {
        if (lat >= 30 && lat <= 72 && lon >= -16 && lon <= 42.5) {
            return true;
        }
        if (lat >= 30 && lat <= 72 && lon >= -16 && lon <= 42.5) {
            return true;
        }
        if (lat >= 48.45 && lat <= 65.85 && lon >= -9.306 && lon <= 30.25) {
            return true;
        }
        if (lat >= 32 && lat <= 50 && lon >= -110 && lon <= -68) {
            return true;
        }
        if (lat >= 24 && lat <= 55.5 && lon >= -80.5 && lon <= -40) {
            return true;
        }
        if (lat >= 0 && lat <= 51 && lon >= 99 && lon <= 150) {
            return true;
        }
        if (lat >= 0 && lat <= 51 && lon >= 99 && lon <= 150) {
            return true;
        }
        return false;
    };

    const validateLatitude = (value) => {
        if (!Number.isNaN(parseFloat(value)) && value >= -90 && value <= 90) {
            return validSingleValidation;
        } else {
            return {
                valid: false,
                message: "Must be a number in range (-90,90)",
            };
        }
    };
    const validateLongitude = (value) => {
        if (!Number.isNaN(parseFloat(value)) && value >= -180 && value <= 180) {
            return validSingleValidation;
        } else {
            return {
                valid: false,
                message: "Must be a number in range (-180,180)",
            };
        }
    };

    const handleValidationUpdate = (state) => {
        setValidation({
            ...validation,
            [state]: validSingleValidation,
        });
    };

    const booleanParameterNameList = [
        "LIGHTNINGRISK",
        "HELI_BLADE_ICING",
        "CLOUD_HEIGHT",
        "ICE",
        "CSNOW",
    ];

    const validate = (value) => {
        const currentValidation = { ...initialValidation };

        currentValidation.longitude = validateLongitude(value.longitude);
        currentValidation.latitude = validateLatitude(value.latitude);

        if (
            currentValidation.longitude.valid &&
            currentValidation.latitude.valid
        ) {
            handleValidationUpdate("latitude");
            handleValidationUpdate("longitude");

            if (!validateDomain(value.latitude, value.longitude)) {
                currentValidation.latitude = {
                    valid: false,
                    message: "Coordinates outside of available domains",
                };
                currentValidation.longitude = {
                    valid: false,
                    message: "",
                };
            } else {
                currentValidation.latitude = {
                    valid: true,
                    message: "",
                };
                currentValidation.longitude = {
                    valid: true,
                    message: "",
                };
            }
        }

        currentValidation.workingDays = validateRequiredObj(value.workingDays);
        currentValidation.windowSelection = validateWindowSelection(
            value.windowSelection
        );
        currentValidation.windowLength = validateRequiredNumber(
            value.windowLength
        );

        if (value.windowLength > 350 || value.windowLength <= 0) {
            currentValidation.windowLength = {
                valid: false,
                message: "Must be a number between 1 and 350",
            };
        }
        if (!Number.isInteger(makeNumber(value.windowLength))) {
            currentValidation.windowLength = {
                valid: false,
                message: "Decimal values not acceptable",
            };
        }

        currentValidation.conditionList = validateRequiredArray(
            value.conditionList
        );
        if (
            value.conditionList.length === 0 ||
            value.conditionList.every((e) => e.apply === false)
        ) {
            currentValidation.conditionList = {
                valid: false,
                message: "At least one rule must be active",
            };
        }
        if (
            value.conditionList.some(
                (e) =>
                    e.condition === "" &&
                    e.apply === true &&
                    !booleanParameterNameList.includes(e.name)
            ) ||
            value.conditionList.some(
                (e) =>
                    (e.testValue === "" || e.testValue === null) &&
                    e.apply === true &&
                    !booleanParameterNameList.includes(e.name)
            )
        ) {
            currentValidation.conditionList = {
                valid: false,
                message: "Active rules need value and condition",
            };
        }

        const valid = checkValidation(currentValidation);

        setValidation(currentValidation);
        return valid;
    };

    const makeNumber = (number) => {
        return number * 1;
    };

    const parseValuesIntoApiData = (value) => {
        let startHour = 0;
        let endHour = 24;
        if (value.windowSelection.name === "custom") {
            startHour = value.windowSelection.customRange.start;
            endHour = value.windowSelection.customRange.end;
        }

        return {
            latitude: makeNumber(value.latitude),
            longitude: makeNumber(value.longitude),
            workingDays: value.workingDays,
            windowLength: makeNumber(value.windowLength),
            daylight: value.windowSelection.name === "daylight",
            startHour: startHour,
            endHour: endHour,
            conditionList: value.conditionList
                .filter((x) => x.apply)
                .map((v, i) => {
                    return {
                        parameterId: v.id,
                        condition: v.condition,
                        testValue: booleanParameterNameList.includes(v.name)
                            ? 0.5
                            : v.testValue,
                        apply: v.apply,
                    };
                }),
            isTimezoneUTC: Boolean(value.isTimezoneUTC === "true"),
            isCompressed: value.isCompressed,
        };
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    const [values, handleChangeValues] = useState(initialValue);
    const [canDownloadRawData, setCanDownloadRawData] = useState(false);

    //predefined list to be used as fallback in case API call fails
    const predefinedParameterList = [
        {
            parameterId: 150,
            name: "WINDSPEED_80m",
            long_description: "Wind speed at 80 meters [m/s]",
            description: "Wind speed 80m",
            unit: "m/s",
        },
        {
            parameterId: 42,
            name: "WAVE_HS",
            long_description: "Significant wave Height [m]",
            description: "Sign. wave height (Hs)",
            unit: "m",
        },
        {
            parameterId: 31,
            name: "WINDSPEED_10m",
            long_description: "Wind speed at 10 meters [m/s]",
            description: "Wind speed 10m",
            unit: "m/s",
        },
        {
            parameterId: 144,
            name: "WINDSPEED_100m",
            long_description: "Wind speed at 100 meters [m/s]",
            description: "Wind speed 100m",
            unit: "m/s",
        },
        {
            parameterId: 260,
            name: "WINDSPEED_115m",
            long_description: "Wind speed at 115 meters [m/s]",
            description: "Wind speed 115m",
            unit: "m/s",
        },
        {
            parameterId: 261,
            name: "WINDSPEED_125m",
            long_description: "Wind speed at 125 meters [m/s]",
            description: "Wind speed 125m",
            unit: "m/s",
        },
        {
            parameterId: 262,
            name: "WINDSPEED_140m",
            long_description: "Wind speed at 140 meters [m/s]",
            description: "Wind speed 140m",
            unit: "m/s",
        },
        {
            parameterId: 145,
            name: "WINDSPEED_150m",
            long_description: "Wind speed at 150 meters [m/s]",
            description: "Wind speed 150m",
            unit: "m/s",
        },
        {
            parameterId: 33,
            name: "WINDDIR_10m",
            long_description: "Wind direction 10 meters [degrees]",
            description: "Wind dir 10m",
            unit: "degree",
        },
        {
            parameterId: 166,
            name: "WINDDIR_80m",
            long_description: "Wind direction 80 meters [degrees]",
            description: "Wind dir 80m",
            unit: "degree",
        },
        {
            parameterId: 45,
            name: "WAVE_DIRMEAN",
            long_description: "Mean wave direction [degrees]",
            description: "Mean wave dir",
            unit: "degree",
        },
        {
            parameterId: 46,
            name: "WAVE_PERMEAN",
            long_description: "Mean wave period [s]",
            description: "Mean wave per",
            unit: "s",
        },
        {
            parameterId: 56,
            name: "WAVE_PERPEAK",
            long_description: "Peak wave period [s]",
            description: "Mean wave peak",
            unit: "s",
        },
        {
            parameterId: 249,
            name: "WAVE_FREQUENCY",
            long_description: "Wave frequency (Hz)",
            description: "Wave frequency",
            unit: "Hz",
        },
        {
            parameterId: 76,
            name: "WAVE_HS_SWELL_P1",
            long_description: "Swell height [m] - primary swell",
            description: "Swell height primary",
            unit: "m",
        },
        {
            parameterId: 83,
            name: "WAVE_DIR_SWELL_P1",
            long_description: "Swell direction [degrees] - primary swell",
            description: "Swell dir primary",
            unit: "degree",
        },
        {
            parameterId: 86,
            name: "WAVE_PER_SWELL_P1",
            long_description: "Swell period [s] - primary swell",
            description: "Swell per primary",
            unit: "s",
        },
        {
            parameterId: 162,
            name: "CURRENTSPEED_0m",
            long_description: "Surface current speed [m/s]",
            description: "Surface cur speed",
            unit: "m/s",
        },
        {
            parameterId: 157,
            name: "CURRENTDIR_0m",
            long_description: "Surface current direction [degrees]",
            description: "Surface cur dir",
            unit: "degree",
        },
        {
            parameterId: 163,
            name: "ELEVATION_sfc",
            long_description: "Surface elevation",
            description: "Tidal elevation",
            unit: "m",
        },
        {
            parameterId: 155,
            name: "SEA_TEMPc",
            long_description: "Sea temperature",
            description: "Sea temperature",
            unit: "C",
        },
        {
            parameterId: 28,
            name: "ICE",
            long_description: "Icecover",
            description: "Ice coverage",
            unit: "nondegree",
        },
        {
            parameterId: 40,
            name: "TMPC_2m",
            long_description: "Temperature at 2 meters [Celsius]",
            description: "Temperature 2m",
            unit: "C",
        },
        {
            parameterId: 228,
            name: "TMPC_30-0",
            long_description: "Temperature at level 1 [C]",
            description: "Temperature 80m",
            unit: "C",
        },
        {
            parameterId: 63,
            name: "APCP1",
            long_description: "Precipitation [mm/hour]",
            description: "Precipitation",
            unit: "mm/hour",
        },
        {
            parameterId: 64,
            name: "VIS",
            long_description: "Visibility at 2 metres [m]",
            description: "Visibility",
            unit: "m",
        },
        {
            parameterId: 71,
            name: "LIGHTNINGRISK",
            long_description: "Risk of lightning [%]",
            description: "Lightning",
            unit: "%",
        },
        {
            parameterId: 152,
            name: "HELI_BLADE_ICING",
            long_description: "Icing on helicopter blades",
            description: "Heli blade icing",
            unit: "",
        },
        {
            parameterId: 153,
            name: "CLOUD_HEIGHT",
            long_description: "Cloud height",
            description: "VFR cloud",
            unit: "m",
        },
        {
            parameterId: 156,
            name: "DPTc_2m",
            long_description: "Dew point 2m",
            description: "Dew point temperature",
            unit: "C",
        },
        {
            parameterId: 251,
            name: "CSNOW",
            long_description: "Categorical snow [yes=1;no=0]",
            description: "Categorical snow",
            unit: "",
        },
        {
            parameterId: 8,
            name: "RH",
            long_description: "Relative Humidity at 2 meters [%]",
            description: "Humidity",
            unit: "%",
        },
        {
            parameterId: 27,
            name: "SWR",
            long_description: "Short Wave Radiation (sun)",
            description: "Short radiation",
            unit: "nondegree",
        },
    ];

    const [parameters, setParameters] = useState(false);
    useEffect(() => {
        if (!parameters) {
            Api.Hindcasts.getHindcastParameters()
                .then((res) => {
                    let params = res.data.map((e) => {
                        return {
                            parameterId: e.id,
                            name: e.name,
                            description: e.shortDescription,
                            unit: e.unit.name,
                        };
                    });
                    params = params.filter((e) => e.description !== "");

                    setParameters(params);
                })
                .catch((err) => {
                    console.log(
                        "Error retrieving parameters from API. Using predefined list."
                    );
                    setParameters(predefinedParameterList);
                });
        }
    }, [parameters, setParameters, predefinedParameterList]);

    useEffect(() => {
        if (permissions.includes("Hindcasts.Download") !== canDownloadRawData) {
            setCanDownloadRawData(permissions.includes("Hindcasts.Download"));
        }
    }, [config, permissions, canDownloadRawData]);

    return (
        <>
            {toggleable && (
                <List className={classes.menu}>
                    <ListItem
                        className={classes.menuItem}
                        button
                        onClick={toggleOpen}
                    >
                        <ListItemIcon className={classes.menuItemIcon}>
                            <FontAwesomeIcon
                                icon={hindcastAnalysisIcon}
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    open && classes.selectedMenuIcon
                                )}
                            />
                        </ListItemIcon>
                    </ListItem>
                </List>
            )}
            <Drawer
                open={toggleable ? open : true}
                variant={toggleable ? "persistent" : "permanent"}
                anchor={"left"}
                ModalProps={{
                    keepMounted: true,
                    container: container.current,
                    style: { position: "absolute" },
                }}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                onClose={toggleOpen}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.formContainer}>
                    <HindcastsForm
                        value={values}
                        validation={validation}
                        downloadData={downloadData}
                        handleValidationUpdate={handleValidationUpdate}
                        handleChangeValues={handleChangeValues}
                        parameters={parameters}
                        // downloadLoading={downloadLoading}
                        canDownloadRawData={canDownloadRawData}
                        loading={loading}
                        setLoading={setLoading}
                        runAnalysis={runAnalysis}
                    ></HindcastsForm>
                </div>
            </Drawer>
            <div className={classes.childrenContainer}>{props.children}</div>
        </>
    );
};

export default connect(mapStateToProps)(HindcastsPanel);
