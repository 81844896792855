import { CUSTOMER } from "../types";
import { customers, defaultCustomerName } from "../../utils/customerConfig";

const defaultConfig = customers.find((x) => x.name === defaultCustomerName);
const initialState = {
    id: null,
    name: "",
    clientId: null,
    clientKeys: null,
    config: defaultConfig,
};

export function customer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CUSTOMER.LOADED:
            // With multiple databases for the same code check customerId AND name
            const configFound = customers.find(
                (x) =>
                    x.customerId === payload.id &&
                    x.name.toLowerCase() === payload.name.toLowerCase()
            );
            return {
                ...payload,
                config: configFound ? configFound : defaultConfig,
            };
        case CUSTOMER.LOADED_FAILED:
            // If it fails for any reason, use the default config, but put -1 for the id, so we know it failed
            return {
                ...state,
                id: -1,
                config: defaultConfig,
            };
        default:
            return state;
    }
}
