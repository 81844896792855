import { createMuiTheme } from "@material-ui/core/styles";

const fontFamily = ["Open sans"];
const logoColor = "#00599C";

export const theme = createMuiTheme({
    palette: {
        company: {
            logoColor: `${logoColor}`,
        },
        primary: {
            main: "#368ED8",
            hover: "#4a99db",
            light: "#00b8d4",
        },
        secondary: {
            main: "#002546",
        },
        warning: {
            main: "#F16262",
            mainHover: "#D14242",
        },
        indicators: {
            green: "#33BA69",
            lightGreen: "#EAF7EF",
            warningYellow: "#F2D327",
            errorRed: "#C63C3C",
        },
        weatherWindow: {
            alarm: "#C62828",
            warning: "#FFB300",
            ok: "#4CAF50",
        },
        generalUi: {
            borders: "#E0E0E0",
            lines: "#C7C7C7",
            controls: "#AFAFAF",
            icons: "#959595",
            lightIcons: "#b6b6b6",
            background: "#FFFFFF",
            panelBackground: "#F5F5F5",
            listSelectBackground: "#F1F7FC",
            actionPanelBackground: "#F5F8FA",
            darkChartBackground: "#dbdbdb",
        },
        secondMenuItem: {
            hoverBackground: "#f5f7f9",
            selectedBackground: "#E6F1FC",
        },
        table: {
            cellBg: "#FAFAFA",
            cellBorder: "#F0F0F0",
            white: "#FEFEFE",
        },
        menu: {
            bg: "#F5F5F5",
            icons: "#959595",
            border: "#EBEBEB",
            text: "#959595",
        },
        text: {
            default: "#383838",
            defaultSub: "#6F6F6F",
            black: "#606060",
            white: "#ffffff",
            header: "#000000",
        },
        common: {
            white: "#fff",
            black: "#000",
        },
        error: {
            main: "#C63C3C",
        },
        faded: {
            green: "#EAF7EF",
            amber: "#FBF2BD",
            red: "#EEC5C5",
        },
        sites: {
            offshore: "#1EBAE7",
            onshore: "#33BA69",
        },
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: "white",
                height: 40,
            },
            input: {
                fontSize: 15,
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 13,
            },
        },
        MuiFormControl: {
            marginDense: {
                marginTop: 4,
                marginBottom: 12,
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "#e0e0e0",
            },
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline":
                    {
                        borderColor: "#1C71AD",
                    },
                "&.Mui-focused $notchedOutline": {
                    borderColor: "#1C71AD",
                },
            },
            multiline: {
                "&.MuiOutlinedInput-marginDense": {
                    paddingBottom: 4,
                },
            },
            focused: {},
            disabled: {},
            error: {},
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
            },
        },
        MuiDialogActions: {
            root: {
                padding: 14,
            },
        },
        MuiTypography: {
            body1: {
                //list in sites panel
                fontSize: 14,
                color: "#6f6f6f",
                fontFamily: `${fontFamily}, Regular`,
                fontWeight: 400,
                lineHeight: 1.4,
                paddingBottom: 2,
            },
            body2: {
                fontSize: 15,
                color: "#383838",
                fontFamily: `${fontFamily}, Regular`,
                lineHeight: 1.3,
            },
            h1: {
                fontSize: 42,
                color: "#0A0A0A",
                fontFamily: `${fontFamily}, Regular`,
                fontWeight: 200,
                lineHeight: 1,
            },
            h2: {
                fontSize: 24,
                color: "#383838",
                fontFamily: `${fontFamily}, Regular`,
                lineHeight: 1.5,
                fontWeight: 600,
            },
            h3: {
                //used as title in block component (and in appbar)
                fontSize: 18,
                color: "#6f6f6f",
                fontFamily: `${fontFamily}, Regular`,
                fontWeight: 600,
                lineHeight: 1,
                paddingBottom: 4,
            },
            h4: {
                //details page title
                fontSize: 15,
                color: "#6f6f6f",
                fontFamily: `${fontFamily}, Regular`,
                fontWeight: 600,
                lineHeight: 1,
                textTransform: "uppercase",
            },
            h5: {
                //sites panel title
                fontSize: 13,
                color: "#6f6f6f",
                fontFamily: `${fontFamily}, Regular`,
                fontWeight: 600,
                lineHeight: 1,
                textTransform: "uppercase",
            },
            h6: {
                //settings panel title
                fontSize: 14,
                color: "#383838",
                fontFamily: `${fontFamily}, Regular`,
                fontWeight: 600,
                lineHeight: 1,
                textTransform: "uppercase",
            },
            subtitle1: {
                fontSize: 12,
                color: "#606060",
                lineHeight: 1.3,
                fontFamily: `${fontFamily}, Regular`,
            },
            subtitle2: {
                fontSize: 14,
                color: "#6F6F6F",
                lineHeight: 1.3,
                fontFamily: `${fontFamily}, Regular`,
            },
            caption: {
                fontSize: 13,
                color: "#383838",
                lineHeight: 1.4,
                fontFamily: `${fontFamily}, Regular`,
            },
        },
    },
    typography: {
        useNextVariants: true,
        // fontSize: 16,
        // htmlFontSize: 14,
        color: "#383838",
        fontFamily,
    },
});
