import { CONFIG } from "../types";

export function config(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case CONFIG.UPDATED:
            return payload;
        default:
            return state;
    }
}
