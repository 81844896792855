import { Path, geometry, Group } from "@progress/kendo-drawing";
export const drawArrow = (props) => {
    if (props.dataItem.visible) {
        const pathHeight = 83.17356;
        const pathWidth = 74.569492;
        const arrowHeight = props.dataItem.size;
        const scale = arrowHeight / pathHeight;
        const arrowWidth = pathWidth * scale;
        const defaultNote = props.createVisual();
        const bbox = defaultNote.bbox();
        const topLeft = bbox.origin;
        const topRight = bbox.topRight();
        const middleTop = { x: (topRight.x + topLeft.x) / 2, y: topLeft.y };
        const displacementFromTop = -20;
        const middle = { x: middleTop.x, y: middleTop.y - displacementFromTop };
        const topLeftArrow = {
            x: middle.x - arrowWidth / 2,
            y: middle.y - arrowHeight / 2,
        };
        const arrow = new Path({
            stroke: {
                color: props.options.color,
                width: 1,
            },
        });
        arrow
            .moveTo(26.057079 * scale, 3.9575 * scale)
            .lineTo(26.057079 * scale, 42.21338 * scale)
            .lineTo(3.9610112 * scale, 42.21338 * scale)
            .curveTo(
                [0.43223624 * scale, 42.21338 * scale],
                [-1.3156608 * scale, 46.4677 * scale],
                [1.1577792 * scale, 48.97412 * scale]
            )
            .lineTo(33.807194 * scale, 81.62353 * scale)
            .curveTo(
                [35.357217 * scale, 83.17356 * scale],
                [37.863637 * scale, 83.17356 * scale],
                [39.413659 * scale, 81.62353 * scale]
            )
            .lineTo(72.063072 * scale, 48.97412 * scale)
            .curveTo(
                [74.569492 * scale, 46.4677 * scale],
                [72.788615 * scale, 42.21338 * scale],
                [69.25984 * scale, 42.21338 * scale]
            )
            .lineTo(47.163772 * scale, 42.21338 * scale)
            .lineTo(47.163772 * scale, 3.9575 * scale)
            .curveTo(
                [47.163772 * scale, 1.78088 * scale],
                [45.382895 * scale, 0],
                [43.206268 * scale, 0]
            )
            .lineTo(30.014586 * scale, 0)
            .curveTo(
                [27.837958 * scale, 0],
                [26.057079 * scale, 1.78088 * scale],
                [26.057079 * scale, 3.9575 * scale]
            )
            .close()
            .fill(props.options.color)
            .transform(
                geometry
                    .transform()
                    .rotate(props.dataItem.rotation, [middle.x, middle.y])
                    .translate(topLeftArrow.x, topLeftArrow.y)
            );
        const group = new Group();
        group.append(arrow);
        return group;
    }
};
