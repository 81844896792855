import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/settings`;

const Settings = {
    createSite(data) {
        return axios({
            method: "post",
            url: `${API_URL}/sites`,
            data,
        });
    },
    updateSite(data) {
        return axios({
            method: "put",
            url: `${API_URL}/sites`,
            data,
        });
    },
    deleteSite(id) {
        return axios({
            method: "delete",
            url: `${API_URL}/sites/${id}`,
        });
    },
    findSites(name, description, isHidden, latMin, latMax, lonMin, lonMax) {
        let url = `${API_URL}/sites?`;
        if (name) url = url.concat(`&name=${name}`);
        if (description) url = url.concat(`&description=${description}`);
        if (isHidden != null) url = url.concat(`&isHidden=${isHidden}`);
        if (latMin) url = url.concat(`&latMin=${latMin}`);
        if (latMax) url = url.concat(`&latMax=${latMax}`);
        if (lonMin) url = url.concat(`&longMin=${lonMin}`);
        if (lonMax) url = url.concat(`&longMax=${lonMax}`);
        return axios({
            method: "get",
            url: url,
        });
    },

    getUsers() {
        return axios({
            method: "get",
            url: `${API_URL}/users`,
        });
    },
    createUser(data) {
        return axios({
            method: "post",
            url: `${API_URL}/users`,
            data,
        });
    },
    updateUser(data) {
        console.log("updateUser", data);
        return axios({
            method: "put",
            url: `${API_URL}/users`,
            data,
        });
    },
    deleteUser(id) {
        return axios({
            method: "delete",
            url: `${API_URL}/users/${id}`,
        });
    },

    connectSiteUser(siteId, userId) {
        return axios({
            method: "post",
            url: `${API_URL}/siteusers/connect/${siteId}/${userId}`,
        });
    },
    disconnectSiteUser(siteId, userId) {
        return axios({
            method: "post",
            url: `${API_URL}/siteusers/disconnect/${siteId}/${userId}`,
        });
    },

    getSiteUsers(siteId) {
        return axios({
            method: "get",
            url: `${API_URL}/siteusers/${siteId}/users`,
        });
    },
    getUserSites(userId) {
        return axios({
            method: "get",
            url: `${API_URL}/siteusers/${userId}/sites`,
        });
    },
    getPermissionList() {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/customer/permissions`,
        });
    },
};

export default Settings;
