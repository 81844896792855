import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    CircularProgress,
    useTheme,
    Grid,
    Typography,
} from "@material-ui/core";
import Api from "../../api";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import SimpleChart from "./SimpleChart";
import YearVariabilityTab from "./YearVariabilityTab";
import AnalyticsChartsTopbar from "./AnalyticsChartsTopbar";
import CloseButton from "../../common/CloseButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(8),
        overflowY: "auto",
        flex: 1,
    },
    topCharts: {
        padding: theme.spacing(0, 3),
    },
    chartsContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    gridContainer: {
        height: "100%",
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: 14,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(8),
    },
    centeredContent: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const mapStateToProps = (state) => ({
    apiLoading: state.status.loading,
});

const AnalyticsCharts = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const { mobile, push } = props;

    const getTitleFromQuery = (q) => {
        let query = new URLSearchParams(q);
        return JSON.parse(query.get("formValues")).site.name;
    };

    const renderPercentageFromQuery = () => {
        let queries = new URLSearchParams(location.search);
        const percentiles = JSON.parse(queries.get("formValues")).workWindow
            .percentiles;
        return (
            "p" +
            (percentiles[0].toString().length === 1
                ? "0" + percentiles[0]
                : percentiles[0])
        );
    };

    const prepareQueryForHwwaApi = (query) => {
        let newQuery = query;
        newQuery.weatherParameters = query.weatherParameters.map((e) => {
            return { value: e.value, name: e.name, isActive: e.isActive };
        });
        return newQuery;
    };

    useEffect(() => {
        const compileBodyFromQuery = (q) => {
            return JSON.parse(q.get("formValues"));
        };
        let query = new URLSearchParams(location.search);
        if (location.pathname) {
            setLoading(true);
            Api.HWWA.getData(
                prepareQueryForHwwaApi(compileBodyFromQuery(query))
            )
                .then((res) => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setData(null);
                    setLoading(false);
                });
        }
    }, [location]);

    const close = () => {
        push("/analytics");
    };

    const parseMonthData = (data, objectNames) => {
        const monthData = [];
        objectNames.forEach((objectName) => {
            const objectData = data[objectName];
            if (objectData) {
                monthData.push(
                    months.map((month, index) => {
                        if (objectData.hasOwnProperty(index + 1)) {
                            return objectData[index + 1];
                        } else {
                            return null;
                        }
                    })
                );
            }
        });
        return monthData;
    };

    return loading ? (
        <div className={classes.centeredContent}>
            <CircularProgress />
        </div>
    ) : data ? (
        <>
            <AnalyticsChartsTopbar title={getTitleFromQuery(location.search)}>
                <CloseButton onClick={close} small={mobile} />
            </AnalyticsChartsTopbar>
            <div className={classes.wrapper}>
                <div
                    className={clsx(classes.chartsContainer, classes.topCharts)}
                >
                    <Grid
                        container
                        spacing={10}
                        className={classes.gridContainer}
                    >
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h4"
                                className={classes.subtitle}
                            >
                                Usable Hours
                            </Typography>
                            <SimpleChart
                                categories={months}
                                seriesPlotTypes={["column", "column"]}
                                seriesNames={[
                                    "Total hours in month",
                                    "Usable hours",
                                ]}
                                data={parseMonthData(data.hwwa[0].hwwaHours, [
                                    "total_hours",
                                    "usable_hours_" +
                                        renderPercentageFromQuery(),
                                ])}
                                colors={[
                                    theme.palette.generalUi.darkChartBackground,
                                    theme.palette.primary.main,
                                ]}
                                sharedTooltip={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h4"
                                className={classes.subtitle}
                            >
                                Usable Days
                            </Typography>
                            <SimpleChart
                                categories={months}
                                seriesPlotTypes={["column", "column"]}
                                seriesNames={[
                                    "Total days in month",
                                    "Usable days",
                                ]}
                                data={parseMonthData(data.hwwa[0].hwwaDays, [
                                    "total_days",
                                    "usable_days_" +
                                        renderPercentageFromQuery(),
                                ])}
                                colors={[
                                    theme.palette.generalUi.darkChartBackground,
                                    theme.palette.primary.main,
                                ]}
                                sharedTooltip={true}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.chartsContainer}>
                    <YearVariabilityTab
                        months={months}
                        data={data.windVariabilityYearly}
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <AnalyticsChartsTopbar title={getTitleFromQuery(location.search)}>
                <CloseButton onClick={close} small={mobile} />
            </AnalyticsChartsTopbar>
            <div className={classes.centeredContent}>
                <Typography variant="h3">Data not found</Typography>
            </div>
        </>
    );
};
export default connect(mapStateToProps, { push })(AnalyticsCharts);
