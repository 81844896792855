import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { USER } from "../types";
import {
    sites,
    favoriteSites,
    sitesView,
    searchedSites,
    searchField,
} from "./sites";
import { createBrowserHistory } from "history";
import { config } from "./config";
import {
    alerts,
    alertOperators,
    alertLogics,
    alertFrequencies,
    expiredAlerts,
} from "./alerts";
import { customer } from "./customer";
import { reports, expiredReports, reportTypes } from "./reports";
import { parameters } from "./parameters";
import { mapState } from "./mapState";
import status from "./status";
import {
    sitesSettings,
    siteSearchSettings,
    sitesList,
    siteUsers,
} from "./settings";
import { user } from "./user";
import { users, userRoles, userSites } from "./users";
import { vestasSites } from "./vestasSites";

const createRootReducer = (state, action) => {
    if (action.type === USER.LOGOUT) {
        const { router } = state;
        state = { router };
    }
    return appReducer(state, action);
};

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    config,
    alerts,
    alertOperators,
    alertLogics,
    alertFrequencies,
    customer,
    expiredAlerts,
    favoriteSites,
    mapState,
    parameters,
    reports,
    expiredReports,
    reportTypes,
    searchedSites,
    searchField,
    sites,
    sitesList,
    siteSearchSettings,
    sitesSettings,
    siteUsers,
    sitesView,
    status,
    user,
    users,
    userRoles,
    userSites,
    vestasSites,
});

export default createRootReducer;
