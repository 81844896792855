import { REPORTS, EXPIRED_REPORTS, REPORT_TYPES, STATUS } from "../types";
import Api from "../../api";
import { handleError } from "../../utils/error";
import { changeStatus } from "./status";
import orderBy from "lodash/sortBy";

export const getReports = () => {
    return (dispatch) => {
        return Api.Reports.getReports()
            .then((res) => {
                const reports = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: REPORTS.LOADED, payload: reports });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const createReport = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Reports.createReport(data)
            .then((res) => {
                dispatch(getReports());
                dispatch(getExpiredReports());
                dispatch(changeStatus(STATUS.SUCCESS, "Report created"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const updateReport = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Reports.updateReport(data)
            .then((res) => {
                if (res) {
                    dispatch(getReports());
                    dispatch(getExpiredReports());
                    dispatch(changeStatus(STATUS.SUCCESS, "Report updated"));
                } else {
                    dispatch(
                        changeStatus(STATUS.ERROR, "Report failed to update")
                    );
                }
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const deleteReport = (id) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Reports.deleteReport(id)
            .then((res) => {
                dispatch(getReports());
                dispatch(changeStatus(STATUS.SUCCESS, "Report deleted"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getExpiredReports = () => {
    return (dispatch) => {
        return Api.Reports.getExpiredReports()
            .then((res) => {
                const reports = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: EXPIRED_REPORTS.LOADED, payload: reports });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const deleteExpiredReports = () => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Reports.deleteExpiredReports()
            .then((res) => {
                dispatch(getReports());
                dispatch(getExpiredReports());
                dispatch(
                    changeStatus(STATUS.SUCCESS, "Expired reports deleted")
                );
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getReportTypes = () => {
    return (dispatch) => {
        return Api.Reports.getReportTypes()
            .then((res) => {
                const reports = orderBy(res.data, ["name"], ["asc"]);
                dispatch({ type: REPORT_TYPES.LOADED, payload: reports });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};
