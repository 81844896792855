import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import ConfirmationDialog, {
    initialConfirmDialog,
} from "../../../common/Confirmation";
import { clockIcon } from "../../../assets/icons/fontawesome/clockIcon";
import { deleteIcon } from "../../../assets/icons/fontawesome/deleteIcon";
import { addIcon } from "../../../assets/icons/fontawesome/addIcon";
import { TimePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TertiaryButton } from "../../../common/restyled-mui/CustomButton";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    timeContainer: {
        display: "flex",
    },
    deleteButton: {
        height: 40,
        width: 40,
        marginTop: 4,
    },
    deleteIcon: {
        height: 21,
        width: 21,
        color: theme.palette.generalUi.icons,
    },
    buttonIcon: {
        color: theme.palette.primary.main,
        height: 18,
        width: 14,
        marginRight: theme.spacing(2),
    },
    button: {
        width: 160,
        fontWeight: 600,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    timeField: {
        paddingRight: 0,
    },
    clockIcon: {
        marginRight: theme.spacing(3),
        color: theme.palette.generalUi.icons,
    },
}));

const SendingTime = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialog);
    const { sendTime, updateSendTimes, validation } = props;

    const removeSendTime = (index) => {
        const renewSendTime = [...sendTime];
        renewSendTime.splice(index, 1);
        updateSendTimes(renewSendTime);
    };

    const handleTimeChange = (index) => (date) => {
        const renewSendTime = [...sendTime];
        renewSendTime[index] = moment(date).format("HH:mm");
        updateSendTimes(renewSendTime);
    };

    const addSendTime = () => {
        const renewSendTime = [...sendTime, "12:00"];
        updateSendTimes(renewSendTime);
    };

    const handleAddNewWarning = (callback) => {
        const handleCallback = () => {
            callback();
            setConfirmDialog(initialConfirmDialog);
        };
        setConfirmDialog({
            open: true,
            confirmCallback: handleCallback,
            cancelCallback: setConfirmDialog(initialConfirmDialog),
            title: "Warning",
            content: (
                <Typography>
                    Forecast values change every 6 hours, approximately at 00,
                    06, 12 and 18 UTC. Reports issued within the same block will
                    contain the same forecast.
                </Typography>
            ),
            color: theme.palette.primary.main,
        });
    };

    return (
        <>
            <ConfirmationDialog {...confirmDialog} />
            {sendTime.map((time, index) => {
                return (
                    <div key={index} className={classes.timeContainer}>
                        <TimePicker
                            inputVariant="outlined"
                            style={{ width: 164 }}
                            margin="dense"
                            minutesStep={60}
                            views={["hours"]}
                            value={moment(time, "HH:mm")}
                            onChange={handleTimeChange(index)}
                            InputProps={{
                                className: classes.timeField,
                                endAdornment: (
                                    <FontAwesomeIcon
                                        icon={clockIcon}
                                        size={"lg"}
                                        className={classes.clockIcon}
                                    />
                                ),
                            }}
                            error={
                                validation[index]
                                    ? !validation[index].valid
                                    : false
                            }
                            helperText={validation[index]?.message}
                        />
                        {index !== 0 && (
                            <IconButton
                                className={classes.deleteButton}
                                onClick={() => removeSendTime(index)}
                            >
                                <FontAwesomeIcon
                                    icon={deleteIcon}
                                    className={classes.deleteIcon}
                                />
                            </IconButton>
                        )}
                    </div>
                );
            })}
            {sendTime.length < 4 && (
                <TertiaryButton
                    onClick={() => handleAddNewWarning(addSendTime)}
                    className={classes.button}
                >
                    <FontAwesomeIcon
                        icon={addIcon}
                        className={classes.buttonIcon}
                    />
                    Add Sending Time
                </TertiaryButton>
            )}
        </>
    );
};

export default SendingTime;
