import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeIcon } from "../assets/icons/fontawesome/closeIcon";

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        color: theme.palette.generalUi.icons,
        fontSize: 21,
        cursor: "pointer",
    },
}));

const CloseIcon = (props) => {
    const classes = useStyles(props);
    const { onClick } = props;
    return (
        <FontAwesomeIcon
            icon={closeIcon}
            className={classes.closeIcon}
            onClick={onClick}
        />
    );
};

export default CloseIcon;
