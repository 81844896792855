import React from "react";
import { Tabs, Tab, withStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const width = 56;
const height = 38;

export const SimpleTab = withStyles((theme) => ({
    root: {
        borderRadius: theme.spacing(1.25, 1.25, 0, 0),
        borderTop: `1px solid transparent`,
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.generalUi.panelBackground,
        minWidth: width,
        minHeight: height,
        textTransform: "none",
        marginRight: 2,
        opacity: 1,
        "&$selected": {
            borderBottom: `1px solid transparent`,
            borderTop: `1px solid ${theme.palette.generalUi.borders}`,
            borderLeft: `1px solid ${theme.palette.generalUi.borders}`,
            borderRight: `1px solid ${theme.palette.generalUi.borders}`,
            backgroundColor: theme.palette.generalUi.background,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const SimpleTabs = withStyles((theme) => ({
    root: {
        minHeight: height,
    },
    indicator: {
        display: "none",
    },
}))(Tabs);

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        border: `1px solid ${theme.palette.generalUi.borders}`,
        borderRadius: theme.spacing(0, 1.25, 1.25, 1.25),
        marginTop: -1,
        padding: theme.spacing(4),
    },
}));

export const SimpleTabPanel = (props) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.tabPanel, props.className)}>
            {props.children}
        </div>
    );
};
