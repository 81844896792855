import moment from "moment";

export const getRange = (data) => {
    const min = data[0].time;
    const max = data[data.length - 1].time;
    return max - min;
};

export const getNumberOfCategories = (data, min, max) => {
    return data.filter((x) => {
        const date = moment.unix(x.time).toDate();
        return date >= min && date < max;
    }).length;
};

export const findRoundedMinMax = ({ min, max }, targetSteps) => {
    const range = max - min === 0 ? 1 : max - min;
    if (targetSteps !== 0) {
        // calculate an initial guess at step size
        let tempStep = range / targetSteps;

        // get the magnitude of the step size
        let mag = Math.floor(Math.log(tempStep) / Math.LN10);
        let magPow = Math.pow(10, mag);

        // calculate most significant digit of the new step size
        let magMsd = Math.round(tempStep / magPow + 0.5);

        // promote the MSD to either 1, 2, or 5
        if (magMsd > 5.0) {
            magMsd = 10.0;
        } else if (magMsd > 2.0) {
            magMsd = 5.0;
        } else if (magMsd > 1.0) {
            magMsd = 2.0;
        }
        const step = magMsd * magPow;

        const padding = step / 2;
        const paddedMinMax = {
            min:
                min < 0 ? min - padding : min - padding < 0 ? 0 : min - padding,
            max: max + padding,
        };
        const roundedMinMax = {
            min: step * Math.floor(paddedMinMax.min / step),
            max: step * Math.ceil(paddedMinMax.max / step),
        };

        return roundedMinMax;
    } else {
        return { min, max };
    }
};

export const negativeValuesInChart = (chartData) => {
    for (let i = 0; i < chartData.groups.length; i++) {
        for (let j = 0; j < chartData.groups[i].data.length; j++) {
            if (chartData.groups[i].data[j].value < 0) {
                return true;
            }
        }
    }
    return false;
};

export const renderEqualMinMax = (chartData, unit, addArrowPadding) => {
    let range = null;
    const groupRange = getUnitValueMinMax(
        chartData.groups,
        unit,
        addArrowPadding
    );
    if (range) {
        if (groupRange.min < range.min) {
            range.min = groupRange.min;
        }
        if (groupRange.max < range.max) {
            range.max = groupRange.max;
        }
    } else {
        range = groupRange;
    }
    if (range.min !== range.max) {
        if (-range.min < range.max) {
            range.min = -range.max;
        } else {
            if (range.min) range.max = -range.min;
        }
    } else {
        range = addPaddingOneValue(range);
    }
    return range;
};

export const getMostCommonUnit = (groups) => {
    let count = [];
    groups.forEach((group) => {
        const currentUnitIndex = count.findIndex((x) => x.unit === group.unit);
        if (currentUnitIndex === -1) {
            count.push({
                unit: group.unit,
                count: 1,
            });
        } else {
            count[currentUnitIndex].count += 1;
        }
    });

    let commonUnit = -1;
    count.forEach((obj, index) => {
        if (commonUnit > -1) {
            if (obj.count > count[commonUnit].count) {
                commonUnit = index;
            }
        } else {
            commonUnit = index;
        }
    });
    return count[commonUnit].unit;
};

export const getUnitValueMinMax = (groups, unit, addArrowPadding) => {
    let highestValue;
    let lowestValue;
    groups
        .filter((group) => group.unit === unit)
        .forEach((group) => {
            group.data.forEach((data) => {
                if (highestValue) {
                    if (data.value > highestValue) {
                        highestValue = data.value;
                    }
                } else {
                    highestValue = data.value;
                }
                if (lowestValue) {
                    if (data.value < lowestValue) {
                        lowestValue = data.value;
                    }
                } else {
                    lowestValue = data.value;
                }
            });
        });

    if (addArrowPadding) {
        highestValue += highestValue / 4;
    }

    return {
        min: lowestValue,
        max: highestValue,
    };
};

export const addPaddingOneValue = (range) => {
    const padding = 1;
    return {
        min: range.min - padding,
        max: range.max + padding,
    };
};
