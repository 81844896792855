import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemText, List, ListItem, Popover } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

const AnalyticsForm = (props) => {
    const classes = useStyles(props);
    const { open, anchor, parameters, onClick, handleClose } = props;

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
        >
            <List>
                {parameters.map((parameter) => {
                    return (
                        <ListItem
                            key={parameter.parameterId}
                            className={classes.listItem}
                            button
                            onClick={() => onClick(parameter)}
                        >
                            <ListItemText primary={parameter.description} />
                        </ListItem>
                    );
                })}
            </List>
        </Popover>
    );
};

export default AnalyticsForm;
