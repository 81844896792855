import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 4),
        height: 60,
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.generalUi.background,
    },
    title: {
        flexGrow: 1,
    },
    appbarContainer: {
        top: 0,
        position: "sticky",
        width: "100%",
    },
}));

const ActionBar = (props) => {
    const classes = useStyles(props);

    return (
        <>
            <div>
                <Toolbar className={classes.root}>
                    <Typography variant="h4" className={classes.title}>
                        Alerts
                    </Typography>
                    {props.children}
                </Toolbar>
            </div>
        </>
    );
};

export default ActionBar;
