import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    FormHelperText,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import {
    CustomTextField,
    CustomFormControlLabel,
    CustomRadioButton,
    textFieldNoArrowStyles,
    CustomCheckbox,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    dashDivider: {
        padding: theme.spacing(1.75),
    },
    radioButtonsFormControl: {
        marginRight: theme.spacing(3.5),
    },
    radioButtonGroup: {
        flexDirection: "row",
        alignItems: "flex-start",
        marginTop: theme.spacing(1),
    },
    customGroupItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    lastItemRadioGroup: {
        marginRight: theme.spacing(0.5),
    },
    miniCustomField: {
        width: 50,
    },
    inputNumber: {
        ...textFieldNoArrowStyles,
    },
    textFieldGroup: {
        marginRight: "auto",
    },
    helperText: {
        marginLeft: theme.spacing(9.5),
    },
    unit: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(3),
    },
}));

const WindowSelection = (props) => {
    const classes = useStyles(props);
    const {
        value,
        handleChangeValues,
        handleValidationUpdate,
        error,
        helperText,
    } = props;

    const handleDaylightChange = (e) => {
        e.persist();
        handleChangeValues((oldValues) => ({
            ...oldValues,
            windowSelection: {
                ...oldValues.windowSelection,
                daylight: e.target.checked,
            },
        }));
    };

    const handleWorkWindowChange = (e) => {
        e.persist();
        handleChangeValues((oldValues) => ({
            ...oldValues,
            windowSelection: {
                ...oldValues.windowSelection,
                name: e.target.value,
            },
        }));
        handleValidationUpdate("windowSelection");
    };

    const handleWorkWindowCustomRange = (name) => (e) => {
        e.persist();
        handleChangeValues((oldValues) => ({
            ...oldValues,
            windowSelection: {
                ...oldValues.windowSelection,
                customRange: {
                    ...oldValues.windowSelection.customRange,
                    [name]: e.target.value,
                },
            },
        }));
        handleValidationUpdate("windowSelection");
    };

    const InputPropsNumber = {
        classes: {
            input: classes.inputNumber,
        },
    };

    return (
        <div style={{ display: "flex" }}>
            <RadioGroup
                name="Window Selection"
                className={classes.radioButtonGroup}
                value={value.name}
                onChange={handleWorkWindowChange}
            >
                <FormControl className={classes.textFieldGroup} error={error}>
                    <div className={classes.customGroupItem}>
                        <CustomFormControlLabel
                            value="custom"
                            className={classes.lastItemRadioGroup}
                            control={
                                <CustomRadioButton
                                    variant="outlined"
                                    margin="dense"
                                />
                            }
                        />
                        <CustomTextField
                            name={"Window Length"}
                            type="number"
                            fullWidth={false}
                            margin={"none"}
                            className={classes.miniCustomField}
                            disabled={value.name !== "custom"}
                            value={value.customRange?.start}
                            onChange={handleWorkWindowCustomRange("start")}
                            error={error}
                            InputProps={InputPropsNumber}
                        />
                        <Typography className={classes.dashDivider}>
                            -
                        </Typography>
                        <CustomTextField
                            name={"Window Length"}
                            type="number"
                            fullWidth={false}
                            margin={"none"}
                            className={classes.miniCustomField}
                            disabled={value.name !== "custom"}
                            value={value.customRange?.end}
                            onChange={handleWorkWindowCustomRange("end")}
                            error={error}
                            InputProps={InputPropsNumber}
                        />
                        <Typography className={classes.unit}>hrs</Typography>
                    </div>
                    <FormHelperText className={classes.helperText}>
                        {helperText}
                    </FormHelperText>
                </FormControl>

                <CustomFormControlLabel
                    value="24h"
                    control={
                        <CustomRadioButton variant="outlined" margin="dense" />
                    }
                    label={"24H"}
                    labelPlacement="end"
                    className={classes.radioButtonsFormControl}
                />
            </RadioGroup>
            <CustomFormControlLabel
                value="daylight"
                control={
                    <CustomCheckbox
                        variant="outlined"
                        margin="dense"
                        onChange={handleDaylightChange}
                    />
                }
                label={"Daylight"}
                labelPlacement="end"
                className={classes.radioButtonsFormControl}
            />
        </div>
    );
};

export default WindowSelection;
