import React from "react";
import {
    makeStyles,
    Typography,
    Grid,
    MenuItem,
    FormControl,
    InputAdornment,
    FormHelperText,
    IconButton,
} from "@material-ui/core";
import {
    CustomInputLabel,
    CustomTextField,
    CustomSelect,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteIcon } from "../../../assets/icons/fontawesome/deleteIcon";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    marginFix: {
        marginTop: theme.spacing(1),
    },
    numberInputInput: {
        "-webkit-appearance": "none",
        margin: 0,
        /* Chrome, Safari, Edge, Opera */
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        /* Firefox */
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
    },
    unitContainer: {
        color: theme.palette.text.primary,
        fontWeight: 800,
    },
    deleteIconContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "flex-start",
    },
    buttonIcon: {
        color: theme.palette.error.main,
    },
    button: {
        fontWeight: 800,
    },
    deleteButton: {
        height: 40,
        width: 40,
        marginTop: 4,
    },
    deleteIcon: {
        height: 21,
        width: 21,
        color: theme.palette.generalUi.icons,
    },
    deleteFiller: {
        height: 14,
        marginTop: 8,
    },
}));

const translateAlertOperator = (type) => {
    switch (type) {
        case "LTE":
            return <span>&#8804;</span>;
        case "GTE":
            return <span>&#8805;</span>;
        case "GT":
            return <span>&#62;</span>;
        case "LT":
            return <span>&#60;</span>;
        case "EQ":
            return <span>=</span>;
        default:
            return <span>{type}</span>;
    }
};

const translateLogicOperator = (type) => {
    switch (type) {
        case "AND":
            return <span>and</span>;
        case "OR":
            return <span>or</span>;
        case null:
            return <span>&nbsp;</span>;
        default:
            return <span>{type}</span>;
    }
};

const ParameterThreshold = (props) => {
    const classes = useStyles();
    const {
        parameters,
        id,
        handleParametersParamChange,
        handleParametersOperatorChange,
        handleParametersValueChange,
        handleParametersDelete,
        handleParametersLogicChange,
        validation,
        alertOperators,
        alertLogics,
        alert,
        disabled,
        showDelete,
        showHeader,
    } = props;

    // Do not allow the user to select the same parameter twice
    const optionsParameters = parameters.filter((parameter) => {
        for (const element of alert.parameters) {
            if (element.parameter?.parameterId === parameter.parameterId) {
                return false;
            }
        }
        return true;
    });
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4}>
                {showHeader && <CustomInputLabel>Parameter</CustomInputLabel>}
                <FormControl
                    fullWidth
                    margin="none"
                    variant="outlined"
                    size="small"
                >
                    <Autocomplete
                        disabled={disabled}
                        options={optionsParameters}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        value={
                            alert.parameters[id].parameter
                                ? alert.parameters[id].parameter
                                : null
                        }
                        getOptionSelected={(option, value) =>
                            option.parameterId === value.parameterId
                        }
                        onChange={(e, value) =>
                            handleParametersParamChange(id)(value)
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => {
                            return (
                                <CustomTextField
                                    {...params}
                                    name={"parameter"}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    type="text"
                                    InputProps={{
                                        ...params.InputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                    error={
                                        validation.parameters[id] &&
                                        !validation.parameters[id].parameter
                                            .valid
                                    }
                                    helperText={
                                        validation.parameters[id]?.parameter
                                            .message
                                    }
                                />
                            );
                        }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4} md={4} lg={1}>
                {showHeader && <CustomInputLabel>Op.</CustomInputLabel>}
                <FormControl
                    fullWidth
                    margin="none"
                    variant="outlined"
                    size="small"
                    error={
                        validation.parameters[id] &&
                        !validation.parameters[id].operator1.valid
                    }
                >
                    <CustomSelect
                        disabled={disabled}
                        inputProps={{
                            margin: "none",
                        }}
                        type="text"
                        value={
                            alert.parameters[id].operators[0]
                                ? alert.parameters[id].operators[0]
                                : null
                        }
                        onChange={handleParametersOperatorChange(id, 0)}
                        renderValue={(x) => translateAlertOperator(x.type)}
                        name={"operator"}
                        className={classes.marginFix}
                    >
                        {alertOperators.map((operator) => {
                            return (
                                <MenuItem
                                    key={operator.id}
                                    value={operator}
                                    className={classes.selectMenuItem}
                                >
                                    {translateAlertOperator(operator.type)}
                                </MenuItem>
                            );
                        })}
                    </CustomSelect>
                    <FormHelperText>
                        {validation.parameters[id]?.operator1.message}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={8} md={8} lg={1}>
                {showHeader && <CustomInputLabel>Value</CustomInputLabel>}
                <div
                    className={`${classes.valueContainer} ${classes.marginFix}`}
                >
                    <CustomTextField
                        disabled={disabled}
                        variant="outlined"
                        margin="none"
                        size="small"
                        fullWidth
                        value={
                            alert.parameters[id].values[0] !== undefined
                                ? alert.parameters[id].values[0]
                                : ""
                        }
                        onChange={handleParametersValueChange(id, 0)}
                        type="number"
                        error={
                            validation.parameters[id] &&
                            !validation.parameters[id].value1.valid
                        }
                        helperText={validation.parameters[id]?.value1.message}
                        name={"value"}
                        InputProps={{
                            classes: {
                                input: classes.numberInputInput,
                            },
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={8} md={8} lg={2}>
                {showHeader && <CustomInputLabel>Logic</CustomInputLabel>}
                <FormControl
                    fullWidth
                    margin="none"
                    variant="outlined"
                    size="small"
                >
                    <CustomSelect
                        disabled={
                            disabled ||
                            alert.parameters[id].operators[0]?.id === 5
                        }
                        inputProps={{
                            margin: "none",
                        }}
                        type="text"
                        value={
                            alert.parameters[id].operators[0]?.id === 5
                                ? null
                                : alert.parameters[id].logic
                        }
                        onChange={handleParametersLogicChange(id)}
                        name={"operator"}
                        className={classes.marginFix}
                    >
                        {alertLogics &&
                            [{ id: 0, type: null }, ...alertLogics].map(
                                (operator) => {
                                    return (
                                        <MenuItem
                                            key={operator}
                                            value={operator}
                                            className={classes.selectMenuItem}
                                        >
                                            {translateLogicOperator(
                                                operator.type
                                            )}
                                        </MenuItem>
                                    );
                                }
                            )}
                    </CustomSelect>
                </FormControl>
            </Grid>
            <Grid item xs={4} md={4} lg={1}>
                {showHeader && <CustomInputLabel>Op.</CustomInputLabel>}
                <FormControl
                    fullWidth
                    margin="none"
                    variant="outlined"
                    size="small"
                    error={
                        validation.parameters[id] &&
                        !validation.parameters[id].operator2.valid
                    }
                >
                    <CustomSelect
                        disabled={
                            disabled ||
                            alert.parameters[id].logic === null ||
                            !alert.parameters[id].logic ||
                            alert.parameters[id].logic.id === 0
                        }
                        inputProps={{
                            margin: "none",
                        }}
                        type="text"
                        value={
                            alert.parameters[id].logic === null
                                ? null
                                : alert.parameters[id].operators[1]
                        }
                        onChange={handleParametersOperatorChange(id, 1)}
                        renderValue={(x) => translateAlertOperator(x.type)}
                        name={"operator"}
                        className={classes.marginFix}
                    >
                        {alertOperators.map((operator) => {
                            // do not put the equal in the second (5)
                            if (operator.id !== 5) {
                                return (
                                    <MenuItem
                                        key={operator.id}
                                        value={operator}
                                        className={classes.selectMenuItem}
                                    >
                                        {translateAlertOperator(operator.type)}
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                    </CustomSelect>
                    <FormHelperText>
                        {validation.parameters[id]?.operator2.message}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={8} md={8} lg={1}>
                {showHeader && <CustomInputLabel>Value</CustomInputLabel>}
                <div
                    className={`${classes.valueContainer} ${classes.marginFix}`}
                >
                    <CustomTextField
                        disabled={
                            disabled ||
                            alert.parameters[id].logic === null ||
                            !alert.parameters[id].logic ||
                            alert.parameters[id].logic.id === 0
                        }
                        variant="outlined"
                        margin="none"
                        size="small"
                        fullWidth
                        value={
                            alert.parameters[id].logic === null
                                ? ""
                                : alert.parameters[id].values[1] !== undefined
                                ? alert.parameters[id].values[1]
                                : ""
                        }
                        onChange={handleParametersValueChange(id, 1)}
                        type="number"
                        error={
                            validation.parameters[id] &&
                            !validation.parameters[id].value2.valid
                        }
                        helperText={validation.parameters[id]?.value2.message}
                        name={"value"}
                        InputProps={{
                            classes: {
                                input: classes.numberInputInput,
                            },
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={8} md={8} lg={1}>
                {showHeader && <CustomInputLabel>Unit</CustomInputLabel>}
                <div
                    className={`${classes.unitContainer} ${classes.marginFix}`}
                >
                    <CustomTextField
                        disabled
                        variant="outlined"
                        margin="none"
                        size="small"
                        fullWidth
                        value={alert.parameters[id].parameter?.unit}
                    />
                </div>
            </Grid>
            <Grid
                item
                xs={1}
                md={1}
                lg={1}
                className={classes.deleteIconContainer}
            >
                {showDelete && (
                    <>
                        {showHeader && <div className={classes.deleteFiller} />}
                        <IconButton
                            className={classes.deleteButton}
                            onClick={handleParametersDelete(id)}
                        >
                            <FontAwesomeIcon
                                icon={deleteIcon}
                                className={classes.deleteIcon}
                            />
                        </IconButton>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default ParameterThreshold;
