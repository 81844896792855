import React from "react";
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    withStyles,
    makeStyles,
} from "@material-ui/core";

import { CustomCheckbox } from "../../common/restyled-mui/CustomInputs";

export const CustomList = withStyles((_theme) => ({
    root: {
        padding: 0,
    },
}))(List);
export const CustomListItem = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 4),
    },
    container: {
        "&:nth-child(odd)": {
            backgroundColor: theme.palette.table.cellBg,
        },
        borderBottom: `1px solid ${theme.palette.table.cellBorder}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
}))(ListItem);

export const CustomListItemText = withStyles((_theme) => ({
    root: {},
}))(ListItemText);

export const CustomListItemSecondaryAction = withStyles((_theme) => ({
    root: {},
}))(ListItemSecondaryAction);

const useStyles = makeStyles((theme) => ({
    listContainer: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.table.cellBorder}`,
        marginTop: 10,
    },
    checkbox: {
        margin: 0,
    },
}));

const PermissionsSelection = (props) => {
    const classes = useStyles(props);
    const { permissionList, userPermissions, setUserPermissions } = props;

    const checkPermissionAssigned = (permission, userPermissions) => {
        return userPermissions.findIndex((e) => e === permission) > -1;
    };

    const handlePermissionClick = (permission) => (_e) => {
        let currentPerm = [...userPermissions];
        if (currentPerm.includes(permission)) {
            setUserPermissions([
                ...userPermissions.filter((x) => x !== permission),
            ]);
        } else {
            setUserPermissions([...userPermissions, permission]);
        }
    };

    const flatPermissionList =
        permissionList != null
            ? permissionList.map((e) => [e.name].concat(e.related)).flat()
            : [];

    const toggleAllPermissions = (e) => {
        if (e.target.checked) {
            setUserPermissions(flatPermissionList);
        } else {
            setUserPermissions([]);
        }
    };
    return (
        permissionList && (
            <div className={classes.listContainer}>
                <div className="MuiListItem-container">
                    <div style={{ height: 38 }}></div>
                    <CustomListItemSecondaryAction>
                        <CustomCheckbox
                            indeterminate={
                                userPermissions.length > 0 &&
                                flatPermissionList.length !==
                                    userPermissions.length
                            }
                            checked={
                                flatPermissionList.length ===
                                userPermissions.length
                            }
                            className={classes.checkbox}
                            onClick={toggleAllPermissions}
                        ></CustomCheckbox>
                    </CustomListItemSecondaryAction>
                </div>
                <CustomList>
                    {permissionList.map((permission, index) => {
                        let relatedList = "";
                        if (permission.related.length > 0) {
                            relatedList = permission.related.map((relPerm) => {
                                return (
                                    <CustomListItem
                                        key={relPerm}
                                        sx={{ pl: 4 }}
                                    >
                                        <CustomListItemText>
                                            {relPerm} ASV
                                        </CustomListItemText>
                                        <CustomListItemSecondaryAction>
                                            <CustomCheckbox
                                                checked={checkPermissionAssigned(
                                                    relPerm,
                                                    userPermissions
                                                )}
                                                className={classes.checkbox}
                                                onClick={handlePermissionClick(
                                                    relPerm
                                                )}
                                            ></CustomCheckbox>
                                        </CustomListItemSecondaryAction>
                                    </CustomListItem>
                                );
                            });
                            relatedList = (
                                <CustomList style={{}}>
                                    {relatedList}
                                </CustomList>
                            );
                        }

                        return (
                            <div key={permission.name}>
                                <CustomListItem>
                                    <CustomListItemText>
                                        {permission.name}
                                    </CustomListItemText>
                                    <CustomListItemSecondaryAction>
                                        <CustomCheckbox
                                            checked={checkPermissionAssigned(
                                                permission.name,
                                                userPermissions
                                            )}
                                            className={classes.checkbox}
                                            onClick={handlePermissionClick(
                                                permission.name
                                            )}
                                        ></CustomCheckbox>
                                    </CustomListItemSecondaryAction>
                                </CustomListItem>
                                {relatedList}
                            </div>
                        );
                    })}
                </CustomList>
            </div>
        )
    );
};

export default PermissionsSelection;
