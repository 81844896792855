import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const Reports = {
    getReports() {
        return axios({
            method: "get",
            url: `${API_URL}/report/list`,
        });
    },
    getReport(id) {
        return axios({
            method: "get",
            url: `${API_URL}/report/${id}`,
        });
    },
    createReport(data) {
        return axios({
            method: "post",
            url: `${API_URL}/report`,
            data,
        });
    },
    updateReport(data) {
        return axios({
            method: "put",
            url: `${API_URL}/report`,
            data,
        });
    },
    deleteReport(id) {
        return axios({
            method: "delete",
            url: `${API_URL}/report/${id}`,
        });
    },
    getExpiredReports() {
        return axios({
            method: "get",
            url: `${API_URL}/report/expired`,
        });
    },
    deleteExpiredReports() {
        return axios({
            method: "delete",
            url: `${API_URL}/report/expired`,
        });
    },
    getChartsForSite(siteId) {
        return axios({
            method: "get",
            url: `${API_URL}/report/charts/${siteId}`,
        });
    },
    getReportTypes() {
        return axios({
            method: "get",
            url: `${API_URL}/report/types`,
        });
    },
};

export default Reports;
