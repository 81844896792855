export const initialSingleValidation = {
    valid: true,
    message: "",
};

export const validSingleValidation = {
    valid: true,
    message: "",
};

export const checkValidation = (validation) => {
    for (var key in validation) {
        if (validation.hasOwnProperty(key)) {
            if (key === "recipients") {
                for (let i = 0; i < validation[key].length; i++) {
                    if (validation[key][i].valid === false) {
                        return false;
                    }
                }
            } else {
                if (validation[key].valid === false) {
                    return false;
                }
            }
        }
    }
    return true;
};

export const validateRequiredString = (value) => {
    if (value) {
        if (value.length > 0) {
            return validSingleValidation;
        }
    }
    return {
        valid: false,
        message: "Required",
    };
};

export const validateRequiredNumber = (value) => {
    if (value !== "" && value !== null && value !== undefined) {
        if (Number(value) !== NaN) {
            return validSingleValidation;
        }
    }
    return {
        valid: false,
        message: "Required",
    };
};

export const validateRequiredPositiveIntegerNumber = (value) => {
    if (value !== "" && value !== null && value !== undefined) {
        if (value > 0) {
            if (Number.isInteger(Number(value))) {
                return validSingleValidation;
            }
        }
    }
    return {
        valid: false,
        message: "Required a positive integer number",
    };
};

export const validateRequiredObj = (value) => {
    if (value) {
        return validSingleValidation;
    }
    return {
        valid: false,
        message: "Required",
    };
};

export const validateRequiredArray = (value) => {
    if (value?.length) {
        return validSingleValidation;
    }
    return {
        valid: false,
        message: "One Required",
    };
};

export const validateRequiredEmail = (value) => {
    if (isEmail(value)) {
        return validSingleValidation;
    } else {
        return {
            valid: false,
            message: "Please enter a valid email address",
        };
    }
};

const isEmail = (string) => {
    var re =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(string);
};

export const handleValidationUpdate = (setValidation, state) => {
    setValidation((validation) => ({
        ...validation,
        [state]: validSingleValidation,
    }));
};

export const validateOneRequiredArray = (array) => {
    if (array.length > 0) {
        return validSingleValidation;
    }
    return {
        valid: false,
        message: "At least one required",
    };
};
