import { Switch, withStyles } from "@material-ui/core";

export const GreenSwitch = withStyles((theme) => ({
    switchBase: {
        "&$checked": {
            color: theme.palette.indicators.green,
        },
        "&$checked + $track": {
            backgroundColor: theme.palette.indicators.green,
        },
    },
    checked: {},
    track: {},
}))(Switch);
