import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Route, Switch } from "react-router-dom";
import { Drawer, Typography, List, ListItem } from "@material-ui/core";
import { getSettingsStyles } from "../styles/settingsStyles";
import { push } from "connected-react-router";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import generalMd from "../assets/wiki/general.md";
import profileMd from "../assets/wiki/profile.md";
import settingsMd from "../assets/wiki/settings.md";
import modulesMd from "../assets/wiki/modules.md";
import weatherForecastMd from "../assets/wiki/weatherForecast.md";
import alertsMd from "../assets/wiki/alerts.md";
import reportsMd from "../assets/wiki/reports.md";
import hwwaMd from "../assets/wiki/hwwa.md";
import faqMd from "../assets/wiki/faq.md";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    centered: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    wikiPage: {
        width: "100%",
    },
    listTitle: {
        fontSize: 17,
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 0),
    },
    moduleText: {
        color: theme.palette.primary.main,
        fontSize: 14,
        padding: theme.spacing(0, 3),
    },
    moduleSecondText: {
        color: theme.palette.primary.text,
        fontSize: 12,
        padding: theme.spacing(0, 5),
    },
    drawer: {
        display: "flex",
        width: "20%",
    },
    drawerPaper: {
        borderRight: `${theme.palette.menu.border} 5px solid`,
        width: "20%",
    },
    drawerContent: {
        padding: theme.spacing(4, 0),
    },
    wikiContent: {
        marginLeft: "20%",
        padding: theme.spacing(0, 7),
        height: "100%",
        overflow: "scroll",

        "& *": {
            color: "#6f6f6f",
            fontSize: 14,
            fontFamily: "Open sans, Regular",
            fontWeight: 400,
            lineHeight: 1.4,
            paddingBottom: theme.spacing(1),
            margin: 0,
        },
        "& h1": {
            color: theme.palette.primary.main,
            fontSize: 35,
            fontWeight: 600,
            paddingTop: theme.spacing(2),
            margin: 0,
        },
        "& h2": {
            color: theme.palette.primary.main,
            fontSize: 25,
            fontWeight: 600,
            paddingTop: theme.spacing(2),
        },
        "& h3": {
            fontSize: 17,
            fontWeight: 600,
            paddingTop: theme.spacing(2),
        },
        "& p": {
            fontSize: 14,
            fontWeight: 400,
            paddingTop: theme.spacing(1),
        },
        "& a": {
            color: theme.palette.primary.main,
            textDecoration: "none",
        },
        "& hr": {
            border: "1px solid " + theme.palette.primary.main,
            margin: theme.spacing(2, 0),
            padding: 0,
        },
        "& pre": {
            backgroundColor: "#f5f5f5",
            color: "#000",
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 15),
            borderRadius: 5,
        },
        "& code": {
            backgroundColor: "#f5f5f5",
            color: "#000",
            borderRadius: 5,
            fontFamily: "monospace;",
        },
        "& strong": {
            fontWeight: 600,
            "& *": {
                fontWeight: 600,
            },
        },
        "& img": {
            maxWidth: "100%",
            height: "auto",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            padding: theme.spacing(2, 0),
        },
        "& table": {
            borderSpacing: 0,
            marginLeft: "auto",
            marginRight: "auto",
            "& th": {
                border: "1px solid " + theme.palette.primary.main,
                padding: theme.spacing(1, 2),
                backgroundColor: theme.palette.primary.main,
                color: "#fff",

                "&:first-child": {
                    borderTopLeftRadius: 5,
                },
                "&:last-child": {
                    borderTopRightRadius: 5,
                },
            },
            "& td": {
                border: "1px solid " + theme.palette.primary.main,
                padding: theme.spacing(1, 2),
                "&:first-child": {
                    borderLeft: "2px solid " + theme.palette.primary.main,
                },
                "&:last-child": {
                    borderRight: "2px solid " + theme.palette.primary.main,
                },
            },
            "& tr:last-child td": {
                borderBottom: "2px solid " + theme.palette.primary.main,

                "&:first-child": {
                    borderBottomLeftRadius: 5,
                },
                "&:last-child": {
                    borderBottomRightRadius: 5,
                },
            },
        },
    },
    wikiTitle: {
        color: theme.palette.primary.main,
        fontSize: 35,
        fontWeight: 600,
        paddingTop: theme.spacing(2),
    },
    wikiSubtitle: {
        color: theme.palette.primary.main,
        fontSize: 25,
        fontWeight: 600,
        paddingTop: theme.spacing(2),
    },
    wikiSubsubtitle: {
        fontSize: 17,
        fontWeight: 600,
        paddingTop: theme.spacing(2),
    },
    wikiText: {
        paddingTop: theme.spacing(2),
    },
    smallSpace: {
        height: theme.spacing(10),
    },
    bigSpace: {
        height: theme.spacing(20),
    },
}));

const Wiki = (props) => {
    const classes = useStyles(props);
    const dispatch = useDispatch();

    const { container } = props;

    const NavigateWiki = (destination) => {
        dispatch(push("/wiki/" + destination));
    };

    const GeneralIndex = () => {
        return (
            <>
                <ListItem button onClick={() => NavigateWiki("")}>
                    <Typography variant="h2" className={classes.listTitle}>
                        General
                    </Typography>
                </ListItem>
                <ListItem button onClick={() => NavigateWiki("profile")}>
                    <Typography className={classes.moduleText}>
                        Profile
                    </Typography>
                </ListItem>
                <ListItem button onClick={() => NavigateWiki("settings")}>
                    <Typography className={classes.moduleText}>
                        Settings
                    </Typography>
                </ListItem>
                {/* <ListItem button onClick={() => NavigateWiki("faq")}>
                    <Typography className={classes.moduleText}>
                        Frequently Asked Questions
                    </Typography>
                </ListItem> */}{" "}
                {/* Not finished yet */}
            </>
        );
    };

    const [generalText, setGeneralText] = useState("*Loading wiki text ...*");

    useEffect(() => {
        fetch(generalMd).then((response) => {
            response.text().then((text) => {
                setGeneralText(text);
            });
        });
    }, []);

    const GeneralContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {generalText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [profileText, setProfileText] = useState("*Loading wiki text ...*");

    useEffect(() => {
        fetch(profileMd).then((response) => {
            response.text().then((text) => {
                setProfileText(text);
            });
        });
    }, []);

    const ProfileContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {profileText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [settingsText, setSettingsText] = useState("*Loading wiki text ...*");

    useEffect(() => {
        fetch(settingsMd).then((response) => {
            response.text().then((text) => {
                setSettingsText(text);
            });
        });
    }, []);

    const SettingsContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {settingsText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const ModulesIndex = () => {
        return (
            <>
                <ListItem button onClick={() => NavigateWiki("modules")}>
                    <Typography variant="h2" className={classes.listTitle}>
                        Modules
                    </Typography>
                </ListItem>
                <ListItem button onClick={() => NavigateWiki("forecasts")}>
                    <Typography className={classes.moduleText}>
                        Weather Forecast Module
                    </Typography>
                </ListItem>
                <ListItem button onClick={() => NavigateWiki("alerts")}>
                    <Typography className={classes.moduleText}>
                        Manage Forecasts Alerts
                    </Typography>
                </ListItem>
                <ListItem button onClick={() => NavigateWiki("reports")}>
                    <Typography className={classes.moduleText}>
                        Manage Reports
                    </Typography>
                </ListItem>
                <ListItem button onClick={() => NavigateWiki("hwwa")}>
                    <Typography className={classes.moduleText}>
                        Hindcast Analysis
                    </Typography>
                </ListItem>
            </>
        );
    };

    const [modulesText, setModulesText] = useState("*Loading wiki text...*");

    useEffect(() => {
        fetch(modulesMd).then((response) => {
            response.text().then((text) => {
                setModulesText(text);
            });
        });
    }, []);

    const ModulesContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {modulesText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [weatherForecastText, setWeatherForecastText] = useState(
        "*Loading wiki text...*"
    );

    useEffect(() => {
        fetch(weatherForecastMd).then((response) => {
            response.text().then((text) => {
                setWeatherForecastText(text);
            });
        });
    }, []);

    const WeatherForecastContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {weatherForecastText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [alertsText, setAlertsText] = useState("*Loading wiki text...*");

    useEffect(() => {
        fetch(alertsMd).then((response) => {
            response.text().then((text) => {
                setAlertsText(text);
            });
        });
    }, []);

    const AlertsContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {alertsText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [reportsText, setReportsText] = useState("*Loading wiki text...*");

    useEffect(() => {
        fetch(reportsMd).then((response) => {
            response.text().then((text) => {
                setReportsText(text);
            });
        });
    }, []);

    const ReportsContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {reportsText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [hindcastAnalysisText, setHindcastAnalysisText] = useState(
        "*Loading wiki text...*"
    );

    useEffect(() => {
        fetch(hwwaMd).then((response) => {
            response.text().then((text) => {
                setHindcastAnalysisText(text);
            });
        });
    }, []);

    const HindcastContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {hindcastAnalysisText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    const [faqText, setFaqText] = useState("*Loading wiki text...*");

    useEffect(() => {
        fetch(faqMd).then((response) => {
            response.text().then((text) => {
                setFaqText(text);
            });
        });
    }, []);

    const FaqContent = () => {
        return (
            <>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    escapeHtml={false}
                >
                    {faqText}
                </ReactMarkdown>
                <div className={classes.bigSpace} />
            </>
        );
    };

    return (
        <div className={classes.wikiPage}>
            <Drawer
                open={true}
                anchor={"left"}
                variant={"permanent"}
                ModalProps={{
                    keepMounted: true,
                    container: container.current,
                    style: { position: "absolute" },
                }}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                className={classes.drawer}
            >
                <List className={classes.drawerContent}>
                    {GeneralIndex()}
                    {ModulesIndex()}
                </List>
            </Drawer>
            <div className={classes.wikiContent}>
                <Switch>
                    <Route path={`/wiki`} exact render={GeneralContent} />
                    <Route
                        path={`/wiki/profile`}
                        exact
                        render={ProfileContent}
                    />
                    <Route
                        path={`/wiki/settings`}
                        exact
                        render={SettingsContent}
                    />
                    <Route path={`/wiki/faq`} exact render={FaqContent} />
                    <Route
                        path={`/wiki/modules`}
                        exact
                        render={ModulesContent}
                    />
                    <Route
                        path={`/wiki/forecasts`}
                        exact
                        render={WeatherForecastContent}
                    />
                    <Route path={`/wiki/alerts`} exact render={AlertsContent} />
                    <Route
                        path={`/wiki/reports`}
                        exact
                        render={ReportsContent}
                    />
                    <Route path={`/wiki/hwwa`} exact render={HindcastContent} />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(Wiki);
