import { CUSTOMER } from "../types";
import Api from "../../api";
import { handleError } from "../../utils/error";

export const getCustomer = () => {
    return (dispatch) => {
        return Api.getCustomer()
            .then((res) => {
                dispatch({ type: CUSTOMER.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
                dispatch({ type: CUSTOMER.LOADED_FAILED, payload: err });
            });
    };
};
