import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Drawer, List, ListItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    mapIcon,
    mapIconSelected,
} from "../../assets/icons/fontawesome/mapIcons";

import {
    fileDownloadIcon,
    fileDownloadIconSelected,
} from "../../assets/icons/fontawesome/fileDownloadIcons";
import {
    slidersIcon,
    slidersIconSelected,
} from "../../assets/icons/fontawesome/slidersIcons";

import {
    paramSettingsIcon,
    paramSettingsIconSelected,
} from "../../assets/icons/fontawesome/paramSettings";
import ExportFlyout from "./ExportFlyout";
import ThresholdFlyout from "./ThresholdFlyout";
import SettingsFlyout from "./SettingsFlyout";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 40,
        borderLeft: `1px solid ${theme.palette.generalUi.borders}`,
        padding: 0,
        zIndex: 1400,
        backgroundColor: theme.palette.common.white,
    },
    listItem: {
        padding: theme.spacing(2.75, 0),
    },
    icon: {
        margin: "auto",
        fontSize: 18,
        color: theme.palette.generalUi.icons,
    },
    selectedIcon: {
        color: theme.palette.primary.main,
    },
    drawerContainer: {
        position: "relative",
    },
    drawerPaper: {
        boxShadow: "-4px 3px 6px #0000000D",
        position: "absolute",
        height: "100%",
        display: "flex",
    },
    drawerBackdrop: {
        position: "absolute",
    },
    drawerModal: {
        position: "absolute",
    },
}));

const DetailsToolbar = (props) => {
    const classes = useStyles(props);
    const [open, setOpen] = useState(0);
    const {
        //drawer
        container,
        dataAvailable,
        mobile,
        //map
        toggleMap,
        mapOpen,
        mapToggleable,
        //export
        setPDFDate,
        pdfDate,
        setPDFReady,
        pdfReady,
        days,
        localTimezone,
        timezoneOffset,
        site,
        //settings/thresholds
        parameters,
        updateParameters,
        chartSets,
        setChartSets,
    } = props;

    const toggleDrawer = (value) => (event) => {
        if (open !== value) {
            setOpen(value);
        } else {
            setOpen(0);
        }
    };

    return (
        <>
            <Drawer
                open={Boolean(open === 1)}
                anchor={"right"}
                variant={"persistent"}
                onClose={toggleDrawer(1)}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                ModalProps={{
                    container: container,
                    style: { position: "absolute" },
                }}
            >
                <ExportFlyout
                    setPDFDate={setPDFDate}
                    pdfDate={pdfDate}
                    setPDFReady={setPDFReady}
                    pdfReady={pdfReady}
                    days={days}
                    localTimezone={localTimezone}
                    timezoneOffset={timezoneOffset}
                    site={site}
                    containerWidth={container ? container.offsetWidth : 0}
                    dataAvailable={dataAvailable}
                    close={toggleDrawer(1)}
                />
            </Drawer>
            <Drawer
                open={Boolean(open === 2)}
                anchor={"right"}
                variant={"persistent"}
                onClose={toggleDrawer(2)}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                ModalProps={{
                    container: container,
                    style: { position: "absolute" },
                }}
            >
                <ThresholdFlyout
                    parameters={parameters}
                    updateParameters={updateParameters}
                    close={toggleDrawer(2)}
                    containerWidth={container ? container.offsetWidth : 0}
                    mobile={mobile}
                />
            </Drawer>
            <Drawer
                open={Boolean(open === 3)}
                anchor={"right"}
                variant={"persistent"}
                onClose={toggleDrawer(3)}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                ModalProps={{
                    container: container,
                    style: { position: "absolute" },
                }}
            >
                <SettingsFlyout
                    parameters={parameters}
                    updateParameters={updateParameters}
                    chartSets={chartSets}
                    setChartSets={setChartSets}
                    containerWidth={container ? container.offsetWidth : 0}
                    close={toggleDrawer(3)}
                    site={site}
                />
            </Drawer>
            <List className={classes.root}>
                {mapToggleable && (
                    <ListItem
                        button
                        onClick={toggleMap}
                        className={classes.listItem}
                    >
                        <FontAwesomeIcon
                            icon={mapOpen ? mapIconSelected : mapIcon}
                            size={"sm"}
                            title={"Map"}
                            className={clsx(
                                classes.icon,
                                mapOpen && classes.selectedIcon
                            )}
                        />
                    </ListItem>
                )}
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={toggleDrawer(1)}
                >
                    <FontAwesomeIcon
                        icon={
                            open === 1
                                ? fileDownloadIconSelected
                                : fileDownloadIcon
                        }
                        size={"sm"}
                        title={"Export"}
                        className={clsx(
                            classes.icon,
                            open === 1 && classes.selectedIcon
                        )}
                    />
                </ListItem>
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={toggleDrawer(2)}
                >
                    <FontAwesomeIcon
                        icon={open === 2 ? slidersIconSelected : slidersIcon}
                        size={"sm"}
                        title={"Weather Window Thresholds"}
                        className={clsx(
                            classes.icon,
                            open === 2 && classes.selectedIcon
                        )}
                    />
                </ListItem>
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={toggleDrawer(3)}
                >
                    <FontAwesomeIcon
                        icon={
                            open === 3
                                ? paramSettingsIconSelected
                                : paramSettingsIcon
                        }
                        size={"sm"}
                        title={"Select View Set"}
                        className={clsx(
                            classes.icon,
                            open === 3 && classes.selectedIcon
                        )}
                    />
                </ListItem>
            </List>
        </>
    );
};

export default DetailsToolbar;
