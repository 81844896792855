import {
    SITES_SETTINGS,
    SITES_SEARCH_SETTINGS,
    SITES,
    SITES_LIST,
    SITE_USERS,
    FAVORITE_SITES,
    VESTAS_SITES,
} from "../types";
import Api from "../../api";
import { STATUS } from "../types";
import { changeStatus } from "./status";
import { handleError } from "../../utils/error";

export const getSitesList = () => {
    return (dispatch) => {
        return Api.Settings.findSites()
            .then((res) => {
                dispatch({ type: SITES_LIST.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const findSites = (
    name,
    description,
    isHidden,
    latMin,
    latMax,
    lonMin,
    lonMax
) => {
    return (dispatch) => {
        dispatch({
            type: SITES_SEARCH_SETTINGS.CHANGED,
            payload: {
                name,
                description,
                isHidden,
                latMin,
                latMax,
                lonMin,
                lonMax,
            },
        });
        return Api.Settings.findSites(
            name,
            description,
            isHidden,
            latMin,
            latMax,
            lonMin,
            lonMax
        )
            .then((res) => {
                dispatch({ type: SITES_SETTINGS.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const clearSites = () => {
    return (dispatch) => {
        dispatch({ type: SITES_SETTINGS.CLEAR, payload: null });
        return null;
    };
};

export const createSite = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Settings.createSite(data)
            .then((res) => {
                const newData = {
                    ...data,
                    id: res.data,
                };

                const sites = getState().sitesSettings;
                if (sites) {
                    sites.push(newData);
                    dispatch({ type: SITES_SETTINGS.CREATED, payload: sites });
                }

                clearSiteCaches(dispatch, true);
                dispatch(changeStatus(STATUS.SUCCESS, "Site created"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const updateSite = (data) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Settings.updateSite(data)
            .then((res) => {
                const sites = getState().sitesSettings;
                if (sites) {
                    sites.splice(
                        sites.findIndex((x) => x.id === data.id),
                        1,
                        data
                    );
                    dispatch({ type: SITES_SETTINGS.UPDATED, payload: sites });
                }

                clearSiteCaches(dispatch, true);
                dispatch(changeStatus(STATUS.SUCCESS, "Site updated"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const deleteSite = (id) => {
    return (dispatch, getState) => {
        dispatch(changeStatus(STATUS.FETCHING));
        return Api.Settings.deleteSite(id)
            .then((res) => {
                const sites = getState().sitesSettings;
                if (sites) {
                    sites.splice(
                        sites.findIndex((s) => s.id === id),
                        1
                    );
                    dispatch({ type: SITES_SETTINGS.DELETED, payload: sites });
                }

                clearSiteCaches(dispatch, true);
                dispatch(changeStatus(STATUS.SUCCESS, "Site deleted"));
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const getSiteUsers = (siteId) => {
    return (dispatch) => {
        return Api.Settings.getSiteUsers(siteId)
            .then((res) => {
                dispatch({ type: SITE_USERS.LOADED, payload: res.data });
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const connectSiteUser = (siteId, userId) => {
    return (dispatch) => {
        return Api.Settings.connectSiteUser(siteId, userId)
            .then((res) => {
                clearSiteCaches(dispatch, false);
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

export const disconnectSiteUser = (siteId, userId) => {
    return (dispatch) => {
        return Api.Settings.disconnectSiteUser(siteId, userId)
            .then((res) => {
                clearSiteCaches(dispatch, false);
            })
            .catch((err) => {
                handleError(dispatch, err);
            });
    };
};

// Clear sites for other pages (so that they are forced to reload)
const clearSiteCaches = (dispatch, sitesList) => {
    dispatch({ type: SITES.LOADED, payload: null });
    dispatch({ type: FAVORITE_SITES.LOADED, payload: null });
    dispatch({ type: VESTAS_SITES.LOADED, payload: null });

    sitesList && dispatch({ type: SITES_LIST.LOADED, payload: null });
};
