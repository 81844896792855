import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import { CustomTextField } from "../../../common/restyled-mui/CustomInputs";
import { deleteIcon } from "../../../assets/icons/fontawesome/deleteIcon";
import { addIcon } from "../../../assets/icons/fontawesome/addIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TertiaryButton } from "../../../common/restyled-mui/CustomButton";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    recipientContainer: {
        display: "flex",
    },
    deleteButton: {
        height: 40,
        width: 40,
        marginTop: 4,
    },
    deleteIcon: {
        height: 21,
        width: 21,
        color: theme.palette.generalUi.icons,
    },
    buttonIcon: {
        color: theme.palette.primary.main,
        height: 18,
        width: 14,
        marginRight: theme.spacing(2),
    },
    button: {
        width: 132,
        fontWeight: 600,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
}));

const RecipientInputs = (props) => {
    const classes = useStyles(props);
    const { recipients, updateRecipients, validation } = props;

    const removeRecipient = (index) => {
        const newRecipients = [...recipients];
        newRecipients.splice(index, 1);
        updateRecipients(newRecipients);
    };

    const handleTextChange = (index) => (e) => {
        const newRecipients = [...recipients];
        newRecipients[index] = e.target.value;
        updateRecipients(newRecipients);
    };

    const addRecipient = () => {
        const newRecipients = [...recipients, ""];
        updateRecipients(newRecipients);
    };

    return (
        <>
            {recipients.map((recipient, index) => {
                return (
                    <div key={index} className={classes.recipientContainer}>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            onChange={handleTextChange(index)}
                            value={recipient}
                            type="text"
                            name="email"
                            error={
                                validation[index]
                                    ? !validation[index].valid
                                    : false
                            }
                            helperText={validation[index]?.message}
                        />
                        <IconButton
                            className={classes.deleteButton}
                            onClick={() => removeRecipient(index)}
                        >
                            <FontAwesomeIcon
                                icon={deleteIcon}
                                className={classes.deleteIcon}
                            />
                        </IconButton>
                    </div>
                );
            })}
            <TertiaryButton onClick={addRecipient} className={classes.button}>
                <FontAwesomeIcon
                    icon={addIcon}
                    className={classes.buttonIcon}
                />
                Add Recipient
            </TertiaryButton>
        </>
    );
};

export default RecipientInputs;
