import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
} from "@material-ui/core";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
    getSites,
    getFavoriteSites,
    clearFavoriteSites,
    setSearchField,
    searchSites,
    toggleSitesView,
    removeFavoriteSite,
    addFavoriteSite,
} from "../../redux/actions/sites";
import { getConfig, updateConfig } from "../../redux/actions/config";
import UIIcons from "../../assets/icons/ui-icons";
import SitesList from "./SitesList";
import ConfirmationDialog, {
    initialConfirmDialog,
} from "../../common/Confirmation";
import { CustomTab, CustomTabs } from "../../common/restyled-mui/CustomTabs";
import { ifDefined } from "../../utils/values";
import { modules } from "../../utils/moduleConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { searchIcon } from "../../assets/icons/fontawesome/searchIcon";
const sitesListWidth = 240;
const topbarHeight = 40;
const tabletSidebarWidth = 48;

const useStyles = makeStyles((theme) => ({
    childrenContainer: {
        marginLeft: (props) =>
            props.fullscreen || props.toggleable ? 0 : sitesListWidth,
        width: (props) =>
            props.fullscreen || props.toggleable
                ? "100%"
                : `calc(100% - ${sitesListWidth}px)`,
        minHeight: "100%",
        display: "flex",
    },
    title: {
        flexGrow: 1,
        fontWeight: 400,
    },
    appbarContainer: {
        top: 0,
        position: "sticky",
        width: "100%",
    },
    list: {
        backgroundColor: theme.palette.common.white,
        height: "100%",
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
        display: "flex",
        flexDirection: "column",
    },
    headerBar: {
        height: topbarHeight - theme.spacing(8),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(4),
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    searchBar: {
        height: 39 - theme.spacing(2),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 4),
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    searchIcon: {
        height: 16,
        width: 16,
        marginRight: theme.spacing(0.8),
        "& path": {
            fill: (props) =>
                props.searchField.length > 0
                    ? theme.palette.primary.main
                    : theme.palette.generalUi.icons,
        },
    },
    closeIconButton: {
        padding: theme.spacing(1),
    },
    closeIcon: {
        height: 16,
        width: 16,
        "& path": {
            fill: theme.palette.generalUi.lines,
        },
    },
    searchRoot: {
        height: 39,
    },
    searchInput: {
        fontSize: 14,
        color: "#383838",
        "&::placeholder": {
            opacity: 0.5,
            fontSize: 14,
            color: "#383838",
            fontWeight: 400,
        },
    },
    tabsBar: {
        height: 24,
        backgroundColor: theme.palette.generalUi.panelBackground,
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
    },
    tabPanel: {
        overflowY: "auto",
        flex: 1,
        display: "flex",
        minHeight: 0,
    },
    listContainer: {
        position: "relative",
        flex: 1,
    },
    row: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    rowText: {
        color: theme.palette.text.default,
        fontSize: 13,
        lineHeight: 1.3,
    },
    rowSubtext: {
        color: theme.palette.text.defaultSub,
        fontSize: 9,
        lineHeight: 1.3,
        "&:last-child": {
            padding: theme.spacing(0, 0, 0, 2),
        },
    },
    rowSubtextContainer: {
        width: "100%",
        display: "flex",
    },
    drawer: {
        marginLeft: (props) => (props.toggleable ? tabletSidebarWidth - 1 : 0),
        borderRight: `${theme.palette.menu.border} 1px solid`,
        width: (props) => (props.fullscreen ? "100%" : sitesListWidth),
    },
    menu: {
        display: "flex",
        flexDirection: "column",
        width: tabletSidebarWidth,
        zIndex: 1400,
        backgroundColor: theme.palette.menu.bg,
        borderRight: `${theme.palette.menu.border} 1px solid`,
    },
    menuItem: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(1, 3),
    },
    menuItemIcon: {
        textAlign: "center",
        minWidth: "100%",
    },
    menuIcon: {
        color: theme.palette.menu.icons,
    },
    selectedMenuIcon: {
        color: theme.palette.primary.main,
    },
}));

const mapStateToProps = (state, ownProps) => {
    return {
        sites: state.sites,
        favoriteSites: state.favoriteSites,
        sitesView: state.sitesView,
        searchedSites: state.searchedSites,
        searchField: state.searchField,
        user: state.user,
        config: state.config,
    };
};

const SitesPanel = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();

    const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialog);
    const [configRead, setConfigRead] = useState();
    const [open, setOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState(
        parseInt(props.selectedSite)
    );
    const {
        sites,
        favoriteSites,
        searchedSites,
        searchSites,
        setSearchField,
        searchField,
        push,
        sitesView,
        getSites,
        getFavoriteSites,
        clearFavoriteSites,
        toggleSitesView,
        removeFavoriteSite,
        addFavoriteSite,
        user,
        config,
        getConfig,
        updateConfig,
        container,
        toggleable,
    } = props;

    useEffect(() => {
        if (config && !configRead) {
            ifDefined(
                getConfig(
                    `${config.username}_sitespanel_sitesview`,
                    undefined,
                    true
                ),
                (value) => toggleSitesView(parseInt(value))
            );
            setConfigRead(true);
        }
    }, [config, configRead, getConfig, toggleSitesView]);

    useEffect(() => {
        if (!sites) {
            getSites();
        }
    }, [sites, getSites, toggleSitesView]);

    useEffect(() => {
        if (favoriteSites) {
            if (favoriteSites.length === 0) {
                toggleSitesView(1);
            }
        }
    }, [favoriteSites, toggleSitesView]);

    useEffect(() => {
        if (
            typeof user !== "undefined" &&
            typeof user.isMultiUser !== "undefined" &&
            !favoriteSites
        ) {
            if (!user.isMultiUser) {
                getFavoriteSites();
            } else {
                clearFavoriteSites();
                toggleSitesView(1);
            }
        }
    }, [
        user,
        favoriteSites,
        getFavoriteSites,
        clearFavoriteSites,
        toggleSitesView,
    ]);

    const handleChange = (event, newValue) => {
        updateConfig(`${config.username}_sitespanel_sitesview`, newValue, true);
        toggleSitesView(newValue);
    };

    const isFavorite = (id) => {
        return (
            !user.isMultiUser &&
            Boolean(favoriteSites?.find((site) => site.id === id))
        );
    };

    const toggleFavoriteSite = (e, site) => {
        e.preventDefault();
        e.stopPropagation();
        if (isFavorite(site.id)) {
            setConfirmDialog({
                open: true,
                confirmCallback: () => {
                    removeFavoriteSite(site.id);
                    setConfirmDialog(initialConfirmDialog);
                },
                cancelCallback: () => setConfirmDialog(initialConfirmDialog),
                title: "Remove Favourite",
                content: (
                    <Typography>
                        Are you sure you want to remove <b>{site.name}</b> as a
                        favourite?
                    </Typography>
                ),
                color: theme.palette.error.main,
            });
        } else {
            addFavoriteSite(site.id);
        }
    };

    const onSearchChange = (e) => {
        setSearchField(e.target.value);
        searchSites(e.target.value);
    };

    const emptySearchField = () => {
        setSearchField("");
        searchSites("");
    };

    const findSiteList = () => {
        if (searchedSites) {
            return searchedSites;
        } else {
            switch (sitesView) {
                case 0:
                    return favoriteSites;
                case 1:
                    return sites;
                default:
                    return [];
            }
        }
    };

    const findSitesView = () => {
        if (searchedSites) {
            return false;
        } else {
            return sitesView;
        }
    };

    const toggleOpen = () => {
        setOpen(!open);
    };
    const siteSelected = (id) => {
        setSelectedSite(id);
        push(`${modules.forecasts.url}/site/${id}`);
    };

    return (
        <>
            <ConfirmationDialog {...confirmDialog} />
            {toggleable && (
                <List className={classes.menu}>
                    <ListItem
                        className={classes.menuItem}
                        button
                        onClick={toggleOpen}
                    >
                        <ListItemIcon className={classes.menuItemIcon}>
                            <FontAwesomeIcon
                                icon={searchIcon}
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    open && classes.selectedMenuIcon
                                )}
                            />
                        </ListItemIcon>
                    </ListItem>
                </List>
            )}
            <Drawer
                open={toggleable ? open : true}
                variant={toggleable ? "persistent" : "permanent"}
                anchor={"left"}
                ModalProps={{
                    keepMounted: true,
                    container: container.current,
                    style: { position: "absolute" },
                }}
                PaperProps={{
                    style: { position: "absolute" },
                    className: classes.drawerPaper,
                }}
                BackdropProps={{ style: { position: "absolute" } }}
                onClose={toggleOpen}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.list}>
                    <div className={classes.searchBar}>
                        <TextField
                            placeholder="Search..."
                            value={searchField}
                            onChange={onSearchChange}
                            InputProps={{
                                classes: {
                                    root: classes.searchRoot,
                                    input: classes.searchInput,
                                },
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <UIIcons.SearchIcon
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    searchField.length > 0 ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={
                                                    classes.closeIconButton
                                                }
                                                onClick={emptySearchField}
                                            >
                                                <UIIcons.CloseIcon
                                                    className={
                                                        classes.closeIcon
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                            }}
                        />
                    </div>
                    {user.isMultiUser === false && (
                        <div className={classes.tabsBar}>
                            <CustomTabs
                                value={findSitesView()}
                                onChange={handleChange}
                                variant="fullWidth"
                            >
                                <CustomTab label="Favorites" value={0} />
                                <CustomTab label="All" value={1} />
                            </CustomTabs>
                        </div>
                    )}
                    <SitesList
                        sites={findSiteList()}
                        toggleFavorite={
                            user.isMultiUser === false
                                ? toggleFavoriteSite
                                : undefined
                        }
                        isFavorite={isFavorite}
                        siteSelected={siteSelected}
                        selectedSite={selectedSite}
                    />
                </div>
            </Drawer>
            <div className={classes.childrenContainer}>{props.children}</div>
        </>
    );
};

export default connect(mapStateToProps, {
    getSites,
    getFavoriteSites,
    clearFavoriteSites,
    searchSites,
    setSearchField,
    toggleSitesView,
    push,
    removeFavoriteSite,
    addFavoriteSite,
    getConfig,
    updateConfig,
})(SitesPanel);
