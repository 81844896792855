import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { FormControl, Grid, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
    CustomTextField,
    CustomInputLabel,
    textFieldNoArrowStyles,
} from "../../common/restyled-mui/CustomInputs";
import ParameterFormTable from "./form-inputs/ParameterFormTable";
import MonthCheckboxes from "./form-inputs/MonthCheckboxes";
import { getSettingsStyles } from "../../styles/settingsStyles";
import WorkingDaysSelection from "./form-inputs/WorkingDaysSelection";
import WindowSelection from "./form-inputs/WindowSelection";
import AnalysisHeights from "./form-inputs/AnalysisHeights";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    formContainer: {
        padding: theme.spacing(4),
        overflowY: "auto",
    },
    checkboxControl: {
        width: 80,
    },
    customSubtitle: {
        fontSize: 14,
    },
    smallMarginTextField: {
        marginBottom: 0,
    },
    inputNumber: {
        ...textFieldNoArrowStyles,
    },
}));

const AnalyticsForm = (props) => {
    const classes = useStyles(props);
    const {
        values,
        handleChangeValues,
        sites,
        parameters,
        analyticsHeights,
        validation,
        handleValidationUpdate,
    } = props;

    const handleSiteChange = (e, value) => {
        handleChangeValues((oldValues) => ({
            ...oldValues,
            site: value,
        }));
        handleValidationUpdate("site");
    };

    const handleValueAttributeChange = (name, value) => {
        handleChangeValues((oldValues) => ({
            ...oldValues,
            [name]: value,
        }));
        handleValidationUpdate(name);
    };

    const handleTextFieldChange = (name) => (e) => {
        e.persist();
        handleValueAttributeChange(name, e.target.value);
        handleValidationUpdate(name);
    };

    const InputPropsNumber = {
        classes: {
            input: classes.inputNumber,
        },
    };

    return (
        <div className={classes.formContainer}>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <CustomInputLabel>Site</CustomInputLabel>
                    <FormControl
                        fullWidth
                        className={classes.smallMarginTextField}
                        error={!validation.site.valid}
                    >
                        <Autocomplete
                            options={sites.map((x) => ({
                                id: x.id,
                                name: x.name,
                            }))}
                            classes={{
                                option: classes.option,
                            }}
                            autoHighlight
                            value={values.site}
                            getOptionSelected={(option, value) =>
                                option.id === value.id
                            }
                            onChange={handleSiteChange}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => {
                                return (
                                    <CustomTextField
                                        {...params}
                                        name={"site"}
                                        error={!validation.site.valid}
                                        helperText={validation.site.message}
                                        placeholder={"Search and select..."}
                                        InputProps={{
                                            ...params.InputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid xs={12} item>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.formSubtitle,
                            classes.customSubtitle
                        )}
                    >
                        Months
                    </Typography>
                    <MonthCheckboxes
                        months={values.months}
                        handleMonthChange={(value) =>
                            handleValueAttributeChange("months", value)
                        }
                        error={!validation.months.valid}
                        helperText={validation.months.message}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.formSubtitle,
                            classes.customSubtitle
                        )}
                    >
                        Work Window
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <CustomInputLabel>
                                Analysis Height Level (m)
                            </CustomInputLabel>
                            <AnalysisHeights
                                value={values.analysisLevelMeters}
                                analyticsHeights={analyticsHeights}
                                handleChange={(value) =>
                                    handleValueAttributeChange(
                                        "analysisLevelMeters",
                                        value
                                    )
                                }
                                error={!validation.analysisLevelMeters.valid}
                                helperText={
                                    validation.analysisLevelMeters.message
                                }
                            />
                            <CustomInputLabel>Working days</CustomInputLabel>
                            <WorkingDaysSelection
                                values={values.workingDays}
                                handleChangeValues={(value) =>
                                    handleValueAttributeChange(
                                        "workingDays",
                                        value
                                    )
                                }
                                error={!validation.analysisLevelMeters.valid}
                                helperText={
                                    validation.analysisLevelMeters.message
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomInputLabel>
                                Percentiles (1-99)
                            </CustomInputLabel>
                            <CustomTextField
                                name={"percentiles"}
                                type="number"
                                InputProps={InputPropsNumber}
                                value={values.percentiles}
                                onChange={handleTextFieldChange("percentiles")}
                                error={!validation.percentiles.valid}
                                helperText={validation.percentiles.message}
                            />
                            <CustomInputLabel>
                                Window Length (hours)
                            </CustomInputLabel>
                            <CustomTextField
                                name={"Window Length"}
                                type="number"
                                InputProps={InputPropsNumber}
                                value={values.windowLengthHours}
                                onChange={handleTextFieldChange(
                                    "windowLengthHours"
                                )}
                                error={!validation.windowLengthHours.valid}
                                helperText={
                                    validation.windowLengthHours.message
                                }
                            />
                        </Grid>
                    </Grid>
                    <CustomInputLabel>Work Window</CustomInputLabel>
                    <WindowSelection
                        handleChangeValues={handleChangeValues}
                        handleValidationUpdate={handleValidationUpdate}
                        value={values.windowSelection}
                        error={!validation.windowSelection.valid}
                        helperText={validation.windowSelection.message}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.formSubtitle,
                            classes.customSubtitle
                        )}
                    >
                        Parameters
                    </Typography>
                    <ParameterFormTable
                        parameters={parameters}
                        selectedParameters={values.weatherParameters}
                        updateSelected={(value) =>
                            handleValueAttributeChange(
                                "weatherParameters",
                                value
                            )
                        }
                        error={!validation.weatherParameters.valid}
                        helperText={validation.weatherParameters.message}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AnalyticsForm;
