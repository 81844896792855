import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { SecondaryButton } from "../../common/restyled-mui/CustomButton";
import { deleteIcon } from "../../assets/icons/fontawesome/deleteIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    expireAllContainer: {
        padding: theme.spacing(3, 10),
        borderTop: `1px solid ${theme.palette.menu.border}`,
        backgroundColor: theme.palette.table.cellBg,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
    deleteIcon: {
        height: 18,
        width: 16,
        marginRight: theme.spacing(1.5),
    },
}));

const AlertsPanelExpireAll = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const { handleExpire, setConfirmation, resetConfirmation } = props;

    const handleOnClick = () => {
        setConfirmation({
            open: true,
            confirmCallback: () => {
                handleExpire();
                resetConfirmation();
            },
            cancelCallback: () => resetConfirmation(),
            title: "Delete Expired Alerts",
            content: (
                <Typography>
                    Are you sure you want to delete <b>all expired alerts</b>?
                </Typography>
            ),
            color: theme.palette.error.main,
            type: "warning",
        });
    };

    return (
        <div className={classes.expireAllContainer}>
            <SecondaryButton className={classes.button} onClick={handleOnClick}>
                <FontAwesomeIcon
                    icon={deleteIcon}
                    className={classes.deleteIcon}
                />
                Delete Expired
            </SecondaryButton>
        </div>
    );
};

export default AlertsPanelExpireAll;
