import { MAP_STATE } from "../types";

export function setMapState(payload) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.config) {
            dispatch({ type: MAP_STATE.SET, payload: payload });
        }
    };
}
