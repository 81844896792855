import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    CircularProgress,
    Grid,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import {
    CustomInputLabel,
    CustomTextField,
} from "../../common/restyled-mui/CustomInputs";
import { CustomCheckbox } from "../../common/restyled-mui/CustomCheckBox";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import ConfirmationDialog from "../../common/Confirmation";
import {
    getSitesList,
    updateSite,
    createSite,
    deleteSite,
} from "../../redux/actions/settings";
import { push } from "connected-react-router";
import Navigation from "../settings-navigation/Navigation";
import { getSettingsStyles } from "../../styles/settingsStyles";
import { moduleIsAvailable, modules } from "../../utils/moduleConfig";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
}));

const mapStateToProps = (state, ownProps) => ({
    sites: state.sitesSettings,
    apiLoading: state.status.loading,
    apiStatusOk: state.status.snackbar.ok,
    availableModules: state.customer.config.modules,
    permissions: state.user.permissions,
});

const isVestas =
    "vestas" === `${process.env.REACT_APP_DEPLOYMENT_CUSTOMER}`.toLowerCase();

const Site = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState(true);
    const [site, setSite] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const {
        sites,
        getSitesList,
        updateSite,
        createSite,
        deleteSite,
        match,
        push,
        apiLoading,
        apiStatusOk,
        creating,
        availableModules,
        permissions,
    } = props;
    const initialConfirmDialog = {
        open: false,
        confirmCallback: null,
        cancelCallback: null,
        title: "",
        content: "",
        color: "",
    };
    const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialog);
    const [orgLatLong, setOrgLatLong] = useState([0, 0]);
    const [latitudeError, setLatitudeError] = useState();
    const [longitudeError, setLongitudeError] = useState();

    useEffect(() => {
        if (!sites) {
            getSitesList();
        }
    }, [getSitesList, sites]);

    useEffect(() => {
        if (sites) {
            if (creating) {
                setSite({
                    customerId: "",
                    name: "",
                    description: "",
                    isFavorite: false,
                    isOffshore: false,
                    markAsOffshoreIfAvailable: false,
                    includeHistoricData: false,
                    longitude: 0,
                    latitude: 0,
                });
                setOrgLatLong([0, 0]);
                setLoading(false);
            } else {
                const id = parseInt(match.params.id);
                const site = sites.find((x) => x.id === id);
                if (site) {
                    setOrgLatLong([site.latitude, site.longitude]);
                    site.markAsOffshoreIfAvailable = site.isOffshore;
                    setSite(site);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        }
    }, [sites, match.params.id, creating]);

    useEffect(() => {
        if (!apiLoading && apiStatusOk && submitted) {
            push(`/settings/sites`);
            setSubmitted(false);
        }
    }, [apiLoading, submitted, apiStatusOk, push]);

    useEffect(() => {
        if (site?.latitude < -90 || site?.latitude > 90) {
            setLatitudeError("Latitude must be between -90 and +90");
        } else {
            setLatitudeError();
        }
        if (site?.longitude < -180 || site?.longitude > 180) {
            setLongitudeError("Longitude must be between -180 and +180");
        } else {
            setLongitudeError();
        }
    }, [site, setLatitudeError, setLongitudeError]);

    const handleTextInputChange = (state) => (e) => {
        setSite({
            ...site,
            [state]: e.target.value,
        });
    };

    const handleCheckBoxChange = (state) => (e) => {
        setSite({
            ...site,
            [state]: e.target.checked,
        });
    };

    const handleSubmit = () => {
        updateSite(site);
        if (
            site.latitude !== orgLatLong[0] ||
            site.longitude !== orgLatLong[1]
        ) {
            setConfirmDialog({
                open: true,
                confirmCallback: () => {
                    setConfirmDialog(initialConfirmDialog);
                    setSubmitted(true);
                },
                title: "Position was changed",
                content: (
                    <Typography>
                        You have changed latitude/longitude, which will reflect
                        the forecasts. Be aware, that it can take up to 6 hours
                        before the right forecasts are available.
                    </Typography>
                ),
                color: theme.palette.error.main,
                type: "information",
            });
        } else {
            setSubmitted(true);
        }
    };

    const handleCreate = () => {
        createSite(site);
        setSubmitted(true);
    };

    const handleCancel = () => {
        push(`/settings/sites`);
    };

    const handleDelete = () => {
        setConfirmDialog({
            open: true,
            confirmCallback: () => {
                deleteTheSite(site.id);
                setConfirmDialog(initialConfirmDialog);
            },
            cancelCallback: () => setConfirmDialog(initialConfirmDialog),
            title: "Delete Site",
            content: (
                <Typography>
                    Are you sure you want to delete <b>{site.name}</b>?
                </Typography>
            ),
            color: theme.palette.error.main,
            type: "warning",
        });
    };

    const deleteTheSite = (id) => {
        deleteSite(id);
        setSubmitted(true);
    };

    const renderActionButtons = () => {
        return (
            <div className={classes.actionButtons}>
                {permissions.includes("Admin.ManageSites.Delete") && !creating && (
                    <CustomButton
                        onClick={handleDelete}
                        disabled={apiLoading}
                        variant="contained"
                        disableElevation
                        className={classes.deleteButton}
                    >
                        Delete
                    </CustomButton>
                )}
                <CustomButton
                    onClick={handleCancel}
                    disabled={apiLoading}
                    variant="contained"
                    disableElevation
                    className={classes.cancelButton}
                >
                    Cancel
                </CustomButton>
                {((creating &&
                    permissions.includes("Admin.ManageSites.Create")) ||
                    permissions.includes("Admin.ManageSites.Modify")) && (
                    <CustomButton
                        onClick={creating ? handleCreate : handleSubmit}
                        disabled={apiLoading}
                        variant="contained"
                        disableElevation
                        color="primary"
                        className={classes.submitButton}
                    >
                        {apiLoading ? <CircularProgress size={14} /> : "Submit"}
                    </CustomButton>
                )}
            </div>
        );
    };

    const renderForm = () => {
        return (
            <Grid container spacing={4} className={classes.formContainer}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography
                            variant="h4"
                            className={classes.formSubtitle}
                        >
                            Details
                        </Typography>
                        <CustomInputLabel>Name</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="text"
                            onChange={handleTextInputChange("name")}
                            value={site.name}
                        />
                        <CustomInputLabel>Description</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="text"
                            onChange={handleTextInputChange("description")}
                            value={site.description}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography
                            variant="h4"
                            className={classes.formSubtitle}
                        >
                            Location [Degrees with decimal point]
                        </Typography>
                        <CustomInputLabel>Latitude [North]</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            onChange={handleTextInputChange("latitude")}
                            value={site.latitude}
                            type="number"
                            error={latitudeError?.length > 0}
                            helperText={latitudeError}
                        />
                        <CustomInputLabel>Longitude [East]</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            onChange={handleTextInputChange("longitude")}
                            value={site.longitude}
                            type="number"
                            error={longitudeError?.length > 0}
                            helperText={longitudeError}
                        />
                        <CustomInputLabel>Display as offshore</CustomInputLabel>
                        <CustomCheckbox
                            variant="outlined"
                            margin="dense"
                            onChange={handleCheckBoxChange(
                                "markAsOffshoreIfAvailable"
                            )}
                            checked={site.markAsOffshoreIfAvailable}
                        />
                        {moduleIsAvailable(
                            modules.hwwa.id,
                            availableModules,
                            permissions
                        ) && (
                            <div>
                                <CustomInputLabel>
                                    Include Historic Data
                                </CustomInputLabel>
                                <CustomCheckbox
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleCheckBoxChange(
                                        "includeHistoricData"
                                    )}
                                    checked={site.includeHistoricData}
                                />
                            </div>
                        )}
                        {isVestas && (
                            <div>
                                <CustomInputLabel>
                                    Identifier Mapping
                                </CustomInputLabel>
                                <CustomTextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    onChange={handleTextInputChange(
                                        "identifierMapping"
                                    )}
                                    value={site.identifierMapping}
                                    type="number"
                                    error={longitudeError?.length > 0}
                                    helperText={longitudeError}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Navigation push={push}>
            {loading ? (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            ) : site ? (
                <>
                    <ConfirmationDialog {...confirmDialog} />
                    <div className={classes.headerContainer}>
                        <div className={classes.titleContainer}>
                            <Typography variant="h2" className={classes.title}>
                                {creating ? "New Site" : site.name}
                            </Typography>
                        </div>
                        {!smallScreen && renderActionButtons()}
                    </div>
                    {renderForm()}
                    {smallScreen && renderActionButtons()}
                </>
            ) : (
                <Typography variant="h2">Site not found</Typography>
            )}
        </Navigation>
    );
};

export default connect(mapStateToProps, {
    getSitesList,
    updateSite,
    createSite,
    deleteSite,
    push,
})(Site);
