import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    Toolbar,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { modules } from "../../utils/moduleConfig";

const maxDrawerWidth = 260;
const minDrawerWidth = 48;
export const menuOverlayBreakpointDown = "md";
export const menuPushBreakpointUp = "lg";
export const topbarHeight = 48;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    main: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
    content: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        position: "relative",
        minHeight: `calc(100% - ${topbarHeight}px)`,
        zIndex: 0,
        overflow: "auto",
    },
    toolbar: {
        height: topbarHeight,
        minHeight: topbarHeight,
    },
}));

const Navigation = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const location = useLocation();
    const menuOverlay = useMediaQuery(
        theme.breakpoints.down(menuOverlayBreakpointDown)
    );
    const [open, setOpen] = useState(false);
    const { logout, logoHidden, username, navContainerRef, availableModules } =
        props;

    const handleDrawerChange = () => {
        setOpen(!open);
    };

    const menuLink = (path) => {
        dispatch(push(path));
        if (menuOverlay) {
            handleDrawerChange();
        }
    };

    const routes = {
        wiki: -1,
        none: 0,
        settings: 1,
        forecasts: 2,
        alerts: 3,
        hwwa: 4,
        reports: 5,
        hindcasts: 6,
        forecastui: 7,
    };

    const selectedMenuItem = () => {
        switch (true) {
            case location.pathname.includes(modules.wiki.url):
                return routes.wiki;
            case location.pathname.includes(modules.settings.url):
                return routes.settings;
            case location.pathname.includes(modules.forecasts.url):
                return routes[modules.forecasts.id];
            case location.pathname.includes(modules.alerts.url):
                return routes[modules.alerts.id];
            case location.pathname.includes(modules.hwwa.url):
                return routes[modules.hwwa.id];
            case location.pathname.includes(modules.reports.url):
                return routes[modules.reports.id];
            case location.pathname.includes(modules.hindcasts.url):
                return routes[modules.hindcasts.id];
            case location.pathname.includes(modules.forecastui.url):
                return routes[modules.forecastui.id];
            default:
                return routes.none;
        }
    };

    const renderTitleText = () => {
        switch (selectedMenuItem()) {
            case routes.wiki:
                return modules.wiki.title;
            case routes.settings:
                return modules.settings.title;
            case routes.forecasts:
                return modules.forecasts.title;
            case routes.alerts:
                return modules.alerts.title;
            case routes.hwwa:
                return modules.hwwa.title;
            case routes.reports:
                return modules.reports.title;
            case routes.hindcasts:
                return modules.hindcasts.title;
            case routes.forecastui:
                return modules.forecastui.title;
            default:
                return "";
        }
    };

    return (
        <div className={classes.root}>
            <Topbar
                open={open}
                handleDrawerChange={handleDrawerChange}
                topbarHeight={topbarHeight}
                minDrawerWidth={minDrawerWidth}
                maxDrawerWidth={maxDrawerWidth}
                logout={logout}
                logoHidden={logoHidden}
                menuOverlay={menuOverlay}
                username={username}
                text={renderTitleText()}
            />
            <Sidebar
                open={open}
                topbarHeight={topbarHeight}
                goTo={menuLink}
                handleDrawerChange={handleDrawerChange}
                minDrawerWidth={minDrawerWidth}
                maxDrawerWidth={maxDrawerWidth}
                menuOverlay={menuOverlay}
                selectedMenuItem={selectedMenuItem}
                routes={routes}
                availableModules={availableModules}
            />
            <main className={classes.main}>
                <Toolbar className={classes.toolbar} />
                <div ref={navContainerRef} className={classes.content}>
                    {props.children}
                </div>
            </main>
        </div>
    );
};

export default Navigation;
