import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UIIcons from "../../assets/icons/ui-icons";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: theme.palette.generalUi.background,
        overflow: "auto",
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        flex: "0 1 240px",
    },
    drawerPaper: {
        left: "auto",
        top: "auto",
        width: "100%",
        backgroundColor: "transparent",
        position: "unset",
        flex: "1 1 240px",
    },
    drawerContainer: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        minWidth: "100%",
        minHeight: "100%",
    },
    drawerContainerFlex: {
        display: "flex",
    },
    title: {
        margin: "32px 24px 6px 24px",
    },
    content: {
        padding: theme.spacing(4, 8, 0, 8),
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        flex: "1 1 800px",
        "@media (max-width: 960px)": {
            padding: theme.spacing(4, 2, 0, 2),
        },
    },
    menuIcon: {
        height: 25,
        margin: "auto",
        width: 25,
        "& path": {
            fill: theme.palette.generalUi.icons,
        },
    },
    row: {
        "&:hover": {
            backgroundColor: theme.palette.secondMenuItem.hoverBackground,
        },
    },
    selected: {
        backgroundColor: theme.palette.secondMenuItem.selectedBackground,
    },
    listItemText: {
        paddingLeft: theme.spacing(2),
    },
}));

const mapStateToProps = (state) => ({
    permissions: state.user.permissions,
});

const Navigation = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { push, location, permissions } = props;
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const pages = [
        {
            path: `/settings/sites`,
            name: "Sites",
            Icon: UIIcons.SensorIcon,
            enabled: permissions.includes("Admin.ManageSites"),
        },
        {
            path: `/settings/users`,
            name: "Users",
            Icon: UIIcons.UserIcon,
            enabled: permissions.includes("Admin.ManageUsers"),
        },
        {
            path: `/settings/access`,
            name: "Manage Access to Sites",
            Icon: UIIcons.MapIcon,
            enabled: permissions.includes("Admin.ManageAccessSites"),
        },
    ];

    return (
        <div className={classes.root}>
            <main
                className={clsx(
                    classes.drawerContainer,
                    !smallScreen && classes.drawerContainerFlex
                )}
            >
                <Drawer
                    className={classes.drawer}
                    variant={"permanent"}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <>
                        <Typography variant="h6" className={classes.title}>
                            Settings
                        </Typography>
                        <List className={classes.subMenu}>
                            {pages.map((page) => {
                                const { path, name } = page;
                                const selected = Boolean(
                                    location.pathname.includes(path)
                                );
                                return (
                                    page.enabled && (
                                        <ListItem
                                            key={path}
                                            button={!selected}
                                            className={
                                                selected
                                                    ? classes.selected
                                                    : classes.row
                                            }
                                            onClick={() => push(path)}
                                        >
                                            <ListItemText
                                                primary={name}
                                                className={classes.listItemText}
                                            />
                                        </ListItem>
                                    )
                                );
                            })}
                        </List>
                    </>
                </Drawer>
                <div className={classes.content}>{props.children}</div>
            </main>
        </div>
    );
};

export default connect(mapStateToProps, null)(withRouter(Navigation));
