import createIcon from "./utils";

const mapIcon = createIcon({
    iconType: "map-icon",
    path: "M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM30.63 118.18L176 67.61V387.8L31.91 441.05l-1.28-322.87zM208 387.71V67.8l160 56.48v319.91l-160-56.48zm192 56.68V124.2l144.09-53.26 1.28 322.87L400 444.39z",
    width: 576,
    height: 512,
});

const mapIconSelected = createIcon({
    iconType: "map-icon-selected",
    path: "M0 117.66v346.32c0 11.32 11.43 19.06 21.94 14.86L160 416V32L20.12 87.95A32.006 32.006 0 0 0 0 117.66zM192 416l192 64V96L192 32v384zM554.06 33.16L416 96v384l139.88-55.95A31.996 31.996 0 0 0 576 394.34V48.02c0-11.32-11.43-19.06-21.94-14.86z",
    width: 576,
    height: 512,
});

export { mapIcon, mapIconSelected };
