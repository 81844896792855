import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SecondaryButton } from "../../common/restyled-mui/CustomButton";

import HindcastsTable from "./HindcastsTable";

import FileDownload from "js-file-download";

import CenteredLoading from "../../common/CenteredLoading";
import { List, ListItemText, ListSubheader } from "@material-ui/core";

import { getSettingsStyles } from "../../styles/settingsStyles";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    formContainer: {
        padding: theme.spacing(4),
        overflowY: "auto",
    },

    customSubtitle: {
        fontSize: 14,
    },
    smallMarginTextField: {
        marginBottom: 0,
    },

    loading: {
        loading: {
            position: "relative",
            marginTop: "50%",
            marginLeft: "50%",
        },
    },
    downloadDataButton: {
        width: "175px",
    },
}));

const HindcastsTablePanel = (props) => {
    const { data, loading, failedParameters } = props;
    const classes = useStyles(props);
    const [selectedPercentile, setSelectedPercentile] = useState("None");

    const replacer = (key, value) => (value === null ? "" : value);

    const generateAnalysisCsv = (data, selectedPercentile) => {
        //let header = Object.keys(data[0]);
        let header = [
            "Year",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Average",
        ];
        header =
            selectedPercentile !== "None"
                ? header.concat(selectedPercentile)
                : header;
        const csv = [
            header.join(","),
            ...data
                .filter(
                    (item) =>
                        item.Year.toString().indexOf("P") < 0 ||
                        item.Year.toString() === selectedPercentile
                )
                .map((row) =>
                    header
                        .map((fieldName) =>
                            JSON.stringify(row[fieldName], replacer)
                        )
                        .join(",")
                ),
        ].join("\r\n");
        return csv;
    };

    const downloadAnalysisData = () => {
        FileDownload(
            generateAnalysisCsv(data, selectedPercentile),
            "hindcast_analysis_results.csv"
        );
    };

    return !loading ? (
        <>
            <div
                style={{
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "row",
                    backgroundColor: "white",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    height: "fit-content",
                    maxHeight: "800px",
                }}
            >
                <HindcastsTable
                    data={data}
                    selectedPercentile={selectedPercentile}
                    setSelectedPercentile={setSelectedPercentile}
                ></HindcastsTable>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginRight: "120px",
                }}
            >
                <div>
                    {failedParameters.length > 0 && (
                        <div>
                            <List
                                dense
                                disablePadding
                                subheader={
                                    <ListSubheader
                                        style={{ color: "red" }}
                                        disablePadding
                                    >
                                        No data was available for following
                                        parameters:
                                    </ListSubheader>
                                }
                            >
                                {failedParameters.map((fp) => {
                                    return (
                                        <ListItemText
                                            primary={fp}
                                            style={{ marginLeft: 32 }}
                                        ></ListItemText>
                                    );
                                })}
                            </List>
                        </div>
                    )}
                </div>
                <SecondaryButton
                    onClick={downloadAnalysisData}
                    className={classes.downloadDataButton}
                >
                    Download Analysis
                </SecondaryButton>
            </div>
        </>
    ) : (
        <CenteredLoading style={{ position: "relative" }} />
    );
};

export default HindcastsTablePanel;
