import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomCheckbox = withStyles((theme) => ({
    root: {
        color: "#e0e0e0",
        "&$checked": {
            color: "#6f6f6f",
        },
        "&:hover": {
            color: "#1C71AD",
        },
        height: 30,
        margin: 5,
        padding: 5,
    },
    checked: {},
}))(Checkbox);
