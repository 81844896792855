import React from "react";
/* global google */
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { InfoBox } from "@react-google-maps/api";
import { CircularProgress, Typography, Checkbox } from "@material-ui/core";
import {
    SimpleTableContainer,
    SimpleTable,
    SimpleTableHead,
    SimpleTableRow,
    SimpleTableCell,
    SimpleTableBody,
} from "../../common/restyled-mui/SimpleTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    mapPinIcon,
    mapUnpinIcon,
} from "../../assets/icons/fontawesome/mapPinIcons";

const width = 420;

const useStyles = makeStyles((theme) => ({
    popup: {
        width: width,
        color: theme.palette.common.white,
        transition: `opacity 200ms ease-in-out`,
        //override gmaps default to add quote triangle
        overflow: "visible!important",
        cursor: "pointer",
        "&::after": {
            backgroundColor: theme.palette.common.white,
            content: '""',
            display: "block",
            borderRadius: theme.spacing(1.25),
            height: 20,
            width: 20,
            left: `calc(50% - 10px)`,
            position: "absolute",
            bottom: -7,
            transform: "rotate(47deg) skew(5deg) ",
        },
    },
    popupContent: {
        flex: 1,
        borderRadius: theme.spacing(1.25),
        backgroundColor: theme.palette.common.white,
        overflow: "hidden",
        boxShadow: "0px 4px 4px #00000029",
    },
    popupTextContainer: {
        position: "relative",
        padding: theme.spacing(1.75, 2.75),
    },
    popupText: {
        fontSize: 16,
        fontWeight: 600,
        padding: 0,
        margin: 0,
        lineHeight: 1.25,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
    },
    popupSubtext: {
        fontSize: 10,
        margin: 0,
        padding: theme.spacing(0.25, 3, 0, 0),
        lineHeight: 1.4,
        fontWeight: 500,
        textTransform: "uppercase",
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        display: "inline-block",
        "&:last-child": {
            padding: theme.spacing(0),
        },
    },

    popupPin: {
        top: 0,
        right: 0,
        position: "absolute",
        fontSize: 16,
        color: `${theme.palette.common.white}!important`,
    },

    noForecastsText: {
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamily,
        margin: `0 0 ${theme.spacing(3)}px 0`,
    },
    popupBattery: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    popupIcon: {
        height: theme.spacing(7),
        width: theme.spacing(7),
    },
    popupTable: {
        padding: theme.spacing(1.25, 3, 3, 3),
    },
    batteryIcon: {
        height: theme.spacing(7),
        width: theme.spacing(7),
        marginTop: -theme.spacing(1),
    },
    popupForecastsContainer: {
        paddingTop: theme.spacing(2),
    },
    popupForecastContainer: {
        position: "relative",
        padding: `${theme.spacing(2)}px ${theme.spacing(5)}px 0 0`,
        "&:last-child": {
            padding: `${theme.spacing(2)}px 0 0 0`,
        },
    },
    loading: {
        color: theme.palette.text.default,
    },
    loadingContainer: {
        margin: "auto",
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
}));

const SitePopup = (props) => {
    const classes = useStyles(props);
    const {
        popup,
        colorFromOffshore,
        initialLatLng,
        // onClose,
        onPin,
        onGetPinState,
        onClick,
        onMouseOver,
    } = props;
    const bgColor = popup.site
        ? colorFromOffshore(popup.site.isOffshore)
        : "#000000";
    const position = popup.site
        ? {
              lat: popup.site.latitude,
              lng: popup.site.longitude,
          }
        : initialLatLng;

    const renderMinMax = (value) => {
        switch (value) {
            case 1:
                return "Min.";
            case 2:
                return "Max.";
            default:
                return "";
        }
    };

    const renderName = (forecast) => {
        const minMax = renderMinMax(forecast.minMaxForecast);
        if (minMax) {
            return `${minMax} ${forecast.groupName} [${forecast.unit}]`;
        } else {
            return forecast.groupName;
        }
    };

    const renderPopupForecasts = (forecasts) => {
        if (forecasts) {
            if (forecasts.length > 0) {
                return (
                    <div className={classes.popupTable}>
                        <SimpleTableContainer>
                            <SimpleTable size="small">
                                <SimpleTableHead>
                                    <SimpleTableRow key="header">
                                        <SimpleTableCell>
                                            Parameter
                                        </SimpleTableCell>
                                        {forecasts[0].data.map((forecast) => {
                                            return (
                                                <SimpleTableCell
                                                    key={forecast.time}
                                                    align="right"
                                                >
                                                    {moment
                                                        .unix(forecast.time)
                                                        .local()
                                                        .format("MMM-DD")}
                                                </SimpleTableCell>
                                            );
                                        })}
                                    </SimpleTableRow>
                                </SimpleTableHead>
                                <SimpleTableBody>
                                    {forecasts.map((forecast) => {
                                        return (
                                            <SimpleTableRow
                                                key={forecast.groupId}
                                            >
                                                <SimpleTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {renderName(forecast)}
                                                </SimpleTableCell>
                                                {forecast.data.map(
                                                    (subForecast) => {
                                                        return (
                                                            <SimpleTableCell
                                                                key={
                                                                    subForecast.time
                                                                }
                                                                align="right"
                                                            >
                                                                {renderForecastValue(
                                                                    subForecast,
                                                                    forecast.decimals
                                                                )}
                                                            </SimpleTableCell>
                                                        );
                                                    }
                                                )}
                                            </SimpleTableRow>
                                        );
                                    })}
                                </SimpleTableBody>
                            </SimpleTable>
                        </SimpleTableContainer>
                    </div>
                );
            } else {
                return (
                    <Typography className={classes.noForecastsText}>
                        No forecasts found
                    </Typography>
                );
            }
        } else {
            return (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={16} className={classes.loading} />
                </div>
            );
        }
    };

    const renderForecastValue = (forecast, decimals) => {
        if (forecast) {
            return (((forecast.value + Number.EPSILON) * 100) / 100).toFixed(
                decimals
            );
        } else {
            return "-";
        }
    };

    const pinClicked = () => {
        const value = !popup.site.isPinned;
        onPin(value);
    };

    const isPinned = onGetPinState ? onGetPinState() : false;

    return (
        <InfoBox
            position={position}
            visible={popup.visible}
            options={{
                closeBoxURL: "",
                boxClass: classes.popup,
                boxStyle: {
                    opacity: popup.visible ? 1 : 0,
                    zIndex: isPinned ? "auto" : 1,
                },
                //x = -(width / 2)
                pixelOffset: new google.maps.Size(-width / 2, -20),
                alignBottom: true,
                fixedWidthSet: true,
                enableEventPropagation: true,
            }}
        >
            <>
                {popup.site && (
                    <div
                        onMouseEnter={() => onMouseOver(true)}
                        onMouseLeave={() => onMouseOver(false)}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick();
                        }}
                    >
                        <div className={classes.popupContent}>
                            <div
                                className={classes.popupTextContainer}
                                style={{ backgroundColor: bgColor }}
                            >
                                <Typography className={classes.popupText}>
                                    {popup.site.name}
                                </Typography>
                                <Typography
                                    className={classes.popupSubtext}
                                >{`LAT: ${popup.site.latitude}`}</Typography>
                                <Typography
                                    className={classes.popupSubtext}
                                >{`LON: ${popup.site.longitude}`}</Typography>
                                <Checkbox
                                    className={classes.popupPin}
                                    icon={
                                        <FontAwesomeIcon icon={mapUnpinIcon} />
                                    }
                                    checkedIcon={
                                        <FontAwesomeIcon icon={mapPinIcon} />
                                    }
                                    checked={isPinned}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        pinClicked();
                                    }}
                                />
                            </div>
                            {renderPopupForecasts(popup.forecasts)}
                        </div>
                    </div>
                )}
            </>
        </InfoBox>
    );
};

export default SitePopup;
