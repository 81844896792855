import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RadioGroup } from "@material-ui/core";
import {
    CustomFormControlLabel,
    CustomRadioButton,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    radioButtonGroup: {
        flexDirection: "row",
        alignItems: "center",
    },
    radioButtonsFormControl: {
        marginRight: theme.spacing(5.5),
    },
}));

const TimezoneRadio = (props) => {
    const classes = useStyles(props);
    const { value, handleChange } = props;

    return (
        <RadioGroup
            name="Time Zone"
            className={classes.radioButtonGroup}
            value={value}
            onChange={handleChange}
        >
            <CustomFormControlLabel
                value={"true"}
                control={
                    <CustomRadioButton variant="outlined" margin="dense" />
                }
                label={"UTC"}
                labelPlacement="end"
                className={classes.radioButtonsFormControl}
            />
            <CustomFormControlLabel
                value={"false"}
                control={
                    <CustomRadioButton variant="outlined" margin="dense" />
                }
                label={"Local Time"}
                labelPlacement="end"
                className={classes.radioButtonsFormControl}
            />
        </RadioGroup>
    );
};

export default TimezoneRadio;
