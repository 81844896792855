import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    TextField,
    FormHelperText,
    FormControl,
    Typography,
    IconButton,
} from "@material-ui/core";
import {
    CustomDataTable as Table,
    CustomDataTableBody as TableBody,
    CustomSmallDataTableCell as TableCell,
    CustomDataTableHead as TableHead,
    CustomDataTableRow as TableRow,
} from "../../../common/restyled-mui/CustomDataTable";
import ParameterSelectionPopover from "./ParameterSelectionPopover";
import {
    CustomCheckbox,
    textFieldNoArrowStyles,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import {
    TertiaryButton,
    SecondaryButton,
} from "../../../common/restyled-mui/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteIcon } from "../../../assets/icons/fontawesome/deleteIcon";
import { addIcon } from "../../../assets/icons/fontawesome/addIcon";
const widthDeleteCell = 18;

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    inputRoot: {
        height: "100%",
        backgroundColor: "transparent",
        fontSize: 13,
        color: theme.palette.text.default,
    },
    inputInput: {
        textAlign: "right",
        margin: 0,
        fontSize: 13,
        ...textFieldNoArrowStyles,
    },
    activeCell: {
        padding: 0,
    },
    smallActiveCheck: {
        padding: 0,
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
    },
    plusIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
    addButton: {
        width: 144,
    },
    invisibleCell: {
        border: "none",
        backgroundColor: "white",
    },
    deleteButton: {
        padding: theme.spacing(1),
    },
    clearTableButton: {
        marginRight: widthDeleteCell + theme.spacing(5),
    },
    helper: {
        fontSize: 12,
        padding: theme.spacing(2, 0),
        marginRight: widthDeleteCell + theme.spacing(5),
    },
}));

const ParameterFormTable = (props) => {
    const classes = useStyles(props);
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const {
        parameters,
        selectedParameters,
        updateSelected,
        error,
        helperText,
    } = props;
    const disabled = Boolean(!parameters.siteId);

    useEffect(() => {
        if (parameters.siteId) {
            const filteredSelection = selectedParameters.filter((x) =>
                parameters.list.some((y) => y.parameterId === x.id)
            );
            if (filteredSelection.length !== selectedParameters.length) {
                updateSelected(filteredSelection);
            }
        }
    }, [parameters, selectedParameters, updateSelected]);

    const textFieldProps = (name, index) => {
        return {
            // value:values[name],
            // onChange:changeValue(name, index),
            type: "number",
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                disableUnderline: true,
            },
        };
    };

    const addParameter = (parameter) => {
        updateSelected([
            ...selectedParameters,
            {
                id: parameter.parameterId,
                name: parameter.name,
                unit: parameter.unit,
                description: parameter.description,
                value: {
                    min: null,
                    max: null,
                },
                isActive: true,
            },
        ]);
        closeParameterSelection();
    };

    const deleteParameter = (index) => {
        const newParams = [...selectedParameters];
        newParams.splice(index, 1);
        updateSelected(newParams);
    };

    const openParameterSelection = (e) => {
        setPopoverAnchor(e.currentTarget);
    };

    const closeParameterSelection = () => {
        setPopoverAnchor(null);
    };

    const handleParameterValueChange = (name, index) => (e) => {
        let newParams = [...selectedParameters];
        newParams[index].value[name] = e.target.value * 1;
        updateSelected(newParams);
    };

    const toggleParameterActive = (index) => {
        const newParams = [...selectedParameters];
        newParams[index].isActive = !newParams[index].isActive;
        updateSelected(newParams);
    };

    const clearTable = () => {
        updateSelected(
            selectedParameters.map((parameter) => ({
                ...parameter,
                value: {
                    min: null,
                    max: null,
                },
                isActive: false,
            }))
        );
    };

    return (
        <FormControl error={error}>
            <ParameterSelectionPopover
                anchor={popoverAnchor}
                open={Boolean(popoverAnchor)}
                handleClose={closeParameterSelection}
                onClick={addParameter}
                parameters={parameters.list.filter(
                    (x) =>
                        !selectedParameters.some((y) => y.id === x.parameterId)
                )}
            />
            <Table stickyHeader className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: 164 }}>Parameter</TableCell>
                        <TableCell align="right">Min</TableCell>
                        <TableCell align="right">Max</TableCell>
                        <TableCell align="center">Active</TableCell>
                        <TableCell
                            style={{ width: widthDeleteCell }}
                            className={classes.invisibleCell}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedParameters.map((parameter, index) => {
                        const minMaxDisabled = parameter.unit === "bool";
                        return (
                            <TableRow
                                key={parameter.id}
                                className={classes.row}
                            >
                                <TableCell>{parameter.description}</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        {...textFieldProps()}
                                        value={parameter.min}
                                        onChange={handleParameterValueChange(
                                            "min",
                                            index
                                        )}
                                        disabled={minMaxDisabled}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField
                                        {...textFieldProps()}
                                        value={parameter.max}
                                        onChange={handleParameterValueChange(
                                            "max",
                                            index
                                        )}
                                        disabled={minMaxDisabled}
                                    />
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.activeCell}
                                >
                                    <CustomCheckbox
                                        checked={parameter.isActive}
                                        onClick={() =>
                                            toggleParameterActive(index)
                                        }
                                        className={classes.smallActiveCheck}
                                    />
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={clsx(
                                        classes.invisibleCell,
                                        classes.activeCell
                                    )}
                                >
                                    <IconButton
                                        className={classes.deleteButton}
                                        onClick={() => deleteParameter(index)}
                                    >
                                        <FontAwesomeIcon
                                            icon={deleteIcon}
                                            size={"xs"}
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div className={classes.buttonContainer}>
                <TertiaryButton
                    disabled={disabled}
                    className={classes.addButton}
                    onClick={openParameterSelection}
                >
                    <FontAwesomeIcon
                        icon={addIcon}
                        size={"lg"}
                        className={classes.plusIcon}
                    />
                    Add Parameter
                </TertiaryButton>
                <SecondaryButton
                    onClick={clearTable}
                    className={classes.clearTableButton}
                >
                    Clear Table
                </SecondaryButton>
            </div>
            <FormHelperText>{helperText}</FormHelperText>
            {disabled && (
                <Typography className={classes.helper}>
                    Site and Analysis Height level required to get list of
                    parameters.
                </Typography>
            )}
        </FormControl>
    );
};

export default ParameterFormTable;
