import React, { memo, useEffect, useState } from "react";
/* global google */
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import { setMapState } from "../../redux/actions/mapState";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useTheme } from "@material-ui/core";
import SitePopup from "./SitePopup";
import mapStyles from "../../styles/mapStyles.json";
import Api from "../../api/index";
import CenteredLoading from "../../common/CenteredLoading";
import { modules } from "../../utils/moduleConfig";

const styles = (theme) => ({
    root: {
        //different border/styles if fullscreen on homepage or a block component in new design
        height: (props) => (props.fullScreen ? "100%" : "calc(100% - 2px)"),
        width: (props) => (props.fullScreen ? "100%" : "calc(100% - 2px)"),
        position: "relative",
        zIndex: theme.zIndex.bing,
        border: (props) =>
            props.fullScreen
                ? "none"
                : `1px solid ${theme.palette.generalUi.borders}`,
        borderRadius: (props) => (props.fullScreen ? 0 : theme.spacing(1)),
        overflow: "hidden",
    },
});

const GoogleMapsLoader = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    return isLoaded ? <GoogleMapsConnected {...props} /> : null;
};

const GoogleMaps = (props) => {
    const theme = useTheme();
    const {
        allSites,
        selectedSite,
        sites,
        isMapLoading,
        setMapState,
        mapState,
        isHomepage,
        classes,
    } = props;
    const [mapRef, setMapRef] = useState(null);
    const selectedSiteObj = sites.find(
        (site) => site.id.toString() === selectedSite
    );
    const [popup, setPopup] = useState({
        visible: false,
        site: null,
        forecasts: null,
    });
    const [popups, setPopups] = useState([]);

    useEffect(() => {
        setPopups([]);
    }, [sites]);

    const colorFromOffshore = (isOffshore) => {
        let _COLOR = "#000000";
        if (isOffshore) {
            //water
            _COLOR = theme.palette.sites.offshore;
        } else {
            //land
            _COLOR = theme.palette.sites.onshore;
        }
        return _COLOR;
    };

    const setPinColor = (id, selectedSite) => {
        const { sites } = props;
        const site = sites.filter((site) => site.id === id)[0];
        let _COLOR = colorFromOffshore(site.isOffshore);
        const _IS_SELECTED = selectedSite
            ? site.id.toString() === selectedSite
            : false;

        return _IS_SELECTED
            ? `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><circle cx="16" cy="16" r="16" fill="${_COLOR}" opacity="0.5"/><circle cx="16" cy="16" r="15.5" fill="none" stroke="${_COLOR}"/><circle cx="16" cy="16" r="8" fill="#fff"/><path d="M19.25,16A3.25,3.25,0,1,1,16,12.75,3.26,3.26,0,0,1,19.25,16ZM24,16a8,8,0,1,1-8-8A8,8,0,0,1,24,16Zm-1.5,0A6.5,6.5,0,1,0,16,22.5,6.5,6.5,0,0,0,22.5,16Z" fill="${_COLOR}"/></svg>`
            : `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><defs><style>.a{fill:#fff;}.b,.d{fill:${_COLOR};}.b{stroke:#fff;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1.5px;}.c,.d{stroke:none;}</style></defs><g transform="translate(-925 -215.811)"><g transform="translate(-268 -146.189)"><g class="a" transform="translate(1193 362)"><path class="c" d="M 8 15.25 C 4.002339839935303 15.25 0.75 11.99765968322754 0.75 8 C 0.75 4.002339839935303 4.002339839935303 0.75 8 0.75 C 11.99765968322754 0.75 15.25 4.002339839935303 15.25 8 C 15.25 11.99765968322754 11.99765968322754 15.25 8 15.25 Z"/><path class="d" d="M 8 1.5 C 4.415889739990234 1.5 1.5 4.415889739990234 1.5 8 C 1.5 11.58411026000977 4.415889739990234 14.5 8 14.5 C 11.58411026000977 14.5 14.5 11.58411026000977 14.5 8 C 14.5 4.415889739990234 11.58411026000977 1.5 8 1.5 M 8 0 C 12.41827964782715 0 16 3.581720352172852 16 8 C 16 12.41827964782715 12.41827964782715 16 8 16 C 3.581720352172852 16 0 12.41827964782715 0 8 C 0 3.581720352172852 3.581720352172852 0 8 0 Z"/></g><circle class="b" cx="4" cy="4" r="4" transform="translate(1197 366)"/></g></g></svg>`;
    };

    useEffect(() => {
        if (mapRef) {
            if (selectedSiteObj) {
                const coords = new google.maps.LatLng(
                    parseFloat(selectedSiteObj.latitude),
                    parseFloat(selectedSiteObj.longitude)
                );
                mapRef.panTo(coords);
                google.maps.event.addListenerOnce(
                    mapRef,
                    "bounds_changed",
                    function (event) {
                        if (mapRef.getZoom() < 8) {
                            mapRef.setZoom(8);
                        }
                    }
                );
            }
        }
    }, [mapRef, selectedSiteObj]);

    useEffect(() => {
        //on unmount
        return () => {
            if (isHomepage && mapRef) {
                setMapState({
                    center: mapRef.getCenter(),
                    bounds: mapRef.getBounds(),
                    mapTypeId: mapRef.getMapTypeId(),
                });
            }
        };
    }, [isHomepage, mapRef, setMapState]);

    const selectSite = (site) => {
        const { push } = props;

        push(`${modules.forecasts.url}/site/${site.id}`);
    };

    /*    const popupParameterList = [
        "Wind Speed 100 m",
        "Lightning Risk",
        "Wind chill 2 m",
        "Significant Wave Height m",
    ]; */

    const movePopupToCurrent = (id) => {
        const site = props.sites.filter((site) => site.id === id)[0];
        setPopup({ visible: true, site: site, forecasts: null });

        Api.Sites.getSiteForecastsPreview(site.id, "3").then((res) => {
            setPopup((popup) => {
                if (popup.site?.id === site.id) {
                    return {
                        ...popup,
                        visible: true,
                        forecasts: res.data,
                        /*  forecasts: res.data.filter((item) =>
                            popupParameterList.includes(item.groupName)
                        ), */
                    };
                } else {
                    return popup;
                }
            });
        });
    };

    const mouseOverHandler = (site, isOver) => {
        setPopup((popup) => {
            return { ...popup, isMouseOver: isOver };
        });
        if (!isOver) {
            hidePopup(site);
        }
    };

    const hidePopup = (site) => {
        const timer = setTimeout(() => {
            clearTimeout(timer);
            setPopup((popup) => {
                if (popup.isMouseOver || site.id !== popup.site?.id) {
                    return popup;
                } else {
                    return {
                        visible: false,
                        site: null,
                        forecasts: null,
                    };
                }
            });
        }, 300);
    };

    const pinHandler = (site, isPinned) => {
        const index = popups.findIndex((p) => p.site.id === site.id);
        if (index < 0) {
            setPopups((popups) => popups.concat(popup));
            setPopup({ visible: false, site: null, forecasts: null });
        } else {
            setPopups((popups) =>
                [].concat(popups.slice(0, index), popups.slice(index + 1))
            );
        }
    };

    const getPinState = (site) => {
        return popups.findIndex((p) => p.site.id === site.id) >= 0;
    };

    const markerRender = () => {
        const { sites } = props;
        return sites.map((site) => {
            return renderSiteMarker(site);
        });
    };

    const renderSiteMarker = (site) => {
        const { selectedSite } = props;
        const _IS_SELECTED = selectedSite
            ? site.id.toString() === selectedSite
            : false;
        const smallSite = !Boolean(_IS_SELECTED);
        return (
            <Marker
                key={site.id}
                position={
                    new google.maps.LatLng(
                        parseFloat(site.latitude),
                        parseFloat(site.longitude)
                    )
                }
                icon={{
                    url:
                        "data:image/svg+xml;charset=utf-8," +
                        encodeURIComponent(setPinColor(site.id, selectedSite)),
                    anchor: smallSite
                        ? new google.maps.Point(8, 8)
                        : new google.maps.Point(16, 16),
                    size: smallSite
                        ? new google.maps.Size(16, 16)
                        : new google.maps.Size(32, 32),
                    scaledSize: smallSite
                        ? new google.maps.Size(16, 16)
                        : new google.maps.Size(32, 32),
                }}
                onClick={() => selectSite(site)}
                onMouseOver={() => {
                    movePopupToCurrent(site.id);
                }}
                onMouseOut={() => {
                    hidePopup(site);
                }}
            />
        );
    };

    return (
        <div className={classes.root}>
            {!isMapLoading && (
                <GoogleMap
                    mapContainerStyle={{ height: "100%" }}
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                        mapTypeControl: true,
                        mapTypeControlOptions: {
                            style: google.maps.MapTypeControlStyle
                                .DROPDOWN_MENU,
                        },
                    }}
                    onLoad={(map) => {
                        setMapRef(map);
                        let options = {
                            minZoom: 2,
                            //restricts zooming outside of map area
                            restriction: {
                                latLngBounds: {
                                    north: 85,
                                    south: -85,
                                    west: -180,
                                    east: 180,
                                },
                                strictBounds: true,
                            },
                            styles: mapStyles,
                            mapTypeId: "hybrid",
                        };
                        let bounds;

                        if (selectedSiteObj) {
                            //no bounds, as centering around selected sensor
                            bounds = new google.maps.LatLngBounds();
                            map.fitBounds(bounds);
                        } else {
                            if (!mapState) {
                                //set bounds of map around every site
                                bounds = new google.maps.LatLngBounds();
                                if (allSites.length > 0) {
                                    bounds.extend(
                                        new google.maps.LatLng(
                                            parseFloat(allSites[0].latitude),
                                            parseFloat(allSites[0].longitude)
                                        )
                                    );
                                    map.fitBounds(bounds);
                                    google.maps.event.addListenerOnce(
                                        map,
                                        "bounds_changed",
                                        function (event) {
                                            map.setZoom(4);
                                        }
                                    );
                                } else {
                                    bounds = new google.maps.LatLngBounds();
                                    bounds.extend(
                                        new google.maps.LatLng(180, 90)
                                    );
                                    bounds.extend(
                                        new google.maps.LatLng(-180, -90)
                                    );
                                    map.fitBounds(bounds);
                                }
                            } else {
                                //fit bounds to stored bounds
                                bounds = mapState.bounds;
                                map.fitBounds(bounds, 0);
                            }
                        }
                        if (mapState) {
                            //set mapType to store mapType
                            options.mapTypeId = mapState.mapTypeId;
                        }
                        map.setOptions(options);
                    }}
                >
                    <>
                        {markerRender()}
                        {popups.map((p) => {
                            return (
                                <SitePopup
                                    popup={p}
                                    colorFromOffshore={colorFromOffshore}
                                    initialLatLng={{
                                        lat: p.latitude,
                                        lng: p.longitude,
                                    }}
                                    onPin={(value) => pinHandler(p.site, value)}
                                    onGetPinState={() => getPinState(p.site)}
                                    // onClose={() => hidePopup(p.site)}
                                    onClick={() => selectSite(p.site)}
                                    onMouseOver={(isOver) => {}}
                                />
                            );
                        })}
                        <SitePopup
                            popup={popup}
                            colorFromOffshore={colorFromOffshore}
                            //initial lat lng has to be at center of map (on selected site) otherwise map re-centers when SitePopup loads
                            initialLatLng={
                                selectedSiteObj
                                    ? {
                                          lat: selectedSiteObj.latitude,
                                          lng: selectedSiteObj.longitude,
                                      }
                                    : mapState
                                    ? mapState.center
                                    : allSites && allSites.length > 0
                                    ? {
                                          lat: allSites[0].latitude,
                                          lng: allSites[0].longitude,
                                      }
                                    : { lat: 0, lng: 0 }
                            }
                            onMouseOver={(isOver) => {
                                mouseOverHandler(popup.site, isOver);
                            }}
                            onPin={(value) => pinHandler(popup.site, value)}
                            onClick={() => selectSite(popup.site)}
                        />
                    </>
                </GoogleMap>
            )}
            {isMapLoading && <CenteredLoading />}
        </div>
    );
};

const GoogleMapsConnected = withStyles(styles)(
    connect(null, { push, setMapState })(memo(GoogleMaps))
);

export default GoogleMapsLoader;
