import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography, ListItem, Tabs, Tab, withStyles } from "@material-ui/core";
import CenteredLoading from "../../common/CenteredLoading";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export const CustomTab = withStyles((theme) => ({
    root: {
        minHeight: 23,
        minWidth: "50%",
        padding: 0,
        fontSize: 13,
        color: theme.palette.common.white,
        opacity: 1,
        transition: "color 150ms ease 0ms",
        "&$selected": {
            color: "#2699FB",
            fontWeight: 600,
        },
    },
    selected: {},
    wrapper: {
        zIndex: 1,
        height: `calc(100% - ${theme.spacing(0.25)}px)`,
        marginBottom: theme.spacing(0.25),
    },
}))((props) => <Tab disableRipple {...props} />);

export const CustomTabs = withStyles((theme) => ({
    root: {
        minHeight: 23,
        height: 23,
    },
    indicator: {
        height: 24,
        zIndex: 0,
        transition: "all 150ms ease 0ms",
        bottom: 0,
        backgroundColor: theme.palette.common.white,
    },
}))(Tabs);

const useStyles = makeStyles((theme) => ({
    listContainer: {
        position: "relative",
        flex: 1,
    },
    row: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: "space-between",
        paddingRight: theme.spacing(1),
        display: "flex",
    },
    buttonRow: {
        "&:hover": {
            backgroundColor: theme.palette.secondMenuItem.hoverBackground,
        },
    },
    selectedRow: {
        backgroundColor: `${theme.palette.secondMenuItem.selectedBackground} !important`,
    },
    noDataRow: {
        padding: theme.spacing(3, 4),
    },
    rowText: {
        color: theme.palette.text.default,
        fontSize: 13,
        lineHeight: 1.3,
        width: "calc(100% - 32px)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: 1,
        paddingRight: theme.spacing(1.5),
    },
    rowSubtext: {
        color: theme.palette.text.defaultSub,
        fontSize: 9,
        lineHeight: 1.3,
        "&:last-child": {
            padding: theme.spacing(0, 0, 0, 2),
        },
    },
    rowSubtextContainer: {
        width: "100%",
        display: "flex",
    },
    rowMainContent: {
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
        flex: 1,
        alignItems: "center",
        maxWidth: "100%",
    },
    activeIcon: {
        backgroundColor: theme.palette.sites.onshore,
        borderRadius: "50%",
        width: 8,
        height: 8,
        marginRight: theme.spacing(2),
    },
    inactiveIcon: {
        backgroundColor: theme.palette.generalUi.borders,
    },
}));

const ReportsList = (props) => {
    const classes = useStyles(props);
    const { reports, selectReport, isSelected } = props;

    const renderRow = (props) => {
        const { index, style } = props;
        const report = reports[index];
        const { name, id, isActive } = report;

        const selected = isSelected(id);
        return (
            <ListItem
                button
                style={style}
                key={index}
                selected={selected}
                className={clsx(
                    classes.row,
                    classes.buttonRow,
                    selected && classes.selectedRow
                )}
                onClick={() => selectReport(id)}
            >
                <div className={classes.rowMainContent}>
                    <Typography className={classes.rowText}>{name}</Typography>
                    <span
                        className={clsx(
                            classes.activeIcon,
                            !isActive && classes.inactiveIcon
                        )}
                    />
                </div>
            </ListItem>
        );
    };

    const renderRowNoData = () => {
        return (
            <ListItem className={clsx(classes.row, classes.noDataRow)}>
                <div className={classes.rowMainContent}>
                    <Typography className={classes.rowText}>
                        {"No reports found"}
                    </Typography>
                </div>
            </ListItem>
        );
    };

    return (
        <div className={classes.listContainer}>
            {reports ? (
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            className="List"
                            height={height}
                            itemCount={reports.length > 0 ? reports.length : 1}
                            itemSize={40}
                            width={width}
                        >
                            {reports.length > 0 ? renderRow : renderRowNoData}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <CenteredLoading />
            )}
        </div>
    );
};

export default ReportsList;
