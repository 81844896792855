import React, { useEffect, useState } from "react";
import { CircularProgress, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import UIIcons from "../assets/icons/ui-icons";
import {
    CustomTable,
    CustomTableHead,
    CustomTableBody,
    CustomTableCell,
    CustomTableRow,
} from "./restyled-mui/CustomTable";
import { CustomTextField } from "./restyled-mui/CustomInputs";
import { CustomCheckbox } from "./restyled-mui/CustomCheckBox";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as VirtualList } from "react-window";

const styles = (theme) => ({
    loading: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
    },
    root: {
        overflow: "hidden",
        flex: "1 1 400px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& th": {
            overflow: "hidden",
            textOverflow: "clip",
            whiteSpace: "nowrap",
        },
        "& td": {
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "@media (max-width: 960px)": {
            "& th": {
                padding: theme.spacing(4, 1, 4, 1),
                whiteSpace: "normal",
            },
            "& td": {
                padding: theme.spacing(4, 1, 4, 1),
            },
        },
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
    },
    stickyTableHeader: {
        marginRight: theme.spacing(4),
    },
    scrollableTableBodyContainer: {
        flex: "1 1 0px",
        minHeight: 0,
        overflow: "auto",
    },
    activeDot: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        top: "50%",
        // left: '50%',
        left: "40px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
    },
    activeDotContainer: {
        position: "relative",
    },

    searchBar: {
        marginTop: theme.spacing(4),
        width: "100%",
    },
    inputAdornmentIconSearch: {
        height: 15,
        width: 15,
    },
    sortIcon: {
        width: 0,
        height: 0,
        display: "inline-grid",
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        cursor: "pointer",
    },
    sortIconInactive: {
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        borderTop: `6px solid ${theme.palette.secondary.main}`,
        opacity: 0.5,
    },
    sortIconActive: {
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        borderBottom: `6px solid ${theme.palette.secondary.main}`,
        opacity: 1,
    },
    sortIconAsc: {
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        borderBottom: `6px solid ${theme.palette.secondary.main}`,
    },
    sortIconDes: {
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        borderTop: `6px solid ${theme.palette.secondary.main}`,
    },
    searchContainer: {
        border: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.generalUi.panelBackground,
        margin: theme.spacing(2, 0, 1, 0),
        padding: theme.spacing(1, 2, 2, 2),
        width: "100%",
    },
    formSubtitle: {
        margin: theme.spacing(4, 0, 4, 0),
    },
    tableColumnBottom: {
        alignSelf: "flex-end",
        textAlign: "right",
    },

    checkboxCell: {
        width: "10%",
        margin: "0",
        padding: "0 0 0 5px",
    },
    virtualTableBodyContainer: {
        height: "100%",
    },
    virtualTableBody: {
        height: "100%",
    },
    tableRow: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        width: "100%",
        borderBottom: "1px solid rgb(224,224,224)",
    },
    tableCell: {
        border: "none",
    },
});

const SelectionTableVirtualized = (props) => {
    const {
        classes,
        header,
        columnHeaders,
        columnNames,
        multiSelect,
        items,
        selected,
        setSelected,
        itemSelected,
        itemUnselected,
    } = props;
    const [sort, setSort] = useState([]);
    const [sortedItems, setSortedItems] = useState([]);
    const [filter, setFilter] = useState("");
    const filterPlaceholder = `Filter ${header}`;

    useEffect(() => {
        if (items && sort) {
            if (items.length > 0) {
                if (sort?.length <= 0) {
                    setSort([columnNames[1], "asc"]);
                } else {
                    let orderedItems = null;
                    if (typeof items[0][sort[0]] == "string") {
                        orderedItems = _.orderBy(
                            items,
                            [
                                (item) =>
                                    item[sort[0]] === null
                                        ? ""
                                        : item[sort[0]].toLowerCase(),
                            ],
                            sort[1]
                        );
                    } else {
                        orderedItems = _.orderBy(items, sort[0], sort[1]);
                    }
                    setSortedItems(orderedItems);
                }
            } else {
                setSortedItems([]);
            }
        }
    }, [sort, items, columnNames]);

    const isSelected = (selected, id) => selected.indexOf(id) !== -1;

    const areAllSelected = () => {
        if (selected?.length > 0 && selected?.length >= filteredItems?.length) {
            for (var i = 0; i < filteredItems.length; i++) {
                if (selected.indexOf(filteredItems[i][columnNames[0]]) < 0) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    };

    const selectAll = () => {
        const selections = [];
        if (selected?.length === 0 || !areAllSelected()) {
            // Select all
            filteredItems.forEach((item) =>
                selections.push(item[columnNames[0]])
            );
            selected.forEach((id) => {
                if (selections.indexOf(id) < 0) selections.push(id);
            });
        } else {
            // Unselect all
            selected.forEach((id) => {
                if (!filteredItems.find((item) => item[columnNames[0]] === id))
                    selections.push(id);
            });
        }

        selections
            .filter((i) => !selected.includes(i))
            .forEach((id) => itemSelected(id));
        selected
            .filter((i) => !selections.includes(i))
            .forEach((id) => itemUnselected(id));

        setSelected(selections);
    };

    const setSelection = (item) => {
        if (multiSelect) setSelected(addSelectedItem(selected, item));
        else {
            setSelected([item]);
            itemSelected && itemSelected(item);
        }
    };

    const addSelectedItem = (selected, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        if (selectedIndex === -1) {
            itemSelected && itemSelected(id);
        } else {
            itemUnselected && itemUnselected(id);
        }

        return newSelected;
    };

    const handleSortBy = (key, order) => {
        setSort([key, order]);
    };

    const renderSortingLink = (key) => {
        const sortBy = sort[0];
        const sortDirection = sort[1];
        return (
            <span
                className={clsx(
                    classes.sortIcon,
                    sortBy === key
                        ? clsx(
                              sortDirection === "asc"
                                  ? classes.sortIconAsc
                                  : classes.sortIconDes
                          )
                        : classes.sortIconInactive
                )}
                onClick={() =>
                    handleSortBy(
                        key,
                        sortBy === key
                            ? sortDirection === "asc"
                                ? "desc"
                                : "asc"
                            : "asc"
                    )
                }
            />
        );
    };

    const searchFilter = (item) => {
        const regex = new RegExp(filter, "i");
        return regex.test(`${item[columnNames[1]]} ${item[columnNames[2]]}`);
    };

    const filteredItems = sortedItems
        ? sortedItems.filter((x) => searchFilter(x))
        : [];

    const dataRowWidth = multiSelect ? "45%" : "50%";
    const Row = ({ index, style, data: { items, classes } }) => {
        const item = items[index];
        const isItemSelected = isSelected(selected, item[columnNames[0]]);

        return (
            <CustomTableRow
                style={style}
                component="div"
                key={item[columnNames[0]]}
                className={classes.tableRow}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                onClick={(e) => setSelection(item[columnNames[0]])}
            >
                {multiSelect && (
                    <CustomTableCell
                        style={{ width: "10%" }}
                        component="div"
                        className={classes.checkboxCell}
                    >
                        <CustomCheckbox checked={isItemSelected} />
                    </CustomTableCell>
                )}
                <CustomTableCell
                    style={{ width: dataRowWidth }}
                    component="div"
                    className={classes.tableCell}
                >
                    {item[columnNames[1]]}
                </CustomTableCell>
                <CustomTableCell
                    style={{ width: dataRowWidth }}
                    component="div"
                    className={classes.tableCell}
                >
                    {item[columnNames[2]]}
                </CustomTableCell>
            </CustomTableRow>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <CustomTextField
                    variant="outlined"
                    size="small"
                    type="text"
                    placeholder={filterPlaceholder}
                    className={classes.searchBar}
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UIIcons.SearchIcon
                                    className={classes.inputAdornmentIconSearch}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <div className={classes.stickyTableHeader}>
                <CustomTable>
                    <CustomTableHead>
                        <CustomTableRow>
                            {multiSelect && (
                                <CustomTableCell
                                    component="td"
                                    className={classes.checkboxCell}
                                    style={{ width: "10%" }}
                                >
                                    <CustomCheckbox
                                        indeterminate={
                                            selected?.length > 0 &&
                                            !areAllSelected()
                                        }
                                        checked={
                                            selected &&
                                            filteredItems &&
                                            areAllSelected()
                                        }
                                        onClick={() => selectAll()}
                                    />
                                </CustomTableCell>
                            )}
                            <CustomTableCell
                                component="th"
                                style={{ width: dataRowWidth }}
                            >
                                {columnHeaders[1]}
                                {renderSortingLink(columnNames[1])}
                            </CustomTableCell>
                            <CustomTableCell
                                style={{ width: dataRowWidth }}
                                component="th"
                            >
                                {columnHeaders[2]}
                                {renderSortingLink(columnNames[2])}
                            </CustomTableCell>
                        </CustomTableRow>
                    </CustomTableHead>
                </CustomTable>
            </div>

            <div className={classes.virtualTableBodyContainer}>
                {items && items.length > 0 && (
                    <CustomTable
                        component="div"
                        style={{ height: "100%", display: "flex" }}
                    >
                        <CustomTableBody
                            component="div"
                            style={{ height: "100%", flex: 1 }}
                        >
                            <div
                                style={{
                                    flex: "1 1 auto",
                                    height: "100%",
                                }}
                            >
                                {items && filteredItems.length > 0 ? (
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <VirtualList
                                                height={height}
                                                width={Math.round(width)}
                                                itemCount={filteredItems.length}
                                                itemSize={55}
                                                itemData={{
                                                    items: filteredItems,
                                                    classes: classes,
                                                }}
                                            >
                                                {Row}
                                            </VirtualList>
                                        )}
                                    </AutoSizer>
                                ) : (
                                    <CustomTableRow
                                        className={classes.tableRow}
                                        component="div"
                                    >
                                        {items &&
                                            filteredItems.length === 0 && (
                                                <CustomTableCell
                                                    component="div"
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    No items found
                                                </CustomTableCell>
                                            )}
                                    </CustomTableRow>
                                )}
                            </div>
                        </CustomTableBody>
                    </CustomTable>
                )}
                {(!items || items.length === 0) && (
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(SelectionTableVirtualized);
