import {
    weatherForecastIcon,
    weatherForecastIconSelected,
} from "../assets/icons/fontawesome/weatherForecastIcons";
import {
    hwwaIcon,
    hwwaIconSelected,
} from "../assets/icons/fontawesome/hwwaIcons";
import {
    forecastAlertsIcon,
    forecastAlertsIconSelected,
} from "../assets/icons/fontawesome/forecastAlertsIcons";

import {
    reportsIcon,
    reportsIconSelected,
} from "../assets/icons/fontawesome/reportsIcons";
import {
    hindcastAnalysisIcon,
    hindcastAnalysisIconSelected,
} from "../assets/icons/fontawesome/hindcastAnalysisIcons";

import {
    forecastUIIcon,
    forecastUIIconSelected,
} from "../assets/icons/fontawesome/forecastUIIcons";

export const modules = {
    forecasts: {
        id: "forecasts",
        url: "/forecasts",
        name: "Weather Forecast",
        title: "Weather Forecast",
        icon: {
            default: weatherForecastIcon,
            selected: weatherForecastIconSelected,
        },
        permission: "Module.Forecasts",
    },
    hwwa: {
        id: "hwwa",
        url: "/analytics",
        name: "Historical Weather Analysis",
        title: "Historical Weather Window Analysis",
        icon: {
            default: hwwaIcon,
            selected: hwwaIconSelected,
        },
        permission: "Module.HWWA",
    },
    alerts: {
        id: "alerts",
        url: "/alerts",
        name: "Manage Forecast Alerts",
        title: "Manage Forecast Alerts",
        icon: {
            default: forecastAlertsIcon,
            selected: forecastAlertsIconSelected,
        },
        permission: "Module.Alerts",
    },
    reports: {
        id: "reports",
        url: "/reports",
        name: "Manage Reports",
        title: "Manage Reports",
        icon: {
            default: reportsIcon,
            selected: reportsIconSelected,
        },
        permission: "Module.Reports",
    },
    hindcasts: {
        id: "hindcasts",
        url: "/hindcasts",
        name: "Hindcast Analysis",
        title: "Hindcast Analysis",
        icon: {
            default: hindcastAnalysisIcon,
            selected: hindcastAnalysisIconSelected,
        },
        permission: "Module.Hindcasts",
    },
    forecastui: {
        id: "forecastui",
        url: "/forecastui",
        name: "Forecast UI",
        title: "Forecast UI",
        icon: {
            default: forecastUIIcon,
            selected: forecastUIIconSelected,
        },
        permission: "Module.ForecastUI",
    },
    wiki: {
        id: "wiki",
        url: "/wiki",
        name: "Wiki",
        title: "Wiki",
        permission: "Wiki",
    },
    settings: {
        id: "settings",
        url: "/settings",
        name: "Settings",
        title: "Settings",
        permission: "Settings",
    },
};

export const permissionToAvailableModules = (permissions) => {
    return permissions
        .filter((e) => e.split(".")[0] === "Module")
        .map((e) => e.split(".")[1].toLowerCase());
};

export const permissionsToModules = (permissions, isAdministrator) => {
    let availableModules = permissionToAvailableModules(permissions);

    availableModules = Object.entries(modules)
        .filter(([_, value]) => availableModules.includes(value.id))
        .map(([_, value]) => value);

    // Always should have wiki
    availableModules.push(modules["wiki"]);

    // If is administrator, add settings
    if (isAdministrator) {
        availableModules.push(modules["settings"]);
    }

    return availableModules;
};

export const getPrimaryModule = (availableModules, mainPage) => {
    const mainModule = availableModules.find((e) => e.permission === mainPage);

    if (mainModule) {
        return mainModule;
    } else if (availableModules.length > 0) {
        // If there is no main page, return the first available module (default behavior)
        return availableModules[0];
    }
    // If there is no available modules, return wiki
    return availableModules["wiki"];
};

export const moduleIsAvailable = (moduleId, availableModules, permissions) => {
    return (
        availableModules.includes(moduleId) &&
        permissions.find((e) => e.toLowerCase() === "module." + moduleId) !==
            undefined
    );
};
