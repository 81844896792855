import React from "react";
import clsx from "clsx";
import {
    Toolbar,
    List,
    ListItem,
    ListItemIcon,
    makeStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    listIcon,
    listIconSelected,
} from "../../assets/icons/fontawesome/listIcons";
import {
    mapIcon,
    mapIconSelected,
} from "../../assets/icons/fontawesome/mapIcons";
const useStyles = makeStyles((theme) => ({
    bar: {
        height: 40,
        minHeight: 40,
        display: "flex",
        borderTop: `1px solid ${theme.palette.generalUi.borders}`,
    },
    list: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        padding: 0,
    },
    flexCenter: {
        justifyContent: "center",
    },
    menuIcon: {
        color: theme.palette.menu.icons,
    },
    selectedMenuIcon: {
        color: theme.palette.primary.main,
    },
}));

const Topbar = (props) => {
    const classes = useStyles(props);
    const { toggleView, view } = props;

    return (
        <>
            <Toolbar className={classes.bar}>
                <List className={classes.list}>
                    <ListItem className={classes.flexCenter}>
                        <ListItemIcon
                            button
                            onClick={() => toggleView(0)}
                            className={classes.flexCenter}
                        >
                            <FontAwesomeIcon
                                icon={view === 0 ? listIconSelected : listIcon}
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    view === 0 ? classes.selectedMenuIcon : null
                                )}
                            />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem className={classes.flexCenter}>
                        <ListItemIcon
                            button
                            onClick={() => toggleView(1)}
                            className={classes.flexCenter}
                        >
                            <FontAwesomeIcon
                                icon={view === 1 ? mapIconSelected : mapIcon}
                                size={"lg"}
                                className={clsx(
                                    classes.menuIcon,
                                    view === 1 ? classes.selectedMenuIcon : null
                                )}
                            />
                        </ListItemIcon>
                    </ListItem>
                </List>
            </Toolbar>
        </>
    );
};

export default Topbar;
