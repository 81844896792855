import React from "react";
import { ChartValueAxisItem } from "@progress/kendo-react-charts";
import { returnLabelFont } from "./chartStyles";
import { useTheme } from "@material-ui/core";

const CustomChartValueAxisItem = (props) => {
    const theme = useTheme();
    const { title, labels, ...otherProps } = props;
    return (
        <ChartValueAxisItem
            line={{ color: theme.palette.generalUi.borders, width: 1 }}
            title={{
                font: returnLabelFont(theme, 13, 600),
                margin: { left: 0 },
                ...title,
            }}
            labels={{
                font: returnLabelFont(theme, 11, 400),
                ...labels,
            }}
            {...otherProps}
        />
    );
};

export default CustomChartValueAxisItem;
