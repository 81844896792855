import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText } from "@material-ui/core";
import {
    CustomFormControlLabel,
    CustomCheckbox,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import { sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    checkboxControl: {
        width: 80,
        marginRight: theme.spacing(1.25),
    },
    control: {
        display: "block",
    },
}));

const daysList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const DayCheckboxes = (props) => {
    const classes = useStyles(props);
    const { days, handleDayChange, error, helperText } = props;

    const toggleDay = (day) => (e) => {
        if (days.includes(day)) {
            handleDayChange(days.filter((x) => x !== day));
        } else {
            handleDayChange(sortBy([...days, day]));
        }
    };

    const isChecked = (day) => {
        return days.includes(day);
    };

    return (
        <>
            <FormControl error={error} className={classes.control}>
                {daysList.map((day, index) => {
                    const dayValue = day.substring(0, 2);
                    return (
                        <CustomFormControlLabel
                            control={
                                <CustomCheckbox
                                    variant="outlined"
                                    margin="dense"
                                    onClick={toggleDay(dayValue)}
                                    checked={isChecked(dayValue)}
                                />
                            }
                            key={index}
                            className={classes.checkboxControl}
                            label={day}
                            labelPlacement="end"
                        />
                    );
                })}
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        </>
    );
};

export default DayCheckboxes;
