import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    TextField,
    FormHelperText,
    FormControl,
    Typography,
    IconButton,
    MenuItem,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import {
    CustomDataTable as Table,
    CustomDataTableBody as TableBody,
    CustomSmallDataTableCell as TableCell,
    CustomDataTableHead as TableHead,
    CustomDataTableRow as TableRow,
} from "../../../common/restyled-mui/CustomDataTable";
import ParameterSelectionPopover from "./HindcastParameterSelectionPopover";
import {
    CustomCheckbox,
    CustomSelect,
    textFieldNoArrowStyles,
} from "../../../common/restyled-mui/CustomInputs";
import { getSettingsStyles } from "../../../styles/settingsStyles";
import { SecondaryButton } from "../../../common/restyled-mui/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteIcon } from "../../../assets/icons/fontawesome/deleteIcon";
const widthDeleteCell = 24;

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    inputRoot: {
        height: "100%",
        backgroundColor: "transparent",
        fontSize: 13,
        color: theme.palette.text.default,
    },
    inputInput: {
        textAlign: "right",
        margin: 0,
        fontSize: 13,
        ...textFieldNoArrowStyles,
    },
    activeCell: {
        padding: 0,
    },
    smallActiveCheck: {
        padding: 0,
    },
    parameterActionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    buttonContainerClear: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },

    plusIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
    addButton: {
        //  width: 170,
        margin: theme.spacing(4),
    },

    addAllButtonContainer: {
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
    },
    invisibleCell: {
        border: "none",
        backgroundColor: "white",
    },
    deleteButton: {
        padding: theme.spacing(1),
    },
    clearTableButton: {
        //  marginRight: widthDeleteCell + theme.spacing(5),
        margin: theme.spacing(1),
    },
    helper: {
        fontSize: 12,
        padding: theme.spacing(2, 0),
        marginRight: widthDeleteCell + theme.spacing(5),
    },
    tableCell: {
        padding: "6px 6px",
    },
}));

const HindcastParameterFormTable = (props) => {
    const classes = useStyles(props);
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const {
        parameters,
        selectedParameters,
        updateSelected,
        error,
        helperText,
    } = props;

    const disabled = false;

    const textFieldProps = (name, index) => {
        return {
            // value:values[name],
            // onChange:changeValue(name, index),
            type: "number",
            InputProps: {
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                disableUnderline: true,
            },
        };
    };

    const addParameter = (parameter) => {
        updateSelected([
            ...selectedParameters,
            {
                id: parameter.parameterId,
                name: parameter.name,
                unit: parameter.unit,
                description: parameter.description,

                condition: "",
                testValue: null,
                apply: true,
            },
        ]);
        closeParameterSelection();
    };

    const deleteParameter = (index) => {
        const newParams = [...selectedParameters];
        newParams.splice(index, 1);
        updateSelected(newParams);
    };

    const openParameterSelection = (e) => {
        setPopoverAnchor(e.currentTarget);
    };

    const closeParameterSelection = () => {
        setPopoverAnchor(null);
    };

    const handleParameterValueChange = (name, index) => (e) => {
        let newParams = [...selectedParameters];
        newParams[index].testValue = e.target.value * 1;
        updateSelected(newParams);
    };

    const toggleParameterActive = (index) => {
        const newParams = [...selectedParameters];
        newParams[index].apply = !newParams[index].apply;
        updateSelected(newParams);
    };

    const handleParameterConditionChange = (index) => (e) => {
        let newParams = [...selectedParameters];
        newParams[index].condition = e.target.value;
        updateSelected(newParams);
    };

    const operators = [
        { id: 1, type: "GT", label: ">" },
        { id: 2, type: "LT", label: "<" },
    ];

    const clearTable = () => {
        updateSelected([]);
    };

    const addAllParameters = () => {
        if (parameters) {
            updateSelected(
                parameters.map((parameter) => ({
                    id: parameter.parameterId,
                    name: parameter.name,
                    unit: parameter.unit,
                    description: parameter.description,
                    condition: "",
                    testValue: null,
                    apply: true,
                }))
            );
        }
    };

    const booleanParameterNameList = [
        "LIGHTNINGRISK",
        "HELI_BLADE_ICING",
        "CLOUD_HEIGHT",
        "ICE",
        "CSNOW",
    ];

    return (
        <FormControl error={error}>
            <ParameterSelectionPopover
                anchor={popoverAnchor}
                open={Boolean(popoverAnchor)}
                handleClose={closeParameterSelection}
                onClick={addParameter}
                parameters={
                    parameters &&
                    parameters.filter(
                        (x) =>
                            !selectedParameters.some(
                                (y) => y.id === x.parameterId
                            )
                    )
                }
            />
            <TableContainer style={{ maxHeight: 300 }}>
                <Table stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{ width: 100 }}
                                className={classes.tableCell}
                            >
                                Parameter
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableCell}
                            >
                                Condition
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableCell}
                            >
                                Value
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableCell}
                                style={{ width: 40 }}
                            >
                                Active
                            </TableCell>
                            <TableCell
                                style={{ width: widthDeleteCell }}
                                className={classes.invisibleCell}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                        {selectedParameters.map((parameter, index) => {
                            const minMaxDisabled =
                                parameter.unit === "bool" ||
                                booleanParameterNameList.includes(
                                    parameter.name
                                );
                            return (
                                <TableRow
                                    key={parameter.id}
                                    className={classes.row}
                                >
                                    <TableCell>
                                        {parameter.description}
                                    </TableCell>
                                    <TableCell align="right">
                                        <CustomSelect
                                            disabled={minMaxDisabled}
                                            inputProps={{
                                                margin: "dense",
                                            }}
                                            type="text"
                                            value={parameter.condition}
                                            onChange={handleParameterConditionChange(
                                                index
                                            )}
                                            variant={"outlined"}
                                            name={"condition"}
                                        >
                                            {operators.map((operator) => {
                                                return (
                                                    <MenuItem
                                                        key={operator.id}
                                                        value={operator.type}
                                                        className={
                                                            classes.selectMenuItem
                                                        }
                                                    >
                                                        {operator.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </CustomSelect>
                                    </TableCell>

                                    <TableCell align="right">
                                        <TextField
                                            {...textFieldProps()}
                                            value={parameter.testValue}
                                            onChange={handleParameterValueChange(
                                                "testValue",
                                                index
                                            )}
                                            disabled={minMaxDisabled}
                                        />
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        className={classes.activeCell}
                                    >
                                        <CustomCheckbox
                                            checked={parameter.apply}
                                            onClick={() =>
                                                toggleParameterActive(index)
                                            }
                                            className={classes.smallActiveCheck}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={clsx(
                                            classes.invisibleCell,
                                            classes.activeCell
                                        )}
                                    >
                                        <IconButton
                                            className={classes.deleteButton}
                                            onClick={() =>
                                                deleteParameter(index)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={deleteIcon}
                                                size={"xs"}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.parameterActionContainer}>
                <div className={classes.buttonContainer}>
                    <SecondaryButton
                        disabled={disabled}
                        className={classes.clearTableButton}
                        onClick={openParameterSelection}
                    >
                        Add...
                    </SecondaryButton>
                    <SecondaryButton
                        disabled={disabled}
                        className={classes.clearTableButton}
                        onClick={addAllParameters}
                    >
                        Add All
                    </SecondaryButton>
                    {/* 
                    <TertiaryButton
                        disabled={disabled}
                        className={classes.addButton}
                        onClick={addAllParameters}
                    >
                        <FontAwesomeIcon
                            icon={plusIcon}
                            size={'lg'}
                            className={classes.plusIcon}
                        />
                        Add All Parameters
                    </TertiaryButton>

                    <TertiaryButton
                        disabled={disabled}
                        className={classes.addButton}
                        onClick={openParameterSelection}
                    >
                        <FontAwesomeIcon
                            icon={plusIcon}
                            size={'lg'}
                            className={classes.plusIcon}
                        />
                        Add Parameter
                    </TertiaryButton>

 */}
                    <SecondaryButton
                        disabled={selectedParameters.length === 0}
                        onClick={clearTable}
                        className={classes.clearTableButton}
                    >
                        Remove All
                    </SecondaryButton>
                </div>
                <div className={classes.buttonContainerClear}></div>
            </div>

            <FormHelperText>{helperText}</FormHelperText>
            {disabled && (
                <Typography className={classes.helper}>
                    Site and Analysis Height level required to get list of
                    parameters.
                </Typography>
            )}
        </FormControl>
    );
};

export default HindcastParameterFormTable;
