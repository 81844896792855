import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Toolbar, Button } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2, 4),
        borderBottom: `1px solid ${theme.palette.generalUi.borders}`,
        backgroundColor: theme.palette.common.white,
        height: `calc(39px - ${theme.spacing(4)}px)`,
        minHeight: `calc(39px - ${theme.spacing(4)}px)`,
        justifyContent: "space-between",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    subTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        paddingRight: theme.spacing(3),
    },
    appbarContainer: {
        top: 0,
        position: "sticky",
        width: "100%",
    },
    titleContainer: {
        borderRight: `1px solid ${theme.palette.generalUi.borders}`,
        paddingRight: theme.spacing(3),
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    subTitleContainer: {
        display: "flex",
        paddingLeft: theme.spacing(3),
    },
    actionContainer: {
        justifySelf: "flex-end",
    },
    content: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    actionButton: {
        height: 18,
        minWidth: 18,
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0, 1),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.generalUi.borders}`,
        fontSize: 11,
        lineHeight: "10px",
        fontWeight: 600,
        color: theme.palette.text.subText,
        textTransform: "uppercase",
        transition: `
            color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        "&:hover": {
            backgroundColor: theme.palette.generalUi.panelBackground + "EF",
            color: theme.palette.text.subText,
        },
    },
    actionButtonActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.main + "EF",
            color: theme.palette.common.white,
        },
    },
}));

const DetailsTopbar = (props) => {
    const classes = useStyles(props);
    const { site, showForecastTable, toggleTableDisplay } = props;

    return (
        <>
            <div className={classes.appbarContainer}>
                <Toolbar className={classes.root}>
                    <div className={classes.content}>
                        <div className={classes.titleContainer}>
                            <Typography variant="h4" className={classes.title}>
                                {site.name}
                            </Typography>
                        </div>
                        <div className={classes.subTitleContainer}>
                            <Typography
                                variant="h4"
                                className={classes.subTitle}
                            >
                                {`LAT: ${site.latitude}`}
                            </Typography>
                            <Typography
                                variant="h4"
                                className={classes.subTitle}
                            >
                                {`LON: ${site.longitude}`}
                            </Typography>
                            <Typography
                                variant="h4"
                                className={classes.subTitle}
                            >
                                {`${site.isOffshore ? "offshore" : "onshore"}`}
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <Button
                            onClick={toggleTableDisplay}
                            disabled={false}
                            style={{}}
                            className={clsx(
                                classes.actionButton,
                                !showForecastTable && classes.actionButtonActive
                            )}
                        >
                            Chart
                        </Button>
                        <Button
                            onClick={toggleTableDisplay}
                            disabled={false}
                            style={{ width: 48 }}
                            className={clsx(
                                classes.actionButton,
                                showForecastTable && classes.actionButtonActive
                            )}
                        >
                            Table
                        </Button>
                    </div>

                    <div className={classes.actionContainer}>
                        {props.children}
                    </div>
                </Toolbar>
            </div>
        </>
    );
};

export default DetailsTopbar;
