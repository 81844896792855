import React from "react";

import {
    CustomInputLabel,
    CustomTextField,
    CustomSelect,
} from "../../../common/restyled-mui/CustomInputs";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";

const translateAlertOperator = (type) => {
    switch (type) {
        case "LTE":
            return <span>&#8804;</span>;
        case "GTE":
            return <span>&#8805;</span>;
        case "GT":
            return <span>&#62;</span>;
        case "LT":
            return <span>&#60;</span>;
        case "EQ":
            return <span>=</span>;
        default:
            return <span>{type}</span>;
    }
};

const useStyle = makeStyles((theme) => ({
    marginFix: {
        marginTop: theme.spacing(1),
    },
    numberInputInput: {
        "-webkit-appearance": "none",
        margin: 0,
        /* Chrome, Safari, Edge, Opera */
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        /* Firefox */
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
    },
}));

const WeatherWindowLength = (props) => {
    const { alertOperators, handlePropertyChange, alert, validation } = props;

    const classes = useStyle();

    const handleChange = (param) => (event) => {
        handlePropertyChange(param)(event.target.value);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <CustomInputLabel>Weather Window Length</CustomInputLabel>
            </Grid>
            <Grid item xs={4} sm={4}>
                <FormControl
                    fullWidth
                    margin="none"
                    variant="outlined"
                    size="small"
                    error={!validation.weatherWindowLengthOperator.valid}
                >
                    <CustomSelect
                        type="text"
                        name="weatherWindowLengthOperator"
                        onChange={handleChange("weatherWindowLengthOperator")}
                        value={alert.weatherWindowLengthOperator}
                        renderValue={(x) => translateAlertOperator(x.type)}
                    >
                        {alertOperators.map((operator) => (
                            <MenuItem key={operator} value={operator}>
                                {translateAlertOperator(operator.type)}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                    <FormHelperText>
                        {validation.weatherWindowLengthOperator.message}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={2} sm={2} />
            <Grid item xs={6} sm={6}>
                <CustomTextField
                    variant="outlined"
                    margin="none"
                    size="small"
                    type="number"
                    name="weatherWindowLength"
                    onChange={handleChange("weatherWindowLengthValue")}
                    value={
                        alert.weatherWindowLengthValue
                            ? alert.weatherWindowLengthValue
                            : ""
                    }
                    error={!validation.weatherWindowLengthValue.valid}
                    helperText={validation.weatherWindowLengthValue.message}
                    InputProps={{
                        classes: {
                            input: classes.numberInputInput,
                        },
                        endAdornment: "hour(s)",
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default WeatherWindowLength;
