import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText, MenuItem } from "@material-ui/core";
import { CustomSelect } from "../../../common/restyled-mui/CustomInputs";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        marginTop: theme.spacing(1),
    },
    field: {
        flex: 1,
    },
    dash: {
        padding: theme.spacing(1.75),
    },
}));

const AnalysisHeights = (props) => {
    const classes = useStyles(props);
    const { value, error, helperText, analyticsHeights, handleChange } = props;

    useEffect(() => {
        if (analyticsHeights.siteId) {
            if (!analyticsHeights.list.includes(value) && value !== "") {
                handleChange("");
            }
        }
    }, [analyticsHeights, value, handleChange]);

    const handleTextFieldChange = (e) => {
        handleChange(e.target.value);
    };

    return (
        <FormControl
            variant="outlined"
            size="small"
            className={classes.field}
            margin="dense"
            fullWidth
        >
            <CustomSelect
                name={"Analysis Height Level"}
                disabled={analyticsHeights.list.length === 0}
                value={value}
                onChange={handleTextFieldChange}
                error={error}
                renderValue={(x) => x}
            >
                {analyticsHeights.list.map((height, index) => {
                    return (
                        <MenuItem key={index} value={height}>
                            {height}
                        </MenuItem>
                    );
                })}
            </CustomSelect>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default AnalysisHeights;
