import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeIcon } from "../assets/icons/fontawesome/closeIcon";

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        color: theme.palette.generalUi.icons,
        fontSize: 21,
    },
    closeButtonText: {
        marginLeft: theme.spacing(2),
        color: theme.palette.generalUi.icons,
        fontSize: 14,
    },
    closeButton: {
        marginRight: -14,
    },
}));

const CloseButton = (props) => {
    const classes = useStyles(props);
    const { onClick, small } = props;

    return !small ? (
        <Button onClick={onClick}>
            <FontAwesomeIcon
                icon={closeIcon}
                className={classes.closeIcon}
                size={"lg"}
            />
            <Typography variant="h4" className={classes.closeButtonText}>
                Close
            </Typography>
        </Button>
    ) : (
        <IconButton onClick={onClick} className={classes.closeButton}>
            <FontAwesomeIcon
                icon={closeIcon}
                className={classes.closeIcon}
                size={"sm"}
            />
        </IconButton>
    );
};

export default CloseButton;
