import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { getSites } from "../../redux/actions/sites";
import { push } from "connected-react-router";
import Map from "./Map";

const useStyles = makeStyles((theme) => ({
    loading: {
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "50%",
        left: "50%",
    },
    root: {
        height: "100%",
        overflow: "hidden",
        flex: 1,
    },
    grid: {
        width: `100%`,
        height: `100%`,
    },
}));

const mapStateToProps = (state, ownProps) => {
    return {
        sites: state.sites,
        favoriteSites: state.favoriteSites,
        sitesView: state.sitesView,
        searchedSites: state.searchedSites,
        mapState: state.mapState,
    };
};

const MapOverview = (props) => {
    const classes = useStyles(props);
    const { sites, favoriteSites, searchedSites, sitesView, mapState } = props;

    const findSiteList = () => {
        if (searchedSites) {
            return searchedSites;
        } else {
            switch (sitesView) {
                case 0:
                    return favoriteSites;
                case 1:
                    return sites;
                default:
                    return [];
            }
        }
    };

    return (
        <div className={classes.root}>
            {sites && favoriteSites ? (
                <Map
                    fullScreen={true}
                    allSites={sites}
                    sites={findSiteList()}
                    selectedSite={null}
                    isMapLoading={Boolean(!sites)}
                    mapState={mapState}
                    isHomepage={true}
                />
            ) : (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, { getSites, push })(MapOverview);
