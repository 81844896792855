const deploymentCustomerId = process.env.REACT_APP_DEPLOYMENT_CUSTOMER;
const defaultCustomerId = "default";

const customers = [
    {
        id: defaultCustomerId,
        wallpaperImages: [
            "assets/customers/default/login_image_1.jpg",
            "assets/customers/default/login_image_2.jpg",
            "assets/customers/default/login_image_3.jpg",
        ],
        logo: "assets/customers/default/conwx_metocean_logo.svg",
    },
    {
        id: "vestas",
        wallpaperImages: [
            "assets/customers/vestas/Vestas_Picture1.png",
            "assets/customers/vestas/Vestas_Picture2.png",
            "assets/customers/vestas/Vestas_Picture3.png",
            "assets/customers/vestas/Vestas_Picture4.png",
        ],
        logo: "assets/customers/vestas/Vestas logo_RGB_100.jpg",
    },
];

export const getLoginCustomer = () => {
    const deploymentCustomer = customers.find(
        (customer) =>
            customer.id.toLowerCase() === deploymentCustomerId.toLowerCase()
    );
    return deploymentCustomer
        ? deploymentCustomer
        : customers.find((customer) => customer.id === defaultCustomerId);
};
