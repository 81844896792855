import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import MobileBottomBar from "../features/navigation/MobileBottomBar";
import MapOverview from "../features/forecasts-map/MapOverview";
import SitesPanel from "../features/forecasts-panel/SitesPanel";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    innerContainer: {
        flex: 1,
        zIndex: 0,
        position: "relative",
    },
}));

const ForecastsPage = (props) => {
    const classes = useStyles();
    const container = useRef();
    const { mobile, tablet, navContainer, ...otherProps } = props;
    const [view, setView] = useState(0);
    const toggleView = (view) => {
        setView(view);
    };

    return !mobile ? (
        <SitesPanel
            container={navContainer}
            toggleable={tablet}
            selectedSite={props?.match?.params?.id}
        >
            <MapOverview {...otherProps} />
        </SitesPanel>
    ) : (
        <div className={classes.container}>
            <div ref={container} className={classes.innerContainer}>
                {view === 0 && (
                    <SitesPanel
                        fullscreen={true}
                        toggleable={false}
                        container={container}
                    />
                )}
                {view === 1 && <MapOverview />}
            </div>
            <MobileBottomBar view={view} toggleView={toggleView} />
        </div>
    );
};

export default ForecastsPage;
