import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 24,
        borderTop: `1px solid ${theme.palette.menu.border}`,
        backgroundColor: theme.palette.table.white,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
    },
    text: {
        fontSize: 13,
    },
}));

const ReportsPanelBottomCount = (props) => {
    const classes = useStyles(props);
    const { count } = props;

    const renderText = (count) => {
        if (count === 1) {
            return `1 Report`;
        } else {
            return `${count} Reports`;
        }
    };

    return (
        <>
            {Number.isInteger(count) && (
                <div className={classes.root}>
                    <Typography className={classes.text}>
                        {renderText(count)}
                    </Typography>
                </div>
            )}
        </>
    );
};

export default ReportsPanelBottomCount;
