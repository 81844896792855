import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    CircularProgress,
    useTheme,
    Divider,
} from "@material-ui/core";
import moment from "moment";
import { CustomButton } from "../../common/restyled-mui/CustomButton";
import {
    getReports,
    getReportTypes,
    updateReport,
    createReport,
    deleteReport,
} from "../../redux/actions/reports";
import { getSites } from "../../redux/actions/sites";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteIcon } from "../../assets/icons/fontawesome/deleteIcon";
import { closeIcon } from "../../assets/icons/fontawesome/closeIcon";
import { checkIcon } from "../../assets/icons/fontawesome/checkIcon";
import { getSettingsStyles } from "../../styles/settingsStyles";
import { handleValidationUpdate } from "../../utils/validation";
import { initialValidation, validate } from "./validation";

import Api from "../../api";
import ReportForm from "./Form";
import NotFoundReport from "./NotFoundReport";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    headerContainerDetails: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1.5),
    },
    scrollContainer: {
        overflow: "auto",
        flex: 1,
    },
}));

const mapStateToProps = (state, ownProps) => ({
    reports: state.reports,
    reportTypes: state.reportTypes,
    sites: state.sites,
    apiLoading: state.status.loading,
    queries: state.router.location.query,
});

const Report = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState(null);
    const [charts, setCharts] = useState({
        list: [],
        pointId: null,
    });
    const [submitted, setSubmitted] = useState(false);
    const [validation, setValidation] = useState(initialValidation);
    const {
        reports,
        reportTypes,
        getReportTypes,
        sites,
        getSites,
        updateReport,
        createReport,
        deleteReport,
        match,
        push,
        apiLoading,
        creating,
        setConfirmation,
        resetConfirmation,
    } = props;

    useEffect(() => {
        if (!reportTypes) {
            getReportTypes();
        }
    }, [reportTypes, getReportTypes]);

    useEffect(() => {
        if (!sites) {
            getSites();
        }
    }, [sites, getSites]);

    useEffect(() => {
        if (reports) {
            if (!reports.find((x) => x.id.toString() === match.params.id)) {
                setReport(null);
            }
        }
    }, [reports, match.params.id]);

    useEffect(() => {
        if (charts.pointId !== report?.pointId && report) {
            if (report.pointId) {
                Api.Reports.getChartsForSite(report.pointId)
                    .then((res) => {
                        const newCharts = res.data;
                        setCharts({
                            list: newCharts,
                            pointId: report.pointId,
                        });
                        if (
                            !newCharts.some(
                                (x) =>
                                    x.parameterId ===
                                    report.parameter?.parameterId
                            )
                        ) {
                            setReport((prevReport) => ({
                                ...prevReport,
                                reportCharts: prevReport.reportCharts.filter(
                                    (x) =>
                                        newCharts.some(
                                            (y) => y.chartId === x.chartId
                                        )
                                ),
                            }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setCharts({
                            list: [],
                            pointId: report.pointId,
                        });
                    });
            } else {
                setCharts({
                    list: [],
                    pointId: null,
                });
            }
        }
    }, [charts.pointId, report]);

    useEffect(() => {
        const initReport = (report) => {
            const site = sites.find((x) => x.id === report.pointId);
            return {
                ...report,
                site,
            };
        };

        const setNewReport = () => {
            setReport({
                isActive: true,
                name: "",
                description: "",
                pointId: "",
                expireDate: moment().add(2, "months").format(),
                reportTypeId: reportTypes[0].id,
                daysForecast: 1,
                inLocalTime: false,
                sendTime: ["12:00"],
                sendDays: [],
                recipients: [""],
                reportCharts: [],
            });
            setLoading(false);
        };

        if (reports && reportTypes && sites) {
            if (creating) {
                if (!report || report.id) {
                    setNewReport();
                }
            } else {
                if (!report || report.id?.toString() !== match.params.id) {
                    const report = reports.find(
                        (x) => x.id.toString() === match.params.id
                    );
                    if (report) {
                        //fetch complete report info
                        Api.Reports.getReport(report.id).then((res) => {
                            const fullReport = res.data;
                            setReport(fullReport);
                            setValidation(initialValidation);
                            setReport(initReport(fullReport));
                            setLoading(false);
                        });

                        //setValidation(initialValidation);
                        //setReport(initReport(report));
                        //setLoading(false);
                    } else {
                        setLoading(false);
                    }
                }
            }
        }
    }, [reports, match.params.id, creating, reportTypes, sites, report]);

    useEffect(() => {
        if (submitted && !apiLoading) {
            if (creating) {
                push("/reports");
            }
            setSubmitted(false);
        }
    }, [submitted, apiLoading, creating, push]);

    const handlePropertyChange = (state) => (value) => {
        setReport({
            ...report,
            [state]: value,
        });
        handleValidationUpdate(setValidation, state);
    };

    const handleSubmit = () => {
        const valid = validate(setValidation, report, sites);
        if (valid) {
            updateReport(report);
            setSubmitted(true);
        }
    };

    const handleCreate = () => {
        const valid = validate(setValidation, report, sites);
        if (valid) {
            const reportWithoutId = { ...report };
            delete reportWithoutId.id;
            createReport(reportWithoutId);
            setSubmitted(true);
        }
    };

    const handleCancel = () => {
        push(`/reports`);
    };

    const handleDelete = () => {
        setConfirmation({
            open: true,
            confirmCallback: () => {
                handleTheReport(report.id);
                resetConfirmation();
            },
            cancelCallback: () => resetConfirmation(),
            title: "Delete Report",
            content: (
                <Typography>
                    Are you sure you want to delete <b>{report.name}</b>?
                </Typography>
            ),
            color: theme.palette.error.main,
            type: "warning",
        });
    };

    const handleTheReport = (id) => {
        push(`/reports`);
        deleteReport(id);
        setSubmitted(true);
    };

    const renderActionButtons = () => {
        return (
            <div className={classes.actionButtonsDetails}>
                {!creating && (
                    <CustomButton
                        onClick={handleDelete}
                        disabled={apiLoading}
                        variant="text"
                        color="inherit"
                        className={classes.actionButton}
                    >
                        <FontAwesomeIcon
                            icon={deleteIcon}
                            className={classes.actionIcon}
                        />
                        Delete
                    </CustomButton>
                )}
                <CustomButton
                    onClick={handleCancel}
                    disabled={apiLoading}
                    variant="text"
                    color="inherit"
                    className={classes.actionButton}
                >
                    <FontAwesomeIcon
                        icon={closeIcon}
                        className={classes.actionIcon}
                    />
                    Cancel
                </CustomButton>
                <CustomButton
                    onClick={creating ? handleCreate : handleSubmit}
                    disabled={apiLoading}
                    variant="text"
                    color="inherit"
                    className={classes.actionButton}
                >
                    <FontAwesomeIcon
                        icon={checkIcon}
                        className={classes.actionIcon}
                    />
                    {apiLoading ? <CircularProgress size={14} /> : "Submit"}
                </CustomButton>
            </div>
        );
    };

    return loading ? (
        <div className={classes.loading}>
            <CircularProgress />
        </div>
    ) : report ? (
        <>
            <div className={classes.headerContainerDetails}>
                {renderActionButtons()}
            </div>
            <Divider orientation="horizontal" variant="fullWidth" />
            <div className={classes.scrollContainer}>
                <ReportForm
                    reportTypes={reportTypes}
                    sites={sites}
                    charts={charts}
                    report={report}
                    validation={validation}
                    handlePropertyChange={handlePropertyChange}
                    title={
                        creating
                            ? "New Report"
                            : reports.find(
                                  (x) => x.id.toString() === match.params.id
                              )?.name
                    }
                />
            </div>
        </>
    ) : (
        <NotFoundReport />
    );
};
export default connect(mapStateToProps, {
    getReports,
    getReportTypes,
    updateReport,
    createReport,
    deleteReport,
    getSites,
    push,
})(Report);
