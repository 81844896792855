import React, { useEffect } from "react";
import {
    makeStyles,
    Typography,
    Grid,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import {
    CustomInputLabel,
    CustomTextField,
    CustomSelect,
} from "../../common/restyled-mui/CustomInputs";
import { GreenSwitch } from "../../common/restyled-mui/GreenSwitch";
import RecipientInputs from "./form-inputs/RecipientInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSettingsStyles } from "../../styles/settingsStyles";

import { calendarIcon } from "../../assets/icons/fontawesome/calendarIcon";
import { addIcon } from "../../assets/icons/fontawesome/addIcon";

import ParameterThreshold from "./form-inputs/ParameterThreshold";
import WeatherWindowLength from "./form-inputs/WeatherWindowLength";
import { TertiaryButton } from "../../common/restyled-mui/CustomButton";

const useStyles = makeStyles((theme) => ({
    ...getSettingsStyles(theme),
    formContainer: {
        padding: theme.spacing(3, 7),
        maxWidth: 900,
    },
    textFieldGridContainer: {
        display: "flex",
        flexDirection: "column",
    },
    textFieldFormContainer: {
        flex: 1,
    },
    textField: {
        height: "100%",
        paddingBottom: theme.spacing(1),
        display: "block",
    },
    endAdornmentIcon: {
        height: 21,
        width: 21,
        color: theme.palette.generalUi.icons,
    },
    numberInputInput: {
        "-webkit-appearance": "none",
        margin: 0,
        /* Chrome, Safari, Edge, Opera */
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        /* Firefox */
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
    },
    calendarField: {
        paddingRight: 0,
    },
    buttonIcon: {
        color: theme.palette.primary.main,
        height: 18,
        width: 14,
        marginRight: theme.spacing(2),
    },
    button: {
        width: 173,
        fontWeight: 600,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    bottomMargin: {
        marginBottom: theme.spacing(2),
    },
}));

const AlertForm = (props) => {
    const classes = useStyles();
    const {
        alert,
        alertOperators,
        alertLogics,
        alertFrequencies,
        handlePropertyChange,
        handleParametersAdd,
        handleParametersParamChange,
        handleParametersOperatorChange,
        handleParametersValueChange,
        handleParametersLogicChange,
        handleParametersDelete,
        handleSiteChange,
        sites,
        title,
        validation,
        parameters,
        user,
        creating,
    } = props;

    const handleTextInputChange = (state) => (e) => {
        handlePropertyChange(state)(e.target.value);
    };

    const toggleProperty = (state) => (_e) => {
        handlePropertyChange(state)(!alert[state]);
    };

    const handleDateChange = (state) => (_date, value) => {
        if (value) {
            handlePropertyChange(state)(value);
        }
    };

    return (
        <Grid container className={classes.formContainer}>
            <Grid item xs={12} md={12} lg={12}>
                <div className={classes.titleContainerDetails}>
                    <Typography variant="h2">{title}</Typography>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <GreenSwitch
                                    checked={alert.isActive}
                                    onChange={toggleProperty("isActive")}
                                />
                            }
                            label="Active"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <CustomInputLabel>Alert Name</CustomInputLabel>
                        <CustomTextField
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="text"
                            onChange={handleTextInputChange("name")}
                            value={alert.name}
                            inputProps={{ maxLength: 30 }}
                            error={!validation.name.valid}
                            helperText={validation.name.message}
                            name={"alertName"}
                        />
                        <CustomInputLabel>Site</CustomInputLabel>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Autocomplete
                                options={sites.map((x) => ({
                                    id: x.id,
                                    name: x.name,
                                }))}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                value={alert.site}
                                getOptionSelected={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={handleSiteChange}
                                getOptionLabel={(option) => option.name || ""}
                                renderInput={(params) => {
                                    return (
                                        <CustomTextField
                                            {...params}
                                            name={"site"}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            type="text"
                                            InputProps={{
                                                ...params.InputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                            error={!validation.site.valid}
                                            helperText={validation.site.message}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                        <WeatherWindowLength
                            alertOperators={alertOperators}
                            handlePropertyChange={handlePropertyChange}
                            alert={alert}
                            validation={validation}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        className={classes.textFieldGridContainer}
                    >
                        <CustomInputLabel>Description</CustomInputLabel>
                        <CustomTextField
                            name={"description"}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            type="text"
                            multiline
                            rows={5}
                            className={classes.textFieldFormContainer}
                            InputProps={{
                                className: classes.textField,
                            }}
                            onChange={handleTextInputChange("description")}
                            value={alert.description}
                            inputProps={{
                                maxLength: 500,
                                className: classes.textArea,
                            }}
                            error={!validation.description.valid}
                            helperText={validation.description.message}
                        />
                    </Grid>
                    <>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography
                                variant="h4"
                                className={classes.formSubtitle}
                            >
                                Parameter and Threshold Selection
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {alert.parameters.map((_, index) => {
                                const uniqueID = index + alert.id;
                                return (
                                    <>
                                        <ParameterThreshold
                                            key={uniqueID}
                                            parameters={parameters}
                                            handleParametersParamChange={
                                                handleParametersParamChange
                                            }
                                            handleParametersOperatorChange={
                                                handleParametersOperatorChange
                                            }
                                            handleParametersValueChange={
                                                handleParametersValueChange
                                            }
                                            handleParametersLogicChange={
                                                handleParametersLogicChange
                                            }
                                            id={index}
                                            handleParametersDelete={
                                                handleParametersDelete
                                            }
                                            validation={validation}
                                            handleTextInputChange={
                                                handleTextInputChange
                                            }
                                            alertOperators={alertOperators}
                                            alertLogics={alertLogics}
                                            alert={alert}
                                            showDelete={
                                                alert.parameters.length > 1
                                            }
                                            showHeader={index === 0}
                                        />
                                        {index <
                                            alert.parameters.length - 1 && (
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Typography
                                                    variant="body1"
                                                    className={
                                                        classes.bottomMargin
                                                    }
                                                >
                                                    and
                                                </Typography>
                                            </Grid>
                                        )}
                                    </>
                                );
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <TertiaryButton
                                onClick={handleParametersAdd}
                                className={classes.button}
                            >
                                <FontAwesomeIcon
                                    icon={addIcon}
                                    className={classes.buttonIcon}
                                />
                                Add new parameter
                            </TertiaryButton>
                        </Grid>
                    </>
                    <Grid item xs={12} md={12} lg={6}>
                        <Typography
                            variant="h4"
                            className={classes.formSubtitle}
                        >
                            Settings
                        </Typography>
                        <CustomInputLabel>Expiry Date</CustomInputLabel>
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            style={{ width: 164 }}
                            margin="dense"
                            value={alert.expiryDate}
                            onChange={handleDateChange("expiryDate")}
                            disablePast
                            maxDate={moment().add(5, "y").toDate()}
                            format="yyyy-MM-DD"
                            InputProps={{
                                className: classes.calendarField,
                            }}
                            keyboardIcon={
                                <FontAwesomeIcon
                                    icon={calendarIcon}
                                    className={classes.endAdornmentIcon}
                                />
                            }
                            error={!validation.expiryDate.valid}
                            helperText={validation.expiryDate.message}
                        />
                        <CustomInputLabel>Updates per day</CustomInputLabel>
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                            style={{ width: 164 }}
                            error={!validation.frequency.valid}
                        >
                            <CustomSelect
                                inputProps={{
                                    margin: "dense",
                                }}
                                type="text"
                                value={alert.frequency}
                                onChange={handleTextInputChange("frequency")}
                                renderValue={(x) => x.frequency}
                                name={"frequency"}
                            >
                                {alertFrequencies.map((frequency) => {
                                    return (
                                        <MenuItem
                                            key={frequency.id}
                                            value={frequency}
                                            className={classes.selectMenuItem}
                                        >
                                            {frequency.frequency}
                                        </MenuItem>
                                    );
                                })}
                            </CustomSelect>
                            <FormHelperText>
                                {validation.operator.message}
                            </FormHelperText>
                        </FormControl>
                        <RecipientInputs
                            validation={validation.recipients}
                            recipients={alert.recipients}
                            updateRecipients={handlePropertyChange(
                                "recipients"
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default AlertForm;
