import React, { useEffect, useState } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import useResizeAware from "react-resize-aware";
import { Typography, useMediaQuery } from "@material-ui/core";
import { ChartSeriesItem } from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";
import UIIcons from "../../assets/icons/ui-icons";
import "@progress/kendo-theme-default/dist/all.css";
import { Path, Text } from "@progress/kendo-drawing";
import CenteredLoading from "../../common/CenteredLoading";
import "../../styles/charts.css";
import MiniChart from "./MiniChart";
import TwinCharts from "./TwinCharts";
import { drawArrow } from "../../common/charts/drawArrow";
import { returnLabelFont } from "../../common/charts/chartStyles";
import {
    negativeValuesInChart,
    findRoundedMinMax,
    renderEqualMinMax,
    getUnitValueMinMax,
    getNumberOfCategories,
    getMostCommonUnit,
} from "../../utils/forecastCharts";
import { removeTransparencyFromHex } from "../../utils/removeTransparencyFromHex";

const targetSteps = 8;
const miniChartHeightDesktop = 56;
const miniChartHeightTablet = 48;
const miniChartHeightMobile = 40;
const miniChartBottomSpacing = 10;
const miniChartWeatherWindow = 10;
const mainWeatherWindow = 12;
const mainWeatherWindowPadding = 10;

const styles = (theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    topCharts: {
        flex: 1,
    },
    tooltip: {
        padding: theme.spacing(2, 3),
    },
    tooltipTitle: {
        color: theme.palette.primary.main,
        fontSize: 11,
        fontWeight: 700,
    },
    tooltipList: {
        padding: theme.spacing(1, 0),
    },
    tooltipListItem: {
        display: "flex",
        padding: theme.spacing(0.25, 0),
    },
    tooltipListItemTitle: {
        flex: 1,
        color: theme.palette.text.defaultSub,
        fontSize: 11,
        fontWeight: 400,
        paddingRight: theme.spacing(5),
    },
    tooltipListItemValue: {
        color: theme.palette.text.default,
        fontSize: 11,
        fontWeight: 600,
    },
});

const ChartsComponent = (props) => {
    const {
        classes,
        siteData,
        timezoneOffset,
        loading,
        pdfDate,
        setPDFReady,
        pdfReady,
        thresholdsVisible,
        chartSets,
    } = props;
    const theme = useTheme();
    const [resizeListener, sizes] = useResizeAware();
    const [hiddenSeries, setHiddenSeries] = useState([]);
    const [minMaxView, setMinMaxView] = useState({ min: 0, max: 100000000 });
    const [selectionLoading, setSelectionLoading] = useState(true);
    const [mainChartAreaSize, setMainChartAreaSize] = useState([]);
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        setMainChartAreaSize([]);
    }, [sizes]);

    useEffect(() => {
        if (siteData.chartSets.length > 0) {
            const length = siteData.chartSets[0].groups[0].data.length;
            const min = moment
                .unix(siteData.chartSets[0].groups[0].data[0].time)
                .toDate();
            const max = moment
                .unix(siteData.chartSets[0].groups[0].data[length - 1].time)
                .add(1, "hour")
                .toDate();
            setMinMaxView({
                min,
                max,
            });
            setSelectionLoading(false);
        }
    }, [siteData.chartSets]);

    const handleLegendOnClick = (e) => {
        if (hiddenSeries.some((x) => x === e.series.id)) {
            setHiddenSeries(hiddenSeries.filter((x) => x !== e.series.id));
        } else {
            setHiddenSeries([...hiddenSeries, e.series.id]);
        }
    };

    const createArrowData = (chartData, group, unit) => {
        const negativeValuesExist = negativeValuesInChart(chartData);
        const minMax = negativeValuesExist
            ? findRoundedMinMax(renderEqualMinMax(chartData, unit), targetSteps)
            : findRoundedMinMax(
                  getUnitValueMinMax(chartData.groups, unit, true),
                  targetSteps
              );
        const maxY = minMax.max;
        const minArrowWidth = 14;
        const maxArrowWidth = 22;
        let data = [];
        let skip = 1;
        let arrowSize = 10;
        const borderWidth = 200;
        const paddingBetweenArrows = 6;
        const containerWidth = sizes.width - borderWidth;
        const maxNumberOfArrows =
            containerWidth / (minArrowWidth + paddingBetweenArrows);
        const numberOfCategories = getNumberOfCategories(
            group.data,
            minMaxView.min,
            minMaxView.max
        );
        const arrowsFit = Boolean(maxNumberOfArrows > numberOfCategories);
        if (arrowsFit) {
            const largestArrowWidth =
                Math.floor(containerWidth / numberOfCategories) -
                paddingBetweenArrows;
            if (largestArrowWidth > maxArrowWidth) {
                arrowSize = maxArrowWidth;
            } else {
                arrowSize = largestArrowWidth;
            }
        } else {
            skip = Math.ceil(numberOfCategories / maxNumberOfArrows);
            arrowSize = minArrowWidth;
        }

        let originRotation;
        switch (group.arrowOriginOrientation) {
            case "Down":
                originRotation = 0;
                break;
            case "Up":
                originRotation = 180;
                break;
            default:
                originRotation = 0;
        }

        group.data.forEach((x, i) => {
            let visible = false;
            if (Number.isInteger(i / skip)) {
                visible = true;
            }
            data.push({
                time: x.time,
                value: maxY,
                rotation: x.value + originRotation,
                size: arrowSize,
                visible,
            });
        });
        return data;
    };

    const renderSeriesOfChart = (chartData, type, props) => {
        return chartData.groups.map((group, index) => {
            let chartProps;
            switch (group.plotType.toLowerCase()) {
                case "rangearea":
                    // plot line for the borders of the range area
                    // this is included in chart mostly to include P10 and P90 values in tooltip
                    chartProps = {
                        type: "line",
                        style: "smooth",
                        markers: { visible: false },
                        stack: false,
                        axis: group.unit + type,
                        data: group.data,
                        decimals: group.decimals,
                        width: 1,
                        legend: null,
                    };
                    break;
                case "rangearea2":
                    chartProps = {
                        type: "rangeArea",
                        fromField: "min",
                        toField: "max",
                        axis: group.unit + type,
                        data: group.data,
                        decimals: group.decimals,
                    };
                    break;
                case "line":
                    chartProps = {
                        type: "line",
                        style: "smooth",
                        markers: { visible: true },
                        stack: false,
                        axis: group.unit + type,
                        data: group.data,
                        decimals: group.decimals,
                        width: mobile ? 1 : 2,
                    };
                    break;
                case "line2":
                    chartProps = {
                        type: "line",
                        style: "smooth",
                        markers: mobile
                            ? { visible: false }
                            : { visible: true },
                        stack: false,
                        axis: group.unit + type,
                        data: group.data,
                        decimals: group.decimals,
                        width: mobile ? 2 : 1,
                    };
                    break;
                case "bar":
                    chartProps = {
                        type: "column",
                        spacing: -1,
                        axis: group.unit + type,
                        data: group.data,
                        decimals: group.decimals,
                    };
                    break;
                case "point":
                    chartProps = {
                        type: "line",
                        stack: false,
                        style: "smooth",
                        markers: mobile
                            ? { visible: false }
                            : { visible: true },
                        axis: group.unit + type,
                        data: group.data,
                        decimals: group.decimals,
                        width: mobile ? 1 : 0,
                    };
                    break;
                case "arrow":
                    const commonUnit = getMostCommonUnit(chartData.groups);
                    chartProps = {
                        type: "column",
                        spacing: 0,
                        arrow: true,
                        data: createArrowData(chartData, group, commonUnit),
                        decimals: group.decimals,
                        axis: commonUnit + type,
                        visual: (props) => drawArrow(props),
                    };
                    break;
                default:
                    chartProps = null;
            }
            if (chartProps) {
                return (
                    <ChartSeriesItem
                        key={group.groupId}
                        pane={chartData.chartType.toString()}
                        tooltip={{ visible: true }}
                        name={`${group.groupName} [${group.unit}]`}
                        color={`#${removeTransparencyFromHex(group.color)}`}
                        id={group.groupId}
                        visible={!hiddenSeries.some((x) => x === group.groupId)}
                        {...chartProps}
                        {...props}
                    />
                );
            } else {
                return null;
            }
        });
    };

    const createArrayOfMidnights = (min, max) => {
        let currentDate;
        if (
            moment(min)
                .add(timezoneOffset, "ms")
                .startOf("day")
                .isSame(moment(min).add(timezoneOffset, "ms"))
        ) {
            currentDate = moment(min).startOf("day");
        } else {
            currentDate = moment(min)
                .add(timezoneOffset, "ms")
                .add(1, "days")
                .startOf("day");
        }

        // Take into account the local offset and add it to the
        // current date
        const localOffset = moment().utcOffset();
        currentDate.add(localOffset, "m");

        let midnights = [];
        while (currentDate.isBefore(moment(max))) {
            midnights.push(
                moment(currentDate).subtract(timezoneOffset, "ms").toDate()
            );
            currentDate.add(1, "days");
        }
        return midnights;
    };

    const renderDayLine = (
        date,
        categoryAxis,
        valueSlot,
        group,
        labelAlignment
    ) => {
        const line = new Path({
            stroke: {
                color: theme.palette.generalUi.lines,
                width: 1,
            },
        });
        // get the coordinates of the value at which the plot band will be rendered
        const categorySlot = categoryAxis.slot(date);
        line.moveTo(categorySlot.origin.x, valueSlot.origin.y).lineTo(
            categorySlot.origin.x,
            valueSlot.bottomRight().y
        );

        const padding = 4;
        const label = new Text(
            moment(date).add(timezoneOffset, "ms").format("ddd"),
            [0, 0],
            {
                fill: {
                    color: theme.palette.generalUi.lines,
                },
                font: returnLabelFont(theme, 11, 400),
            }
        );
        //   console.log(label.options.content);
        const bbox = label.bbox();
        const labelBottomAlignedPosition = valueSlot.bottomLeft().y + padding;
        const labelTopAlignedPosition =
            valueSlot.origin.y - bbox.size.height - padding;
        const y =
            labelAlignment === "top"
                ? labelTopAlignedPosition
                : labelBottomAlignedPosition;
        label.position([categorySlot.origin.x, y]);

        group.append(line, label);
    };

    const renderDayLineByChartType = (
        chart,
        group,
        arrayOfMidnights,
        valueAxisName,
        categoryAxisName,
        labelAlignment
    ) => {
        const valueAxis = chart.findAxisByName(valueAxisName);
        const categoryAxis = chart.findAxisByName(categoryAxisName);
        const range = valueAxis.range();
        const valueSlot = valueAxis.slot(range.min, range.max);
        arrayOfMidnights.forEach((date) => {
            renderDayLine(date, categoryAxis, valueSlot, group, labelAlignment);
        });
    };

    const findMiniChartHeight = () => {
        if (mobile) {
            return miniChartHeightMobile;
        } else if (tablet) {
            return miniChartHeightTablet;
        } else {
            return miniChartHeightDesktop;
        }
    };

    const findMiniChartData = () => {
        const defaultGroupId = "225";
        //looking for groupId 225 (Wind Speed 10m) as this is the defined minichart group
        let group;
        for (let i = 0; i < siteData.chartSets.length; i++) {
            const chartSet = siteData.chartSets[i];
            const foundChart = chartSet.groups.find(
                (group) => group.groupId === defaultGroupId
            );
            if (foundChart) {
                group = foundChart;
            }
        }

        //return in same format as chartSet (for renderSeriesOfChart)
        if (group) {
            return {
                chartId: "mini",
                name: "Mini Chart",
                groups: [group],
                chartType: "mini",
            };
        } else {
            //if 225 doesn't exist (edge case) default to first group of upper chart
            return {
                chartId: "mini",
                name: "Mini Chart",
                groups: [upper.groups[0]],
                chartType: "mini",
            };
        }
    };

    const findChartDataByChartType = (chartType) => {
        //use chart set selection (from flyout)
        const chartData = siteData.chartSets.find(
            (x) =>
                x.chartType === chartType && x.chartId === chartSets[chartType]
        );
        if (chartData) {
            let rangeAreaCharts = chartData.groups.filter(
                (g) => g.plotType === "RangeArea"
            );
            // if there are any charts of type rangeArea,
            // merge them as a single group that has min and max value
            if (rangeAreaCharts.length !== 0) {
                // merge P10 and P90
                // we assume that there are only 2 values
                let rangeData = rangeAreaCharts[0].data.map((e, i) => {
                    return {
                        time: e.time,
                        min: e.value,
                        max: rangeAreaCharts[1].data[i].value,
                        pointId: e.pointId,
                    };
                });
                let newGroup = {
                    ...rangeAreaCharts[1],
                    data: rangeData,
                    plotType: "RangeArea2",
                    groupId: (rangeAreaCharts[1].groupId + ".1").toString(),
                    groupName: "P10 - P90 range",
                };
                let newGroupsList = [...chartData.groups, newGroup];
                newGroupsList.sort(
                    (a, b) =>
                        (parseFloat(a.groupId) > parseFloat(b.groupId) && 1) ||
                        -1
                );

                let newChartData = { ...chartData, groups: newGroupsList };
                return newChartData;
            } else {
                return chartData;
            }
        } else {
            //if selection doesn't exist on this site, show first of type
            return siteData.chartSets.find((x) => x.chartType === chartType);
        }
    };

    const weatherWindow = (data) => {
        let plotBands = [];
        for (let i = 0; i < data.length; i++) {
            const time = new Date(data[i].time * 1000);
            let color;
            switch (data[i].value) {
                case 2:
                    color = theme.palette.weatherWindow.alarm;
                    break;
                case 1:
                    color = theme.palette.weatherWindow.warning;
                    break;
                case 0:
                    color = theme.palette.weatherWindow.ok;
                    break;
                default:
                //do nothing
            }
            plotBands.push({
                from: time,
                to: time,
                color,
                opacity: 1,
            });
        }
        return plotBands;
    };

    const weatherWindowBar = siteData.chartSets.find((x) => x.chartType === 2);
    const weatherWindowData = weatherWindow(weatherWindowBar.groups[0].data);
    const upper = findChartDataByChartType(0);
    const lower = findChartDataByChartType(1);
    const miniChart = findMiniChartData();
    const miniChartHeight = findMiniChartHeight();
    const range = (minMaxView.max - minMaxView.min) / 1000;
    const categories =
        siteData.chartSets.length > 0
            ? siteData.chartSets[0].groups[0].data.map((x) =>
                  moment.unix(x.time).toDate()
              )
            : [];

    return (
        <>
            {resizeListener}
            {siteData.chartSets.length > 0 ? (
                <div className={classes.root}>
                    {!selectionLoading && !loading ? (
                        <>
                            <TwinCharts
                                range={range}
                                upper={upper}
                                lower={lower}
                                renderSeriesOfChart={renderSeriesOfChart}
                                handleLegendOnClick={handleLegendOnClick}
                                minMaxView={minMaxView}
                                sizes={sizes}
                                setMainChartAreaSize={setMainChartAreaSize}
                                mainChartAreaSize={mainChartAreaSize}
                                timezoneOffset={timezoneOffset}
                                hiddenSeries={hiddenSeries}
                                targetSteps={targetSteps}
                                miniChartHeight={miniChartHeight}
                                weatherWindowBar={weatherWindowBar}
                                weatherWindowData={weatherWindowData}
                                thresholdsVisible={thresholdsVisible}
                                mainWeatherWindow={mainWeatherWindow}
                                mainWeatherWindowPadding={
                                    mainWeatherWindowPadding
                                }
                                createArrayOfMidnights={createArrayOfMidnights}
                                pdfReady={pdfReady}
                                pdfDate={pdfDate}
                                setPDFReady={setPDFReady}
                            />
                            <MiniChart
                                miniChartWeatherWindow={miniChartWeatherWindow}
                                miniChartHeight={miniChartHeight}
                                miniChartBottomSpacing={miniChartBottomSpacing}
                                mobile={mobile}
                                categories={categories}
                                weatherWindowData={weatherWindowData}
                                minMaxView={minMaxView}
                                setMinMaxView={setMinMaxView}
                                pdfDate={pdfDate}
                                renderSeriesOfChart={renderSeriesOfChart}
                                miniChart={miniChart}
                                pdfReady={pdfReady}
                                createArrayOfMidnights={createArrayOfMidnights}
                                renderDayLineByChartType={
                                    renderDayLineByChartType
                                }
                            />
                        </>
                    ) : (
                        <CenteredLoading />
                    )}
                </div>
            ) : (
                <>
                    <UIIcons.NoDataIcon
                        className={classes.noData}
                        style={{ width: "100%" }}
                    />
                    <Typography className={classes.noDataText}>
                        Ingen data
                    </Typography>
                </>
            )}
        </>
    );
};

export default withStyles(styles)(ChartsComponent);
