import React from "react";
import { Tabs, Tab, withStyles } from "@material-ui/core";

export const CustomTab = withStyles((theme) => ({
    root: {
        minHeight: 24,
        padding: 0,
        fontSize: 13,
        minWidth: 0,
        color: theme.palette.generalUi.icons,
        backgroundColor: theme.palette.generalUi.panelBackground,
        transition: "color 150ms ease 0ms",
        fontWeight: 500,
        "&$selected": {
            color: theme.palette.common.white,
        },
        "&:last-child": {
            borderLeft: `1px solid ${theme.palette.generalUi.borders}`,
        },
    },
    fullWidth: {
        maxWidth: "initial",
    },
    selected: {},
    wrapper: {
        zIndex: 1,
        height: `calc(100% - ${theme.spacing(0.25)}px)`,
        marginBottom: theme.spacing(0.25),
    },
}))((props) => <Tab disableRipple {...props} />);

export const CustomTabs = withStyles((theme) => ({
    root: {
        minHeight: 24,
        height: 24,
    },
    indicator: {
        height: 24,
        zIndex: 0,
        transition: "all 150ms ease 0ms",
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
    },
}))(Tabs);
