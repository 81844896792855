import React, { useEffect, useRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Navigation from "./features/navigation/Navigation";
import StatusSnackbar from "./common/StatusSnackbar";
import Settings from "./pages/Settings";
import Wiki from "./pages/Wiki";
import SiteDetails from "./pages/SiteDetails";
import SitesPanel from "./features/forecasts-panel/SitesPanel";
import ForecastsPage from "./pages/ForecastsPage";
import { getCustomer } from "./redux/actions/customer";
import AlertsPage from "./pages/AlertsPage";
import HWWAPage from "./pages/HWWAPage";
import ReportsPage from "./pages/ReportsPage";
import HindcastsPage from "./pages/HindcastsPage";
import ForecastUIPage from "./pages/ForecastUIPage";
import CenteredLoading from "./common/CenteredLoading";
import PageNotFound from "./pages/PageNotFound";
import {
    moduleIsAvailable,
    modules,
    getPrimaryModule,
    permissionsToModules,
} from "./utils/moduleConfig";
import Unauthorized from "./pages/Unauthorized";

const mapStateToProps = (state) => ({
    isAdministrator: state.user.isAdministrator,
    username: state.config ? state.config.username : "",
    customer: state.customer,
    permissions: state.user.permissions,
    mainPage: state.user.mainPage,
});

const App = (props) => {
    const theme = useTheme();
    const navContainer = useRef();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("md"));
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));
    const dispatch = useDispatch();
    const {
        logout,
        isAdministrator,
        username,
        customer,
        getCustomer,
        permissions,
        mainPage,
    } = props;

    const availableModules = customer.config.modules;
    const primaryModule = getPrimaryModule(
        permissionsToModules(permissions, isAdministrator),
        mainPage
    );

    const goToHomepage = () => {
        dispatch(push("/"));
    };

    useEffect(() => {
        if (!customer.id) {
            getCustomer();
        }
        // If the id is -1, it means there was an error getting the customer and should be redirected to the login page (log out)
        if (customer.id === -1) {
            logout();
        }
    }, [customer, getCustomer, logout]);

    return (
        <>
            {customer.id ? (
                <>
                    <StatusSnackbar />
                    <Navigation
                        logout={logout}
                        logoHidden={mobile}
                        username={username}
                        navContainerRef={navContainer}
                        availableModules={availableModules}
                    >
                        <Switch>
                            {primaryModule && (
                                <Redirect
                                    exact
                                    from="/"
                                    to={primaryModule.url}
                                />
                            )}
                            {moduleIsAvailable(
                                modules.forecasts.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={modules.forecasts.url}
                                    exact
                                    render={(props) => (
                                        <ForecastsPage
                                            {...props}
                                            mobile={mobile}
                                            tablet={tablet}
                                            navContainer={navContainer}
                                        />
                                    )}
                                />
                            )}
                            {moduleIsAvailable(
                                modules.forecasts.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={`${modules.forecasts.url}/site/:id`}
                                    render={(props) =>
                                        mobile ? (
                                            <SiteDetails {...props} />
                                        ) : (
                                            <SitesPanel
                                                container={navContainer}
                                                toggleable={tablet}
                                                selectedSite={
                                                    props?.match?.params?.id
                                                }
                                            >
                                                <SiteDetails
                                                    mobile={mobile}
                                                    mapToggleable={desktop}
                                                    {...props}
                                                />
                                            </SitesPanel>
                                        )
                                    }
                                />
                            )}
                            {moduleIsAvailable(
                                modules.alerts.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={modules.alerts.url}
                                    render={(props) => (
                                        <AlertsPage
                                            container={navContainer}
                                            mobile={mobile}
                                            tablet={tablet}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            {moduleIsAvailable(
                                modules.hwwa.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={modules.hwwa.url}
                                    render={(props) => (
                                        <HWWAPage
                                            container={navContainer}
                                            mobile={mobile}
                                            tablet={tablet}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            {moduleIsAvailable(
                                modules.reports.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={modules.reports.url}
                                    render={(props) => (
                                        <ReportsPage
                                            container={navContainer}
                                            mobile={mobile}
                                            tablet={tablet}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            {moduleIsAvailable(
                                modules.hindcasts.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={modules.hindcasts.url}
                                    render={(props) => (
                                        <HindcastsPage
                                            container={navContainer}
                                            mobile={mobile}
                                            tablet={tablet}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            {moduleIsAvailable(
                                modules.forecastui.id,
                                availableModules,
                                permissions
                            ) && (
                                <Route
                                    path={modules.forecastui.url}
                                    render={(props) => (
                                        <ForecastUIPage></ForecastUIPage>
                                    )}
                                />
                            )}
                            <Route
                                path={`/wiki`}
                                render={(props) => (
                                    <Wiki container={navContainer}></Wiki>
                                )}
                            />
                            <Route
                                path={`/settings`}
                                render={(props) => (
                                    <>
                                        {isAdministrator ? (
                                            <Settings {...props} />
                                        ) : (
                                            <Unauthorized
                                                goToHomepage={goToHomepage}
                                            />
                                        )}
                                    </>
                                )}
                            />
                            <Route
                                render={(props) => (
                                    <PageNotFound goToHomepage={goToHomepage} />
                                )}
                            />
                        </Switch>
                    </Navigation>
                </>
            ) : (
                <CenteredLoading />
            )}
        </>
    );
};

export default connect(mapStateToProps, { getCustomer })(App);
